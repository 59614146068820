import {
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Flex,
  ButtonGroup,
  Button,
  Input,
  FormControl,
  FormLabel,
  MenuDivider,
  MenuItemProps,
  IconButtonProps,
  Icon,
  IconProps,
} from '@chakra-ui/react';
import { getAndUpsertLink } from '@udecode/plate';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdLink } from 'react-icons/md';

import useEditorState from '../../../hooks/useEditorState';

interface LinkToolbarButtonProps extends Omit<IconButtonProps, 'aria-label'> {
  iconProps?: IconProps;
}

export const LinkToolbarButton: React.FC<LinkToolbarButtonProps> = ({
  iconProps,
  ...props
}) => {
  const editor = useEditorState();
  const { register, reset, handleSubmit } = useForm<{ linkUrl: string }>({
    defaultValues: { linkUrl: '' },
  });
  const [isMenuOpen, setMenuOpen] = useState(false);
  const closeMenu = () => {
    reset();
    setMenuOpen(false);
  };

  const handleInsertLinkURL = ({ linkUrl }: { linkUrl: string }) => {
    getAndUpsertLink(editor, async () => linkUrl);
    closeMenu();
  };

  return (
    <Menu
      placement='bottom'
      closeOnSelect={false}
      isOpen={isMenuOpen}
      onClose={closeMenu}
      onOpen={() => setMenuOpen(true)}
    >
      <MenuButton
        as={IconButton}
        variant='ghost'
        aria-label='Add Link'
        icon={<Icon as={MdLink} {...(iconProps || {})} />}
        {...props}
      />
      <MenuList bgColor='gray.700' fontSize='sm'>
        <LinkToolbarMenuItem fontSize='xs' fontWeight='bold' py='0'>
          Insert Link
        </LinkToolbarMenuItem>
        <MenuDivider />
        <LinkToolbarMenuItem>
          <FormControl>
            <FormLabel fontSize='xs'>Enter Link URL</FormLabel>
            <Input
              size='xs'
              bgColor='gray.800'
              _selected={{ bgColor: 'gray.800' }}
              {...register('linkUrl')}
            />
          </FormControl>
        </LinkToolbarMenuItem>
        <LinkToolbarMenuItem as={Flex} w='full' justifyContent='end'>
          <ButtonGroup size='sm'>
            <Button
              colorScheme='whiteAlpha'
              variant='outline'
              onClick={closeMenu}
            >
              Cancel
            </Button>
            <Button
              colorScheme='blue'
              onClick={handleSubmit(handleInsertLinkURL)}
            >
              Insert
            </Button>
          </ButtonGroup>
        </LinkToolbarMenuItem>
      </MenuList>
    </Menu>
  );
};

type LinkToolbarMenuItemProps = MenuItemProps;
const LinkToolbarMenuItem: React.FC<LinkToolbarMenuItemProps> = props => {
  return (
    <MenuItem
      _hover={{}}
      _focus={{}}
      _selected={{}}
      _active={{}}
      _disabled={{}}
      isDisabled
      color='white'
      {...props}
    />
  );
};

export default LinkToolbarButton;
