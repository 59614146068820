import { socket } from '../../config/socketIoInstance';
import { MessageDto } from '../../dto/messages.dto';
import { User } from '../../models';
import { Message } from '../../models/Message.model';
import { ValueCallback } from '../../types/common.type';
import {
  kMultipleStatusUpdateEvent,
  kNewMessageEvent,
  kStatusUpdateEvent,
  kStoppedTypingEvent,
  kTypingEvent,
} from './events/messages.events';

export interface OnTypingPayload {
  user: Pick<User, 'id' | 'firstName' | 'lastName'>;
}

export type OnTypingCallback = ValueCallback<OnTypingPayload, void>;

interface TypingEventPayload {
  messageThreadId: number;
  user: OnTypingPayload['user'];
}

type StatusUpdateEventPayload = Pick<Message, 'id' | 'status'>;
type MultipleStatusUpdateEventPayload = StatusUpdateEventPayload[];

export const typing = (payload: TypingEventPayload) =>
  socket.emit(kTypingEvent, payload);

export const stoppedTyping = (payload: TypingEventPayload) =>
  socket.emit(kStoppedTypingEvent, payload);

export const onSomeoneTyping = (callback: OnTypingCallback) =>
  socket.on(kTypingEvent, callback);

export const unsubscribeSomeoneTyping = (callback: OnTypingCallback) =>
  socket.off(kTypingEvent, callback);

export const onStoppedTyping = (callback: OnTypingCallback) =>
  socket.on(kStoppedTypingEvent, callback);

export const unsubscribeStoppedTyping = (callback: OnTypingCallback) =>
  socket.off(kStoppedTypingEvent, callback);

export const onNewMessage = (callback: ValueCallback<MessageDto>) =>
  socket.on(kNewMessageEvent, callback);

export const unsubscribeNewMessage = (callback: ValueCallback<MessageDto>) => {
  socket.off(kNewMessageEvent, callback);
};

export const onStatusUpdated = (
  callback: ValueCallback<StatusUpdateEventPayload>,
) => socket.on(kStatusUpdateEvent, callback);

export const unsubscribeStatusUpdated = (
  callback: ValueCallback<StatusUpdateEventPayload>,
) => {
  socket.off(kStatusUpdateEvent, callback);
};

export const onMultipleStatusUpdated = (
  callback: ValueCallback<MultipleStatusUpdateEventPayload>,
) => socket.on(kMultipleStatusUpdateEvent, callback);

export const unsubscribeMultipleStatusUpdated = (
  callback: ValueCallback<MultipleStatusUpdateEventPayload>,
) => {
  socket.off(kMultipleStatusUpdateEvent, callback);
};
