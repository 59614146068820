import { useCallback, useEffect } from 'react';
import { IconButton } from '@chakra-ui/button';
import { List } from '@chakra-ui/layout';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { MdAdd } from 'react-icons/md';
import * as _ from 'lodash';

import { PersonalMessageThread } from '../../../models/PersonalMessageThread.model';
import {
  CHAT_PAGE_ROUTE,
  kMessageThreadIdParamName,
} from '../../../routes/routeList';
import usePersonalMessages, {
  usePersonalMessageQueryClient,
} from '../hooks/usePersonalMessages';
import useDialog from '../../../hooks/useDialog';

import { markAllMessagesDeliveredForMultipleThreads } from '../../../apis/message-threads.api';
import {
  onNewPersonalMessageThread,
  unsubscribeNewPersonalMessageThread,
} from '../../../apis/socket.io/personal-message-threads.socket.io';

import { getUserDisplayName } from '../../../utils/user.utils';
import getLatestMessageText from '../utils/getLatestMessage.util';
import getMessageThreadDate from '../utils/getMessageThreadDate.util';

import UserAvatar from '../../../components/UserAvatar';
import FloatingActionButton from '../../../components/FloatingActionButton';
import NewPersonalChatDialog from '../components/NewPersonalChatDialog';
import MessageItem from '../components/MessageItem';
import useUnreadMessageThreads from '../hooks/useUnreadMessageThreads';
import { MessageDto } from '../../../dto/messages.dto';
import useNewMessageObserver from '../hooks/useNewMessageObserver';
import { sortMessageThreads } from './utils/sort-message-threads.util';
import EmptyPersonalChats from './components/EmptyPersonalChats';
import LoadingChats from './components/LoadingChats';
import { Icon } from '@chakra-ui/react';

const PersonalMessagesPanel = () => {
  const { personalMessageThreads, queryMetadata: personalChatsQuery } =
    usePersonalMessages();

  const { addOne: addPersonalChat, updateOne: updatePersonalChat } =
    usePersonalMessageQueryClient();

  const unreadMessageThreadMap = useUnreadMessageThreads(
    personalMessageThreads,
  );

  const history = useHistory();
  const goToChat = (messageThreadId: number) => {
    history.push(
      CHAT_PAGE_ROUTE.replace(
        `:${kMessageThreadIdParamName}`,
        messageThreadId.toString(),
      ),
    );
  };

  useEffect(() => {
    if (personalMessageThreads.length) {
      markAllMessagesDeliveredForMultipleThreads(
        personalMessageThreads.map(({ id }) => id),
      );
    }
  }, [personalMessageThreads]);

  const [isNewChatDialogOpen, openNewChatDialog, closeNewChatDialog] =
    useDialog();

  const onChatCreated = (chat: PersonalMessageThread) => {
    closeNewChatDialog();
    goToChat(chat.id);
  };

  const handleNewMessageThread = useCallback(addPersonalChat, [
    addPersonalChat,
  ]);

  useEffect(() => {
    onNewPersonalMessageThread(handleNewMessageThread);

    return () => unsubscribeNewPersonalMessageThread(handleNewMessageThread);
  }, [handleNewMessageThread]);

  const handleNewMessage = useCallback(
    (message: MessageDto) => {
      _.forEach(personalMessageThreads, thread => {
        if (thread.id === message.MessageThreadId) {
          updatePersonalChat({
            ...thread,
            latestMessage: {
              ...thread.latestMessage,
              ...message,
              createdAt: new Date(message.createdAt),
              updatedAt: new Date(message.updatedAt),
              messageAt: new Date(message.messageAt),
            },
          });
        }
      });
    },
    [updatePersonalChat, personalMessageThreads],
  );

  useNewMessageObserver({ handleNewMessage });

  const sortedPersonalChats = sortMessageThreads<PersonalMessageThread>(
    personalMessageThreads,
    id => unreadMessageThreadMap[id],
  );

  return (
    <>
      <List spacing='4' paddingInline='0'>
        {_.isEmpty(personalMessageThreads) ? (
          <EmptyPersonalChats />
        ) : (
          _.map(
            sortedPersonalChats,
            ({ id, recipient, latestMessage, createdAt }) => (
              <MessageItem
                key={id}
                title={getUserDisplayName(recipient)}
                subtitle={getLatestMessageText(latestMessage)}
                topRightInfo={getMessageThreadDate({
                  createdAt,
                  latestMessage,
                })}
                unreadMessageCount={unreadMessageThreadMap[id]}
                avatarComponent={
                  <IconButton
                    as={RouterLink}
                    aria-label='Open user details'
                    icon={<UserAvatar user={recipient} />}
                    to={`/user/${recipient.id}/profile`}
                  />
                }
                onClick={() => goToChat(id)}
              />
            ),
          )
        )}
      </List>
      <FloatingActionButton
        aria-label='Create New Group Chat Button'
        icon={<Icon as={MdAdd} boxSize='5' />}
        onClick={openNewChatDialog}
        isDisabled={personalChatsQuery.isLoading}
        position='fixed'
        right={{ base: '20%', md: '80%' }}
        left={{ base: '80%', md: '60%' }}
        bottom='13%'
      />
      <NewPersonalChatDialog
        onChatCreated={onChatCreated}
        existingPersonalChats={personalMessageThreads}
        isOpen={isNewChatDialogOpen}
        onClose={closeNewChatDialog}
      />
    </>
  );
};

export default PersonalMessagesPanel;
