import { QueryKey, useQuery } from 'react-query';
import { getIndividualComments } from 'src/apis/comment.api';
import { Comment } from 'src/models/Comment.model';
import { EntityType } from 'src/models/View.model';

const getIndividualCommentId = (
  entityId: Comment['EntityId'],
  entityType: Comment['entityType'],
): QueryKey => ['getIndividualComments', entityId, entityType];

const useComments = (entityId: number, entityType: EntityType) => {
  const { data, refetch } = useQuery(
    getIndividualCommentId(entityId, entityType),
    () => getIndividualComments(entityId, entityType),
    {
      staleTime: 1000 * 60 * 2, // 2 minutes
    },
  );

  return { data, refetch };
};
export default useComments;
