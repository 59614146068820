import { Box, Center } from '@chakra-ui/layout';
import { Alert, AlertIcon, Button, Text } from '@chakra-ui/react';
import React from 'react';
import { maxWidth } from '../../../constants/max-width.constant';
import { ValueCallback } from '../../../types/common.type';
import ChatInputPanel, { ChatInputPanelProps } from './ChatInputPanel';

interface ChatFooterProps {
  chatInputPanelProps: ChatInputPanelProps;
  typing?: boolean;
  typingText: string;
  isGroupChat: boolean;
  hasAccepted: boolean | null;
  onAcceptanceChange?: ValueCallback<boolean>;
  hasRecipientBlocked: boolean;
}

const ChatFooter = React.forwardRef<HTMLDivElement, ChatFooterProps>(
  (
    {
      chatInputPanelProps,
      typing,
      typingText,
      isGroupChat,
      onAcceptanceChange,
      hasAccepted,
      hasRecipientBlocked,
    },
    ref
  ) => {
    const onAcceptanceClickHandler = (accepted: boolean) => () =>
      onAcceptanceChange && onAcceptanceChange(accepted);

    // null indicates no action. We ensure user has taken some action before checking for block
    // If user has taken an action (not-null), check if he accepted
    const hasUserBlockedRecipient = hasAccepted !== null && !hasAccepted;

    const Typing = typing && (
      <Box position="relative" mb="6">
        <Text
          maxW="full"
          isTruncated
          position="absolute"
          w="full"
          fontSize="xs"
          color="gray.500"
          mb="0.5"
          ml="1"
        >
          {typingText}
        </Text>
      </Box>
    );

    const ChatInput = <ChatInputPanel bg="white" {...chatInputPanelProps} />;

    const renderBody = () => {
      const CommonBody = (
        <>
          {Typing}
          {ChatInput}
        </>
      );

      if (isGroupChat) {
        return CommonBody;
      }

      return (
        <>
          {hasRecipientBlocked && (
            <Alert status="info">
              <AlertIcon />
              You are blocked and can no longer send messages in this
              conversation
            </Alert>
          )}
          {!hasRecipientBlocked && (
            <>
              {hasUserBlockedRecipient && (
                <Alert status="info">
                  <AlertIcon />
                  You have blocked this chat. Please unblock to send messages
                  <Button
                    onClick={onAcceptanceClickHandler(true)}
                    colorScheme="blue"
                    size="xs"
                    variant="ghost"
                  >
                    Unblock
                  </Button>
                </Alert>
              )}

              {hasAccepted === null && (
                <Center h="6" p="4">
                  <Button
                    onClick={onAcceptanceClickHandler(true)}
                    colorScheme="gray"
                    variant="ghost"
                  >
                    Accept
                  </Button>
                  <Box h="8" />
                  <Button
                    onClick={onAcceptanceClickHandler(false)}
                    colorScheme="red"
                    variant="ghost"
                  >
                    Block
                  </Button>
                </Center>
              )}
              {!hasUserBlockedRecipient && CommonBody}
            </>
          )}
        </>
      );
    };

    return (
      <Center
        ref={ref}
        w="full"
        position="fixed"
        bottom="0"
        left="0"
        zIndex="modal"
      >
        <Box maxW={maxWidth} w="full" p="2">
          {renderBody()}
        </Box>
      </Center>
    );
  }
);

export default ChatFooter;
