import { useMemo } from 'react';
import { QueryKey, useQuery } from 'react-query';

import { getAllSessionsForCall } from 'src/apis/call-sessions.api';
import { CallSession } from 'src/models/CallSession.model';
import useArrayDataQuery from 'src/utils/query-utils/useArrayDataQuery';

import useCallParams from './useCallParams';

const getCallQueryKey = (callId: CallSession['CallId']): QueryKey => [
  'getAllCallSessionsForCall',
  callId,
];

export const useCallSessionsUpdates = () => {
  const { callId } = useCallParams();

  return useArrayDataQuery<CallSession>({
    queryKey: getCallQueryKey(callId),
  });
};

/**
 * Why do we need explicit staleTime?
 * Updates in call sessions are not immediate. And whatever the updates are, they are
 * communicated in real-time through the websocket. So, if we don't set a staleTime,
 * the query will be re-run after the [less] default staleTime. This becomes unnecessary
 * so we wait atleast kCallSessionsStaleTime before re-running the query (if required).
 */
const kCallSessionsStaleTime = 1000; 

const useCallSessions = () => {
  const { callId } = useCallParams();
  const { data , refetch} = useQuery(
    getCallQueryKey(callId),
    () => getAllSessionsForCall(callId),
    {
      staleTime: kCallSessionsStaleTime,
    },
  );

  const callSessions = useMemo(() => data || [], [data]);

  return { callSessions , refetch};
};

export default useCallSessions;
