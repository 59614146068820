import {
  Avatar,
  AvatarProps,
  ButtonGroup,
  Center,
  Flex,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { MdCall, MdCallEnd } from 'react-icons/md';
import { maxWidth } from 'src/constants/max-width.constant';
import IncomingCallButton from './IncomingCallButton';

interface IncomingGroupCallAlertProps {
  groupName: string | undefined;
  groupImageProps?: Pick<AvatarProps, 'src' | 'name'>;
  onAcceptCall: () => void;
  onRejectCall: () => void;
  sound: () => void;
  stopSound: () => void;
}

const IncomingGroupCallAlert: React.FC<IncomingGroupCallAlertProps> = ({
  groupImageProps,
  groupName,
  onAcceptCall,
  onRejectCall,
  sound,
  stopSound,
}) => {

  useEffect(() => {
    sound();
    return stopSound;
}, []);

  return (
    <Center w='100vw' m='-2'>
      <Center w='full' maxW={maxWidth} h='16' color='white'>
        <Flex alignItems='center' w='full' bg='gray.700' py='2' px='4'>
          <HStack flex={8} spacing='4'>
            {groupImageProps && <Avatar size='sm' {...groupImageProps} />}
            <VStack fontSize='sm' spacing='0' align='start'>
              <Text color='whiteAlpha.600'>Incoming Group Call...</Text>
              <Text
                as='span'
                fontSize='md'
                fontWeight='semibold'
                letterSpacing='wide'
              >
                {groupName}
              </Text>
            </VStack>
          </HStack>
          <ButtonGroup w='full' spacing='2' flex={2} justifyContent='flex-end'>
            <IncomingCallButton
              aria-label='Accept Call'
              colorScheme='green'
              onClick={onAcceptCall}
              icon={MdCall}
            />
            <IncomingCallButton
              aria-label='Reject Call'
              colorScheme='red'
              onClick={onRejectCall}
              icon={MdCallEnd}
            />
          </ButtonGroup>
        </Flex>
      </Center>
    </Center>
  );
};

export default IncomingGroupCallAlert;
