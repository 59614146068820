import { axiosInstance } from 'src/config/axiosInstance';
import { PersonalCalls } from 'src/models/PersonalCall';
import { getAccessToken } from 'src/providers/auth-store.provider';
import { getRequestData } from './utils/get-request-data.util';

axiosInstance.interceptors.request.use(config => {
  const accessToken = getAccessToken();
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return config;
});

const kPersonalCallsUrl = 'calls/personal';

export const getAllPersonalCalls = async (): Promise<PersonalCalls[]> => {
  const personalCalls = await getRequestData<PersonalCalls[]>(
    axiosInstance.get(kPersonalCallsUrl),
  );

  return personalCalls.map<PersonalCalls>(personalCall => ({
    ...personalCall,
    createdAt: new Date(personalCall.createdAt),
  }));
};
