import { useEffect, useState } from 'react';

import { Page } from '../../../../models/Page.model';
import {
  deserializePageContent,
  serializePlainText,
} from '../../utils/page-content.utils';
import { DeserializedPageContent } from '../../types/page-content.type';

interface UsePageContentOptions {
  page?: Page;
}

const usePageContent = ({ page }: UsePageContentOptions) => {
  const [content, setContent] = useState<DeserializedPageContent>();
  const [plainTextContent, setPlainTextContent] = useState<string>();

  useEffect(() => {
    if (page) {
      setContent(deserializePageContent(page.content));
    }
  }, [page]);

  useEffect(() => {
    if (content) {
      const plainText = serializePlainText(content);
      setPlainTextContent(plainText);
    }
  }, [content]);

  return { content, plainTextContent };
};

export default usePageContent;
