import React from 'react';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';

import { CustomFormFieldProps } from './common.types';

const TextField: React.FC<CustomFormFieldProps> = ({
  inputProps,
  formControlProps,
  label,
  children,
}) => {
  return (
    <FormControl {...formControlProps}>
      <FormLabel>{label}</FormLabel>
      <Input {...inputProps} />
      {children}
    </FormControl>
  );
};

export default TextField;
