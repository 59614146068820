import {
  Container,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanelProps,
  TabPanels,
  TabProps,
  Tabs,
  Text,
} from '@chakra-ui/react';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import HeaderWrapper from '../../hoc/HeaderWrapper';
import {
  TAB_INDEX_FOR_CALL_LOGS,
  STRING_CONTAINS_ZERO,
} from './constants/call-logs.constant';
import GroupCallsPanel from './panels/groupcall/GroupCallsPanel';
import PersonalCallsPanel from './panels/PersonalCallsPanel';

export interface CallPanelTabProps extends TabProps {}

const CallsPage = () => {
  const tabPanelProps: TabPanelProps = {
    position: 'relative',
    px: '0',
    pt: '8',
    h: {
      base: '75vh',
      md: '68vh',
    },
  };
  const [tabIndex, setTabIndex] = useState<number>(0);

  const handleTabChange = (indexValue: number) => {
    setTabIndex(indexValue);
    localStorage.setItem(TAB_INDEX_FOR_CALL_LOGS, indexValue.toString());
  };

  useEffect(() => {
    const getTabIndexFromLocalStorage = localStorage.getItem(
      TAB_INDEX_FOR_CALL_LOGS,
    );

    getTabIndexFromLocalStorage &&
      setTabIndex(
        _.toNumber(getTabIndexFromLocalStorage || STRING_CONTAINS_ZERO),
      );
  }, []);

  return (
    <>
      <HeaderWrapper pageTitle='Calls'>
        <Container my='8'>
          <Tabs
            isFitted
            isLazy
            variant='unstyled'
            index={tabIndex}
            onChange={handleTabChange}
          >
            <TabList bg='gray.100' borderRadius='10' mx='3'>
              <Tab
                as={HStack}
                alignItems='center'
                position='relative'
                m='1'
                fontWeight='bold'
                color='gray.500'
                borderRadius='10'
                _selected={{
                  color: 'black',
                  bg: 'white',
                  // boxShadow: xAxisOffset yAxisOffset blur spread color
                  boxShadow: '0 0.2rem 0.5rem -0.1rem grey',
                }}
              >
                <Text>Personal</Text>
              </Tab>
              <Tab
                as={HStack}
                alignItems='center'
                position='relative'
                m='1'
                fontWeight='bold'
                color='gray.500'
                borderRadius='10'
                _selected={{
                  color: 'black',
                  bg: 'white',
                  // boxShadow: xAxisOffset yAxisOffset blur spread color
                  boxShadow: '0 0.2rem 0.5rem -0.1rem grey',
                }}
              >
                <Text>Groups</Text>
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel {...tabPanelProps}>
                <PersonalCallsPanel />
              </TabPanel>
              <TabPanel {...tabPanelProps}>
                <GroupCallsPanel />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Container>
      </HeaderWrapper>
    </>
  );
};

export default CallsPage;
