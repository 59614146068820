import _ from 'lodash';

import { axiosInstance } from '../config';
import { PostReviewDto, UpdatePostDto } from '../dto/feed-posts.dto';
import { Post } from '../models/Post.model';
import { PostMedia } from '../models/PostMedia.model';
import {
  NewPostType,
  PostsListQuery,
  QueryParamsType,
} from '../types/post.type';
import { getRequestData } from './utils/get-request-data.util';

const buildQueryParams = (query: QueryParamsType) => {
  let queryParams = `?`;
  if (query.page) {
    queryParams = `${queryParams}page[pageNumber]=${query.page}&`;
  }
  if (query.limit) {
    queryParams = `${queryParams}page[limit]=${query.limit}&`;
  }
  if (query.searchUserInput) {
    queryParams = `${queryParams}filter[userName]=${query.searchUserInput}&`;
  }
  if (query.startDate) {
    queryParams = `${queryParams}filter[startDate]=${query.startDate}&`;
  }
  if (query.endDate) {
    queryParams = `${queryParams}filter[endDate]=${query.endDate}&`;
  }
  if (query.sortOrder) {
    queryParams = `${queryParams}orderBy[sortOrder]=${query.sortOrder}&`;
  }

  return queryParams;
};

export const getPostsWithFilter = async (
  feedId: string,
  query: QueryParamsType = { page: 1, limit: 30 },
): Promise<PostsListQuery> => {
  let queryParams = `?`;
  if (query.page) {
    queryParams = `${queryParams}page[pageNumber]=${query.page}&`;
  }
  if (query.limit) {
    queryParams = `${queryParams}page[limit]=${query.limit}&`;
  }
  if (query.searchUserInput) {
    queryParams = `${queryParams}filter[userName]=${query.searchUserInput}&`;
  }
  if (query.startDate) {
    queryParams = `${queryParams}filter[startDate]=${query.startDate}&`;
  }
  if (query.endDate) {
    queryParams = `${queryParams}filter[endDate]=${query.endDate}&`;
  }
  if (query.sortOrder) {
    queryParams = `${queryParams}orderBy[sortOrder]=${query.sortOrder}&`;
  }

  const { data } = await axiosInstance.get(
    `/feeds/${feedId}/posts/query${queryParams}`,
  );
  if (data.length === 0) {
    return { ...query, posts: data };
  }
  return { ...query, posts: data, page: query.page + 1 };
};

export const getAllPosts = async (
  feedId: string,
  query: QueryParamsType,
): Promise<PostsListQuery> => {
  const queryParams = buildQueryParams(query);

  const posts = await getRequestData<Post[]>(
    axiosInstance.get(`/feeds/${feedId}/posts/${queryParams}`),
  );

  if (posts.length === 0) {
    return { ...query, posts };
  }

  return { ...query, posts, page: query.page + 1 };
};

export const getAllPostsForPublicFeed = async (
  feedId: string,
  query: QueryParamsType,
): Promise<PostsListQuery> => {
  const queryParams = buildQueryParams(query);

  const posts = await getRequestData<Post[]>(
    axiosInstance.get(`/feeds/${feedId}/posts/public${queryParams}`),
  );

  if (posts.length === 0) {
    return { ...query, posts };
  }

  return { ...query, posts, page: query.page + 1 };
};

export const createPost = async (
  newPost: NewPostType,
  feedId: string,
): Promise<Post> => {
  const formData = new FormData();
  formData.append('textContent', newPost.textContent);
  formData.append('CreatedByUserId', newPost.CreatedByUserId);
  _.forEach(newPost.files, file => formData.append('mediaUrl', file));
  const { data } = await axiosInstance.post(`/feeds/${feedId}/posts`, formData);
  return data;
};

export const updatePostReview = (
  feedId: number,
  postId: number,
  review: PostReviewDto,
): Promise<Post> => {
  const url = `/feeds/${feedId}/posts/${postId}/review`;

  return getRequestData<Post>(axiosInstance.put(url, review));
};

export const deletePostMedia = async (
  feedId: number,
  id: number,
  images: PostMedia[],
): Promise<Post> => {
  const { data } = await axiosInstance.put(
    `feeds/${feedId}/posts/${id}/remove-media`,
    images,
  );
  return data;
};

export const updatePost = async (
  feedId: number,
  postId: number,
  updatedPost: UpdatePostDto,
): Promise<Post> => {
  const formData = new FormData();
  formData.append('textContent', updatedPost.textContent);
  _.forEach(updatedPost.media, media => formData.append('mediaUrl', media));
  const { data } = await axiosInstance.put(
    `feeds/${feedId}/posts/${postId}`,
    formData,
  );

  return data;
};

export const getAllCommmentOfOnePost = async (
  feedId: number,
  postId: number,
): Promise<Post> => {
  const { data } = await axiosInstance.get(
    `feeds/${feedId}/posts/${postId}/public`,
  );
  return data;
};
