import { IconButton, IconButtonProps } from '@chakra-ui/button';
import { useRef } from 'react';
import { MdImage } from 'react-icons/md';
import FileInput from 'src/components/FileInput';

import useEditorState from '../../hooks/useEditorState';
import { insertImage } from './insertImage';

interface ImageToolbarButtonProps
  extends Omit<IconButtonProps, 'onClick' | 'aria-label'> {
  getImageUrl: (image: File) => Promise<string>;
}

const ImageToolbarButton: React.FC<ImageToolbarButtonProps> = ({
  getImageUrl,
  ...props
}) => {
  const imageFileInputRef = useRef<HTMLInputElement | null>(null);
  const editor = useEditorState();

  return (
    <>
      <IconButton
        variant='ghost'
        aria-label='attach image'
        icon={<MdImage />}
        onClick={() => {
          if (imageFileInputRef.current) {
            imageFileInputRef.current.click();
          }
        }}
        {...props}
      />
      <FileInput
        ref={imageFileInputRef}
        accept='image/*'
        onFiles={async files => {
          const file = files?.item(0);
          if (file) {
            const imageUrl = await getImageUrl(file);
            insertImage(editor, imageUrl);
          }
        }}
      />
    </>
  );
};

export default ImageToolbarButton;
