import { axiosInstance } from 'src/config';
import { getRequestData } from './utils/get-request-data.util';

const netTraversalServiceUrl = '/net-traversal-service';

export const getIceServersUrl = async (): Promise<RTCIceServer[]> => {
  return getRequestData<RTCIceServer[]>(
    axiosInstance.get(`${netTraversalServiceUrl}/ice-servers`),
  );
};
