import _ from 'lodash';

import { axiosInstance } from '../config/axiosInstance';
import {
  CreateGroupMessageThreadDto,
  GroupMessageThreadDto,
  UpdateGroupMessageThreadDto,
} from '../dto/group-messageThreads.dto';
import { AsDto } from '../dto/model-as.dto';
import { GroupMessageThread } from '../models/GroupMessageThread.model';
import { MessageThreadMember } from '../models/MessageThreadMember.model';
import { getAccessToken } from '../providers/auth-store.provider';
import { getMessageThreadMembersUrl } from './message-thread-members.api';
import { kMessageThreadsUrl } from './message-threads.api';
import { getRequestData } from './utils/get-request-data.util';

axiosInstance.interceptors.request.use((config) => {
  const accessToken = getAccessToken();
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return config;
});

const dtoToData = (dto: GroupMessageThreadDto): GroupMessageThread => {
  return {
    ...dto,
    createdAt: new Date(dto.createdAt),
    updatedAt: new Date(dto.updatedAt),
    group: {
      ...dto.group,
      createdAt: new Date(dto.group.createdAt),
      updatedAt: new Date(dto.group.updatedAt),
    },
  };
};

const kGroupMessageThreadsUrl = `${kMessageThreadsUrl}/group`;

export const createGroupMessageThread = async (
  data: CreateGroupMessageThreadDto
): Promise<GroupMessageThread> => {
  const createdMessageThread = await getRequestData<GroupMessageThreadDto>(
    axiosInstance.post(kGroupMessageThreadsUrl, data)
  );

  return dtoToData(createdMessageThread);
};

export const getGroupMessageThread = async (
  messageThreadId: number
): Promise<GroupMessageThread | undefined> => {
  const messageThread = await getRequestData<GroupMessageThreadDto>(
    axiosInstance.get(`${kGroupMessageThreadsUrl}/${messageThreadId}`)
  );

  return messageThread && dtoToData(messageThread);
};

export const getAllGroupChats = async (): Promise<GroupMessageThread[]> => {
  const groupChats = await getRequestData<GroupMessageThreadDto[]>(
    axiosInstance.get(kGroupMessageThreadsUrl)
  );

  return _.map(groupChats, dtoToData);
};

export const leaveGroupMessageThread = async (
  groupMessageThreadId: number
): Promise<MessageThreadMember> => {
  const url = `${getMessageThreadMembersUrl(groupMessageThreadId)}/leave`;

  return getRequestData(axiosInstance.patch(url));
};

export const addUsersToGroupMessageThread = async (
  groupMessageThreadId: number,
  userIdsToAdd: number[]
): Promise<MessageThreadMember[]> => {
  const url = `${getMessageThreadMembersUrl(groupMessageThreadId)}/add`;

  const addedMembers = await getRequestData<AsDto<MessageThreadMember>[]>(
    axiosInstance.patch(url, { userIds: userIdsToAdd })
  );

  return _.map<AsDto<MessageThreadMember>, MessageThreadMember>(
    addedMembers,
    (member) => ({
      ...member,
      createdAt: new Date(member.createdAt),
      updatedAt: new Date(member.updatedAt),
    })
  );
};

export const removeUserFromGroupMessageThread = async (
  groupMessageThreadId: number,
  userIdToRemove: number
): Promise<MessageThreadMember> => {
  const url = `${getMessageThreadMembersUrl(
    groupMessageThreadId
  )}/remove/${userIdToRemove}`;

  return getRequestData(axiosInstance.patch(url));
};

export const makeUserGroupAdmin = async (
  groupMessageThreadId: number,
  userId: number
): Promise<MessageThreadMember> => {
  const url = `${getMessageThreadMembersUrl(
    groupMessageThreadId
  )}/make-admin/${userId}`;

  const updatedMember = await getRequestData<AsDto<MessageThreadMember>>(
    axiosInstance.patch(url)
  );

  return {
    ...updatedMember,
    createdAt: new Date(updatedMember.createdAt),
    updatedAt: new Date(updatedMember.updatedAt),
  };
};

export const removeUserAsGroupAdmin = async (
  groupMessageThreadId: number,
  userId: number
): Promise<MessageThreadMember> => {
  const url = `${getMessageThreadMembersUrl(
    groupMessageThreadId
  )}/remove-admin/${userId}`;

  const updatedMember = await getRequestData<AsDto<MessageThreadMember>>(
    axiosInstance.patch(url)
  );

  return {
    ...updatedMember,
    createdAt: new Date(updatedMember.createdAt),
    updatedAt: new Date(updatedMember.updatedAt),
  };
};

export const editGroupMessageThread = async (
  messageThreadId: number,
  data: UpdateGroupMessageThreadDto
): Promise<GroupMessageThread> => {
  const updatedGroupThread = await getRequestData<GroupMessageThreadDto>(
    axiosInstance.patch(`${kGroupMessageThreadsUrl}/${messageThreadId}`, data)
  );

  return dtoToData(updatedGroupThread);
};

// For now, server doesn't send user record so omitting that from response
export const getGroupMemberRecordForSelf = async (
  messageThreadId: number
): Promise<Omit<MessageThreadMember, 'user'> | undefined> => {
  const url = `${kGroupMessageThreadsUrl}/${messageThreadId}/members/me`;

  const memberRecord = await getRequestData<AsDto<MessageThreadMember>>(
    axiosInstance.get(url)
  );

  return (
    memberRecord && {
      ...memberRecord,
      createdAt: new Date(memberRecord.createdAt),
      updatedAt: new Date(memberRecord.updatedAt),
    }
  );
};
