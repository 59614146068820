import { useQuery } from 'react-query';

import {
  getAllMembers,
  getAllMembersForPublicFeed,
} from '../../../apis/feedsMembers.api';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { FeedMember } from '../../../models/FeedMember.model';
import useArrayDataQuery from '../../../utils/query-utils/useArrayDataQuery';

interface UseFeedMembersOptions {
  feedId: number;
}

const useFeedMembers = ({ feedId }: UseFeedMembersOptions) => {
  const [currentUser, { isLoading: isLoadingUser }] = useCurrentUser();

  const getAllFeedMembersQueryKey = ['getAllFeedMembers', feedId];
  const { data: feedMembers } = useQuery(
    getAllFeedMembersQueryKey,
    () => {
      if (currentUser) {
        return getAllMembers(feedId);
      }

      return getAllMembersForPublicFeed(feedId);
    },
    { enabled: !isLoadingUser },
  );

  const { addOne, addMultiple, updateOne, removeOne } =
    useArrayDataQuery<FeedMember>({
      queryKey: getAllFeedMembersQueryKey,
    });

  return {
    feedMembers,
    addMember: addOne,
    addMultipleMembers: addMultiple,
    updateMember: updateOne,
    removeMember: removeOne,
  };
};

export default useFeedMembers;
