import { useMemo } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { Plate, PlateProps } from '@udecode/plate';
import { Page } from 'src/models/Page.model';

import useSlatePlugins from './hooks/useSlatePlugins';
import { EditorIdProvider } from './providers/EditorId.provider';
import Toolbar from './components/toolbar';

interface EditorProps extends Omit<BoxProps, 'value' | 'onChange'> {
  value: PlateProps['value'];
  onChange: PlateProps['onChange'];
  pageId: Page['id'];
  isReadonly: boolean;
}

const getEditorId = (pageId: Page['id']) => `editor-${pageId}`;

export const Editor: React.FC<EditorProps> = ({
  value,
  onChange,
  pageId,
  isReadonly,
  ...props
}) => {
  const plugins = useSlatePlugins();
  const editorId = useMemo(() => getEditorId(pageId), [pageId]);

  return (
    <EditorIdProvider value={{ editorId }}>
      <Box maxW='full' {...props}>
        {!isReadonly && <Toolbar />}
        <Plate
          id={editorId}
          value={value}
          onChange={onChange}
          editableProps={{
            placeholder: isReadonly ? '' : 'Type your page content here...',
            readOnly: isReadonly,
            style: {
              backgroundColor: 'white',
              padding: '1rem',
              marginTop: '0.25rem',
            },
          }}
          plugins={plugins}
        />
      </Box>
    </EditorIdProvider>
  );
};
