import _ from 'lodash';
import { useEffect } from 'react';
import { useBoolean } from '@chakra-ui/react';
import { MdVolumeUp, MdVolumeOff } from 'react-icons/md';

import { usePeerConnection } from 'src/pages/call/context/peer-connection/hooks/usePeerConnections';
import useCallSessions from 'src/pages/call/hooks/useCallSessions';
import FooterIconButton from './FooterIconButton';

interface CallPageSpeakerButtonProps {
  isSpeakerDisabled: boolean;
}

const CallPageSpeakerButton: React.FC<CallPageSpeakerButtonProps> = ({isSpeakerDisabled}) => {
  const [isSpeakerOn, { toggle: toggleSpeaker }] = useBoolean();
  const { unmutePeerStream, mutePeerStream } = usePeerConnection();
  const {callSessions} = useCallSessions()

  useEffect(() => {
    if (!callSessions) {
      return;
    }

    if (isSpeakerOn) {
      _.map(callSessions, (callSession) => {
        unmutePeerStream(callSession.id);
      })
    } else {
      _.map(callSessions, (callSession) => {
        mutePeerStream(callSession.id);
      })
    }
  }, [isSpeakerOn, callSessions, mutePeerStream, unmutePeerStream]);

  return (
    <FooterIconButton
      activeIcon={MdVolumeUp}
      inactiveIcon={MdVolumeOff}
      onToggleClick={toggleSpeaker}
      isActive={isSpeakerOn}
      aria-label='Toggle volume'
      disabled={isSpeakerDisabled}
    />
  );
};

export default CallPageSpeakerButton;
