import React from 'react';

import SearchSelectMultipleUsers from '../../../../components/SearchSelectMultipleUsers';
import { User } from '../../../../models';

type SelectedUser = User & { selectedAt: Date };
export type SelectedUserMap = Record<string, SelectedUser>;

interface AddUsersProps {
  selectedUsers: SelectedUserMap;
  setSelectedUsers: React.Dispatch<React.SetStateAction<SelectedUserMap>>;
}

const AddUsers: React.FC<AddUsersProps> = ({
  selectedUsers,
  setSelectedUsers,
}) => {
  return (
    <SearchSelectMultipleUsers
      selectedUsers={selectedUsers}
      setSelectedUsers={setSelectedUsers}
    />
  );
};

export default AddUsers;
