import { QueryKey, useQuery } from 'react-query';

import { getCall } from 'src/apis/calls.api';
import { Call } from 'src/models/Call.model';
import useCallParams from './useCallParams';

const getCallQueryKey = (callId: Call['id']): QueryKey => [
    'getCallById',
    callId,
];

const useCall = () => {
    const { callId } = useCallParams();
    const { data: call, ...queryMetadata } = useQuery(
        getCallQueryKey(callId),
        () => getCall(callId),
    );

    return { call, ...queryMetadata };
};

export default useCall;
