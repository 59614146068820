import { useQuery } from 'react-query';

import { getAllPages } from '../../../apis/pages.api';
import { Page } from '../../../models/Page.model';
import useArrayDataQuery from '../../../utils/query-utils/useArrayDataQuery';

const kGetPagesQueryKey = 'getAllPages';

export const usePagesQueryClient = () => {
  return useArrayDataQuery<Page>({ queryKey: kGetPagesQueryKey });
};

const usePages = () => {
  const { data, ...queryMetadata } = useQuery(kGetPagesQueryKey, getAllPages);
  const pages = data || [];

  return {
    pages,
    ...queryMetadata,
  };
};

export default usePages;
