import { createContext, useContext } from 'react';

interface UserMediaContextType {
  localStream: MediaStream | null;
  requestUserMedia: (constraints: MediaStreamConstraints) => void;
  toggleAudio: () => void;
  toggleVideo: () => void;
  isAudioMuted: boolean;
  isVideoMuted: boolean;
}

export const UserMediaContext = createContext<UserMediaContextType>({
  localStream: null,
  requestUserMedia: () => {},

  isVideoMuted: true,
  toggleVideo: () => {},

  isAudioMuted: true,
  toggleAudio: () => {},
});

export const useUserMedia = () => useContext(UserMediaContext);
