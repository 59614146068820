import { User } from '../models';

export const getUserDisplayName = (
  user: Pick<User, 'firstName' | 'lastName'>,
) => `${user.firstName} ${user.lastName}`;

export const usersAlphabeticSortWithCurrentUserLast = (
  user?: User,
  currentUser?: User,
) => {
  // return `null` for current loggedIn user to make them appear at last during sort
  // lodash keeps null/undefined at the end for ascending order
  // Source: https://github.com/lodash/lodash/issues/4169#issuecomment-457607652
  if (user?.id === currentUser?.id) {
    return null;
  }

  return user && getUserDisplayName(user);
};
