import _ from 'lodash';

import { MessageThread } from '../../../models/MessageThread.model';

const getLatestMessageText = (
  msg: MessageThread['latestMessage'],
  fallbackMsg: string = 'Begin a conversation',
): string => {
  if (!msg) {
    return fallbackMsg;
  }

  if (msg.text) {
    return msg.text;
  }

  const hasMessageMedia = !_.isEmpty(msg.messageMedias);

  if (hasMessageMedia) {
    return 'Sent a file';
  }

  return fallbackMsg;
};

export default getLatestMessageText;
