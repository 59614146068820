import { Box } from '@chakra-ui/react';
import { CustomArrowProps } from 'react-slick';

interface CarouselArrowProps extends CustomArrowProps {
  direction: 'next' | 'prev';
}

const CarouselArrow: React.FC<CarouselArrowProps> = ({
  style,
  direction,
  ...props
}) => {
  const offset = '-6%';
  const offsetProps = {
    left: direction === 'prev' ? offset : '',
    right: direction === 'next' ? offset : '',
  };

  return (
    <Box
      style={{
        ...style,
        ...offsetProps,
        display: 'block',
        zIndex: 100,
      }}
      _before={{
        color: 'black',
      }}
      {...props}
    />
  );
};

export default CarouselArrow;
