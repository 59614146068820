import { createContext } from 'react';

import { CallSession } from 'src/models/CallSession.model';
import { ValueCallback } from 'src/types/common.type';

type PeerMap<T> = Record<CallSession['id'], T>;

export type SessionPeerConnectionMap = PeerMap<RTCPeerConnection>;
export type SessionPeerStreamMap = PeerMap<MediaStream>;
export type SessionDataChannelMap = PeerMap<RTCDataChannel>;
export type SessionPeerVideoStatus = PeerMap<boolean>;

interface PeerConnectionContextType {
  peerConnections: SessionPeerConnectionMap;
  peerStreams: SessionPeerStreamMap;
  addStream: ValueCallback<MediaStream>;
  addTrack: (track: MediaStreamTrack, stream: MediaStream) => void;
  mutePeerStream: ValueCallback<keyof SessionPeerStreamMap>;
  unmutePeerStream: ValueCallback<keyof SessionPeerStreamMap>;
  notifyEnableVideo: () => void;
  notifyDisableVideo: () => void;
  notifyEnableMic: () => void;
  notifyDisableMic: () => void;
  peerVideoStatus: SessionPeerVideoStatus;
  peerConnCleanups: Function[];
  callSessions: CallSession[];
  refetchCallSessions: () => void;
}

export const initialPeerConnectionContextValue: PeerConnectionContextType = {
  peerConnections: {},
  peerStreams: {},
  addStream: () => {},
  addTrack: () => {},
  mutePeerStream: () => {},
  unmutePeerStream: () => {},
  notifyEnableVideo: () => {},
  notifyDisableVideo: () => {},
  notifyDisableMic: () => {},
  notifyEnableMic: () => {},
  peerVideoStatus: {},
  peerConnCleanups: [],
  callSessions: [],
  refetchCallSessions: () => {},
};

export const PeerConnectionContext = createContext<PeerConnectionContextType>(
  initialPeerConnectionContextValue,
);
