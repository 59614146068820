import { createContext, FC, useEffect, useState } from 'react';
import { useIsFetching, useIsMutating } from 'react-query';

import Loader from 'src/components/loader/Loader';

interface QueryLoaderContextType {
  isQueryLoading: boolean;
  isFetching: boolean;
  isMutating: boolean;
  disableLoader: () => void;
  enableLoader: () => void;
}

export const QueryLoaderContext = createContext<QueryLoaderContextType>(null!);

export const QueryLoaderProvider: FC = ({ children }) => {
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();
  const [isQueryLoading, setIsQueryLoading] = useState<boolean>(false);
  const [isLoaderDisabled, setIsLoaderDisabled] = useState<boolean>(false);
  useEffect(() => {
    setIsQueryLoading(!!isFetching || !!isMutating);
  }, [isFetching, isMutating]);
  const disableLoader = () => {
    setIsLoaderDisabled(true);
  };
  const enableLoader = () => {
    setIsLoaderDisabled(false);
  };

  const value = {
    isQueryLoading,
    isFetching: !!isFetching,
    isMutating: !!isMutating,
    disableLoader,
    enableLoader,
  };

  return (
    <QueryLoaderContext.Provider value={value}>
      {children}
      {isQueryLoading && !isLoaderDisabled && <Loader />}
    </QueryLoaderContext.Provider>
  );
};
