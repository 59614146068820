import { FC } from 'react';
import { User } from 'src/models';
import { CallSession } from 'src/models/CallSession.model';
import VideoStream from '../../VideoStream';
import CallUserImage from './CallUserImage';

interface CallVideoProps {
  stream: MediaStream;
  peerVideoStatus?: boolean;
  user: User | undefined;
  session?: CallSession;
  height: string;
}

const CallVideo: FC<CallVideoProps> = ({
  stream,
  peerVideoStatus,
  user,
  session,
  height,
}) => {
  return (
    <>
      <VideoStream
        stream={stream}
        w='full'
        h={height}
        objectFit='cover'
        controls={false}
        display={peerVideoStatus ? 'block' : 'none'}
      />
      {!peerVideoStatus && (
        <CallUserImage receiver={user} joinedCall={session?.joinedAt} />
      )}
    </>
  );
};

export default CallVideo;
