import { AxiosError } from 'axios';
import { useMemo } from 'react';
import { QueryClient, QueryCache, MutationCache } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { notifyUserUnauthorized } from '../apis/socket.io/users.socket.io';

import {
  FEEDS_PAGE_ROUTE,
  LOGIN_PAGE_ROUTE,
  REGISTER_PAGE_ROUTE,
} from '../routes/routeList';
import useLocalStorageCleanup from './useLocalStorageCleanup';

const kAuthRoutes = [LOGIN_PAGE_ROUTE, REGISTER_PAGE_ROUTE, FEEDS_PAGE_ROUTE];
// Regex to check if we are in the exact routes required (kAuthRoutes, in this context)
const kAuthRoutesRegex = new RegExp(`^/?(${kAuthRoutes.join('|')})/?$`);

// Feeds routes are like: /feeds/:feedId, /feeds/:feedId/posts, /feeds/:feedId/members
// So to match the route, we need to check if the route is in the list of feeds routes
// To do so, simply see if route begins with /feed and may end with anything
const kFeedsRouteRegex = new RegExp('^/?feed/?');
const kPagesRouteRegex = new RegExp('^/?pages/?');

const isPublicRoute = (route: string) => {
  const isAuthRoute = kAuthRoutesRegex.test(route);
  const isFeedRoute = kFeedsRouteRegex.test(route);
  const isPagesRoute = kPagesRouteRegex.test(route);
  const isHomePageRoute = route === '/';

  return isAuthRoute || isFeedRoute || isPagesRoute || isHomePageRoute;
};

export const useQueryClientInstance = () => {
  const history = useHistory();
  const location = useLocation();
  const { cleanup } = useLocalStorageCleanup();

  const handleError = (error: unknown) => {
    const isUnauthorizedError = (error as AxiosError).response?.status === 401;

    // Even if the user is in a public route, we still want to notify to the socket.io server
    if (isUnauthorizedError) {
      notifyUserUnauthorized();
    }

    if (isUnauthorizedError && !isPublicRoute(location.pathname)) {
      cleanup();
      history.push(LOGIN_PAGE_ROUTE);
    }
  };

  const queryClient = useMemo(
    () =>
      new QueryClient({
        mutationCache: new MutationCache({ onError: handleError }),
        queryCache: new QueryCache({ onError: handleError }),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname],
  );

  return queryClient;
};
