import { localStorageKeys } from '../constants/local-storage-keys.constant';
import { AccessToken } from '../dto/auth.dto';

const kAccessTokenKey = localStorageKeys.accessTokenKey;
const store: Storage = localStorage;

export const setAccessToken = (token: AccessToken) =>
  store.setItem(kAccessTokenKey, token);

export const getAccessToken = () => store.getItem(kAccessTokenKey);

export const removeAccessToken = () => store.removeItem(kAccessTokenKey);
