import { axiosInstance } from '../config';
import { User } from '../models';
import { UpdateUserType } from '../types/user.type';

export const getUsersPagination = async (
  name: string = '',
): Promise<User[]> => {
  if (name) {
    const resp = await axiosInstance.get(`/users?name=${name}`);
    return resp.data;
  }
  return [];
};

export const getUserDetails = async (id: string): Promise<User | undefined> => {
  const res = await axiosInstance.get(`/users/${id}`);
  return res.data;
};

export const updateUserAvatar = async (
  id: string,
  image: File,
): Promise<User> => {
  const formData = new FormData();
  formData.append('avatar', image);
  const resp = await axiosInstance.put(`/users/${id}/avatar`, formData);
  return resp.data;
};

export const updateUser = async (
  id: string,
  user: UpdateUserType,
): Promise<User> => {
  const resp = await axiosInstance.put(`/users/${id}`, user);
  return resp.data;
};

export const removeCurrentUserAvatar = async () =>{
  await axiosInstance.delete('/users/me/avatar')
}