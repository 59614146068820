import {
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  UnorderedList,
  VStack,
  Text,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { MdSearch } from 'react-icons/md';
import { useMutation, useQuery } from 'react-query';
import _ from 'lodash';

import { ValueCallback } from '../../../types/common.type';
import { getUsersPagination } from '../../../apis/users.api';
import { User } from '../../../models';
import { getUserDisplayName } from '../../../utils/user.utils';
import { createPersonalMessageThread } from '../../../apis/personal-message-threads.api';
import { PersonalMessageThread } from '../../../models/PersonalMessageThread.model';
import UserAvatar from '../../../components/UserAvatar';

interface NewPersonalChatDialogProps extends Omit<ModalProps, 'children'> {
  existingPersonalChats: PersonalMessageThread[];
  onChatCreated: (chat: PersonalMessageThread) => void;
}

type InputChangeHandler = React.ChangeEventHandler<HTMLInputElement>;
type SearchCallback = ValueCallback<string>;

const NewPersonalChatDialog: React.FC<NewPersonalChatDialogProps> = ({
  existingPersonalChats,
  onChatCreated,
  ...props
}) => {
  const [createdChat, setCreatedChat] = useState<PersonalMessageThread>();
  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchQuery, setSearchQuery] = useState<string>();

  const { data: userSearchResults, isLoading: isSearchingUsers } = useQuery<
    User[]
  >(
    ['searchUsersForNewChat', searchQuery],
    () => getUsersPagination(searchQuery),
    { enabled: !!searchQuery },
  );

  const { mutate: createChat, isSuccess: isChatCreated } = useMutation(
    createPersonalMessageThread,
    { onSuccess: setCreatedChat },
  );

  useEffect(() => {
    if (isChatCreated && createdChat) {
      onChatCreated(createdChat);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChatCreated]);

  // React Hook useCallback received a function whose dependencies are unknown. Pass an inline function instead  react-hooks/exhaustive-deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const performSearch = useCallback<SearchCallback>(
    _.debounce<SearchCallback>(setSearchQuery, 500),
    [setSearchQuery],
  );

  const onSearchInputChange: InputChangeHandler = e => {
    const searchInputValue = e.currentTarget.value?.trim();
    setSearchInputValue(searchInputValue);

    if (!_.isEmpty(searchInputValue)) {
      performSearch(searchInputValue);
    }
  };

  const handleOnUserResultItemClick = (userId: number) => {
    createChat({ recipient: userId });
  };

  const doesntAlreadyHaveChatWithUser = (user: User) => {
    return !_.find(existingPersonalChats, chat => {
      return chat.recipient.id === user.id;
    });
  };

  return (
    <Modal scrollBehavior='inside' isCentered {...props}>
      <ModalOverlay />

      <ModalContent mx='10' minH='50%'>
        <ModalHeader>New Personal Chat</ModalHeader>

        <ModalBody as={VStack} spacing='6' align='start'>
          <InputGroup>
            <InputLeftElement>
              <Icon as={MdSearch} color='gray.300' boxSize='6' />
            </InputLeftElement>
            <Input
              placeholder='Search for a user...'
              onChange={onSearchInputChange}
              value={searchInputValue}
            />
          </InputGroup>

          {_.isEmpty(userSearchResults) ? (
            isSearchingUsers &&
            !_.isEmpty(searchQuery) && (
              <Text fontSize='sm' color='gray.500' pl='2'>
                No users found
              </Text>
            )
          ) : (
            <UnorderedList spacing='4'>
              {_.chain(userSearchResults)
                .filter(doesntAlreadyHaveChatWithUser)
                .sortBy(getUserDisplayName)
                .value()
                .map(user => (
                  <ListItem
                    as={HStack}
                    key={user.id}
                    cursor='pointer'
                    spacing='4'
                    onClick={() => handleOnUserResultItemClick(user.id)}
                  >
                    <UserAvatar user={user} size='sm' />
                    <Text fontWeight='bold'>{getUserDisplayName(user)}</Text>
                  </ListItem>
                ))}
            </UnorderedList>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NewPersonalChatDialog;
