import { axiosInstance } from '../config';
import { AsDto } from '../dto/model-as.dto';
import { MessageThreadMember } from '../models/MessageThreadMember.model';
import { getAccessToken } from '../providers/auth-store.provider';
import { kMessageThreadsUrl } from './message-threads.api';
import { getRequestData } from './utils/get-request-data.util';
import _ from 'lodash';

axiosInstance.interceptors.request.use(config => {
  const accessToken = getAccessToken();
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return config;
});

export const getMessageThreadMembersUrl = (messageThreadId: number) =>
  `${kMessageThreadsUrl}/${messageThreadId}/members`;

export const getMembersOfMessageThread = async (
  messageThreadId: number,
): Promise<MessageThreadMember[]> => {
  const url = getMessageThreadMembersUrl(messageThreadId);

  const messageThreadMembers = await getRequestData<
    AsDto<MessageThreadMember>[]
  >(axiosInstance.get(url));

  return _.map<AsDto<MessageThreadMember>, MessageThreadMember>(
    messageThreadMembers,
    member => ({
      ...member,
      createdAt: new Date(member.createdAt),
      updatedAt: new Date(member.updatedAt),
    }),
  );
};
