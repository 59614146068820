import { axiosInstance } from '../config/axiosInstance';
import {
  CreatePersonalMessageThreadDto,
  PersonalMessageThreadDto,
  UnreadMessageThreadsCountDto,
} from '../dto/personal-messageThreads.dto';
import { PersonalMessageThread } from '../models/PersonalMessageThread.model';
import { getAccessToken } from '../providers/auth-store.provider';
import { kMessageThreadsUrl } from './message-threads.api';
import { getRequestData } from './utils/get-request-data.util';

axiosInstance.interceptors.request.use((config) => {
  const accessToken = getAccessToken();
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return config;
});

const kPersonalMessageThreadsUrl = `${kMessageThreadsUrl}/personal`;

export const getAllPersonalMessageThreads = async (): Promise<
  PersonalMessageThread[]
> => {
  const personalMessageThreads = await getRequestData<
    PersonalMessageThreadDto[]
  >(axiosInstance.get(kPersonalMessageThreadsUrl));

  return personalMessageThreads.map<PersonalMessageThread>((messageThread) => ({
    ...messageThread,
    createdAt: new Date(messageThread.createdAt),
  }));
};

export const createPersonalMessageThread = async (
  data: CreatePersonalMessageThreadDto
): Promise<PersonalMessageThread> => {
  const createdMessageThread = await getRequestData<PersonalMessageThreadDto>(
    axiosInstance.post(kPersonalMessageThreadsUrl, data)
  );

  return {
    ...createdMessageThread,
    createdAt: new Date(createdMessageThread.createdAt),
  };
};

export const getOnePersonalMessageThreadWithUser = async (
  targetUserId: number
): Promise<PersonalMessageThread | null> => {
  const url = `${kPersonalMessageThreadsUrl}/with-user/${targetUserId}`;

  const messageThread = await getRequestData<PersonalMessageThreadDto | null>(
    axiosInstance.get(url)
  );

  return (
    messageThread && {
      ...messageThread,
      createdAt: new Date(messageThread.createdAt),
    }
  );
};

export const getOnePersonalMessageThread = async (
  id: number
): Promise<PersonalMessageThread> => {
  const url = `${kMessageThreadsUrl}/${id}`;

  const messageThread = await getRequestData<PersonalMessageThreadDto>(
    axiosInstance.get(url)
  );

  return {
    ...messageThread,
    createdAt: new Date(messageThread.createdAt),
  };
};

export const getUnreadPersonalChatsCount =
  async (): Promise<UnreadMessageThreadsCountDto> => {
    const url = `${kPersonalMessageThreadsUrl}/unread-count`;
    return getRequestData<UnreadMessageThreadsCountDto>(axiosInstance.get(url));
  };
