import { Box, BoxProps } from '@chakra-ui/react';
import { FC } from 'react';

import { Topbar, Footer } from '../components';
import { maxWidth } from '../constants/max-width.constant';

interface HeaderWrapperProps {
  pageTitle: string;
  /**
   * For now, to ensure the maxWidth and center alignment adjustments are not mistakenly hampered
   * by passing in the `maxW` or `mx` props, we need to make sure those are not passed in.
   */
  bodyContainerProps?: Omit<BoxProps, 'maxW' | 'mx'>;
}

const HeaderWrapper: FC<HeaderWrapperProps> = ({
  children,
  pageTitle,
  bodyContainerProps,
}) => {
  return (
    <Box m="0">
      <Topbar pageTitle={pageTitle} />
      <Box py="12" mx="auto" maxW={maxWidth} {...(bodyContainerProps || {})}>
        {children}
      </Box>
      <Footer />
    </Box>
  );
};

export default HeaderWrapper;
