import { Box } from '@chakra-ui/react';

import VideoStream from '../../VideoStream';
import { useUserMedia } from '../context/user-media/user-media.context';

interface UserSelfVideoProps {}

const UserSelfVideo: React.FC<UserSelfVideoProps> = () => {
  const { localStream, isVideoMuted } = useUserMedia();

  if (isVideoMuted) {
    return null;
  }

  return (
    <Box
      position='absolute'
      bottom='24'
      right='4'
      h='24'
      w='32'
      bgColor='gray.800'
    >
      <VideoStream
        stream={localStream}
        w='full'
        h='full'
        muted
        controls={false}
      />
    </Box>
  );
};

export default UserSelfVideo;
