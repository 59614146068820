import React from 'react';
import { Grid, HStack, Text, Link } from '@chakra-ui/layout';

import { BaseSection } from '../BaseSection';
import { TextSectionProps } from './types/TextSection.props.types';
import { TextSectionItemProps } from './types/TextSectionItem.props.types';
import { Image } from '@chakra-ui/image';
import useDashboardSectionLinks from '../../hooks/useDashboardSectionLinks';
import { Link as RouterLink } from 'react-router-dom';

const TextSectionItem: React.FC<TextSectionItemProps> = ({
    iconImgSrc,
    text,
}) => {
    // Why min-wdth={0} https://stackoverflow.com/a/53784508
    return (
        <HStack spacing='4' minWidth='0' minH='0'>
            <Image src={iconImgSrc} objectFit='cover' boxSize='14' minW='14' />
            <Text fontSize='md' maxHeight='full' noOfLines={3}>
                {text}
            </Text>
        </HStack>
    );
};

const TextSection: React.FC<TextSectionProps> = ({ dashboardSection }) => {
    const [dashboardSectionLinks] = useDashboardSectionLinks({
        dashboardSectionId: dashboardSection.id,
    });

    const body = (
        <Grid gap={4} mx='2'>
            {dashboardSectionLinks.map(link =>
                link.isExternal ? (
                    <Link href={link.linkUrl} target='_blank'>
                        <TextSectionItem
                            key={link.id}
                            text={link.linkText}
                            iconImgSrc={link.imageUrl}
                        />
                    </Link>
                ) : (
                    <RouterLink to={link.linkUrl}>
                        <TextSectionItem
                            key={link.id}
                            text={link.linkText}
                            iconImgSrc={link.imageUrl}
                        />
                    </RouterLink>
                ),
            )}
        </Grid>
    );

    return <BaseSection title={dashboardSection.title} body={body} />;
};

export default TextSection;
