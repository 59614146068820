import { Box, BoxProps } from '@chakra-ui/layout';
import { Picker, PickerProps } from 'emoji-mart';

interface EmojiPickerProps extends BoxProps {
  show: boolean;
  pickerProps?: PickerProps;
}

const EmojiPicker: React.FC<EmojiPickerProps> = ({
  show,
  pickerProps = {},
  ...props
}) => {
  return (
    <Box display={show ? 'block' : 'none'} {...props}>
      <Picker
        set='google'
        showPreview={false}
        showSkinTones={false}
        enableFrequentEmojiSort
        title=''
        emoji=''
        autoFocus
        {...pickerProps}
      />
    </Box>
  );
};

export default EmojiPicker;
