import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { markAllMessagesSeenForOneThread } from '../../../apis/message-threads.api';
import { getMessages } from '../../../apis/messages.api';
import { Message } from '../../../models/Message.model';
import useMessageStatusUpdateObserver from './useMessageStatusUpdateObserver';
import getAllMessagesQueryKey from './getMessagesQueryKey';
import useNewMessageObserver from './useNewMessageObserver';
interface UseMessagesOptions {
  messageThreadId: number;
}

const useMessages = ({ messageThreadId }: UseMessagesOptions) => {
  const [haveMarkedSeen, setHaveMarkedSeen] = useState(false);

  const updateBatchMessageStatusMutation = useMutation<
    unknown,
    unknown,
    Message[]
  >(() => markAllMessagesSeenForOneThread(messageThreadId));

  const { data: messages, ...queryMetadata } = useQuery<Message[]>(
    getAllMessagesQueryKey(messageThreadId),
    () => getMessages(messageThreadId),
    {
      onSuccess: (messages) => {
        if (!haveMarkedSeen) {
          setHaveMarkedSeen(true);
          updateBatchMessageStatusMutation.mutate(messages);
        }
      },
    }
  );
  useNewMessageObserver(messageThreadId);
  useMessageStatusUpdateObserver(messageThreadId);

  return {
    messages: messages || [],
    ...queryMetadata,
  };
};

export default useMessages;
