import { VStack, Text } from '@chakra-ui/react';

const EmptyPersonalChats: React.FC = () => {
  return (
    <VStack mt="12">
      <Text fontSize="lg" color="gray.500" fontWeight="semibold">
        You don't have any personal chats yet.
      </Text>
      <Text fontSize="sm" color="gray.500">
        Create one using the + button below.
      </Text>
    </VStack>
  );
};

export default EmptyPersonalChats;
