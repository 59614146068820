import { insertMediaEmbed as plateInsertMediaEmbed } from '@udecode/plate';
import { useCallback } from 'react';

import useEditorState from '../../../../hooks/useEditorState';

const useInsertMediaEmbed = () => {
  const editor = useEditorState();

  const insertMediaEmbed = useCallback(
    (url: string) => plateInsertMediaEmbed(editor, { url }),
    [editor],
  );

  return { insertMediaEmbed };
};

export default useInsertMediaEmbed;
