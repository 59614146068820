import { DraftPageDto, UpdatePageDto } from 'src/dto/pages.dto';
import { axiosInstance } from '../config/axiosInstance';
import { Page } from '../models/Page.model';
import { getRequestData } from './utils/get-request-data.util';

const kPagesRootUrl = '/pages';

export const getAllPages = (): Promise<Page[]> => {
  return getRequestData<Page[]>(axiosInstance.get(kPagesRootUrl));
};

export const getOnePage = (id: Page['id']): Promise<Page | undefined> => {
  const url = `${kPagesRootUrl}/${id}`;

  return getRequestData<Page | undefined>(axiosInstance.get(url));
};

export const updatePage = (
  id: Page['id'],
  pageUpdate: UpdatePageDto,
): Promise<Page> => {
  const url = `${kPagesRootUrl}/${id}`;
  return getRequestData<Page>(axiosInstance.patch(url, pageUpdate));
};

export const draftPage = (
  id: Page['id'],
  pageDraft: DraftPageDto,
): Promise<Page> => {
  const url = `${kPagesRootUrl}/${id}/draft`;
  return getRequestData<Page>(axiosInstance.patch(url, pageDraft));
};

export const reviewPage = (
  id: Page['id'],
  pageUpdate: UpdatePageDto,
): Promise<Page> => {
  const url = `${kPagesRootUrl}/${id}/review`;
  return getRequestData<Page>(axiosInstance.patch(url, pageUpdate));
};
