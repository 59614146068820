import { socket } from '../../config/socketIoInstance';
import {
  kJoinMessageThreadEvent,
  kLeaveMessageThreadEvent,
} from './events/message-threads.events';

export const joinMessageThreadRoom = (messageThreadId: number) =>
  socket.emit(kJoinMessageThreadEvent, { messageThreadId });

export const leaveMessageThreadRoom = (messageThreadId: number) =>
  socket.emit(kLeaveMessageThreadEvent, { messageThreadId });
