export interface FeedPostView {
    entityType: EntityType;
    entityId: number;
    UserId?: number | undefined;
}

export enum EntityType {
    post = 'post',
    pages = 'pages',
}
