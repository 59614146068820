import { Flex, Heading, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import FeedCoverImage from '../../components/FeedCoverImage';
import { FeedModel } from '../../models';
import { FEED_PAGE_ROUTE } from '../../routes/routeList';

interface FeedCardProps {
  feed: FeedModel;
}

const FeedCard: FC<FeedCardProps> = ({ feed }) => {
  return (
    <Link to={FEED_PAGE_ROUTE.replace(':id', feed.id.toString())}>
      <Flex
        flexDirection="column"
        bg="white"
        border="1px solid"
        borderColor="gray.200"
        rounded="sm"
        px="2"
      >
        <Heading py="2" isTruncated>
          {feed.name}
        </Heading>
        <FeedCoverImage
          coverImageUrl={feed.coverImageUrl}
          maxH="52"
          objectFit="cover"
        />
        <Text py="2" noOfLines={4}>
          {feed.description}
        </Text>
      </Flex>
    </Link>
  );
};

export default FeedCard;
