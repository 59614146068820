import _ from 'lodash';

import useUnreadGroupChatsRecord from './useUnreadGroupChatsRecord';

const useUnreadGroupChatsCount = (): number => {
  const [unreadGroupChatsRecord] = useUnreadGroupChatsRecord();
  const initialUnreadCount = 0;

  // Using _.reduce, we go through all the unread group chats and sum up their unread counts.
  // _.reduce gives us the value of the object (the unread count) as the second argument
  return _.reduce(
    unreadGroupChatsRecord,
    (totalUnreadGroupChats, unreadCount) => {
      if (unreadCount > 0) {
        return ++totalUnreadGroupChats;
      }

      return totalUnreadGroupChats;
    },
    initialUnreadCount
  );
};

export default useUnreadGroupChatsCount;
