import { useQuery } from 'react-query';

import { getAllLinksOfDashboardSection } from '../../../apis/dashboard-section-links.api';
import { DashboardSection } from '../../../models/DashboardSection.model';
import { DashboardSectionLink } from '../../../models/DashboardSectionLink.model';

interface UseDashboardSectionsOptions {
  dashboardSectionId: DashboardSection['id'];
}

const useDashboardSectionLinks = ({
  dashboardSectionId,
}: UseDashboardSectionsOptions) => {
  const { data: dashboardSectionLinks } = useQuery<DashboardSectionLink[]>(
    `getDashboardSectionLinks-${dashboardSectionId}`,
    () => getAllLinksOfDashboardSection(dashboardSectionId),
  );

  return [dashboardSectionLinks || []];
};

export default useDashboardSectionLinks;
