import { useHistory } from 'react-router';
import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { FiArrowLeft } from 'react-icons/fi';

interface GoBackButtonProps extends Omit<IconButtonProps, 'aria-label'> {}

const GoBackButton: React.FC<GoBackButtonProps> = ({ ...props }) => {
  const history = useHistory();

  // Don't override the onClick button since that's the main functionality here
  return (
    <IconButton
      aria-label='back icon'
      icon={<FiArrowLeft />}
      boxSize='6'
      variant='ghost'
      {...props}
      onClick={history.goBack}
    />
  );
};

export default GoBackButton;
