import { QueryKey, useQuery } from 'react-query';
import { getLikesOfPost } from 'src/apis/likes.api';
import { Comment } from 'src/models/Comment.model';
import { EntityType } from 'src/models/View.model';

const getIndividualLikes = (
  entityId: Comment['EntityId'],
  entityType: Comment['entityType'],
): QueryKey => ['getLikesOfPost', entityId, entityType];

const useLikes = (entityId: number, entityType: EntityType) => {
  const { data, refetch } = useQuery(
    getIndividualLikes(entityId, entityType),
    () => getLikesOfPost(entityId, entityType),
    {
      staleTime: 1000 * 60 * 2, // 2 minutes
    },
  );

  return { data, refetch };
};
export default useLikes;
