import { axiosInstance } from 'src/config/axiosInstance';

import { GroupCalls } from 'src/models/GroupCall';
import { getAccessToken } from 'src/providers/auth-store.provider';
import { getRequestData } from './utils/get-request-data.util';

axiosInstance.interceptors.request.use(config => {
  const accessToken = getAccessToken();
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return config;
});

const kGroupCallsUrl = 'calls/group';

export const getAllGroupCalls = async (): Promise<GroupCalls[]> => {
  const groupCalls = await getRequestData<GroupCalls[]>(
    axiosInstance.get(kGroupCallsUrl),
  );

  return groupCalls.map<GroupCalls>(groupCall => ({
    ...groupCall,
    createdAt: new Date(groupCall.createdAt),
  }));
};
