import { Center, Flex, Text } from '@chakra-ui/react';

import { ONE_MEMBER_IN_GROUP as ONE_JOINED_CALL_SESSION } from 'src/constants/group-call.constant';
import useCall from 'src/pages/call/hooks/useCall';
import useCallSessions from 'src/pages/call/hooks/useCallSessions';
import UserAvatar from '../../../../../components/UserAvatar';
import { CallSession } from '../../../../../models/CallSession.model';

interface CallUserImageProps {
  receiver: CallSession['receiver'];
  joinedCall?: Date | null;
  isCurrentUser?: boolean;
}

const CallUserImage: React.FC<CallUserImageProps> = ({
  receiver,
  joinedCall,
  isCurrentUser,
}) => {
  const { callSessions } = useCallSessions();

  const { call } = useCall();

  if (call && !call.isGroupCall) {
    return (
      <Center h='full' w='full' bgColor='gray.700'>
        <UserAvatar user={receiver} size='xl' fontSize='44' />
      </Center>
    );
  }

  return (
    <Center h='full' w='full' bgColor='gray.700' flexDirection='column'>
      <Flex flexDirection='column' alignContent='center'>
        <UserAvatar user={receiver} size='xl' fontSize='44' />
        {!isCurrentUser &&
          callSessions.length !== ONE_JOINED_CALL_SESSION &&
          !joinedCall && <Text> connecting....</Text>}
      </Flex>
    </Center>
  );
};

export default CallUserImage;
