import { useCallback, useEffect, useState } from 'react';
import * as _ from 'lodash';

import { MessageDto } from '../../../dto/messages.dto';
import { PersonalMessageThread } from '../../../models/PersonalMessageThread.model';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useNewMessageObserver from './useNewMessageObserver';

type MessageThreadUnreadMessageCountRecord = Record<
  PersonalMessageThread['id'],
  number
>;

const useUnreadMessageThreads = (
  messageThreads: PersonalMessageThread[]
): MessageThreadUnreadMessageCountRecord => {
  const [currentUser] = useCurrentUser();
  const [unreadMessageThreadMap, setUnreadMessageThreadMap] =
    useState<MessageThreadUnreadMessageCountRecord>({});

  useEffect(() => {
    setUnreadMessageThreadMap((prev) =>
      _.reduce(
        messageThreads,
        (acc, msgThread) => {
          return {
            ...acc,
            [msgThread.id]: prev[msgThread.id] || msgThread.unreadMessagesCount,
          };
        },
        {}
      )
    );
  }, [messageThreads]);

  const handleNewMessage = useCallback(
    (message: MessageDto) => {
      const { MessageThreadId } = message;

      if (message.SenderId !== currentUser?.id) {
        setUnreadMessageThreadMap((prevMsgThreadUnreadMsgCountMap) => {
          const prevUnreadMsgCount =
            prevMsgThreadUnreadMsgCountMap[MessageThreadId] || 0;

          return {
            ...prevMsgThreadUnreadMsgCountMap,
            [MessageThreadId]: prevUnreadMsgCount + 1,
          };
        });
      }
    },
    [currentUser?.id]
  );

  useNewMessageObserver({ handleNewMessage });

  return unreadMessageThreadMap;
};

export default useUnreadMessageThreads;
