import { Input, InputProps } from '@chakra-ui/input';
import { Box, BoxProps, HStack } from '@chakra-ui/layout';

interface BasicIputPanelProps extends BoxProps {
  inputPrefix?: React.ReactNode;
  inputSuffix?: React.ReactNode;
  inputProps?: InputProps;
}

const BasicInputPanel: React.FC<BasicIputPanelProps> = ({
  inputPrefix,
  inputSuffix,
  inputProps = {},
  ...props
}) => {
  return (
    <Box
      borderColor='gray'
      borderWidth='1px'
      borderRadius='8'
      w='full'
      p='2'
      {...props}
    >
      <HStack>
        {inputPrefix}
        <Input
          h='full'
          pl='1'
          variant='unstyled'
          placeholder='Type something here...'
          flexGrow={2}
          {...inputProps}
        />
        {inputSuffix}
      </HStack>
    </Box>
  );
};

export default BasicInputPanel;
