import { useQuery } from 'react-query';

import { getIceServersUrl } from 'src/apis/ice-servers-url.api';

const useIceServers = () => {
    const { data: iceServers } = useQuery('getIceServers', getIceServersUrl);

    return iceServers;
};

export default useIceServers;
