import { Image, ImageProps } from '@chakra-ui/image';
import { BoxProps, HStack, Text } from '@chakra-ui/layout';

import { isImage } from '../../../utils/mime-type.utils';
import MediaPreviewIcon from './MediaPreviewIcon';

interface MediaWithMime {
  mimeType: string;
  mediaUrl: string;
  previewName?: string;
}

interface MediaPreviewProps extends BoxProps {
  media: MediaWithMime;
}

const MediaPreview: React.FC<MediaPreviewProps> = ({ media, ...props }) => {
  const { mimeType: mediaMimeType, mediaUrl, previewName } = media;

  if (isImage(mediaMimeType)) {
    return <Image src={mediaUrl} {...(props as ImageProps)} />;
  }

  const split = mediaUrl.split('/');
  const displayName = previewName || split[split.length - 1];

  return (
    <HStack {...props}>
      <MediaPreviewIcon mimeType={mediaMimeType} boxSize='6' />
      <Text textOverflow='ellipsis' whiteSpace='nowrap' overflow='hidden'>
        {displayName}
      </Text>
    </HStack>
  );
};

export default MediaPreview;
