import { ValueCallback } from 'src/types/common.type';
import { CallSession } from 'src/models/CallSession.model';
import { socket } from 'src/config/socketIoInstance';

enum CallSessionsEvents {
  callSessionIncoming = 'call_sessions:incoming',
  callSessionAccepted = 'call_sessions:accepted',
  callSessionRejected = 'call_sessions:rejected',
  callSessionConnected = 'call_sessions:connected',
  callSessionUnreachable = 'call_sessions:unreachable',
  callSessionMissed = 'call_sessions:missed',
  callSessionDisconnected = 'call_sessions:disconnected',
  callSessionCreatedOnExistingCall = 'call_sessions:created_on_existing_call',
  callSessionRemoveOnExistingCall = 'call_sessions:remove_on_existing_call',
}

export type CallSessionHandler = ValueCallback<CallSession>;

export const subscribeIncomingCall = (handler: CallSessionHandler) => {
  socket.on(CallSessionsEvents.callSessionIncoming, handler);
};

export const unsubscribeIncomingCall = (handler: CallSessionHandler) => {
  socket.off(CallSessionsEvents.callSessionIncoming, handler);
};

export const subscribeCallConnected = (handler: CallSessionHandler) => {
  socket.on(CallSessionsEvents.callSessionConnected, handler);
};

export const unsubscribeCallConnected = (handler: CallSessionHandler) => {
  socket.off(CallSessionsEvents.callSessionConnected, handler);
};

export const subscribeCallAccepted = (handler: CallSessionHandler) => {
  socket.on(CallSessionsEvents.callSessionAccepted, handler);
};

export const unsubscribeCallAccepted = (handler: CallSessionHandler) => {
  socket.off(CallSessionsEvents.callSessionAccepted, handler);
};

export const subscribeCallRejected = (handler: CallSessionHandler) => {
  socket.on(CallSessionsEvents.callSessionRejected, handler);
};

export const unsubscribeCallRejected = (handler: CallSessionHandler) => {
  socket.off(CallSessionsEvents.callSessionRejected, handler);
};

export const subscribeCallUnreachable = (handler: CallSessionHandler) => {
  socket.on(CallSessionsEvents.callSessionUnreachable, handler);
};

export const unsubscribeCallUnreachable = (handler: CallSessionHandler) => {
  socket.off(CallSessionsEvents.callSessionUnreachable, handler);
};

export const subscribeCallMissed = (handler: CallSessionHandler) => {
  socket.on(CallSessionsEvents.callSessionMissed, handler);
};

export const unsubscribeCallMissed = (handler: CallSessionHandler) => {
  socket.off(CallSessionsEvents.callSessionMissed, handler);
};

export const subscribeCallSessionDisconnected = (
  handler: CallSessionHandler,
) => {
  socket.on(CallSessionsEvents.callSessionDisconnected, handler);
};

export const unsubscribeCallSessionDisconnected = (
  handler: CallSessionHandler,
) => {
  socket.off(CallSessionsEvents.callSessionDisconnected, handler);
};

export const unsubscribeCallSessionExistingGroupCallConnected = (
  handler: CallSessionHandler,
) => {
  socket.off(CallSessionsEvents.callSessionCreatedOnExistingCall, handler);
};

export const subscribeCallSessionExistingGroupCallConnected = (
  handler: CallSessionHandler,
) => {
  socket.on(CallSessionsEvents.callSessionCreatedOnExistingCall, handler);
};

export const unsubscribeCallSessionWhenSomeoneLeavesInBetween = (
  handler: CallSessionHandler,
) => {
  socket.off(CallSessionsEvents.callSessionRemoveOnExistingCall, handler);
};

export const subscribeCallSessionWhenSomeoneLeavesInBetween = (
  handler: CallSessionHandler,
) => {
  socket.on(CallSessionsEvents.callSessionRemoveOnExistingCall, handler);
};
