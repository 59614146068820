import { Box, Badge, BadgeProps, BoxProps } from '@chakra-ui/layout';

interface BadgeBoxProps extends BoxProps {
  badgeProps?: Omit<BadgeProps, 'children'>;
  badgeContent?: React.ReactNode;
  showBadge?: boolean;
}

const BadgeBox: React.FC<BadgeBoxProps> = ({
  children,
  badgeProps = {},
  showBadge,
  badgeContent,
  ...props
}) => {
  return (
    <Box position='relative' {...props}>
      {children}
      {showBadge && (
        <Badge
          bg='blue.500'
          color='white'
          position='absolute'
          top='0'
          right='0'
          borderRadius='16'
          {...badgeProps}
        >
          {badgeContent}
        </Badge>
      )}
    </Box>
  );
};

export default BadgeBox;
