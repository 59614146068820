import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  ModalProps,
  ButtonGroup,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import _ from 'lodash';

import { ValueCallback } from '../../../../types/common.type';
import { CreateGroupMessageThreadDto } from '../../../../dto/group-messageThreads.dto';
import { createGroupMessageThread } from '../../../../apis/group-message-threads.api';
import { GroupMessageThread } from '../../../../models/GroupMessageThread.model';
import AddUsers, { SelectedUserMap } from './AddUsers.step';
import { useSteps } from '../../../../hooks/useSteps';
import GroupMetadataForm, {
  kNewGroupChatFormSchema,
  NewGroupChatFormData,
} from './GroupMetadataForm.step';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';

interface NewGroupChatDialogProps extends Omit<ModalProps, 'children'> {
  onChatCreated: ValueCallback<GroupMessageThread>;
}

const kSteps = {
  groupMetadata: 0,
  addUsers: 1,
};
const kGroupMetadataFormId = 'new-groupchat-metadata';

const NewGroupChatDialog: React.FC<NewGroupChatDialogProps> = ({
  onChatCreated,
  ...props
}) => {
  const {
    data: newlyCreatedGroupChat,
    isLoading: isCreatingGroup,
    isSuccess: isCreateGroupSuccess,
    mutate: createGroupChat,
  } = useMutation<GroupMessageThread, any, CreateGroupMessageThreadDto>(
    'createGroupMessageThread',
    createGroupMessageThread,
  );
  const [selectedUsers, setSelectedUsers] = useState<SelectedUserMap>({});
  const { activeStep, prevStep, nextStep } = useSteps({
    initialStep: kSteps.groupMetadata,
  });
  const groupMetadataFormProps = useForm<NewGroupChatFormData>({
    resolver: yupResolver(kNewGroupChatFormSchema),
  });

  useEffect(() => {
    if (isCreateGroupSuccess && newlyCreatedGroupChat) {
      onChatCreated(newlyCreatedGroupChat);
      setSelectedUsers({});
    }
  }, [isCreateGroupSuccess, newlyCreatedGroupChat, onChatCreated]);

  const allowCreatingGroup = !_.isEmpty(selectedUsers);
  const hasMetadataErrors = !_.isEmpty(groupMetadataFormProps.formState.errors);

  const handleFormSubmit = () => {
    if (allowCreatingGroup && !hasMetadataErrors) {
      const metadata = groupMetadataFormProps.getValues();
      const selectedUserIds = _.map(selectedUsers, 'id');
      createGroupChat({ ...metadata, recipients: selectedUserIds });
    }
  };

  return (
    <Modal isCentered scrollBehavior='inside' {...props}>
      <ModalOverlay />

      <ModalContent
        mx='10'
        minH={activeStep === kSteps.groupMetadata ? 'unset' : '50%'}
        maxH='75%'
      >
        <ModalHeader>New Group Chat</ModalHeader>

        <ModalBody>
          {activeStep === kSteps.groupMetadata ? (
            <GroupMetadataForm
              formProps={groupMetadataFormProps}
              onSubmit={nextStep}
              id={kGroupMetadataFormId}
            />
          ) : (
            <AddUsers
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
            />
          )}
        </ModalBody>

        <ModalFooter>
          {activeStep === kSteps.groupMetadata ? (
            <Button
              disabled={hasMetadataErrors}
              colorScheme='blue'
              rightIcon={<BiRightArrowAlt />}
              type='submit'
              form={kGroupMetadataFormId}
            >
              Next
            </Button>
          ) : (
            <ButtonGroup>
              <Button onClick={prevStep} leftIcon={<BiLeftArrowAlt />}>
                Prev
              </Button>
              <Button
                isLoading={isCreatingGroup}
                isDisabled={!allowCreatingGroup || hasMetadataErrors}
                cursor={allowCreatingGroup ? 'pointer' : 'not-allowed'}
                colorScheme='blue'
                onClick={handleFormSubmit}
              >
                Create Group
              </Button>
            </ButtonGroup>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NewGroupChatDialog;
