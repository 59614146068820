export const videoConstraints = {
    video: {
        width: 640,
        height: 480,
        frameRate: 30,
        facingMode: 'user',
    },
};

export const audioConstraints = {
    audio: {
        advanced: [
            {
                echoCancellation: true,
                noiseSuppression: true,
                autoGainControl: true,
            },
        ],
    },
};
