import { Box, Grid } from '@chakra-ui/react';
import { useDisableLoader } from 'src/hooks/useDisableLoader';
import UserMediaProvider from '../components/footer/context/user-media/provider/UserMedia.provider';
import PeerConnectionProvider from '../context/peer-connection/provider';
import useCallParams from '../hooks/useCallParams';
import useCallSessionsUpdates from '../hooks/useCallSessionUpdates';
import GroupCallBody from './components/body';
import GroupCallFooter from './components/footer';
import GroupCallHeader from './components/header';

const GroupCall = () => {
  useCallSessionsUpdates();
  useDisableLoader();

  return (
    <Box maxW='xl' mx='auto'>
      <Grid maxH='100vh'>
        <GroupCallHeader />
        <PeerConnectionProvider>
          <UserMediaProvider>
            <GroupCallBody />
            <GroupCallFooter />
          </UserMediaProvider>
        </PeerConnectionProvider>
      </Grid>
    </Box>
  );
};

const GroupCallPageWrapper = () => {
  const { callId } = useCallParams();

  return <GroupCall key={`group-call-page-${callId}`} />;
};

export default GroupCallPageWrapper;
