export enum GroupCallParams {
  callId = 'callId',
}

const asParam = (param: string): string => `:${param}`;
const kGroupCallPageRegex = /^\/group-call\/(\d+)$/;

export const buildGroupCallPageRoute = (callId: string): string => {
  return GROUP_CALL_ROUTE.replace(asParam(GroupCallParams.callId), callId);
};

export const GROUP_CALL_ROUTE = `/group-call/${asParam(
  GroupCallParams.callId,
)}`;

export const isGroupCallPageRoute = (route: string): boolean =>
  kGroupCallPageRegex.test(route);
