import {
  insertNodes,
  getPluginType,
  ELEMENT_IMAGE,
  ELEMENT_PARAGRAPH,
  PlateEditor,
} from '@udecode/plate';
import { ImageElement } from 'slate';

interface ImageDimensions {
  height: number;
  width: number;
}

// @future infer image dimensions from file
// currently, there's one way: load to an <img /> tag and onload, get the dimensions
// is there a better way?
const kDefaultImageDimensions: ImageDimensions = { height: 300, width: 600 };

const insertParagraph = (editor: PlateEditor) => {
  insertNodes(editor, {
    type: getPluginType(editor, ELEMENT_PARAGRAPH),
    children: [{ text: '' }],
  });
};

export const insertImage = (editor: PlateEditor, imageUrl: string) => {
  insertNodes<ImageElement>(editor, {
    type: getPluginType(editor, ELEMENT_IMAGE),
    url: imageUrl,
    align: 'left',
    children: [{ text: '' }],
    ...kDefaultImageDimensions,
  });

  // inserting paragraph after image to allow the user to continue typing
  insertParagraph(editor);
};
