import {
  IconButton,
  IconButtonProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
} from '@chakra-ui/react';
import { useState } from 'react';

import DeviceUploadMediaButton from './components/DeviceUploadMediaButton';
import InsertMediaUsingURLOption from './components/InsertMediaUsingURLOption';
import MenuItem from './components/MenuItem';

interface MediaEmbedToolbarButtonProps
  extends Omit<IconButtonProps, 'icon' | 'aria-label'> {
  icon: Required<IconButtonProps['icon']>;
}

export const MediaEmbedToolbarButton: React.FC<MediaEmbedToolbarButtonProps> =
  props => {
    const [isMenuOpen, setMenuOpen] = useState(false);

    const handleMediaInserted = () => {
      setMenuOpen(false);
    };

    return (
      <Menu
        placement='bottom'
        isOpen={isMenuOpen}
        onOpen={() => setMenuOpen(true)}
        onClose={() => setMenuOpen(false)}
        closeOnSelect={false}
      >
        <MenuButton
          as={IconButton}
          aria-label='Add Media'
          variant='ghost'
          icon={props.icon}
        />
        {/* 
          The editor toolbar menus, icons have similar color scheme(s). Find a way to 
          keep the color scheme consistent across the editor. Maybe a custom theme just for the editor?
          See: https://chakra-ui.com/docs/theming/customize-theme
        */}
        <MenuList bgColor='gray.700' fontSize='sm'>
          {/* 
            Why `as='div'? MenuItem renders as a button by default. Since we have another button
            as a direct child of the said MenuItem, we don't want the MenuItem to be a button.
            Since we have no interaction with the menu item itself (closeOnSelect, etc), we can safely
            use the `as='div'` prop.
            Reason: button should not be a direct child of another button. Ref: https://html.spec.whatwg.org/
          */}
          <MenuItem as='div' _hover={{ bg: 'gray.800' }}>
            <DeviceUploadMediaButton onMediaInserted={handleMediaInserted} />
          </MenuItem>
          <MenuDivider borderColor='gray.600' />
          <InsertMediaUsingURLOption onMediaInserted={handleMediaInserted} />
        </MenuList>
      </Menu>
    );
  };

export default MediaEmbedToolbarButton;
