import { Route, Switch } from 'react-router-dom';

import {
    FEEDS_PAGE_ROUTE,
    FEED_MEMBERS_PAGE_ROUTE,
    FEED_PAGE_ROUTE,
    HOME_PAGE_ROUTE,
    MY_PROFILE_ROUTE,
    USER_PROFILE_ROUTE,
    LOGIN_PAGE_ROUTE,
    REGISTER_PAGE_ROUTE,
    MESSAGES_PAGE_ROUTE,
    CHAT_PAGE_ROUTE,
    MESSAGE_MEMBERS_PAGE_ROUTE,
    PAGE_MEMBERS_PAGE_ROUTE,
    PAGE_CONTENT_PAGE_ROUTE,
    DRAFT_REQUEST_PAGE_ROUTE,
    APPROVE_USER_PAGE_ROUTE,
    CALL_PAGE_ROUTE,
    CALLS_PAGE_ROUTE,
    GROUP_CALL_ROUTE,
    COMMENT_CARD_ROUTE,
} from './routeList';

import HomePage from '../pages';
import FeedsPage from '../pages/feeds';
import FeedPage from '../pages/feeds/feed';
import FeedMembersPage from '../pages/feed-members/FeedMembersPage';
import MyProfilePage from '../pages/users/me';
import UserProfilePage from '../pages/users/profile';
import LoginPage from '../pages/login/LoginPage';
import RegisterPage from '../pages/register/RegisterPage';
import MessagesPage from '../pages/messages/MessagesPage';
import MessageMembersPage from '../pages/message-members/MessageMembersPage';

import ChatPage from '../pages/chat/ChatPage';
import BlogsPage from '../pages/blogs/BlogsPage';
import BlogPage from '../pages/blogs/blog/BlogPage';
import PageMembersPage from 'src/pages/page-members/PageMembersPage';
import EditPageContent from 'src/pages/page-members/edit-page-content/EditPageContent';
import DraftPageContent from 'src/pages/page-members/edit-page-content/DraftPageContent';
import ApprovePageContent from 'src/pages/page-members/edit-page-content/ApprovePageContent';
import CallPage from 'src/pages/call/CallPage';
import CallsPage from 'src/pages/calls/CallsPage';
import GroupCall from 'src/pages/call/group-call/group-call-page';
import { CommentCard } from 'src/pages/feeds/feed/CommentCard';



const Routes = () => {
    return (
        <Switch>
            <Route path={FEEDS_PAGE_ROUTE} exact component={FeedsPage} />
            <Route path={FEED_PAGE_ROUTE} exact component={FeedPage} />
            <Route
                path={FEED_MEMBERS_PAGE_ROUTE}
                exact
                component={FeedMembersPage}
            />
            <Route path={HOME_PAGE_ROUTE} exact component={HomePage} />
            <Route
                path={USER_PROFILE_ROUTE}
                exact
                component={UserProfilePage}
            />
            <Route path={MY_PROFILE_ROUTE} exact component={MyProfilePage} />
            <Route path={LOGIN_PAGE_ROUTE} exact component={LoginPage} />
            <Route path={REGISTER_PAGE_ROUTE} exact component={RegisterPage} />
            <Route path={MESSAGES_PAGE_ROUTE} exact component={MessagesPage} />
            <Route path={CHAT_PAGE_ROUTE} exact component={ChatPage} />
            <Route
                path={MESSAGE_MEMBERS_PAGE_ROUTE}
                exact
                component={MessageMembersPage}
            />
            <Route path='/pages' exact component={BlogsPage} />
            <Route path='/pages/:id' exact component={BlogPage} />
            <Route
                path={PAGE_MEMBERS_PAGE_ROUTE}
                exact
                component={PageMembersPage}
            />
            <Route
                path={PAGE_CONTENT_PAGE_ROUTE}
                exact
                component={EditPageContent}
            />
            <Route
                path={DRAFT_REQUEST_PAGE_ROUTE}
                exact
                component={DraftPageContent}
            />
            <Route
                path={APPROVE_USER_PAGE_ROUTE}
                exact
                component={ApprovePageContent}
            />
            <Route path={CALL_PAGE_ROUTE} exact component={CallPage} />

             <Route path={CALLS_PAGE_ROUTE} exact component={CallsPage} />
            <Route path={GROUP_CALL_ROUTE} exact component={GroupCall} />

             

            <Route path={COMMENT_CARD_ROUTE} exact component={CommentCard} />
        </Switch>
    );
};

export default Routes;
