import { forwardRef } from 'react';
import { Input, InputProps } from '@chakra-ui/input';

export interface FileInputProps extends Omit<InputProps, 'type' | 'onChange'> {
  onFiles?: (
    files: HTMLInputElement['files'],
    event: React.ChangeEvent<HTMLInputElement>
  ) => any;
}

const FileInput = forwardRef<HTMLInputElement, FileInputProps>(
  ({ onFiles, ...props }, ref) => {
    // Since this is a file input, it would be a good idea to have a easy way to directly access the files
    // Hence we take the files in the target and pass it as the first argument and the event as the second in case the caller needs it
    const onChange: InputProps['onChange'] = (e) =>
      onFiles && onFiles(e.target.files, e);

    return (
      <Input
        ref={ref}
        type="file"
        display="none"
        onChange={onChange}
        {...props}
      />
    );
  }
);

export default FileInput;
