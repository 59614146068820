import { ValueCallback } from '../types/common.type';

type MimeTypeChecker = ValueCallback<string, boolean>;

export const isImage: MimeTypeChecker = mimeType => /image/.test(mimeType);

export const isAudio: MimeTypeChecker = mimeType => /audio/.test(mimeType);

export const isVideo: MimeTypeChecker = mimeType => /video/.test(mimeType);

export const isBinary: MimeTypeChecker = mimeType =>
  /application\/octet-stream/.test(mimeType);

export const isText: MimeTypeChecker = mimeType => /text/.test(mimeType);

export const isPdf: MimeTypeChecker = mimeType =>
  /application\/pdf/.test(mimeType);
