import { useQuery } from 'react-query';
import {
  getAllMembersForPublicPage,
  getAllPageMembers,
} from 'src/apis/pageMembers.api';
import { PageMembers } from 'src/models/PageMembersPage.model';

import useCurrentUser from '../../../hooks/useCurrentUser';
import useArrayDataQuery from '../../../utils/query-utils/useArrayDataQuery';

interface UsePageMembersOptions {
  pageId: number;
}

const usePageMembers = ({ pageId }: UsePageMembersOptions) => {
  const [currentUser, { isLoading: isLoadingUser }] = useCurrentUser();

  const getAllPageMembersQueryKey = ['getAllPageMembers', pageId];
  const { data: pageMembers } = useQuery(
    getAllPageMembersQueryKey,
    () => {
      if (currentUser) {
        return getAllPageMembers(pageId);
      }

      return getAllMembersForPublicPage(pageId);
    },
    { enabled: !isLoadingUser },
  );

  const { addOne, addMultiple, updateOne, removeOne } =
    useArrayDataQuery<PageMembers>({
      queryKey: getAllPageMembersQueryKey,
    });

  return {
    pageMembers,
    addMember: addOne,
    addMultipleMembers: addMultiple,
    updateMember: updateOne,
    removeMember: removeOne,
  };
};

export default usePageMembers;
