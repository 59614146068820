import { Center, Flex } from '@chakra-ui/react';
import * as _ from 'lodash';

import useCurrentUser from 'src/hooks/useCurrentUser';
import { CallSession } from 'src/models/CallSession.model';
import MicrophoneButton from 'src/pages/call/components/footer/components/MicrophoneButton';
import SpeakerButton from 'src/pages/call/components/footer/components/SpeakerButton';
import VideoButton from 'src/pages/call/components/footer/components/VideoButton';
import useCallSessions from 'src/pages/call/hooks/useCallSessions';
import EndGroupCallButton from '../body/components/EndCallButton';
import { ONE_MEMBER_IN_GROUP as ONE_JOINED_CALL_SESSION } from 'src/constants/group-call.constant';
import { maxWidth } from 'src/constants/max-width.constant';
import UserSelfVideo from 'src/pages/call/components/footer/components/UserSelfVideo';

const GroupCallFooter = () => {
  const { callSessions } = useCallSessions();
  const [currentUser] = useCurrentUser();

  const isDisabled = !_.some(
    _.filter(
      callSessions,
      callSession =>
        currentUser &&
        (callSession.ReceiverId === currentUser.id ||
          callSession.CallerId === currentUser.id),
    ) as CallSession[],
    callSession => !!callSession.joinedAt,
  );

  return (
    <>
      {callSessions.length === ONE_JOINED_CALL_SESSION ? (
        <Center position='absolute' w='full' bottom='0' left='0'>
          <Center
            position='relative'
            bgColor='blue.500'
            w='full'
            h='20'
            maxW={maxWidth}
          >
            <Flex justifyContent='space-around' w='full'>
              <SpeakerButton isSpeakerDisabled={isDisabled} />
              <VideoButton isVideoDisabled={isDisabled} />
              <MicrophoneButton isMicDisabled={isDisabled} />
              <EndGroupCallButton />
            </Flex>
            <UserSelfVideo />
          </Center>
        </Center>
      ) : (
        <Center w='full' h='20'>
          <Center position='relative' bgColor='blue.500' w='full' h='20'>
            <Flex justifyContent='space-around' w='full'>
              <SpeakerButton isSpeakerDisabled={isDisabled} />
              <VideoButton isVideoDisabled={isDisabled} />
              <MicrophoneButton isMicDisabled={isDisabled} />
              <EndGroupCallButton />
            </Flex>
          </Center>
        </Center>
      )}
    </>
  );
};
export default GroupCallFooter;
