import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { EditablePageContent } from '../types/EditablePageContent.type';

import { useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { reviewPage } from 'src/apis/pages.api';
import { Page } from 'src/models/Page.model';
import { useHistory } from 'react-router-dom';

interface UseApprovePageContentOptions extends Pick<Page, 'id'> {}

type PageContentUpdateDto = Pick<Page, 'isApproved'>;

const useDiscardContent = ({ id }: UseApprovePageContentOptions) => {
  const showToast = useToast();

  const history = useHistory();

  const updatePageContentMutation = useMutation<
    Page,
    AxiosError,
    PageContentUpdateDto
  >(pageContentUpdate => reviewPage(id, pageContentUpdate), {
    onSuccess: () => {
      history.goBack();
      showToast({
        title: 'Page content has Discarded',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
    onError: () => {
      showToast({
        title: 'Error',
        description: 'Error while discard page content',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const handleDiscardPageContent = useCallback(
    (isApproved: EditablePageContent) =>
      updatePageContentMutation.mutate({ isApproved: false }),
    [updatePageContentMutation],
  );

  return { handleDiscardPageContent, updatePageContentMutation };
};

export default useDiscardContent;
