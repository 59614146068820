import { Box } from '@chakra-ui/react';
import { PlateRenderElementProps } from '@udecode/plate';
import { MediaEmbedElement as SlateMediaEmbedElement } from 'slate';
import ReactPlayer from 'react-player/lazy';
import { useMemo } from 'react';
import _ from 'lodash';

import { getDocumentUrl } from 'src/config/document-url.config';

interface MediaEmbedElementProps
  extends PlateRenderElementProps<SlateMediaEmbedElement> {}

const MediaEmbedElement: React.FC<MediaEmbedElementProps> = ({
  element,
  attributes,
  children,
}) => {
  const isLocalDocumentUrl = useMemo(
    // Document url example = "abcdef.mkv"
    // non-local url example = "https://youtube.com/watch?v=<video-id>"
    () => _.split(element.url, '/').length === 1,
    [element.url],
  );

  return (
    <Box {...attributes} contentEditable={false}>
      <Box
        as={ReactPlayer}
        url={isLocalDocumentUrl ? getDocumentUrl(element.url) : element.url}
        maxW='full'
        maxH='64'
        controls
      />
      {children}
    </Box>
  );
};

export default MediaEmbedElement;
