import { VStack } from '@chakra-ui/layout';
import { UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';

import ErrorMessage from '../../../../components/form/ErrorMessage';
import TextField from '../../../../components/form/TextField';

import { CreateGroupMessageThreadDto } from '../../../../dto/group-messageThreads.dto';
import { ValueCallback } from '../../../../types/common.type';

export type NewGroupChatFormData = Omit<
  CreateGroupMessageThreadDto,
  'recipients'
>;
export type GroupMetadataSubmitCallback = ValueCallback<NewGroupChatFormData>;

interface GroupMetadataFormProps {
  onSubmit: GroupMetadataSubmitCallback;
  id: string;
  formProps: UseFormReturn<NewGroupChatFormData>;
}

export const kNewGroupChatFormSchema: yup.SchemaOf<NewGroupChatFormData> = yup
  .object()
  .shape({
    name: yup
      .string()
      .min(3, ({ min }) => `Group name must be longer than ${min} characters`)
      .max(
        30,
        ({ max }) => `Group name must not be longer than ${max} characters`,
      )
      .required('Please enter a name for the group'),
    description: yup.string().optional(),
  });

const GroupMetadataForm: React.FC<GroupMetadataFormProps> = ({
  onSubmit,
  id,
  formProps,
}) => {
  const { register, handleSubmit, formState } = formProps;

  return (
    <VStack
      align='start'
      spacing='6'
      as='form'
      id={id}
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextField
        label='Name'
        inputProps={register('name')}
        formControlProps={{ isInvalid: !!formState.errors.name }}
      >
        <ErrorMessage error={formState.errors.name} />
      </TextField>
      <TextField
        label='Description (optional)'
        inputProps={register('description')}
        formControlProps={{ isInvalid: !!formState.errors.description }}
      >
        <ErrorMessage error={formState.errors.description} />
      </TextField>
    </VStack>
  );
};

export default GroupMetadataForm;
