import { useState, useCallback, useEffect, useMemo } from 'react';
import { usePeerConnection } from 'src/pages/call/context/peer-connection/hooks/usePeerConnections';
import {
    hasAudioTracks,
    enableAudioTracks,
    disableAudioTracks,
    enableVideoTracks,
    disableVideoTracks,
    hasVideoTracks,
} from 'src/pages/call/utils/media-stream.utils';

import { UserMediaContext } from '../user-media.context';
import { audioConstraints, videoConstraints } from './user-media.constants';

const UserMediaProvider: React.FC = ({ children }) => {
    const [localStream, setLocalStream] = useState<MediaStream | null>(null);
    const [isVideoMuted, setIsVideoMuted] = useState(true);
    const [isAudioMuted, setIsAudioMuted] = useState(true);
    const { addStream, addTrack, notifyDisableVideo, notifyEnableVideo } =
        usePeerConnection();

    useEffect(() => {
        if (localStream) {
            const releaseUserMedia = () => {
                localStream.getTracks().forEach(track => track.stop());
            };

            return releaseUserMedia;
        }
    }, [localStream]);

    const requestUserMedia = useCallback(
        async (constraints: MediaStreamConstraints) => {
            const stream = await navigator.mediaDevices.getUserMedia(
                constraints,
            );
            setLocalStream(stream);
            addStream(stream);
        },
        [addStream],
    );

    const toggleAudio = useCallback(() => {
        setIsAudioMuted(prevAudioMuted => {
            const willAudioBeMuted = !prevAudioMuted;

            if (!localStream) {
                requestUserMedia(audioConstraints);
            } else {
                if (hasAudioTracks(localStream)) {
                    if (willAudioBeMuted) {
                        disableAudioTracks(localStream!);
                    } else {
                        enableAudioTracks(localStream!);
                    }
                } else {
                    const fetchAudioAndMerge = async () => {
                        const audioStream =
                            await navigator.mediaDevices.getUserMedia(
                                audioConstraints,
                            );

                        if (audioStream) {
                            audioStream.getAudioTracks().forEach(track => {
                                localStream.addTrack(track);
                                addTrack(track, localStream);
                            });
                        }
                    };

                    fetchAudioAndMerge();
                }
            }

            return willAudioBeMuted;
        });
    }, [localStream, requestUserMedia, addTrack]);

    const toggleVideo = useCallback(() => {
        notifyEnableVideo();
        setIsVideoMuted(prevVideoMuted => {
            const willVideoBeMuted = !prevVideoMuted;

            if (!localStream) {
                requestUserMedia(videoConstraints);
            } else {
                if (hasVideoTracks(localStream)) {
                    if (willVideoBeMuted) {
                        notifyDisableVideo();
                        disableVideoTracks(localStream!);
                    } else {
                        notifyEnableVideo();
                        enableVideoTracks(localStream!);
                    }
                } else {
                    const fetchVideoAndMerge = async () => {
                        const videoStream =
                            await navigator.mediaDevices.getUserMedia(
                                videoConstraints,
                            );

                        if (videoStream) {
                            videoStream.getVideoTracks().forEach(track => {
                                localStream.addTrack(track);
                                addTrack(track, localStream);
                            });
                        }
                    };

                    fetchVideoAndMerge();
                }
            }

            return willVideoBeMuted;
        });
    }, [
        localStream,
        requestUserMedia,
        addTrack,
        notifyDisableVideo,
        notifyEnableVideo,
    ]);

    const contextValue = useMemo(
        () => ({
            localStream,
            requestUserMedia,
            isAudioMuted,
            toggleAudio,
            isVideoMuted,
            toggleVideo,
        }),
        [
            localStream,
            requestUserMedia,
            isAudioMuted,
            toggleAudio,
            isVideoMuted,
            toggleVideo,
        ],
    );

    return (
        <UserMediaContext.Provider value={contextValue}>
            {children}
        </UserMediaContext.Provider>
    );
};

export default UserMediaProvider;
