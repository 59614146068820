import { VStack, Flex, Box, Text, BoxProps } from '@chakra-ui/layout';
import { CloseButton, Image } from '@chakra-ui/react';
import _ from 'lodash';

import MediaPreview from '../../../../components/MediaPreview';
import { isImage, isVideo } from '../../../../utils/mime-type.utils';

interface FilePreviewsProps {
  fileURIs: string[];
  files: File[];
  label: React.ReactNode;
  previewBoxProps?: BoxProps;
  closeBtnAriaLabel: React.AriaAttributes['aria-label'];
  onFileRemove: (src: string, index: number) => any;
}

const FilePreviews: React.FC<FilePreviewsProps> = ({
  fileURIs,
  files,
  onFileRemove,
  label,
  previewBoxProps,
  closeBtnAriaLabel,
}) => {
  if (_.isEmpty(fileURIs)) {
    return null;
  }

  const renderPreview = (src: string, index: number) => {
    const file = files[index];
    if (!file) {
      return null;
    }

    if (isImage(file.type)) {
      return <Image src={src} boxSize='full' objectFit='cover' />;
    }

    if (isVideo(file.type)) {
      return <Box as='video' src={src} autoPlay={false} boxSize='full' />;
    }

    return (
      <MediaPreview
        flexDirection='column'
        media={{ mimeType: file.type, mediaUrl: src, previewName: file.name }}
      />
    );
  };

  return (
    <VStack align='start' spacing='0'>
      <Text ml='1' color='gray.800' fontWeight='500'>
        {label}
      </Text>
      <Flex flexWrap="wrap">
        {_.map(fileURIs, (src, index) => (
          <Box
            key={index}
            mx='1'
            boxSize='24'
            my='2'
            position='relative'
            {...(previewBoxProps || {})}
          >
            <CloseButton
              aria-label={closeBtnAriaLabel}
              position='absolute'
              top='0'
              right='0'
              cursor='pointer'
              borderRadius='full'
              size='sm'
              bg='gray.500'
              color='white'
              zIndex='sticky'
              _hover={{}}
              onClick={() => onFileRemove(src, index)}
            />
            {renderPreview(src, index)}
          </Box>
        ))}
      </Flex>
    </VStack>
  );
};

export default FilePreviews;
