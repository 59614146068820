import { Box, Flex } from '@chakra-ui/react';

import { User } from 'src/models';
import { CallSession } from 'src/models/CallSession.model';
import useCall from 'src/pages/call/hooks/useCall';
import useCallSessions from 'src/pages/call/hooks/useCallSessions';
import { ONE_MEMBER_IN_GROUP as ONE_JOINED_CALL_SESSION } from 'src/constants/group-call.constant';
import { boxSize } from 'src/utils/chakra-theme/theme-blocks/boxSize';
import CallVideo from './CallVideo';

interface PeerVideoProps {
  stream: MediaStream;
  user?: User;
  peerVideoStatus?: boolean;
  session?: CallSession;
  isSmallerScreen?: boolean;
}

const PeerVideo: React.FC<PeerVideoProps> = ({
  stream,
  user,
  peerVideoStatus,
  session,
  isSmallerScreen,
}) => {
  const { callSessions } = useCallSessions();

  const { call } = useCall();

  if (call && !call.isGroupCall) {
    return (
      <Box w='full' h='full'>
        <CallVideo
          stream={stream}
          user={user}
          peerVideoStatus={peerVideoStatus}
          height='full'
        />
      </Box>
    );
  }

  return (
    <Flex w='full' h='full' alignItems='center' backgroundColor='gray.700'>
      {callSessions.length === ONE_JOINED_CALL_SESSION ? (
        <CallVideo
          stream={stream}
          user={user}
          peerVideoStatus={peerVideoStatus}
          session={session}
          height={boxSize.callVideo.sizeWhenOnlyTwo}
        />
      ) : (
        <CallVideo
          stream={stream}
          user={user}
          peerVideoStatus={peerVideoStatus}
          session={session}
          height={
            isSmallerScreen
              ? boxSize.callVideo.size
              : boxSize.videoHeight.height.sm
          }
        />
      )}
    </Flex>
  );
};

export default PeerVideo;
