import useUnreadGroupChatsCount from './useUnreadGroupChatsCount';
import useUnreadPersonalChatsCount from './useUnreadPersonalChatsCount';

const useUnreadChatsCount = () => {
  const unreadPersonalChats = useUnreadPersonalChatsCount();
  const unreadGroupChats = useUnreadGroupChatsCount();

  return unreadPersonalChats + unreadGroupChats;
};

export default useUnreadChatsCount;
