export const convertToCardDate = (date: string) => {
  if (!date) {
    return '-';
  }
  return Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour12: true,
    hour: '2-digit',
    minute: '2-digit',
  }).format(new Date(date));
};
