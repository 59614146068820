import { useCallback } from 'react';

import { joinUserRoom } from '../apis/socket.io/users.socket.io';
import useCurrentUser from './useCurrentUser';

const useUserRoom = () => {
  const [currentUser] = useCurrentUser();

  const joinRoom = useCallback<() => boolean>(() => {
    if (currentUser?.id) {
      joinUserRoom(currentUser.id);
      return true;
    }

    return false;
  }, [currentUser?.id]);

  return { joinRoom };
};

export default useUserRoom;
