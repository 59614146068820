import { axiosInstance } from 'src/config';
import { CallSession } from 'src/models/CallSession.model';
import { getRequestData } from './utils/get-request-data.util';

const kCallSessionsUrl = '/call-sessions';

export const acknowledgeCallSession = async (
  callSessionId: CallSession['id'],
): Promise<void> => {
  await axiosInstance.put(`${kCallSessionsUrl}/${callSessionId}/acknowledge`);
};

export const acceptCallSession = async (
  callSessionId: CallSession['id'],
): Promise<void> => {
  await axiosInstance.put(`${kCallSessionsUrl}/${callSessionId}/accept`, {
    joinedAt: new Date(),
  });
};

export const rejectCallSession = async (
  callSessionId: CallSession['id'],
): Promise<void> => {
  await axiosInstance.put(`${kCallSessionsUrl}/${callSessionId}/reject`);
};

export const leaveCallSession = async (
  callSessionId: CallSession['id'],
): Promise<void> => {
  await axiosInstance.put(`${kCallSessionsUrl}/${callSessionId}/leave`);
};

export const getAllSessionsForCall = (
  callId: CallSession['CallId'],
): Promise<CallSession[]> => {
  const url = `${kCallSessionsUrl}/for-call/${callId}`;

  return getRequestData<CallSession[]>(axiosInstance.get(url));
};
