import { axiosInstance } from 'src/config';
import { CreateFileDto } from 'src/dto/documents.dto';
import { Document } from 'src/models/Document.model';
import { getRequestData } from './utils/get-request-data.util';

const kDocumentsUrl = 'documents';

export const createFile = ({ file }: CreateFileDto): Promise<Document> => {
  const formData = new FormData();
  formData.append('file', file);

  return getRequestData<Document>(axiosInstance.post(kDocumentsUrl, formData));
};

export const getDocumentUrl = (documentUrl: string): string => {
  const base = process.env.REACT_APP_UPLOADS_URL || '/uploads';

  return `${base}/${documentUrl}`;
};
