import { BoxProps, HStack, Spacer, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { PageMembers } from 'src/models/PageMembersPage.model';

import UserAvatar from '../../../components/UserAvatar';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { getUserDisplayName } from '../../../utils/user.utils';
import PageMemberActionsMenu from './PageMemberActionsMenu';

interface PageMemberCardProps extends BoxProps {
  pageMember: PageMembers;
  currentUserMemberRecord: PageMembers | undefined;
  onMemberRemoved: () => any;
}

export const PageMemberCard: FC<PageMemberCardProps> = ({
  pageMember,
  currentUserMemberRecord,
  onMemberRemoved,
  ...props
}) => {
  const [currentUser] = useCurrentUser();
  const isCurrentUserAndMemberSame = pageMember.user?.id === currentUser?.id;

  return (
    <HStack spacing='4' w='full' {...props}>
      <UserAvatar user={pageMember.user} />
      <VStack align='start' spacing='0'>
        <HStack>
          <Text fontWeight='bold'>
            {pageMember.user && getUserDisplayName(pageMember.user)}
          </Text>
          {isCurrentUserAndMemberSame && (
            <Text as='span' color='gray' fontWeight='500'>
              (you)
            </Text>
          )}
        </HStack>
        <Text color='gray.500' fontSize='sm'>
          {pageMember.user?.email}
        </Text>
      </VStack>
      <Spacer />
      {!isCurrentUserAndMemberSame &&
        currentUserMemberRecord?.canModerateMembers && (
          <PageMemberActionsMenu
            pageMember={pageMember}
            onMemberRemoved={onMemberRemoved}
          />
        )}
    </HStack>
  );
};
