import {
  Center,
  Divider,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { FC } from 'react';
import { IoEllipsisHorizontal } from 'react-icons/io5';
import UserAvatar from 'src/components/UserAvatar';
import useCurrentUser from 'src/hooks/useCurrentUser';
import { Comment } from 'src/models/Comment.model';
import { Post } from 'src/models/Post.model';
import { convertToCardDate, getUserDisplayName } from 'src/utils';

interface PostCommentCardProps {
  post: Post;
  openReviewDialog: (comment?: Comment) => void;
  openDeleteDialog: () => void;
  isDeleteDialogOpen: boolean;
  closeDeleteDialog: () => void;
  comment: Comment;
  showAllComments: boolean;
  setShowAllComments: (showAllComments: boolean) => void;
  setCommentIdToDelete: (commentId: number) => void;
}

export const PostCommentCard: FC<PostCommentCardProps> = ({
  post,
  openReviewDialog,
  openDeleteDialog,
  isDeleteDialogOpen,
  closeDeleteDialog,
  comment,
  showAllComments,
  setShowAllComments,
  setCommentIdToDelete,
}) => {
  const [currentUser] = useCurrentUser();

  const isCurrentUserComment = comment?.UserId === currentUser?.id;

  return (
    <>
      <Flex bgColor='blue.50' flexDir='column'>
        <Flex m='1.5'>
          <Flex m='2'>
            <UserAvatar size='sm' user={comment.user} />
          </Flex>
          <Flex flexDir='column'>
            <Text fontWeight='bold' fontSize='sm'>
              {comment.user && getUserDisplayName(comment.user)}
            </Text>
            <Text fontSize='sm' color='gray.500'>
              {convertToCardDate(comment.updatedAt)}
            </Text>
          </Flex>
          <Flex>
            <Flex pl={{base:'9rem', sm:'18rem'}}>
              {isCurrentUserComment && (
                <Menu autoSelect={false}>
                  <MenuButton>
                    <Icon as={IoEllipsisHorizontal} boxSize='6' />
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => openReviewDialog(comment)}>
                      Edit Comment
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setCommentIdToDelete(comment.id);
                        openDeleteDialog();
                      }}
                    >
                      <Text color='red.500'>Delete Comment</Text>
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}
            </Flex>
        </Flex>
          </Flex>
        <Flex mb='2' ml='7' >
          <Text fontSize='md' mt='-2'>{comment.description}</Text>
        </Flex>
        <Center pr='10' pl='10' borderColor='gray.400'>
          <Divider orientation='horizontal' />
        </Center>
      </Flex>
    </>
  );
};
