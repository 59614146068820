import { useEffect, useMemo, useState } from 'react';

import useCurrentUser from 'src/hooks/useCurrentUser';

import { getOtherUser } from 'src/pages/call/utils/call-session.utils';
import { getUserDisplayName } from 'src/utils/user.utils';
import usePersonalCallSession from '../../hooks/usePersonalCallSession';

const usePersonalCallPeerName = () => {
  const [peerUserName, setPeerName] = useState<string>();

  const session = usePersonalCallSession();
  const [currentUser] = useCurrentUser();
  const otherUser = useMemo(() => {
    if (session && currentUser) {
      return getOtherUser(session, currentUser.id);
    }
  }, [currentUser, session]);

  useEffect(() => {
    if (otherUser) {
      setPeerName(getUserDisplayName(otherUser));
    } else {
      setPeerName('Unknown user');
    }
  }, [otherUser]);

  return peerUserName;
};

export default usePersonalCallPeerName;
