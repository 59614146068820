import { PlateRenderElementProps, setNodes } from '@udecode/plate';
import { useCallback } from 'react';
import { ImageElement, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

type UseImageModifyOptions = Pick<
  PlateRenderElementProps<ImageElement>,
  'element' | 'editor'
>;

const useImageModify = ({ element, editor }: UseImageModifyOptions) => {
  const setImageAlignment = useCallback(
    (align: ImageElement['align']) => {
      const path = ReactEditor.findPath(editor, element);
      setNodes<ImageElement>(editor, { align }, { at: path });
    },
    [editor, element],
  );

  const removeImage = useCallback(
    () =>
      Transforms.delete(editor, { at: ReactEditor.findPath(editor, element) }),
    [editor, element],
  );

  return { removeImage, setImageAlignment };
};

export default useImageModify;
