import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';

/**
    If we use chakra's ButtonProps, we get this type error:

    ```
        Types of property 'as' are incompatible.
        Type 'string | undefined' is not assignable to type 'As<any> | undefined'.
        Type 'string' is not assignable to type 'As<any> | undefined'
      ```
*/
type ButtonProps = React.ComponentProps<typeof Button>;

interface ConfirmationDialogProps extends Omit<ModalProps, 'children'> {
  onCancelled?: () => any;
  onConfirmed?: () => any;
  cancelButtonProps?: ButtonProps;
  confirmButtonProps?: ButtonProps;
  cancelLabel?: string;
  confirmLabel?: string;
  title: React.ReactNode;
  description: React.ReactNode;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  title,
  description,
  cancelLabel,
  confirmLabel,
  onClose,
  onCancelled,
  onConfirmed,
  cancelButtonProps,
  confirmButtonProps,
  ...props
}) => {
  const handleOnCancelled = () => {
    onClose();
    onCancelled && onCancelled();
  };

  const handleOnConfirmed = onConfirmed;

  return (
    <Modal isCentered onClose={onClose} {...props}>
      <ModalOverlay />
      <ModalContent mx='2'>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>{description}</ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button
              variant='outline'
              onClick={handleOnCancelled}
              {...cancelButtonProps}
            >
              {cancelLabel || 'Cancel'}
            </Button>
            <Button
              colorScheme='red'
              onClick={handleOnConfirmed}
              {...confirmButtonProps}
            >
              {confirmLabel || 'Confirm'}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationDialog;
