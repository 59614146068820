import { useQuery } from 'react-query';
import { getAllGroupCalls } from 'src/apis/group-calls.api';
import { GroupCalls } from 'src/models/GroupCall';
import useArrayDataQuery from 'src/utils/query-utils/useArrayDataQuery';

const kGroupCallsQueryKey = 'groupCalls';

export const useGroupCallQueryClient = () => {
  return useArrayDataQuery<GroupCalls>({
    queryKey: kGroupCallsQueryKey,
  });
};

const useGroupCalls = () => {
  const { data, ...queryMetadata } = useQuery<GroupCalls[]>(
    kGroupCallsQueryKey,
    getAllGroupCalls,
  );
  const groupCalls = data || [];

  return { groupCalls, queryMetadata };
};

export default useGroupCalls;
