import {
  Text,
  Divider,
  Flex,
  Grid,
  Heading,
  HStack,
  VStack,
  Spacer,
  IconButton,
  Icon,
} from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import { MdAdd } from 'react-icons/md';
import UserAvatar from 'src/components/UserAvatar';
import HeaderWrapper from 'src/hoc/HeaderWrapper';
import useCurrentUser from 'src/hooks/useCurrentUser';
import useDialog from 'src/hooks/useDialog';
import { PageMembers } from 'src/models/PageMembersPage.model';
import {
  getUserDisplayName,
  usersAlphabeticSortWithCurrentUserLast,
} from 'src/utils/user.utils';
import usePage from '../blogs/blog/hooks/usePage';
import usePageParams from '../blogs/blog/hooks/usePageParams';
import AddPageMemberDialog from './components/AddPageMemberDialog';
import { PageMemberCard } from './components/PageMemberCard';
import usePageMembers from './hooks/usePageMembers';

const PageMembersPage = () => {
  const { id: pageId } = usePageParams();
  const { page } = usePage();

  const { pageMembers, addMultipleMembers, removeMember } = usePageMembers({
    pageId,
  });
  const [currentUser] = useCurrentUser();

  const currentUserMemberRecord = _.find(
    pageMembers,
    member => member.UserId === currentUser?.id,
  );

  const handleOnMembersAdded = (members: PageMembers[]) => {
    addMultipleMembers(members);
    closeAddMemberDialog();
  };

  const pageMembersWithoutCreator = _.filter(
    pageMembers,
    member => member.UserId !== page?.CreatedByUserId,
  );

  const [isAddMemberDialogOpen, openAddMemberDialog, closeAddMemberDialog] =
    useDialog();

  return (
    <HeaderWrapper pageTitle={page?.title || 'Page Members'}>
      {page && (
        <Flex
          border='1px solid'
          borderColor='gray.200'
          my='2'
          p='1'
          mx='1'
          flexDir='column'
        >
          <Heading my='1' isTruncated>
            {page.title}
          </Heading>
        </Flex>
      )}
      <Grid gap='2' mx='4'>
        <Grid gap='8' my='4'>
          {page?.createdBy && (
            <VStack align='start'>
              <Text fontSize='lg' fontWeight='semibold'>
                Page Creator
              </Text>
              <HStack spacing='4' w='full'>
                <UserAvatar user={page?.createdBy} size='sm' mr='2' />
                <VStack align='start' spacing='0'>
                  <HStack>
                    <Text fontSize='sm'>
                      By
                      <Text as='span' ml='1.5' fontWeight='semibold'>
                        {getUserDisplayName(page?.createdBy)}
                      </Text>
                    </Text>
                  </HStack>
                </VStack>
              </HStack>
              <Divider my='-2' />
            </VStack>
          )}
          <HStack w='full'>
            <Text fontSize='lg' fontWeight='semibold'>
              Page members
            </Text>
            <Spacer />
            {currentUserMemberRecord?.canModerateMembers && (
              <IconButton
                aria-label='launch add feed member dialog'
                colorScheme='blue'
                size='sm'
                borderRadius='8'
                backgroundColor='none'
                icon={<Icon as={MdAdd} boxSize='6' />}
                onClick={openAddMemberDialog}
              />
            )}
          </HStack>
          {_.chain(pageMembersWithoutCreator)
            .sortBy(({ user }) =>
              usersAlphabeticSortWithCurrentUserLast(user, currentUser),
            )
            .value()
            .map((member, index, { length: memberLength }) => {
              const isLastMember = index === memberLength - 1;
              return (
                <React.Fragment key={member.id}>
                  {currentUser && isLastMember && <Divider my='-2' />}
                  <PageMemberCard
                    pageMember={member}
                    onMemberRemoved={() => removeMember(member.id)}
                    currentUserMemberRecord={currentUserMemberRecord}
                    mt={currentUser && isLastMember ? '-4' : 'unset'}
                  />
                </React.Fragment>
              );
            })}
        </Grid>
      </Grid>
      <AddPageMemberDialog
        isOpen={isAddMemberDialogOpen}
        onClose={closeAddMemberDialog}
        pageId={pageId}
        onMembersAdded={handleOnMembersAdded}
        existingMembers={pageMembers || []}
      />
    </HeaderWrapper>
  );
};

export default PageMembersPage;
