import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Tag,
  TagLabel,
  Textarea,
  Icon,
  useToast,
  VStack,
  ModalOverlay,
} from '@chakra-ui/react';
import _ from 'lodash';
import { useForm, useWatch } from 'react-hook-form';
import { HiLocationMarker } from 'react-icons/hi';
import { RiImageFill, RiVideoFill } from 'react-icons/ri';
import { useMutation } from 'react-query';

import { createPost } from '../../../../apis/posts.api';
import { NewPostType } from '../../../../types/post.type';
import CurrentUserAvatar from '../../../../components/CurrentUserAvatar';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import { getUserDisplayName } from '../../../../utils/user.utils';
import useFiles from '../hooks/useFiles';
import FileInput from '../FileInput';
import FilePreviews from './FilePreviews';
import { IoDocumentOutline } from 'react-icons/io5';
import { useFeedPostsQueryClient } from '../hooks/useFeedPosts';
import { maxPostLength } from '../constants/max-post-length.constant';

interface AddPostFormProps {
  isOpen: boolean;
  handleClose: () => void;
  feedId: string;
}

export const AddPostForm: React.FC<AddPostFormProps> = ({
  isOpen,
  handleClose,
  feedId,
}) => {
  const {
    handleSubmit,
    register,
    reset: resetForm,
    control,
  } = useForm<NewPostType>({
    mode: 'onChange',
  });
  const {
    files: images,
    fileURIs: imageURIs,
    addFile: addImage,
    removeFile: removeImage,
    removeAllFiles: removeAllImages,
  } = useFiles();

  const {
    files: videos,
    fileURIs: videoURIs,
    addFile: addVideo,
    removeFile: removeVideo,
    removeAllFiles: removeAllVideos,
  } = useFiles();

  const {
    files: documentFiles,
    fileURIs: documentFileURIs,
    addFile: addDocumentFile,
    removeFile: removeDocumentFile,
    removeAllFiles: removeAllDocumentFiles,
  } = useFiles();

  const getAllFiles = (): File[] => _.concat(images, videos, documentFiles);
  const onClose = () => {
    resetForm();
    removeAllImages();
    removeAllVideos();
    removeAllDocumentFiles();
    handleClose();
  };

  const { addFeedPost } = useFeedPostsQueryClient({ feedId: Number(feedId) });

  const showToast = useToast();
  const addPostMutation = useMutation(
    (newPost: NewPostType) => createPost(newPost, feedId),
    {
      onSuccess: (addedPost) => {
        addFeedPost(addedPost);
        showToast({
          status: 'success',
          title: 'New Post Added',
        });
        onClose();
      },
    }
  );

  const textContent = useWatch({
    name: 'textContent',
    control,
  });
  const hasFiles = !_.isEmpty(getAllFiles());
  const hasPostContent = hasFiles || textContent?.trim()?.length > 0;

  const formSubmitHandle = (data: NewPostType) => {
    if (!hasPostContent) {
      showToast({
        title: 'Cannot post empty content',
        description: 'Your post must have content - text, file, or both',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    addPostMutation.mutate({
      ...data,
      files: getAllFiles(),
    });
  };

  const [currentUser] = useCurrentUser();

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent
        margin={0}
        minW={{
          base: 'auto',
          md: 'xl',
        }}
        rounded="none"
        h="full"
      >
        <ModalHeader>Create a Post</ModalHeader>
        <Divider />
        <ModalCloseButton />
        <ModalBody p="0">
          <form onSubmit={handleSubmit(formSubmitHandle)}>
            <Box m="2">
              <Tag size="lg" borderRadius="full">
                <CurrentUserAvatar size="sm" mr="1" />
                <TagLabel>
                  {currentUser && getUserDisplayName(currentUser)}
                </TagLabel>
              </Tag>
              <FormControl my="2">
                <Textarea
                  maxLength={maxPostLength}
                  placeholder="What do you want to post about?"
                  size="lg"
                  rows={3}
                  {...register('textContent')}
                  autoFocus
                />
              </FormControl>

              <VStack my="4" align="start" w="full" spacing="6">
                <FilePreviews
                  fileURIs={imageURIs}
                  files={images}
                  label="Images:"
                  closeBtnAriaLabel="remove image"
                  onFileRemove={removeImage}
                />
                <FilePreviews
                  fileURIs={videoURIs}
                  files={videos}
                  label="Videos:"
                  closeBtnAriaLabel="remove video"
                  onFileRemove={removeVideo}
                  previewBoxProps={{ bg: 'gray.800' }}
                />

                <FilePreviews
                  fileURIs={documentFileURIs}
                  files={documentFiles}
                  label="Documents:"
                  closeBtnAriaLabel="remove document"
                  onFileRemove={removeDocumentFile}
                  previewBoxProps={{ h: 'unset', minW: '32' }}
                />
              </VStack>

              <Box>
                <FormControl id="add-image">
                  {/* Using the label, user can just click on the button below (which is the label) and add files  */}
                  <FormLabel htmlFor="add-image-input" m="0">
                    <FileInput
                      accept="image/*"
                      id="add-image-input"
                      onFiles={addImage}
                    />
                    <Button
                      aria-label="add image button"
                      colorScheme="green"
                      variant="ghost"
                      as="span"
                      cursor="pointer"
                      leftIcon={<Icon as={RiImageFill} boxSize="6" />}
                    >
                      Image
                    </Button>
                  </FormLabel>
                </FormControl>
                <FormControl id="add-video">
                  <FormLabel htmlFor="add-video-input" m="0">
                    <FileInput
                      accept="video/*, .mkv"
                      id="add-video-input"
                      onFiles={addVideo}
                    />
                    <Button
                      aria-label="video upload button"
                      colorScheme="purple"
                      variant="ghost"
                      as="span"
                      cursor="pointer"
                      leftIcon={<Icon as={RiVideoFill} boxSize="6" />}
                    >
                      Video
                    </Button>
                  </FormLabel>
                </FormControl>
                <FormControl id="add-document">
                  <FormLabel htmlFor="add-document-input" m="0">
                    <FileInput
                      id="add-document-input"
                      onFiles={addDocumentFile}
                    />
                    <Button
                      aria-label="non-media upload button"
                      colorScheme="linkedin"
                      variant="ghost"
                      as="span"
                      cursor="pointer"
                      leftIcon={<Icon as={IoDocumentOutline} boxSize="6" />}
                    >
                      Document
                    </Button>
                  </FormLabel>
                </FormControl>
                <FormControl id="add-location">
                  <FormLabel htmlFor="add-location-input">
                    <Button
                      aria-label="add location button"
                      colorScheme="blue"
                      variant="ghost"
                      leftIcon={<Icon as={HiLocationMarker} boxSize="6" />}
                    >
                      Location
                    </Button>
                  </FormLabel>
                </FormControl>
                <Box my="2">
                  <Button
                    colorScheme="blue"
                    type="submit"
                    w="full"
                    isLoading={addPostMutation.isLoading}
                    loadingText="Posting..."
                    isDisabled={!hasPostContent || addPostMutation.isLoading}
                  >
                    Post
                  </Button>
                </Box>
                <Box my="2">
                  <Button
                    onClick={onClose}
                    variant="outline"
                    colorScheme="red"
                    w="full"
                    isDisabled={addPostMutation.isLoading}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
