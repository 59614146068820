import {
    Box,
    Center,
    SimpleGrid,
    Text,
} from '@chakra-ui/react';
import _ from 'lodash';

import HeaderWrapper from '../../hoc/HeaderWrapper';
import BlogPageCard from './components/blog-page-card';
import usePages from './hooks/usePages';

const BlogsPage: React.FC = () => {
    const { pages, ...queryMetadata } = usePages();

    const EmptyPages = () => {
        return (
            <Text
                w='full'
                textAlign='center'
                fontSize='xl'
                color='gray.500'
                fontWeight='semibold'
            >
                There are no pages yet.
            </Text>
        );
    };

 

    const renderPages = () => {
       
        if (_.isEmpty(pages)) {
            return (
                <Center pt='4'>
                    <Text fontSize='xl' color='gray.500' fontWeight='semibold'>
                        No pages to show
                    </Text>
                </Center>
            );
        }
        if (queryMetadata.isFetched && _.isEmpty(pages)) {
            return <EmptyPages />;
        }

        return (
            <SimpleGrid
                p='2'
                columns={1}
                spacing={4}
                mx='auto'
                justifyContent='center'
            >
                {pages.map(page => (
                    <BlogPageCard key={page.id} page={page} />
                ))}
            </SimpleGrid>
        );
    };

    return (
        <HeaderWrapper pageTitle='Pages'>
            <Box bg='gray.200' minH='100vh'>
                {renderPages()}
            </Box>
        </HeaderWrapper>
    );
};

export default BlogsPage;
