import { useCallback, useEffect } from 'react';

import {
  CallSessionHandler,
  subscribeCallSessionDisconnected,
  unsubscribeCallSessionDisconnected,
} from 'src/apis/socket.io/call-sessions.socket.io';
import { useCallSessionsUpdates } from '../../useCallSessions';

const useCallDisconnectedUpdate = () => {
  const { updatePartial } = useCallSessionsUpdates();

  const handleCallSessionDisconnected = useCallback<CallSessionHandler>(
    updatePartial,
    [updatePartial],
  );

  useEffect(() => {
    subscribeCallSessionDisconnected(handleCallSessionDisconnected);

    return () => {
      unsubscribeCallSessionDisconnected(handleCallSessionDisconnected);
    };
  }, [handleCallSessionDisconnected]);
};

export default useCallDisconnectedUpdate;
