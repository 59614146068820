import { Center, Flex } from '@chakra-ui/react';

import SpeakerButton from './components/SpeakerButton';
import VideoButton from './components/VideoButton';
import MicrophoneButton from './components/MicrophoneButton';
import UserSelfVideo from './components/UserSelfVideo';
import UserMediaProvider from './context/user-media/provider/UserMedia.provider';
import { maxWidth } from 'src/constants/max-width.constant';
import usePersonalCallSession from '../../hooks/usePersonalCallSession';

interface CallPageFooterProps {}

const CallPageFooter: React.FC<CallPageFooterProps> = () => {
    const personalCallSession = usePersonalCallSession();
    return (
        <UserMediaProvider>
            <Center position='absolute' w='full' bottom='0' left='0'>
                <Center
                    position='relative'
                    bgColor='blue.500'
                    w='full'
                    h='20'
                    maxW={maxWidth}
                >
                    <Flex justifyContent='space-around' w='full'>
                        <SpeakerButton
                            isSpeakerDisabled={!personalCallSession?.joinedAt}
                        />
                        <VideoButton
                            isVideoDisabled={!personalCallSession?.joinedAt}
                        />
                        <MicrophoneButton
                            isMicDisabled={!personalCallSession?.joinedAt}
                        />
                    </Flex>
                    <UserSelfVideo />
                </Center>
            </Center>
        </UserMediaProvider>
    );
};

export default CallPageFooter;
