import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useQuery, QueryKey, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';

import { getOneMessageThread } from '../../../apis/message-threads.api';
import {
  joinMessageThreadRoom,
  leaveMessageThreadRoom,
} from '../../../apis/socket.io/message-threads.socket.io';
import { MessageThread } from '../../../models/MessageThread.model';

const getMessageThreadQueryKey = (messageThreadId: number): QueryKey => [
  'getMessageThread',
  messageThreadId,
];

const useMessageThread = (messageThreadId: number) => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const { data: messageThread, ...queryMetadata } = useQuery(
    getMessageThreadQueryKey(messageThreadId),
    () => getOneMessageThread(messageThreadId),
    {
      onError: (err: AxiosError) => {
        if (err.response?.status === 403) {
          history.goBack();
        }
      },
      retry: (_, err) => err.response?.status !== 403,
    },
  );

  useEffect(() => {
    if (messageThread) {
      joinMessageThreadRoom(messageThread.id);
      return () => {
        leaveMessageThreadRoom(messageThread.id);
      };
    }
  }, [messageThread]);

  const updateMessageThread = (updatedData: MessageThread) => {
    queryClient.setQueryData<MessageThread>(
      getMessageThreadQueryKey(messageThreadId),
      prevData => ({ ...(prevData || {}), ...updatedData }),
    );
  };

  return { messageThread, updateMessageThread, ...queryMetadata };
};

export default useMessageThread;
