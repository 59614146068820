import { axiosInstance } from '../config';
import { FeedModel } from '../models';
import { getRequestData } from './utils/get-request-data.util';

const kFeedsUrl = '/feeds';

const getFeeds = (url: string): Promise<FeedModel[]> => {
  return getRequestData<FeedModel[]>(axiosInstance.get(url));
};

export const getFeedsForAuthenticated = (): Promise<FeedModel[]> => {
  return getFeeds(kFeedsUrl);
};

export const getPublicFeeds = (): Promise<FeedModel[]> => {
  return getFeeds(`${kFeedsUrl}/public`);
};

export const getFeed = async (feedId: string) => {
  const { data } = await axiosInstance.get(`/feeds/${feedId}`);
  return data;
};
