import { HStack, Tab, TabProps } from '@chakra-ui/react';

export interface PagePanelTabProps extends TabProps {}

export const PagePanelTab: React.FC<PagePanelTabProps> = ({
  children,
  ...props
}) => (
  <Tab
    as={HStack}
    alignItems='center'
    positon='relative'
    m='1'
    fontWeight='bold'
    color='gray.500'
    borderRadius='10'
    _selected={{
      color: 'black',
      bg: 'white',
      // boxShadow: xAxisOffset yAxisOffset blur spread color
      boxShadow: '0 0.2rem 0.5rem -0.1rem grey',
    }}
    {...props}
  >
    {children}
  </Tab>
);