import { Container, Text, useToast, UseToastOptions } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { Redirect } from 'react-router';

import HeaderWrapper from '../../hoc/HeaderWrapper';
import { LOGIN_PAGE_ROUTE } from '../../routes/routeList';
import RegisterForm from './components/RegisterForm';

const RegisterPage = () => {
  const [isRegisterSuccessful, setRegisterSuccessFul] = useState(false);
  const showToast = useToast();

  const onRegisterSuccess = () => {
    // toast success
    showToast({
      title: 'You have successfully created an account',
      description: 'Please login to continue',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });

    setRegisterSuccessFul(true);
  };

  const onRegisterError = (err: AxiosError) => {
    let toastOptions: UseToastOptions = {
      title: 'Failed to Register',
      description: 'An unkown error occured. Please try again later.',
      status: 'error',
      isClosable: true,
    };

    if (err && err.response) {
      if (err.response.status === 409) {
        toastOptions.description =
          'User with the same email already exists. Please login with that email or use another one.';
      }
    }

    setRegisterSuccessFul(false);
    showToast(toastOptions);
  };

  if (isRegisterSuccessful) {
    return <Redirect to={LOGIN_PAGE_ROUTE} />;
  }

  return (
    <HeaderWrapper pageTitle="Register">
      <Container my="8">
        <Text
          align="center"
          mb="8"
          fontWeight="bold"
          color="blue.500"
          fontSize="2xl"
        >
          Create an account
        </Text>
        <RegisterForm
          onRegisterError={onRegisterError}
          onRegisterSuccess={onRegisterSuccess}
        />
      </Container>
    </HeaderWrapper>
  );
};

export default RegisterPage;
