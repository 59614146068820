import { useEffect, useState } from 'react';
import { ValueCallback } from '../../../types/common.type';
import { useMessageLimitValidator } from './useMessageWithLimit';

interface UseMediaPreviewOptions {
  media: Blob[] | File[] | FileList;
}

type SelectedMediaType = Blob[];
type CaptionType = string | undefined;

type UseMediaPreviewData = [
  SelectedMediaType,
  CaptionType,
  { setCaption: ValueCallback<CaptionType> }
];

const useMediaPreview = ({
  media,
}: UseMediaPreviewOptions): UseMediaPreviewData => {
  const [selectedFiles, setSelectedFiles] = useState<SelectedMediaType>([]);
  const [caption, setCaption] = useState<CaptionType>();
  const validateCaption = useMessageLimitValidator();

  const setCaptionWithLimit = (newCaption: string | undefined) => {
    // Don't validate if caption is empty
    if (!newCaption) {
      setCaption(newCaption);
      return;
    }

    if (validateCaption(newCaption)) {
      setCaption(newCaption);
    }
  };

  useEffect(() => {
    const mediaFiles: SelectedMediaType = [];
    if (Array.isArray(media)) {
      mediaFiles.push(...(media as any));
    }

    if (media instanceof FileList) {
      for (let i = 0; i < media.length; i++) {
        const mediaFile = media.item(i);
        if (mediaFile) {
          mediaFiles.push(mediaFile);
        }
      }
    }

    setSelectedFiles(mediaFiles);
  }, [media]);

  return [selectedFiles, caption, { setCaption: setCaptionWithLimit }];
};

export default useMediaPreview;
