import { BoxProps, HStack, Text, VStack } from '@chakra-ui/layout';
import React from 'react';

import { Message } from '../../../models/Message.model';
import MediaMessage from './message-box/MediaMessage';
import MessageStatus from './MessageStatus';

export interface MessageBoxProps extends BoxProps {
  message: Message;
}

const time24Hour = (date: Date) => {
  return Intl.DateTimeFormat('en-US', {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  }).format(date);
};

const MessageBox: React.FC<MessageBoxProps> = ({ message, ...props }) => {
  const isMediaMessage = message.messageMedias.length > 0;

  return (
    <VStack
      spacing="4"
      borderRadius="8"
      bg="blue.500"
      color="white"
      p="2"
      fontSize="sm"
      alignItems="baseline"
      justifyContent="start"
      minW="20%"
      {...props}
    >
      {!isMediaMessage && <Text wordBreak="break-word">{message.text}</Text>}
      {isMediaMessage && (
        <MediaMessage
          message={message}
          mediaBoxProps={{ minW: '44', maxH: '60', objectFit: 'cover' }}
        />
      )}
      <HStack alignSelf="end" align="end">
        <Text fontSize="xs" color="gray.500">
          {time24Hour(message.messageAt)}
        </Text>
        <MessageStatus message={message} />
      </HStack>
    </VStack>
  );
};

export default MessageBox;
