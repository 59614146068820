import { useEffect } from 'react';

import {
  onNewMessage,
  unsubscribeNewMessage,
} from '../../../apis/socket.io/messages.socket.io';
import { MessageDto } from '../../../dto/messages.dto';

interface UseNewMessageObserverProps {
  handleNewMessage: (message: MessageDto) => void;
}

/**
 * NOTE: Please ensure that `handleNewMessage` doesn't change on every render.
 * Preferably, use useCallback to memoize the function.
 */
const useNewMessageObserver = ({
  handleNewMessage,
}: UseNewMessageObserverProps) => {
  useEffect(() => {
    onNewMessage(handleNewMessage);

    return () => unsubscribeNewMessage(handleNewMessage);
  }, [handleNewMessage]);
};

export default useNewMessageObserver;
