import { BoxProps, HStack, Text } from '@chakra-ui/layout';
import { VStack } from '@chakra-ui/react';

import useCurrentUser from '../../../hooks/useCurrentUser';
import { User } from '../../../models';
import { Message } from '../../../models/Message.model';
import { getUserDisplayName } from '../../../utils/user.utils';
import MediaMessage from './message-box/MediaMessage';
import MessageStatus from './MessageStatus';

export interface MessageBoxProps extends BoxProps {
  message: Message;
}

const time24Hour = (date: Date) => {
  return Intl.DateTimeFormat('en-US', {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  }).format(date);
};

const getSenderDisplayName = (
  sender?: User,
  currentUser?: User
): string | null => {
  if (sender && currentUser) {
    if (sender.id === currentUser.id) {
      return null;
    }

    return getUserDisplayName(sender);
  }

  return 'Unknown User';
};

const GroupMessageBox: React.FC<MessageBoxProps> = ({ message, ...props }) => {
  const [currentUser] = useCurrentUser();
  const isMediaMessage = message.messageMedias.length > 0;

  const senderName = getSenderDisplayName(message.sender, currentUser);
  return (
    <VStack
      p="2"
      align="start"
      minW="20%"
      spacing="0.5"
      borderRadius="8"
      bg="blue.500"
      color="white"
      fontSize="sm"
      {...props}
    >
      <Text fontWeight="600">{senderName}</Text>
      <VStack
        minW="full"
        spacing="2"
        alignItems="baseline"
        justifyContent="space-between"
      >
        {!isMediaMessage && <Text wordBreak="break-word">{message.text}</Text>}
        {isMediaMessage && (
          <MediaMessage
            message={message}
            mediaBoxProps={{
              minW: '44',
              maxH: '60',
              objectFit: 'cover',
            }}
          />
        )}

        <HStack alignSelf="end" align="end">
          <Text fontSize="xs" color="gray.500">
            {time24Hour(message.messageAt)}
          </Text>
          {message.status === 'sent' && <MessageStatus message={message} />}
        </HStack>
      </VStack>
    </VStack>
  );
};

export default GroupMessageBox;
