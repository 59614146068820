import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Flex,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { Plate } from '@udecode/plate';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import HeaderWrapper from 'src/hoc/HeaderWrapper';
import useSlatePlugins from 'src/pages/blogs/blog/components/page-content/hooks/useSlatePlugins';
import usePage from 'src/pages/blogs/blog/hooks/usePage';
import usePageParams from 'src/pages/blogs/blog/hooks/usePageParams';
import { Editor } from './components/editor/Editor';
import { PagePanelTab } from './components/PagePanelTab';
import useApproveContent from './hooks/useApproveContent';
import useDiscardContent from './hooks/useDiscardContent';
import usePageContentForEdit from './hooks/usePageContentForEdit';
import useRejectContent from './hooks/useRejectContent';
import useReviewContent from './hooks/useReviewContent';
import PageRemarkDialog from './PageRemarkDialog';

const ApprovePageContent = () => {
  const { id, id: pageId } = usePageParams();
  const { content, setContent } = usePageContentForEdit();
  const { draft, setDraft } = useReviewContent();

  const { handlePublishPageContent, updatePageContentMutation } =
    useApproveContent({ id });

  const { handleDiscardPageContent } = useDiscardContent({ id });

  const { handleRejectPageContent } = useRejectContent({ id });

  const plugins = useSlatePlugins();

  const { page } = usePage();

  const handleDiscard = () => {
    if (draft) {
      handleDiscardPageContent(draft);
    }
  };

  const handleSubmit = () => {
    if (draft) {
      handlePublishPageContent(draft);
    }
  };

  return (
    <HeaderWrapper pageTitle={page?.title || 'Page Members'}>
      <Container my='8'>
        <Tabs isFitted isLazy variant='unstyled'>
          <TabList bg='gray.100' borderRadius='10' mx='3'>
            <PagePanelTab>
              <Text>Page Content</Text>
            </PagePanelTab>
            <PagePanelTab>
              <Text>Page Request</Text>
            </PagePanelTab>
          </TabList>
          <TabPanels>
            <TabPanel px='0' pt='8'>
              <Box bg='white'>
                {content && (
                  <Plate
                    id={_.toString(`${id}-content`)}
                    value={content}
                    editableProps={{
                      readOnly: true,
                    }}
                    plugins={plugins}
                  />
                )}
              </Box>
            </TabPanel>
            <TabPanel px='0' pt='8'>
              {draft && (
                <Editor
                  isReadonly
                  pageId={_.toNumber(`${id}-draft`)}
                  w='full'
                  value={draft}
                  onChange={setDraft}
                />
              )}
              <Flex justifyContent='flex-end'>
                <ButtonGroup colorScheme='blue'>
                  <Button variant='outline' onClick={handleDiscard}>
                    Discard
                  </Button>
                  <PageRemarkDialog id={id} />
                  <Button onClick={handleSubmit}>Approve Request</Button>
                </ButtonGroup>
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </HeaderWrapper>
  );
};

export default ApprovePageContent;
