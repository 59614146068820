import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { getCurrentUserMemberRecord } from '../../../../apis/feedsMembers.api';
import { FeedMember } from '../../../../models/FeedMember.model';

const useCurrentUserFeedMember = (feedId: number) => {
  const { data: memberRecord } = useQuery<FeedMember | undefined, AxiosError>(
    ['getCurrentUserMemberRecord', feedId],
    () => getCurrentUserMemberRecord(feedId),
    {
      retry: (_, err) => {
        const responseStatus = err.response?.status;
        // 401 is when user is not logged in at all - no use retrying since we'd still hit 401
        // 404 is when user's member record was not found - no use retrying since we'd still hit 404 (member record won't be found)
        const isErrorNotUsefulToRetry =
          responseStatus === 401 || responseStatus === 404;

        return !isErrorNotUsefulToRetry;
      },
    },
  );

  return memberRecord;
};

export default useCurrentUserFeedMember;
