import React from 'react';
import { Box } from '@chakra-ui/react';
import { Text, SimpleGrid } from '@chakra-ui/layout';

import { BaseSectionProps } from './types';

export const BaseSection: React.FC<BaseSectionProps> = ({
  title,
  body,
  gap,
}) => {
  return (
    <SimpleGrid columns={1} gap={gap || 4} alignItems="start" maxW="full">
      <Box
        position="relative"
        _before={{
          content: '""',
          position: 'absolute',
          width: '15%',
          height: '4px',
          backgroundColor: '#4299E1',
          bottom: '-4px',
        }}
        maxW="full"
      >
        <Text fontSize="xl" fontWeight="semibold" maxW="full" isTruncated>
          {title}
        </Text>
      </Box>
      {body}
    </SimpleGrid>
  );
};
