import { Box, Flex, StackProps, Text, Center } from '@chakra-ui/layout';
import {
  AvatarGroup,
  Button,
  FormControl,
  HStack,
  Divider,
  Icon,
  Input,
  useToast,
} from '@chakra-ui/react';
import Linkify from 'linkify-react';
import _ from 'lodash';
import { Fragment, useEffect, useRef, useState } from 'react';
import { AiFillLike, AiOutlineLike, AiOutlineSend } from 'react-icons/ai';
import { BiComment } from 'react-icons/bi';
import { useForm, useWatch } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Link, useLocation } from 'react-router-dom';

import CurrentUserAvatar from 'src/components/CurrentUserAvatar';
import { addFeedPostViews } from '../../../apis/views.api';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
import { EntityType, FeedPostView } from '../../../models/FeedPostView.model';
import { Post } from '../../../models/Post.model';
import { Colors } from '../../../utils/chakra-theme/theme-blocks/color';
import PostActionsMenu from './PostActionsMenu';
import PostCardHeader from './PostCardHeader';
import PostCardMedia from './PostCardMediaSlider';
import { ViewLikesByUsersModal } from './ViewLikesByUsersModal';
import UserAvatar from 'src/components/UserAvatar';
import { EditCommentModal } from './EditCommentModal';
import useDialog from 'src/hooks/useDialog';
import { Like } from 'src/models/Like.model';
import { FeedPostLikeDto } from 'src/dto/feed-post-likes.dto';
import { addLikesofPost } from 'src/apis/likes.api';
import { addCommentsToPost } from 'src/apis/comment.api';
import { FeedPostCommentDto } from 'src/dto/feed-post-comment.dto';
import { PostCommentCard } from './PostCommentCard';
import useComments from './hooks/useComments';
import useLikes from './hooks/useLikes';
import { useDisableLoader } from 'src/hooks/useDisableLoader';
import { COMMENT_CARD_ROUTE } from 'src/routes/routeList';
import { DeleteCommentModal } from './DeleteCommentModal';
import {
  COMMENT_CARD_URL,
  MAXIMUM_NUMBER_OF_COMMENT_TO_SHOW,
  MAXIMUM_NUMBER_OF_LIKE_TO_SHOW,
} from './constants/post-form.constant';
import { Comment } from 'src/models/Comment.model';

var linkProps = {
  style: { color: Colors.colors.hyperlink.color },
};

interface NewPostCardProps extends StackProps {
  post: Post;
  showPostApprovalStatus?: boolean;
}

const PostCard: React.FC<NewPostCardProps> = ({
  post,
  showPostApprovalStatus,
  ...props
}) => {
  const [likeModal, setLikeModal] = useState<boolean>(false);
  const [showAllComments, setShowAllComments] = useState<boolean>(false);
  const [showCommentBox, setShowCommentBox] = useState<boolean>(false);
  const [commentToEdit, setCommentToEdit] = useState<Comment>();
  const location = useLocation();

  const toggleCommentEditDialog = (comment?: Comment) => {
    setCommentToEdit(comment);
  };

  const HorizontalLine = useRef<HTMLDivElement>(null);
  const addPostViewMutation = useMutation((postView: FeedPostView) =>
    addFeedPostViews(postView),
  );
  useDisableLoader();
  const addPostLikeMutation = useMutation((postLike: FeedPostLikeDto) =>
    addLikesofPost(postLike),
  );

  const { data: commentsData, refetch: refetchComments } = useComments(
    post.id,
    EntityType.post,
  );

  const { data: LikesData, refetch: refetchLikes } = useLikes(
    post.id,
    EntityType.post,
  );

  const addPostCommentMutation = useMutation(
    (postComment: FeedPostCommentDto) => addCommentsToPost(postComment),
  );

  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useDialog();

  const addView = (post: Post) => {
    if (post) {
      const feedPostsStoredInSession = sessionStorage.getItem('feedPosts');
      let isFeedPostAlreadyExist = false;
      if (feedPostsStoredInSession) {
        const parsedList = JSON.parse(feedPostsStoredInSession);
        if (parsedList.length) {
          isFeedPostAlreadyExist = _.includes(parsedList, post.id);
        }
      }
      if (isFeedPostAlreadyExist) {
        return;
      }
      // api call

      addPostViewMutation.mutate(
        {
          entityId: post.id,
          entityType: EntityType.post,
          UserId: currentUser?.id,
        },
        {
          onSuccess: () => {
            // store data in session
            // here if the data is already stored in session so we do not store that data multiple time
            const feedPostsStoredInSession =
              sessionStorage.getItem('feedPosts');
            let updatedList: string;
            if (feedPostsStoredInSession) {
              const parsedList = JSON.parse(feedPostsStoredInSession);
              if (parsedList.length) {
                updatedList = JSON.stringify([...parsedList, post.id]);
              } else {
                updatedList = JSON.stringify([post.id]);
              }
            } else {
              updatedList = JSON.stringify([post.id]);
            }

            sessionStorage.setItem('feedPosts', updatedList);
          },
        },
      );
    }
  };

  const [currentUser] = useCurrentUser();

  useIntersectionObserver({
    target: HorizontalLine,
    onIntersect: () => addView(post),
    enabled: true,
  });

  const [hasLiked, setHasLiked] = useState(false);

  const {
    handleSubmit,
    register,
    reset: resetForm,
    control,
  } = useForm({
    mode: 'onChange',
  });

  const getModalForLikes = () => {
    setLikeModal(prevState => !prevState);
    return null;
  };

  const textInCommentContent = useWatch({
    name: 'commentContent',
    control,
  });

  const toast = useToast();

  interface commentType {
    commentContent: string;
  }
  const formSubmitHandle = (data: commentType) => {
    addPostCommentMutation.mutate(
      {
        EntityId: post.id,
        entityType: EntityType.post,
        description: data.commentContent,
      },
      {
        onSuccess: () => {
          resetForm();
          toast({
            title: 'Comment added successfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          refetchComments();
        },
      },
    );
    return;
  };
  const likePost = () => {
    setHasLiked(prevHasLiked => !prevHasLiked);
    addPostLikeMutation.mutate(
      {
        EntityId: post.id,
        entityType: EntityType.post,
      },
      {
        onSuccess: data => {
          refetchLikes();
        },
      },
    );
  };

  const textContent = post.textContent;
  const CommentCardFeedIdURL = COMMENT_CARD_ROUTE.replace(
    ':id',
    String(post.FeedId),
  );
  const CommentCardFeedIdWithPostIdURL = CommentCardFeedIdURL.replace(
    ':postId',
    String(post.id),
  );
  const [commentIdToDelete, setCommentIdToDelete] = useState<number>();

  useEffect(() => {
    if (
      _.filter(LikesData, (like: Like) => {
        return like?.UserId === currentUser?.id;
      }).length === 1
    ) {
      setHasLiked(true);
    }
  }, [LikesData]);

  useEffect(() => {
    if (location.pathname.endsWith(COMMENT_CARD_URL)) {
      setShowAllComments(true);
    }
  }, []);

  return (
    <>
      <Flex
        flexDir='column'
        borderRadius='8'
        borderColor='gray.400'
        borderStyle='solid'
        borderWidth='1px'
        my='4'
        py='2'
        bg='white'
        {...props}
      >
        <HStack minW='full' alignItems='start' justify='space-between'>
          <PostCardHeader
            post={post}
            showPostApprovalStatus={showPostApprovalStatus}
            my='2'
          />
          <PostActionsMenu post={post} pt='2' />
        </HStack>
        <Box minW='full' h='1px' bg='gray.300' />
        <Text
          mt='3'
          mb='2'
          as='pre'
          fontFamily='inherit'
          minWidth='0px'
          whiteSpace='pre-wrap'
          px='4'
        >
          <Linkify options={{ attributes: linkProps }}>{textContent}</Linkify>
        </Text>
        <PostCardMedia post={post} />
        <Flex justifyContent='space-between' px='3'>
          <Flex>
            <AvatarGroup size='sm' max={2}>
              {_.map(LikesData, (like: Like, index: number) => {
                return index < MAXIMUM_NUMBER_OF_LIKE_TO_SHOW ? (
                  <UserAvatar user={like.user} />
                ) : null;
              })}
            </AvatarGroup>

            <Text as='button' onClick={getModalForLikes} pl='2'>
              {LikesData &&
                LikesData?.length > 0 &&
                LikesData?.length === 1 && (
                  <Text color='gray.400'>{LikesData?.length} Like</Text>
                )}
              {LikesData && LikesData?.length > 0 && LikesData?.length > 1 && (
                <Text color='gray.400'>{LikesData?.length} Likes</Text>
              )}
            </Text>
          </Flex>
          <Flex>
            <Flex
              ref={HorizontalLine}
              display='flex'
              justifyContent='flex-end'
            ></Flex>
          </Flex>
        </Flex>
        <Divider />
        <Flex w='full'>
          <Button variant='unstyled' w='full' onClick={likePost}>
            <Flex justifyContent='center'>
              <Icon
                as={hasLiked ? AiFillLike : AiOutlineLike}
                boxSize='6'
                color='blue.500'
              />
              <Text pl='3'>Like</Text>
            </Flex>
          </Button>
          <Center height='40px'>
            <Divider orientation='vertical' bgColor='gray.400' />
          </Center>
          <Button
            variant='unstyled'
            w='full'
            onClick={() => setShowCommentBox(prevState => !prevState)}
          >
            <Flex justifyContent='center'>
              <Icon as={BiComment} boxSize='6' />
              <Text pl='3'>Comment</Text>
            </Flex>
          </Button>
        </Flex>
        <Divider />
        {showCommentBox && (
          <form onSubmit={handleSubmit(formSubmitHandle)}>
            <Flex
              alignItems='center'
              w='full'
              px='1'
              py='2'
              p='4'
              bgColor='blue.50'
            >
              <CurrentUserAvatar size='sm' />
              <Box
                cursor='pointer'
                w='full'
                mx='2'
                borderRadius='md'
                bgColor='blue.50'
              >
                <FormControl my='2'>
                  <Input
                    maxLength={1000}
                    placeholder='Add your comment'
                    size='md'
                    rows={1}
                    {...register('commentContent')}
                    fontWeight='normal'
                    letterSpacing='wide'
                    bgColor='white'
                    fontSize='sm'
                  />
                </FormControl>
              </Box>
              <Button
                loadingText='Posting...'
                type='submit'
                size='md'
                colorScheme='blue'
              >
                <Text p='4'>
                  <Icon as={AiOutlineSend} boxSize='6' />
                </Text>
              </Button>
            </Flex>
          </form>
        )}
        <Center borderColor='gray.400'>
          <Divider orientation='horizontal' />
        </Center>
        {commentsData &&
          _.map(commentsData, (comment, index) => {
            return index < MAXIMUM_NUMBER_OF_COMMENT_TO_SHOW ? (
              <PostCommentCard
                key={comment.id}
                comment={comment}
                post={post}
                openReviewDialog={toggleCommentEditDialog}
                openDeleteDialog={openDeleteDialog}
                isDeleteDialogOpen={isDeleteDialogOpen}
                closeDeleteDialog={closeDeleteDialog}
                showAllComments={showAllComments}
                setShowAllComments={setShowAllComments}
                setCommentIdToDelete={setCommentIdToDelete}
              />
            ) : showAllComments ? (
              <PostCommentCard
                key={comment.id}
                comment={comment}
                post={post}
                openReviewDialog={toggleCommentEditDialog}
                openDeleteDialog={openDeleteDialog}
                isDeleteDialogOpen={isDeleteDialogOpen}
                closeDeleteDialog={closeDeleteDialog}
                showAllComments={showAllComments}
                setShowAllComments={setShowAllComments}
                setCommentIdToDelete={setCommentIdToDelete}
              />
            ) : null;
          })}
        {(commentsData && commentsData.length && (
          <Flex px='3' mt='2' pb='1'>
            <Link to={CommentCardFeedIdWithPostIdURL}>
              <Button
                onClick={() => setShowAllComments(prevState => !prevState)}
                variant='link'
                color='blue.500'
              >
                <Text color='blue.500' fontSize='sm'>
                  {showAllComments
                    ? 'Show less comments'
                    : 'Show more comments'}
                </Text>
              </Button>
            </Link>
          </Flex>
        )) ||
          null}

        {likeModal && LikesData && (
          <ViewLikesByUsersModal
            isOpen={likeModal}
            onClose={getModalForLikes}
            post={post}
          />
        )}
      </Flex>
      {commentToEdit && (
        <EditCommentModal
          isOpen={!!commentToEdit}
          onClose={toggleCommentEditDialog}
          post={post}
          comment={commentToEdit}
        />
      )}

      {commentIdToDelete && (
        <DeleteCommentModal
          isOpen={isDeleteDialogOpen}
          onClose={closeDeleteDialog}
          commentId={commentIdToDelete}
          post={post}
        />
      )}
    </>
  );
};

export default PostCard;
