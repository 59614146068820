import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Text,
  Textarea,
} from '@chakra-ui/react';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import useCurrentUser from 'src/hooks/useCurrentUser';
import HeaderWrapper from '../../../hoc/HeaderWrapper';
import usePage from '../../../pages/blogs/blog/hooks/usePage';
import usePageParams from '../../../pages/blogs/blog/hooks/usePageParams';
import usePageMembers from '../hooks/usePageMembers';
import { Editor } from './components/editor/Editor';
import usePageContentForEdit from './hooks/usePageContentForEdit';
import usePageContentForReview from './hooks/usePageContentForReview';
import useReviewContent from './hooks/useReviewContent';

interface DraftPageContentTabProps {}

const DraftPageContent: React.FC<DraftPageContentTabProps> = () => {
    const { id, id: pageId } = usePageParams();
    const { draft, setDraft } = useReviewContent();
    const { content } = usePageContentForEdit();

    const { page } = usePage();

    const { pageMembers } = usePageMembers({
        pageId,
    });

    const [currentUser] = useCurrentUser();

    const currentUserMemberRecord = _.find(
        pageMembers,
        member => member.UserId === currentUser?.id,
    );

    const { handleReviewPageContent, reviewPageContentMutation } =
        usePageContentForReview({ id });

    const handleReviewChanges = () => {
        if (draft) {
            handleReviewPageContent(draft);
        }
    };

    const [isReadonly, setIsReadonly] = useState(false);
    const toggleReadonly = () => setIsReadonly(prev => !prev);

    const isPublishingReviewChanges = reviewPageContentMutation.isLoading;

    return (
        <HeaderWrapper pageTitle={page?.title || 'Page Members'}>
            <Flex justify='flex-end' m='5'>
                {page?.isApproved === true && (
                    <Badge colorScheme='green'>Accepted</Badge>
                )}
                {page?.isApproved === false && (
                    <Badge colorScheme='red'>Rejected</Badge>
                )}
            </Flex>
            {currentUserMemberRecord?.canUpdate && (
                <Editor
                    isReadonly={isReadonly}
                    pageId={id}
                    w='full'
                    value={content}
                    onChange={setDraft}
                />
            )}
            <Flex justify='flex-end'>
                <ButtonGroup colorScheme='blue' alignSelf='end'>
                    <Button onClick={toggleReadonly} variant='outline'>
                        {isReadonly ? 'Continue Editing' : 'Preview Changes'}
                    </Button>
                    {currentUserMemberRecord?.canUpdate && (
                        <Button
                            onClick={handleReviewChanges}
                            disabled={isPublishingReviewChanges}
                            isLoading={isPublishingReviewChanges}
                            loadingText='Review Request'
                        >
                            Review Request
                        </Button>
                    )}
                </ButtonGroup>
            </Flex>
            {page?.reviewRemarks && !page?.isApproved && (
                <Flex direction='column' mt='5'>
                    <Text>Review For Your Request :- </Text>
                    <Box mt='4'>
                        <Textarea
                            value={page.reviewRemarks}
                            isDisabled
                            textColor='black'
                            borderColor='black'
                        />
                    </Box>
                </Flex>
            )}
        </HeaderWrapper>
    );
};

export default DraftPageContent;
