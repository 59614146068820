import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Flex,
  Grid,
  HStack,
  Icon,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react';
import { Fragment } from 'react';
import * as _ from 'lodash';
import { CallSession } from '../../../../models/CallSession.model';
import useGroupCalls from '../../hooks/useGroupCalls';
import GroupVoiceCallButton from '../../components/GroupVoiceCallButton';
import CallIcons from '../../components/CallIcons';
import useCurrentUser from 'src/hooks/useCurrentUser';
import { VscCallOutgoing } from 'react-icons/vsc';
import {
  getDateDiffSecondsFromNow,
  secondsToDuration,
} from 'src/pages/call/components/header/components/call-duration/utils/call-duration.utils';
const GroupCallsPanel = () => {
  const { groupCalls } = useGroupCalls();
  const time24HourWithDay = (date: Date) => {
    return Intl.DateTimeFormat('en-US', {
      day: 'numeric',
      month: 'short',
      hour12: true,
      hour: '2-digit',
      minute: '2-digit',
    }).format(new Date(date));
  };
  const time24Hour = (date: Date) => {
    return Intl.DateTimeFormat('en-US', {
      hour12: true,
      hour: '2-digit',
      minute: '2-digit',
    }).format(new Date(date));
  };
  const [currentUser] = useCurrentUser();
  const getColor = (joinedAt: Date | null) => {
    if (joinedAt) {
      return 'green.500';
    } else if (!joinedAt) {
      return 'red.500';
    } else {
      return 'gray.500';
    }
  };

  const getDuration = (joinedAt: Date, disconnectedAt: Date) => {
    if (joinedAt && disconnectedAt) {
      const userJoinedAt = getDateDiffSecondsFromNow(new Date(joinedAt));
      const userDisconnectedAt = getDateDiffSecondsFromNow(
        new Date(disconnectedAt),
      );
      const durationDifference = userJoinedAt - userDisconnectedAt;
      return secondsToDuration(durationDifference);
    }
  };

  return (
    <List spacing='4' paddingInline='0'>
      <Accordion allowMultiple>
        {_.chain(groupCalls)
          .orderBy(({ createdAt }) => createdAt, 'desc')
          .value()
          .map(
            ({
              messageGroup,
              session,
              MessageGroupId,
              createdAt,
              HostUserId,
            }) => (
              <AccordionItem>
                <ListItem py='2' as={AccordionButton}>
                  <Flex pr='4'>
                    <Avatar name={messageGroup.name} />
                  </Flex>
                  <Flex flexDirection='column' w='full' flex='1'>
                    <HStack w='full'>
                      <Flex>
                        <Flex>
                          <Text fontWeight='bold' fontSize='lg'>
                            {messageGroup.name}
                          </Text>
                        </Flex>
                      </Flex>
                    </HStack>
                    <HStack w='full'>
                      <Flex>
                        <Flex mr='3'>
                          <Text fontWeight='bold'>
                            {_.map(session, session => {
                              if (session.CallerId === HostUserId) {
                                return (
                                  <Fragment>
                                    {session &&
                                      session.ReceiverId ===
                                        currentUser?.id && (
                                        <CallIcons
                                          key={session.CallId}
                                          callerId={session.CallerId}
                                          receiverId={session.ReceiverId}
                                          currentUser={currentUser}
                                          joinedAt={session.joinedAt}
                                          hasRejectedCall={session.hasRejected}
                                          hasMissedCall={session.hasMissed}
                                          isUnreachable={session.isUnreachable}
                                          color={getColor(session.joinedAt)}
                                        />
                                      )}
                                  </Fragment>
                                );
                              }
                            })}
                            {HostUserId === currentUser?.id && (
                              <Icon
                                as={VscCallOutgoing}
                                aria-label='user'
                                bg='white'
                                color='green.500'
                              />
                            )}
                          </Text>
                        </Flex>
                        <Flex>
                          <Text
                            overflow='hidden'
                            textOverflow='ellipsis'
                            whiteSpace='nowrap'
                            fontWeight='normal'
                            color='gray.500'
                          >
                            {time24HourWithDay(createdAt)}
                          </Text>
                        </Flex>
                      </Flex>
                    </HStack>
                  </Flex>
                  <Flex
                    flex='1'
                    justifyContent='flex-end'
                    pr='5'
                    alignItems='center'
                  >
                    <Flex>
                      <GroupVoiceCallButton
                        groupMessageThreadId={messageGroup.MessageThreadId}
                        groupId={MessageGroupId}
                      />
                    </Flex>
                  </Flex>
                  <Flex>
                    <HStack w='full' as={Flex} justifyContent='flex-end'>
                      <AccordionIcon />
                    </HStack>
                  </Flex>
                </ListItem>
                <AccordionPanel pb={4}>
                  <Grid gridTemplateColumns='1fr 1fr 1fr 1fr'>
                    <Flex bgColor='blue.50'>
                      <Text p='3' fontWeight='medium'>
                        Member
                      </Text>
                    </Flex>
                    <Flex bgColor='blue.50'>
                      <Text p='3' fontWeight='medium'>
                        Joined
                      </Text>
                    </Flex>
                    <Flex bgColor='blue.50'>
                      <Text p='3' fontWeight='medium'>
                        Ended
                      </Text>
                    </Flex>
                    <Flex bgColor='blue.50'>
                      <Text p='3' fontWeight='medium'>
                        Duration
                      </Text>
                    </Flex>
                    {_.map(
                      _.uniqBy(session, 'otherUsers.id'),
                      (session: CallSession) => {
                        const sessionWithJoinedAt = session.joinedAt;
                        const sessionWithDisconnectedAt =
                          session.disconnectedAt;

                        return (
                          <Fragment key={session.otherUsers.id}>
                            <Flex justifyContent='normal' mt='1'>
                              <Flex pl='3'>
                                <Avatar
                                  name={session.otherUsers.firstName}
                                  size='xs'
                                />
                              </Flex>
                              <Flex>
                                <Text
                                  fontWeight='normal'
                                  color='gray.500'
                                  pl='3'
                                >
                                  {session.otherUsers.firstName
                                    .charAt(0)
                                    .toUpperCase() +
                                    session.otherUsers.firstName.slice(1)}
                                </Text>
                              </Flex>
                            </Flex>
                            <Flex mt='1'>
                              <Text fontWeight='normal' color='gray.500'>
                                {sessionWithJoinedAt &&
                                  sessionWithDisconnectedAt &&
                                  time24Hour(sessionWithJoinedAt)}
                              </Text>
                            </Flex>
                            <Flex mt='1'>
                              <Text fontWeight='normal' color='gray.500'>
                                {sessionWithDisconnectedAt &&
                                  sessionWithJoinedAt &&
                                  time24Hour(sessionWithDisconnectedAt)}
                              </Text>
                            </Flex>
                            <Flex mt='1' pl='6'>
                              <Text fontWeight='normal' color='gray.500'>
                                {session.joinedAt &&
                                  session.disconnectedAt &&
                                  getDuration(
                                    session.joinedAt,
                                    session.disconnectedAt,
                                  )}
                              </Text>
                            </Flex>
                          </Fragment>
                        );
                      },
                    )}
                  </Grid>
                </AccordionPanel>
              </AccordionItem>
            ),
          )}
      </Accordion>
    </List>
  );
};
export default GroupCallsPanel;
