import { useState } from 'react';

type UseDialogAction = () => any;

const useDialog = (): [
  boolean,
  UseDialogAction,
  UseDialogAction,
  UseDialogAction,
] => {
  const [isDialogOpen, setDialogOpen] = useState(false);

  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);
  const toggleDialog = () => setDialogOpen(prev => !prev);

  return [isDialogOpen, openDialog, closeDialog, toggleDialog];
};

export default useDialog;
