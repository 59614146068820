import { useContext } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';

import UserRoomWrapper from './components/UserRoomWrapper';
import AuthQueryClientProvider, {
  AuthQueryClientContext,
} from '../hoc/AuthQueryClientProvider';
import Routes from '../routes';
import { theme } from '../utils/chakra-theme/theme';
import useCallNotifications from './hooks/call-notifications/useCallNotifications';
import { QueryCustomProvider } from 'src/hoc/hooks/query-context';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <AuthQueryClientProvider>
          <AppQueryProvider>
            {/* 
              Components below can now use both react-router (through BrowserRouter) and 
              the customized QueryClient instance (through AuthQueryClientProvider)
            */}
            <AppContent />
          </AppQueryProvider>
        </AuthQueryClientProvider>
      </BrowserRouter>
    </ChakraProvider>
  );
}

const AppContent = () => {
  useCallNotifications();

  return (
    <UserRoomWrapper>
      <Routes />
    </UserRoomWrapper>
  );
};

//TODO: this component not working outside this scope for unknown reason
const AppQueryProvider: React.FC = ({ children }) => {
  const context = useContext(AuthQueryClientContext);
  if (!context?.queryClient) {
    return null;
  }

  return <QueryCustomProvider>{children}</QueryCustomProvider>;
};

export default App;
