import {
  ListToolbarButton,
  getPluginType,
  ELEMENT_UL,
  ELEMENT_OL,
} from '@udecode/plate';
import { MdFormatListNumbered, MdFormatListBulleted } from 'react-icons/md';

import useEditorState from '../../hooks/useEditorState';

export const ListToolbarButtons = () => {
  const editor = useEditorState()!;

  return (
    <>
      <ListToolbarButton
        type={getPluginType(editor, ELEMENT_UL)}
        icon={<MdFormatListBulleted />}
      />
      <ListToolbarButton
        type={getPluginType(editor, ELEMENT_OL)}
        icon={<MdFormatListNumbered />}
      />
    </>
  );
};
