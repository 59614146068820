import { Avatar, AvatarProps } from '@chakra-ui/avatar';
import { User } from '../models';
import { getUserDisplayName } from '../utils/user.utils';

interface UserAvatarProps extends Omit<AvatarProps, 'name'> {
  user: User | undefined;
}

const UserAvatar: React.FC<UserAvatarProps> = ({ user, ...props }) => {
  const getBgProps = (): Partial<Pick<AvatarProps, 'bg'>> => {
    if (!user?.avatarUrl) {
      return {};
    }

    return { bg: 'unset' };
  };

  return (
    <Avatar
      src={user?.avatarUrl}
      name={user && getUserDisplayName(user)}
      {...getBgProps()}
      {...props}
    />
  );
};

export default UserAvatar;
