import React from 'react';
import { IconButton } from '@chakra-ui/button';
import {
  FormControl,
  FormControlProps,
  FormLabel,
} from '@chakra-ui/form-control';
import {
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/input';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

interface PasswordFieldProps {
  label: React.ReactNode;
  inputProps?: InputProps;
  formControlProps?: FormControlProps;
}

export const PasswordField: React.FC<PasswordFieldProps> = ({
  label,
  inputProps = {},
  formControlProps = {},
  children,
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClick = () => setShowPassword(!showPassword);

  const passwordInputIcon = showPassword ? <FaEye /> : <FaEyeSlash />;

  return (
    <FormControl {...formControlProps}>
      <FormLabel>{label}</FormLabel>
      <InputGroup>
        <Input
          pr='10'
          type={showPassword ? 'text' : 'password'}
          {...inputProps}
        />
        <InputRightElement width='14'>
          <IconButton
            variant='ghost'
            size='lg'
            icon={passwordInputIcon}
            onClick={handleClick}
            aria-label={
              showPassword
                ? 'Hide Password IconButton'
                : 'Show Password IconButton'
            }
          />
        </InputRightElement>
      </InputGroup>
      {children}
    </FormControl>
  );
};