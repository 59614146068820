import { useMemo } from 'react';
import { Box, Image, StyleProps } from '@chakra-ui/react';
import _ from 'lodash';

import {
  DeserializedPageContent,
  PageContentMediaEmbedNode,
} from '../../../types/page-content.type';
import {
  findFirstImageNode,
  findFirstMediaEmbedNode,
} from '../../../utils/page-content.utils';
import { getDocumentUrl } from 'src/config/document-url.config';

interface BlogCardThumbnailProps {
  deserializedContent: DeserializedPageContent;
}

const isLocallyUploadedEmbed = (embed: PageContentMediaEmbedNode): boolean => {
  // Document url example = "abcdef.mkv"
  // non-local url example = "https://youtube.com/watch?v=<video-id>"
  return _.split(embed.url, '/').length === 1;
};

const BlogCardThumbnail: React.FC<BlogCardThumbnailProps> = ({
  deserializedContent,
}) => {
  const firstImage = useMemo(
    () => findFirstImageNode(deserializedContent),
    [deserializedContent],
  );

  const firstMediaEmbed = useMemo(
    () => findFirstMediaEmbedNode(deserializedContent),
    [deserializedContent],
  );

  const commonProps: Pick<StyleProps, 'w' | 'maxW' | 'maxH' | 'objectFit'> = {
    w: 'full',
    maxW: 'full',
    maxH: '40',
    objectFit: 'cover',
  };

  if (firstImage) {
    return <Image src={getDocumentUrl(firstImage.url)} {...commonProps} />;
  }

  if (firstMediaEmbed && isLocallyUploadedEmbed(firstMediaEmbed)) {
    return (
      <Box
        as='video'
        src={getDocumentUrl(firstMediaEmbed.url)}
        preload='metadata'
        {...commonProps}
      />
    );
  }

  return null;
};

export default BlogCardThumbnail;
