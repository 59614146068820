import { createContext } from 'react';

type EditorId = string | undefined;

interface EditorIdContextType {
  editorId: EditorId;
}

export const EditorIdContext = createContext<EditorIdContextType>({
  editorId: undefined,
});

export const EditorIdProvider = EditorIdContext.Provider;
