import { useEffect, useState } from 'react';
import usePage from 'src/pages/blogs/blog/hooks/usePage';
import { EditablePageContent } from '../types/EditablePageContent.type';

const usePageContentForEdit = () => {
  const { page } = usePage();

  const [content, setContent] = useState<EditablePageContent>();

  useEffect(() => {
    if (page && page.content) {
      setContent(JSON.parse(page.content));
    }
  }, [page]);

  return { content, setContent };
};

export default usePageContentForEdit;
