import { IconButton } from '@chakra-ui/button';
import Icon from '@chakra-ui/icon';
import { Center } from '@chakra-ui/layout';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalProps,
  ModalFooter,
} from '@chakra-ui/modal';
import { BaseEmoji } from 'emoji-mart';
import { useEffect, useState } from 'react';
import { MdSend } from 'react-icons/md';
import { useMutation } from 'react-query';
import Slider, { Settings as CarouselSettings } from 'react-slick';

import { createMediaMessage } from '../../../apis/messages.api';
import BadgeBox from '../../../components/BadgeButton';
import CarouselArrow from '../../../components/CarouselArrow';
import { MediaMessageDto } from '../../../dto/messages.dto';
import useMediaPreview from '../hooks/useMediaPreview';
import BasicInputPanel from './BasicInputPanel';
import MediaPreview from './MediaPreview';
import PopoverEmojiButton from './PopoverEmojiButton';
import './MediaPreviewDialog.css';

interface MediaPreviewProps {
  media: Blob;
}

const MediaPreviewItem: React.FC<MediaPreviewProps> = ({ media }) => {
  const [previewUrl, setPreviewUrl] = useState<string>();

  useEffect(() => {
    if (!previewUrl) {
      
      const url = URL.createObjectURL(media);
      setPreviewUrl(url);
    }

    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [media, previewUrl]);

  if (!previewUrl) {
    return null;
  }

  return (
    <Center h='full'>
      <MediaPreview
        h='full'
        maxH='96'
        media={{
          mimeType: media.type,
          mediaUrl: previewUrl,
          previewName: media instanceof File ? media.name : undefined,
        }}
      />
    </Center>
  );
};

const kMediaPreviewSliderSettings: CarouselSettings = {
  dots: true,
  infinite: true,
  lazyLoad: 'ondemand',
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <CarouselArrow direction='next' />,
  prevArrow: <CarouselArrow direction='prev' />,
};

export interface MediaPreviewDialogProps extends Omit<ModalProps, 'children'> {
  media: Blob[] | File[] | FileList;
  messageThreadId: number;
}

const MediaPreviewDialog: React.FC<MediaPreviewDialogProps> = ({
  media,
  messageThreadId,
  ...props
}) => {
  const createMediaMessageMutation = useMutation(
    (mediaMessage: MediaMessageDto) =>
      createMediaMessage(messageThreadId, mediaMessage),
  );

  const [selectedMediaItems, caption, { setCaption }] = useMediaPreview({
    media,
  });

  const countMediaPreviews = selectedMediaItems.length;
  const hasMultipleMediaPreviews = countMediaPreviews > 1;

  const handleOnInputChange: React.ChangeEventHandler<HTMLInputElement> = e =>
    setCaption(e.currentTarget.value);

  const onSend = () => {
    createMediaMessageMutation.mutate(
      {
        caption,
        messageMedia: selectedMediaItems,
      },
      { onSuccess: props.onClose, onError: props.onClose },
    );
  };

  const onEmojiSelect = ({ native }: BaseEmoji) => {
    setCaption(`${caption || ''}${native}`);
  };

  const sendButton = (
    <BadgeBox
      badgeContent={countMediaPreviews}
      showBadge={hasMultipleMediaPreviews}
    >
      <IconButton
        variant='ghost'
        icon={<Icon as={MdSend} boxSize='6' />}
        aria-label='send button'
        onClick={onSend}
      />
    </BadgeBox>
  );

  
  return (
    <Modal isCentered {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Preview Files</ModalHeader>
        <ModalCloseButton />
        <ModalBody py='8'>
          <Slider {...kMediaPreviewSliderSettings}>
            {selectedMediaItems.map(mediaItem => (
              <MediaPreviewItem media={mediaItem} key={mediaItem.size} />
            ))}
          </Slider>
        </ModalBody>

        <ModalFooter>
          <BasicInputPanel
            inputProps={{
              value: caption,
              onChange: handleOnInputChange,
            }}
            inputPrefix={<PopoverEmojiButton onSelectEmoji={onEmojiSelect} />}
            inputSuffix={sendButton}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MediaPreviewDialog;
