import { Button } from '@chakra-ui/button';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { HStack, Text, VStack } from '@chakra-ui/layout';
import { AxiosError } from 'axios';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';

import { PasswordField } from '../../../components/form/PasswordField';
import { login } from '../../../apis/auth.api';
import { LoginDto } from '../../../dto/auth.dto';
import useAuthStore from '../../../hooks/useAuthStore';
import RouteLink from '../../../components/RouteLink';
import { REGISTER_PAGE_ROUTE } from '../../../routes/routeList';

interface LoginFormProps {
  onLoginSuccess: () => void;
  onLoginError: (err: AxiosError) => void;
}

interface LoginFormData extends LoginDto {}

const kLoginFormId = 'login-form';

const kLoginFormSchema: yup.SchemaOf<LoginFormData> = yup.object().shape({
  email: yup
    .string()
    .required('Please enter your email')
    .email('Please enter a valid email'),
  password: yup.string().required('Please enter your password'),
});

const LoginForm: React.FC<LoginFormProps> = ({
  onLoginError,
  onLoginSuccess,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormData>({
    resolver: yupResolver(kLoginFormSchema),
    shouldUnregister: false,
  });

  const { setAccessToken } = useAuthStore()[1];

  const submitLoginForm = async (loginData: LoginFormData) => {
    try {
      const { accessToken } = await login(loginData);
      setAccessToken(accessToken);
      onLoginSuccess();
    } catch (err: any) {
      onLoginError(err);
    }
  };

  return (
    <VStack
      height="96"
      spacing="8"
      as="form"
      onSubmit={handleSubmit(submitLoginForm)}
      id={kLoginFormId}
    >
      <FormControl isInvalid={!!errors.email} isRequired>
        <FormLabel>Email</FormLabel>
        <Input
          {...register('email', {
            setValueAs: (value: string) => value?.trim(),
          })}
        />
        {errors.email && (
          <FormErrorMessage>{errors.email.message}</FormErrorMessage>
        )}
      </FormControl>

      <PasswordField
        label="Enter Password"
        formControlProps={{ isInvalid: !!errors.password, isRequired: true }}
        inputProps={register('password')}
      >
        {errors.password && (
          <FormErrorMessage>{errors.password.message}</FormErrorMessage>
        )}
      </PasswordField>

      <Button
        w="100%"
        py="4"
        colorScheme="blue"
        type="submit"
        form={kLoginFormId}
      >
        Login
      </Button>

      <HStack>
        <Text fontWeight="bold">Don't have an account?</Text>
        <RouteLink to={REGISTER_PAGE_ROUTE}>Create One</RouteLink>
      </HStack>
    </VStack>
  );
};

export default LoginForm;
