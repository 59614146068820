import { VStack } from '@chakra-ui/layout';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/modal';
import {
  Button,
  FormControl,
  FormLabel,
  Textarea,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { reviewPage } from 'src/apis/pages.api';
import { UpdatePageDto } from 'src/dto/pages.dto';
import { Page } from 'src/models/Page.model';
import usePage from 'src/pages/blogs/blog/hooks/usePage';
import useReviewContent from './hooks/useReviewContent';
import { EditablePageContent } from './types/EditablePageContent.type';
import { ReviewPageType } from './types/pageMember.types';

interface UseRejectPageContentOptions extends Pick<Page, 'id'> {}

const PageRemarkDialog = ({ id }: UseRejectPageContentOptions) => {
  const { page } = usePage();
  const [review, setReview] = useState<UpdatePageDto>({
    isApproved: page?.isApproved || null,
    reviewRemarks: page?.reviewRemarks,
  });

  const history = useHistory();

  const showToast = useToast();

  const updatePageContentMutation = useMutation<
    Page,
    AxiosError,
    UpdatePageDto
  >(pageContentUpdate => reviewPage(id, pageContentUpdate), {
    onSuccess: () => {
      history.goBack();
      showToast({
        title: 'Review remarks send successfully..!',
        description: 'Remarks has been sended to User',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
    onError: () => {
      showToast({
        title: 'Error while sending review message to user',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const handleRejectPageContent = useCallback(
    (isApproved: EditablePageContent) =>
      updatePageContentMutation.mutate({
        isApproved: false,
        reviewRemarks: review.reviewRemarks,
      }),
    [updatePageContentMutation],
  );

  const { register, handleSubmit } = useForm<ReviewPageType>();

  const handleRemarkChange: React.ChangeEventHandler<HTMLTextAreaElement> =
    e => {
      setReview(prev => ({ ...prev, reviewRemarks: e.target.value }));
    };

  const { draft } = useReviewContent();

  const handleReject = () => {
    if (draft) {
      handleRejectPageContent(draft);
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button variant='outline' onClick={onOpen}>
        Reject
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <form onSubmit={handleSubmit(handleReject)}>
          <ModalContent>
            <ModalHeader>Reject Remark</ModalHeader>
            <ModalBody>
              <VStack align='start' spacing='6'>
                <FormControl>
                  <FormLabel>Review Remarks</FormLabel>
                  <Textarea
                    placeholder='Let the page reviewer know your remarks.'
                    value={review.reviewRemarks}
                    {...register('reviewRemarks')}
                    onChange={handleRemarkChange}
                  />
                </FormControl>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme='blue'
                isLoading={updatePageContentMutation.isLoading}
                onClick={() => updatePageContentMutation.mutate}
                type='submit'
              >
                Submit Page Review
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
};

export default PageRemarkDialog;
