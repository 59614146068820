import { useState } from 'react';

interface UseStepsOptions {
  initialStep?: number;
}

export function useSteps(options?: UseStepsOptions) {
  const initialStep = options?.initialStep || 0;
  const [activeStep, setActiveStep] = useState(initialStep);

  const nextStep = () => setActiveStep(prev => prev + 1);

  const prevStep = () => setActiveStep(prev => prev - 1);

  const reset = () => setActiveStep(initialStep);

  const setStep = (step: number) => setActiveStep(step);

  const isCurrentStep = (step: number) => step === activeStep;

  return { nextStep, prevStep, reset, setStep, activeStep, isCurrentStep };
}
