import CallConnectionStatus from '../../call-connection-status/CallConnectionStatus';
import CallDuration from '../../call-duration';
import usePersonalCallSession from '../hooks/usePersonalCallSession';

interface PersonalCallStatusProps {}

const PersonalCallStatus: React.FC<PersonalCallStatusProps> = () => {
  const session = usePersonalCallSession();

  if (!session) {
    return null;
  }

  // Session is ongoing. Show the call duration.
  if (session.joinedAt) {
    return <CallDuration callSession={session} />;
  }

  return <CallConnectionStatus callSession={session} />;
};

export default PersonalCallStatus;
