import { AxiosRequestConfig } from 'axios';
import { getAccessToken } from '../../providers/auth-store.provider';

export const getAuthorizationHeaders = () => {
  const headers: AxiosRequestConfig['headers'] = {};
  const accessToken = getAccessToken();
  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return headers;
};
