import { Icon, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { IoEllipsisVertical } from 'react-icons/io5';
import { Link } from 'react-router-dom';

import { PersonalMessageThread } from '../../../models/PersonalMessageThread.model';
import { USER_PROFILE_ROUTE } from '../../../routes/routeList';

interface PersonalChatMenuProps {
  messageThread: PersonalMessageThread;
}

const PersonalChatMenu: React.FC<PersonalChatMenuProps> = ({
  messageThread,
}) => {
  const recipientProfileRoute = USER_PROFILE_ROUTE.replace(
    `:userId`,
    messageThread.recipient.id.toString()
  );

  return (
    <>
      <Menu autoSelect={false} placement="left-start">
        <MenuButton>
          <Icon as={IoEllipsisVertical} boxSize="6" />
        </MenuButton>
        <MenuList>
          <MenuItem as={Link} to={recipientProfileRoute}>
            View Profile
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

export default PersonalChatMenu;
