import { Button, ButtonProps } from '@chakra-ui/react';
import { useState } from 'react';

import FileInput, { FileInputProps } from './FileInput';

interface FileInputButtonProps extends Omit<ButtonProps, 'onClick'> {
  fileInputProps: Omit<FileInputProps, 'ref'>;
}

const FileInputButton: React.FC<FileInputButtonProps> = ({
  fileInputProps,
  ...props
}) => {
  /**
   * Why not use useRef?
   * Because when the ref actually gets registered in the DOM, we need to re-render the button
   * below with the updated ref that it will use when user clicks on the button.
   *
   * If we use useRef, it won't re-render the button, and the ref will be null (the initial value).
   * This means, even if the ref gets registered, the button will still reference the old null ref
   * and will not work as intended.
   */
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

  return (
    <>
      <Button
        disabled={!inputRef}
        onClick={() => {
          if (inputRef) {
            inputRef.click();
          }
        }}
        {...props}
      />
      <FileInput ref={setInputRef} {...fileInputProps} />
    </>
  );
};

export default FileInputButton;
