import { Center, Flex, IconButton, Text } from '@chakra-ui/react';
import { FiArrowLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { getUserDisplayName } from '../../../utils/user.utils';
import { GroupMessageThread } from '../../../models/GroupMessageThread.model';
import { MessageThread } from '../../../models/MessageThread.model';
import { PersonalMessageThread } from '../../../models/PersonalMessageThread.model';

import GroupMenu from './GroupMenu';
import PersonalChatMenu from './PersonalChatMenu';
import { maxWidth } from '../../../constants/max-width.constant';
import GroupCallButton from './GroupCallButton';
import PersonalCallButton from './PersonalCallButton';

interface ChatHeaderProps {
  messageThread: MessageThread;
}

const ChatHeader: React.FC<ChatHeaderProps> = ({ messageThread }) => {
  const history = useHistory();

  const getTitle = (): string => {
    if (messageThread.isGroup) {
      const group = (messageThread as unknown as GroupMessageThread).group;
      return group.name;
    }

    const recipient = (messageThread as unknown as PersonalMessageThread)
      .recipient;
    return getUserDisplayName(recipient);
  };

  return (
    <Center position='fixed' w='full' zIndex='modal'>
      <Flex
        alignItems='center'
        bg='gray.50'
        h='12'
        justifyContent='space-between'
        boxShadow='lg'
        borderTop='1px solid'
        borderColor='gray.200'
        px='2'
        zIndex='200'
        w='full'
        maxW={maxWidth}
        mx='auto'
      >
        <IconButton
          aria-label='back icon'
          icon={<FiArrowLeft />}
          boxSize='6'
          variant='ghost'
          onClick={() => history.replace('/messages')}
        />
        <Text fontWeight='bold' flexGrow={2} align='center'>
          {getTitle()}
        </Text>
        {messageThread.isGroup ? (
          <GroupCallButton
            groupMessageThread={messageThread as GroupMessageThread}
          />
        ) : (
          <PersonalCallButton
            personalMessageThread={
              messageThread as unknown as PersonalMessageThread
            }
          />
        )}
        {messageThread.isGroup ? (
          <GroupMenu messageThreadId={messageThread.id} />
        ) : (
          <PersonalChatMenu
            messageThread={messageThread as unknown as PersonalMessageThread}
          />
        )}
      </Flex>
    </Center>
  );
};

export default ChatHeader;
