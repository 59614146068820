import {
  MARK_BOLD,
  MARK_ITALIC,
  MARK_UNDERLINE,
  MARK_CODE,
} from '@udecode/plate';
import {
  MdFormatBold,
  MdFormatItalic,
  MdFormatUnderlined,
  MdCode,
} from 'react-icons/md';

import { MarkToolbarButton } from './MarkToolbarButton';

export const MarkToolbarButtons = () => {
  return (
    <>
      <MarkToolbarButton icon={MdFormatBold} mark={MARK_BOLD} />
      <MarkToolbarButton icon={MdFormatItalic} mark={MARK_ITALIC} />
      <MarkToolbarButton icon={MdFormatUnderlined} mark={MARK_UNDERLINE} />
      <MarkToolbarButton icon={MdCode} mark={MARK_CODE} />
    </>
  );
};
