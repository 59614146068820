import { Input, InputProps } from '@chakra-ui/input';

interface FileInputProps extends Omit<InputProps, 'type' | 'onChange'> {
  onFiles?: (
    files: HTMLInputElement['files'],
    event: React.ChangeEvent<HTMLInputElement>
  ) => any;
}

const FileInput: React.FC<FileInputProps> = ({ onFiles, ...props }) => {
  // Since this is a file input, it would be a good idea to have a easy way to directly access the files
  // Hence we take the files in the target and pass it as the first argument and the event as the second in case the caller needs it
  const onChange: InputProps['onChange'] = (e) => {
    onFiles && onFiles(e.target.files, e);

    /**
       * Reset the input field.
       * This is required for a specific case:
       * 
       * If the user removes a file and selects the same file back again, 
       * the input field will not register the change event - since the file is the same.
       * 
       * To fix this, we reset the input field on every change so that even if user selects the same file again after removing
       * the file, the input field will register the change event.
       */
     e.target.value = '';
  };

  return <Input type="file" display="none" onChange={onChange} {...props} />;
};

export default FileInput;
