import { Center, CenterProps } from '@chakra-ui/layout';

const kMaxDisplayNumber = 99;

interface NumberBadgeProps extends CenterProps {
  displayNumber: number;
}

const NumberBadge: React.FC<NumberBadgeProps> = ({
  displayNumber,
  ...props
}) => {
  const clippedDisplayNumber =
    displayNumber > kMaxDisplayNumber ? `${kMaxDisplayNumber}+` : displayNumber;

  return (
    <Center
      p="0.5"
      fontSize="xs"
      color="white"
      bg="blue.500"
      borderRadius="full"
      {...props}
    >
      {clippedDisplayNumber}
    </Center>
  );
};

export default NumberBadge;
