import { axiosInstance } from '../config';
import { DashboardSection } from '../models/DashboardSection.model';
import { DashboardSectionLink } from '../models/DashboardSectionLink.model';

const rootUrl = '/dashboard-section-links';

export const getAllLinksOfDashboardSection = async (
  dashboardSectionId: DashboardSection['id'],
) => {
  const endpointUrl = `${rootUrl}/dashboard-section/${dashboardSectionId}`;

  const { data } = await axiosInstance.get<DashboardSectionLink[]>(endpointUrl);
  return data;
};
