import { HStack, ListItem, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import useCurrentUser from 'src/hooks/useCurrentUser';
import { getOtherUserId } from './getOtherUserId';
import PersonalVoiceCallButton from './PersonalVoiceCallButton';
import CallIcons from './CallIcons';

export interface CallItemProps {
  callerId: number;
  receiverId: number;
  joinedAt: Date | null;
  hasRejectedCall: boolean | null;
  hasMissedCall?: boolean;
  isUnreachable: boolean;
  avatarComponent?: React.ReactNode;
  title: React.ReactNode;
  subtitle: React.ReactNode;
}

const CallItem: React.FC<CallItemProps> = ({
  callerId,
  receiverId,
  joinedAt,
  hasRejectedCall,
  hasMissedCall,
  avatarComponent,
  isUnreachable,
  title,
  subtitle,
}) => {
  // get current user
  const [currentUser] = useCurrentUser();
  // get Other User Id
  const [otherUserId, setOtherUserId] = useState<number>();

  useEffect(() => {
    if (currentUser) {
      setOtherUserId(
        getOtherUserId(
          { CallerId: callerId, ReceiverId: receiverId },
          currentUser.id,
        ),
      );
    }
  }, [currentUser, callerId, receiverId]);

  const getColor = (joinedAt: Date | null) => {
    if (joinedAt) {
      return 'green.500';
    } else if (!joinedAt) {
      return 'red.500';
    } else {
      return 'gray.500';
    }
  };

  return (
    <ListItem as={HStack} py='2'>
      {avatarComponent}
      <VStack spacing='1' align='start' w='full' minW='0'>
        <HStack justifyContent='space-between' w='full'>
          <Text fontWeight='bold'>{title}</Text>
        </HStack>
        <HStack w='full'>
          {/* 
             - fetch current user
             - compare if current user is caller it means that the call is outgoing
             - if the current user is receiver , it means that the call is incoming
            

             - make individual components fot them

             - check if call is joined..simply check value of joinedAt
             - if the call is joined so no matter weather the call is incoming or outgoing it will always green
             - if call is not joined by anyone so  icons will be red
             */}

          {currentUser && (
            <CallIcons
              joinedAt={joinedAt}
              callerId={callerId}
              currentUser={currentUser}
              receiverId={receiverId}
              hasMissedCall={hasMissedCall}
              hasRejectedCall={hasRejectedCall}
              isUnreachable={isUnreachable}
              color={getColor(joinedAt)}
            />
          )}

          <Text
            pr='75'
            overflow='hidden'
            whiteSpace='nowrap'
            textOverflow='ellipsis'
            maxW='80%'
            fontWeight='normal'
            color='gray.500'
          >
            {subtitle}
          </Text>
        </HStack>
      </VStack>

      {/* to make a call while button click
      1- get other user id using function getOtherUserId(callerId, currentUserId)
      2- then pass the other user id in the component as a props
      3- pass this id to useNewCall function as an argument and also return the icon.
*/}

      {otherUserId && <PersonalVoiceCallButton otherUserId={otherUserId} />}
    </ListItem>
  );
};

export default CallItem;
