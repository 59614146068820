import { AxiosError } from 'axios';
import { useCallback, useMemo } from 'react';
import {
    useQuery,
    useQueryClient,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query';
import { getCurrentUser } from '../apis/auth.api';
import { User } from '../models';

const kGetCurrentUserQueryKey = 'getCurrentUser';

export const useCurrentUserQueryClient = () => {
    const queryClient = useQueryClient();

    const refetchCurrentUser = useCallback(() => {
        queryClient.refetchQueries(kGetCurrentUserQueryKey);
    }, [queryClient]);

    return { refetchCurrentUser };
};

const useCurrentUser = (
    options?: UseQueryOptions<User, AxiosError>,
): [User | undefined, Omit<UseQueryResult, 'data'>] => {
    const { data: user, ...queryResult } = useQuery<User, AxiosError>(
        kGetCurrentUserQueryKey,
        () => getCurrentUser(),
        {
            retry: (fc, err) => err.response?.status !== 401,
            ...options,
            cacheTime: 5000,
        },
    );
    const currentUser = useMemo(() => user, [user]);

    return [currentUser, queryResult];
};

export default useCurrentUser;
