import {
  BalloonToolbar,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_UNDERLINE,
} from '@udecode/plate';
import {
  MdFormatBold,
  MdFormatItalic,
  MdFormatUnderlined,
} from 'react-icons/md';

import { LinkToolbarButton } from './link-toolbar';
import { MarkToolbarButton } from './mark-toolbar';

export const BallonToolbarMarks = () => {
  return (
    <BalloonToolbar
      popperOptions={{
        placement: 'top',
      }}
      theme='dark'
    >
      <MarkToolbarButton mark={MARK_BOLD} icon={MdFormatBold} />
      <MarkToolbarButton mark={MARK_ITALIC} icon={MdFormatItalic} />
      <MarkToolbarButton mark={MARK_UNDERLINE} icon={MdFormatUnderlined} />
      <LinkToolbarButton
        _hover={{ color: 'white' }}
        _selected={{ color: 'white' }}
        _active={{ color: 'white' }}
        iconProps={{
          boxSize: '5',
        }}
      />
    </BalloonToolbar>
  );
};
