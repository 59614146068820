import HeaderWrapper from '../hoc/HeaderWrapper';
import HomePagee from './home';

const HomePage = () => {
  return (
    <HeaderWrapper pageTitle='Home'>
      <HomePagee />
    </HeaderWrapper>
  );
};

export default HomePage;
