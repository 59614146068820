import {
  MenuGroup,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Button,
  FormErrorMessage,
  HStack,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { MdInsertLink } from 'react-icons/md';
import * as yup from 'yup';

import MenuItem from './MenuItem';
import useInsertMediaEmbed from '../hooks/useInsertMediaEmbed';
import { VoidCallback } from 'src/types/common.type';

interface InsertLinkMediaFormData {
  mediaUrl: string;
}

const insertMediaUrlFormSchema: yup.SchemaOf<InsertLinkMediaFormData> = yup
  .object()
  .shape({
    mediaUrl: yup
      .string()
      .url('Please enter a valid url')
      .required('Please enter a url'),
  });

interface InsertMediaUsingURLOptionProps {
  onMediaInserted: VoidCallback;
}

const InsertMediaUsingURLOption: React.FC<InsertMediaUsingURLOptionProps> = ({
  onMediaInserted,
}) => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<InsertLinkMediaFormData>({
    resolver: yupResolver(insertMediaUrlFormSchema),
  });
  const { insertMediaEmbed } = useInsertMediaEmbed();

  const handleInsertMedia = (data: InsertLinkMediaFormData) => {
    insertMediaEmbed(data.mediaUrl);
    reset();
    onMediaInserted();
  };

  return (
    <MenuGroup>
      {/* Need to disable to allow focus on input element */}
      <MenuItem isDisabled _disabled={{}}>
        <FormControl isInvalid={!!errors.mediaUrl}>
          {/*
              Don't remove the `display='flex'` prop. FormLabel's display block will override the flex display
              set by HStack. To avoid this, we need to set the display flex explicitly.
          */}
          <FormLabel as={HStack} display='flex' fontSize='xs' maxW='full'>
            <MdInsertLink />
            <Text>Insert media using an URL</Text>
          </FormLabel>
          <Input size='xs' rounded='4' {...register('mediaUrl')} />
          <FormErrorMessage fontSize='xs'>
            {errors.mediaUrl?.message}
          </FormErrorMessage>
        </FormControl>
      </MenuItem>
      <MenuItem>
        <Flex w='full' justifyContent='end'>
          {/* We have just one form field so not using html form (btn type submit, formId, etc) */}
          <Button
            onClick={handleSubmit(handleInsertMedia)}
            size='xs'
            colorScheme='blue'
          >
            Insert Media
          </Button>
        </Flex>
      </MenuItem>
    </MenuGroup>
  );
};

export default InsertMediaUsingURLOption;
