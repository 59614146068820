import {
  createPlugins,
  createParagraphPlugin,
  createListPlugin,
  createBoldPlugin,
  createItalicPlugin,
  createUnderlinePlugin,
  createCodePlugin,
  createImagePlugin,
  createAlignPlugin,
  ELEMENT_IMAGE,
  createPlateUI,
  createLinkPlugin,
  ELEMENT_MEDIA_EMBED,
  createMediaEmbedPlugin,
} from '@udecode/plate';
import { useMemo } from 'react';

import CustomImageElement from '../plugins/image/image-element';
import CustomMediaEmbedElement from '../plugins/media-embed/media-embed-element';

const useSlatePlugins = () => {
  return useMemo(
    () =>
      createPlugins(
        [
          // mark plugins
          createBoldPlugin(),
          createItalicPlugin(),
          createUnderlinePlugin(),
          createCodePlugin(),

          // block (element) plugins
          createParagraphPlugin(),
          createListPlugin(),
          createImagePlugin(),
          createAlignPlugin(),
          createMediaEmbedPlugin(),
          createLinkPlugin(),
        ],
        {
          components: createPlateUI({
            [ELEMENT_IMAGE]: CustomImageElement,
            [ELEMENT_MEDIA_EMBED]: CustomMediaEmbedElement,
          }),
        },
      ),
    [],
  );
};

export default useSlatePlugins;
