import { useToast } from '@chakra-ui/react';
import { useCallback } from 'react';

import { kMaxFileSizeMegabytes } from '../constants/files.constants';
import { ValueCallback } from '../types/common.type';
import { megabytes, toMegabytes } from '../utils/data.utils';

const useFileWithSizeLimit = () => {
  const showToast = useToast();

  const validateFileSize = useCallback<ValueCallback<File, boolean>>(
    file => {
      if (file.size > megabytes(kMaxFileSizeMegabytes)) {
        showToast({
          title:
            'File is too large (' + toMegabytes(file.size).toFixed(2) + ' MB)',
          description: `File size should be less than ${kMaxFileSizeMegabytes} MB`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return false;
      }

      return true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return { validateFileSize };
};

export default useFileWithSizeLimit;
