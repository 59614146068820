import { axiosInstance } from 'src/config';
import { FeedPostCommentDto } from 'src/dto/feed-post-comment.dto';
import { Comment } from 'src/models/Comment.model';
import { getRequestData } from './utils/get-request-data.util';

export const kCommentRootUrl = '/comments';

export const getIndividualComments = (
  EntityId: number,
  entityType: string,
): Promise<Comment[]> => {
  const url = `${kCommentRootUrl}/entity/${EntityId}/${entityType}`;
  const result = getRequestData(axiosInstance.get(url));
  return result;
};

export const addCommentsToPost = ({
  EntityId,
  entityType,
  description,
}: FeedPostCommentDto): Promise<FeedPostCommentDto[]> => {
  const url = `${kCommentRootUrl}/`;
  return getRequestData<FeedPostCommentDto[]>(
    axiosInstance.post(url, { EntityId, entityType, description }),
  );
};

export const updateCommentsToPost = (
  commentId: number,
  { EntityId, entityType, description }: FeedPostCommentDto,
): Promise<FeedPostCommentDto[]> => {
  const url = `${kCommentRootUrl}/${commentId}`;
  return getRequestData<FeedPostCommentDto[]>(
    axiosInstance.put(url, { EntityId, entityType, description }),
  );
};

export const deleteComment = (commentId: number) => {
  const url = `${kCommentRootUrl}/${commentId}`;
  return getRequestData(axiosInstance.delete(url));
};
