import { Container, Text, useToast, UseToastOptions } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { Redirect } from 'react-router';
import { useDisableLoader } from 'src/hooks/useDisableLoader';

import HeaderWrapper from '../../hoc/HeaderWrapper';
import useCurrentUser from '../../hooks/useCurrentUser';
import useUserRoom from '../../hooks/useUserRoom';
import { HOME_PAGE_ROUTE } from '../../routes/routeList';
import LoginForm from './components/LoginForm';

const LoginPage = () => {
  const [currentUser] = useCurrentUser();
  const { joinRoom } = useUserRoom();

  const [isLoginSuccessful, setLoginSuccessFul] = useState(false);
  const showToast = useToast();

  const onLoginSuccess = () => {
    setLoginSuccessFul(true);
    joinRoom();
    // This is a hack to ensure the currenUser hook is updated
    // queryClient.refetchQueries or invalidateQueries didn't work
    // TODO: find a better way to update the currentUser hook state after login
    window.location.reload();
  };
  useDisableLoader();
  const onLoginError = (err: AxiosError) => {
    let toastOptions: UseToastOptions = {
      title: 'Failed to Login',
      description: 'An unkown error occured. Please try again later.',
      status: 'error',
      isClosable: true,
    };

    if (err && err.response) {
      if (err.response.status === 401) {
        toastOptions.description =
          'Invalid credentials. Please check your login data.';
      }
    }

    setLoginSuccessFul(false);
    showToast(toastOptions);
  };

  if (isLoginSuccessful || currentUser) {
    return <Redirect to={HOME_PAGE_ROUTE} />;
  }

  return (
    <HeaderWrapper pageTitle='Login'>
      <Container mt='40'>
        <Text
          align='center'
          mb='8'
          fontWeight='bold'
          color='blue.500'
          fontSize='2xl'
        >
          Login
        </Text>
        <LoginForm
          onLoginSuccess={onLoginSuccess}
          onLoginError={onLoginError}
        />
      </Container>
    </HeaderWrapper>
  );
};

export default LoginPage;
