import {
  MenuItem as ChakraMenuItem,
  MenuItemProps as ChakraMenuItemProps,
} from '@chakra-ui/menu';

type MenuItemProps = ChakraMenuItemProps;
const MenuItem: React.FC<MenuItemProps> = props => {
  return (
    <ChakraMenuItem
      _hover={{}}
      _focus={{}}
      _selected={{}}
      _active={{}}
      color='white'
      {...props}
    />
  );
};

export default MenuItem;
