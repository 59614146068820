import { BoxProps, Center, Flex } from '@chakra-ui/layout';
import { Tag } from '@chakra-ui/tag';
import _ from 'lodash';
import { animateScroll, Events } from 'react-scroll';

import MessageBox from './MessageBox';
import { sortByDate } from '../../../utils/sort-by-date';
import { Message } from '../../../models/Message.model';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { Button } from '@chakra-ui/button';
import { MdArrowDownward } from 'react-icons/md';
import Icon from '@chakra-ui/icon';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  onNewMessage,
  unsubscribeNewMessage,
} from '../../../apis/socket.io/messages.socket.io';
import { useDisableLoader } from 'src/hooks/useDisableLoader';

interface MessageBoxContainerProps {
  message: Message;
  alignMessage: 'start' | 'end';
  MessageComponent?: React.ComponentType<{ message: Message } & BoxProps>;
}

const MessageBoxContainer: React.FC<MessageBoxContainerProps> = ({
  message,
  alignMessage,
  MessageComponent,
}) => {
  const msgColor = 'blackAlpha.800';
  return (
    <Flex
      w='full'
      justifyContent={alignMessage}
      flexDir={alignMessage === 'start' ? 'row' : 'row-reverse'}
      mt='6'
    >
      {MessageComponent ? (
        <MessageComponent
          maxW='80%'
          bg={alignMessage === 'start' ? '#bee3f8' : 'gray.100'}
          color={msgColor}
          message={message}
        />
      ) : (
        <MessageBox
          maxW='80%'
          bg={alignMessage === 'start' ? '#bee3f8' : 'gray.100'}
          color={msgColor}
          message={message}
        />
      )}
    </Flex>
  );
};

interface ChatMessagesPanelProps extends BoxProps {
  messages: Message[];
  MessageComponent?: React.ComponentType<
    { message: Message; senderNameColor?: BoxProps['color'] } & BoxProps
  >;
}

const dateMonthYear = (dateObj: Date) => {
  return Intl.DateTimeFormat('en-US', {
    month: 'short',
    year: 'numeric',
    day: '2-digit',
  }).format(dateObj);
};

const renderMessages = (
  messages: Message[],
  currentUserId: number,
  MessageComponent?: ChatMessagesPanelProps['MessageComponent'],
) => {
  return messages.map(message => (
    <MessageBoxContainer
      key={message.id}
      message={message}
      alignMessage={message.SenderId === currentUserId ? 'end' : 'start'}
      MessageComponent={MessageComponent}
    />
  ));
};

const ChatMessagesPanel: React.FC<ChatMessagesPanelProps> = ({
  messages,
  MessageComponent,
  ...props
}) => {
  const [currentUser] = useCurrentUser();
  const groupedMessages: Record<string, Message[]> = _.groupBy(
    messages,
    message => dateMonthYear(message.createdAt),
  );

  useDisableLoader()

  // We need the most recent/last msg in the top of the list hence order by descending
  const groupedMessageKeys = Object.keys(groupedMessages).sort((a, b) =>
    sortByDate(a, b, 'desc'),
  );

  const msgContainerRef = useRef<HTMLDivElement | null>(null);

  const [shouldShowNewMsgBtn, setShouldShowNewMsgBtn] = useState(false);

  const checkIfScrolledTooFar = () => {
    const container = msgContainerRef.current;
    return !!container && container.scrollTop < -100;
  };

  useEffect(() => {
    Events.scrollEvent.register('end', function () {
      setShouldShowNewMsgBtn(false);
    });

    return () => Events.scrollEvent.remove('end');
  }, []);

  const scrollToBottom = useCallback(
    () =>
      animateScroll.scrollToBottom({
        containerId: 'chat-messages-panel',
        smooth: 'easeInQuad',
      }),
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleNewMessage = useCallback(() => {
    if (checkIfScrolledTooFar()) {
      setShouldShowNewMsgBtn(true);
    } else {
      scrollToBottom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onNewMessage(handleNewMessage);
    return () => unsubscribeNewMessage(handleNewMessage);
  }, [handleNewMessage]);

  if (!currentUser) {
    return null;
  }

  return (
    <>
      {shouldShowNewMsgBtn && (
        <Center position="absolute" bottom="16" left="0" w="full">
          <Button
            rightIcon={<Icon as={MdArrowDownward} mt='1' />}
            alignItems='center'
            size='sm'
            colorScheme='blue'
            onClick={scrollToBottom}
          >
            New Message(s)
          </Button>
        </Center>
      )}
      <Flex
        {...props}
        w="full"
        overflow="scroll"
        maxH={{
          base: '90vh',
          lg: '82vh',
        }}
        h={{
          base: '90vh',
          lg: '82vh',
        }}
        flexDir="column-reverse"
        px="4"
        id="chat-messages-panel"
        ref={msgContainerRef}
        overflowX="hidden"
      >
        {groupedMessageKeys.map(msgKey => {
          let dateLabel = msgKey;

          const msgGroupDate = new Date(msgKey);
          const dateNow = new Date();
          const dateDiff = dateNow.getDate() - msgGroupDate.getDate();

          if (dateDiff === 0) {
            dateLabel = 'Today';
          }

          if (dateDiff === 1) {
            dateLabel = 'Yesterday';
          }

          return (
            <React.Fragment key={msgKey}>
              {renderMessages(
                groupedMessages[msgKey].sort((a, b) =>
                  sortByDate(a.createdAt, b.createdAt, 'desc'),
                ),
                currentUser.id,
                MessageComponent,
              )}
              <Center>
                <Tag mb='2' mt='6' align='center' color='gray.500' size='sm'>
                  {dateLabel}
                </Tag>
              </Center>
            </React.Fragment>
          );
        })}
      </Flex>
    </>
  );
};

export default ChatMessagesPanel;
