import {
  Menu,
  MenuButton,
  MenuButtonProps,
  Icon,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { IoEllipsisVertical } from 'react-icons/io5';
import { useQuery } from 'react-query';

import useCurrentUser from '../../../hooks/useCurrentUser';
import useDialog from '../../../hooks/useDialog';
import { Post } from '../../../models/Post.model';
import PostReviewDialog from './PostReviewDialog';
import useFeed from '../../../hooks/apis/useFeed';
import EditPostModal from './EditPostForm/EditPostModal';
import useCurrentUserFeedMember from './hooks/useCurrentUserFeedMember';
import { getUserDetails } from '../../../apis/users.api';
import { User } from '../../../models';

interface PostActionsMenuProps extends Omit<MenuButtonProps, 'children'> {
  post: Post;
}

const PostActionsMenu: React.FC<PostActionsMenuProps> = ({
  post,
  ...props
}) => {
  const currentUserFeedMemberRecord = useCurrentUserFeedMember(post.FeedId);
  const [currentUser] = useCurrentUser();
  const { feed } = useFeed(post.FeedId);

  const { data: feedCreatorUser } = useQuery<User | undefined>(
    ['getUser', feed?.CreatedByUserId],
    () => {
      if (!feed) return;

      return getUserDetails(feed.CreatedByUserId.toString());
    },
    {
      enabled: Boolean(feed),
    },
  );

  const [isReviewDialogOpen, openReviewDialog, closeReviewDialog] = useDialog();
  const [isEditDialogOpen, openEditDialog, closeEditDialog] = useDialog();

  const isPostByCurrentUser = post.CreatedByUserId === currentUser?.id;
  const isPostByFeedCreator = post.CreatedByUserId === feedCreatorUser?.id;
  const canUserUpdatePost =
    !isPostByFeedCreator &&
    (isPostByCurrentUser || currentUserFeedMemberRecord?.canUpdate);
  const canUserReviewPost =
    feed?.isModerated &&
    !isPostByCurrentUser &&
    !isPostByFeedCreator &&
    post.isApproved === null &&
    currentUserFeedMemberRecord?.canModeratePosts;

  const hasPostActions = canUserReviewPost || canUserUpdatePost;
  if (!hasPostActions) {
    return null;
  }

  return (
    <>
      <Menu autoSelect={false}>
        <MenuButton {...props} px='4'>
          <Icon as={IoEllipsisVertical} boxSize='6' />
        </MenuButton>
        <MenuList>
          {canUserReviewPost && (
            <MenuItem onClick={openReviewDialog}>Review Post</MenuItem>
          )}
          {canUserUpdatePost && (
            <MenuItem onClick={openEditDialog}>Edit Post</MenuItem>
          )}
        </MenuList>
      </Menu>
      <PostReviewDialog
        post={post}
        isOpen={isReviewDialogOpen}
        onClose={closeReviewDialog}
      />
      <EditPostModal
        isOpen={isEditDialogOpen}
        onClosePost={closeEditDialog}
        post={post}
      />
    </>
  );
};

export default PostActionsMenu;
