import { socket } from 'src/config/socketIoInstance';
import { ValueCallback } from 'src/types/common.type';

enum CallSignallingSocketEvents {
  sdpOffer = 'call_signalling:sdp_offer',
  sdpAnswer = 'call_signalling:sdp_answer',
  iceCandidate = 'call_signalling:ice_candidate',
}

export interface BaseSignallingDto {
  fromUserId: number;
  toUserId: number;
}

interface BaseSdpDto extends BaseSignallingDto {
  sdp: RTCSessionDescriptionInit;
}

interface CallOfferDto extends BaseSdpDto {}
export type CallOfferHandler = ValueCallback<CallOfferDto>;

interface CallAnswerDto extends BaseSdpDto {}
export type CallAnswerHandler = ValueCallback<CallAnswerDto>;

interface CallIceCandidateDto extends BaseSignallingDto {
  candidate: RTCIceCandidateInit;
}
export type CallIceCandidateHandler = ValueCallback<CallIceCandidateDto>;

export const emitCallOffer = (dto: CallOfferDto) => {
  socket.emit(CallSignallingSocketEvents.sdpOffer, dto);
};

export const emitCallAnswer = (dto: CallAnswerDto) => {
  socket.emit(CallSignallingSocketEvents.sdpAnswer, dto);
};

export const emitCallIceCandidate = (dto: CallIceCandidateDto) => {
  socket.emit(CallSignallingSocketEvents.iceCandidate, dto);
};

export const onCallOffer = (handler: CallOfferHandler) => {
  socket.on(CallSignallingSocketEvents.sdpOffer, handler);
};

export const unsubscribeCallOffer = (handler: CallOfferHandler) => {
  socket.off(CallSignallingSocketEvents.sdpOffer, handler);
};

export const onCallAnswer = (handler: CallAnswerHandler) => {
  socket.on(CallSignallingSocketEvents.sdpAnswer, handler);
};

export const unsubscribeCallAnswer = (handler: CallAnswerHandler) => {
  socket.off(CallSignallingSocketEvents.sdpAnswer, handler);
};

export const onCallIceCandidate = (handler: CallIceCandidateHandler) => {
  socket.on(CallSignallingSocketEvents.iceCandidate, handler);
};

export const unsubscribeCallIceCandidate = (
  handler: CallIceCandidateHandler,
) => {
  socket.off(CallSignallingSocketEvents.iceCandidate, handler);
};
