import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { leaveGroupMessageThread } from '../../../apis/group-message-threads.api';

interface LeaveGroupDialogProps extends Omit<ModalProps, 'children'> {
  messageThreadId: number;
  onLeave?: () => any;
}

const LeaveGroupDialog: React.FC<LeaveGroupDialogProps> = ({
  messageThreadId,
  onClose,
  onLeave,
  ...props
}) => {
  const {
    mutate: leaveGroup,
    isLoading: isLeavingGroup,
    isSuccess: hasLeftGroup,
  } = useMutation(['leaveGroup', messageThreadId], () =>
    leaveGroupMessageThread(messageThreadId),
  );

  useEffect(() => {
    if (hasLeftGroup) {
      onClose();
      onLeave && onLeave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLeftGroup, onClose]);

  return (
    <Modal isCentered onClose={onClose} {...props}>
      <ModalOverlay />
      <ModalContent mx='2'>
        <ModalHeader>
          <Text>Leave Group</Text>
        </ModalHeader>
        <ModalBody>
          <Text>Are you sure you want to leave this group?</Text>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button variant='outline' onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme='red'
              isLoading={isLeavingGroup}
              onClick={() => leaveGroup()}
            >
              Leave Group
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LeaveGroupDialog;
