import { IconButton, IconButtonProps } from '@chakra-ui/button';
import Icon from '@chakra-ui/icon';
import { forwardRef } from 'react';
import { FaRegSmile } from 'react-icons/fa';

interface EmojiButtonProps extends IconButtonProps {}

const EmojiButton = forwardRef<HTMLButtonElement, EmojiButtonProps>(
  (props, ref) => (
    <IconButton
      ref={ref}
      icon={<Icon as={FaRegSmile} boxSize='6' />}
      variant='ghost'
      size='6'
      p='0'
      {...props}
    />
  ),
);

export default EmojiButton;
