import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { EditablePageContent } from '../types/EditablePageContent.type';

import { useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { reviewPage } from 'src/apis/pages.api';
import { Page } from 'src/models/Page.model';

interface UseRejectPageContentOptions extends Pick<Page, 'id'> {}

type PageContentUpdateDto = Pick<Page, 'isApproved' | 'draft'>;

const useRejectContent = ({ id }: UseRejectPageContentOptions) => {
  const showToast = useToast();

  const updatePageContentMutation = useMutation<
    Page,
    AxiosError,
    PageContentUpdateDto
  >(pageContentUpdate => reviewPage(id, pageContentUpdate), {
    onSuccess: () => {
      showToast({
        title: 'Page content has Rejected',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
    onError: () => {
      showToast({
        title: 'Error',
        description: 'Error while Approve page content',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const handleRejectPageContent = useCallback(
    (isApproved: EditablePageContent) =>
      updatePageContentMutation.mutate({ isApproved: false, draft: '' }),
    [updatePageContentMutation],
  );

  return { handleRejectPageContent, updatePageContentMutation };
};

export default useRejectContent;
