// TODO: Find a way to make the params type-safe
// Right now, if you change the /feed/:id/posts to /feed/:feedId/posts, the parser won't recognize
// that we now send feed's id as feedId and not id.

export const HOME_PAGE_ROUTE = '/';

export const FEEDS_PAGE_ROUTE = '/feeds';
export const FEED_PAGE_ROUTE = '/feed/:id/posts';
export const FEED_MEMBERS_PAGE_ROUTE = '/feed/:id/members';

export const USER_PROFILE_ROUTE = '/user/:userId/profile';
export const MY_PROFILE_ROUTE = '/user/me';

export const LOGIN_PAGE_ROUTE = '/login';
export const REGISTER_PAGE_ROUTE = '/register';

export const MESSAGES_PAGE_ROUTE = '/messages';

export const kMessageThreadIdParamName = 'messageThreadId';
export const CHAT_PAGE_ROUTE = `/messages/:${kMessageThreadIdParamName}`;
export const MESSAGE_MEMBERS_PAGE_ROUTE = `/messages/:${kMessageThreadIdParamName}/members`;

export const PAGES_ROUTE = '/pages';
export const PAGE_ROUTE = '/page/:id';
export const PAGE_MEMBERS_PAGE_ROUTE = '/page/:id/members';
export const PAGE_CONTENT_PAGE_ROUTE = '/pages/:id/content';
export const DRAFT_REQUEST_PAGE_ROUTE = '/pages/:id/draft-request';
export const APPROVE_USER_PAGE_ROUTE = '/pages/:id/approve';

export const CALLS_PAGE_ROUTE = '/calls';
export * from './call/call.route';
export * from './call/groupCall.route';
export const COMMENT_CARD_ROUTE = '/feed/:id/posts/:postId/comments';
