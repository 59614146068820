import {
  Button,
  Flex,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagLabel,
  Text,
  useToast,
} from '@chakra-ui/react';
import { FC } from 'react';
import UserAvatar from 'src/components/UserAvatar';
import { useForm } from 'react-hook-form';

import useCurrentUser from 'src/hooks/useCurrentUser';
import { updateCommentsToPost } from 'src/apis/comment.api';
import { useMutation } from 'react-query';
import useComments from './hooks/useComments';
import { Post } from 'src/models/Post.model';
import { EntityType } from 'src/models/View.model';
import { Comment } from 'src/models/Comment.model';

interface EditCommentModalProps {
  isOpen: boolean;
  onClose: () => void;
  post: Post;
  comment: Comment;
}

export const EditCommentModal: FC<EditCommentModalProps> = ({
  isOpen,
  onClose,
  post,
  comment,
}) => {
  const {
    handleSubmit,
    register,
    reset: resetForm,
  } = useForm({
    mode: 'onChange',
  });
  const [currentUser] = useCurrentUser();

  const { refetch } = useComments(post.id, EntityType.post);

  const updateCommentsToPostMutation = useMutation(
    (data: {
      commentId: number;
      EntityId: number;
      description: string;
      entityType: EntityType;
    }) =>
      updateCommentsToPost(data.commentId, {
        EntityId: data.EntityId,
        description: data.description,
        entityType: data.entityType,
      }),
  );

  interface commentType {
    commentContent: string;
  }
  const toast = useToast();

  const formSubmitHandle = (data: commentType) => {
    updateCommentsToPostMutation.mutate(
      {
        commentId: comment.id,
        EntityId: comment.EntityId,
        description: data.commentContent,
        entityType: comment.entityType,
      },
      {
        onSuccess: () => {
          resetForm();
          toast({
            title: 'Comment edited successfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          refetch();
          onClose();
        },
      },
    );
    return;
  };

  return (
    <Modal isOpen={isOpen} onClose={() => onClose()}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          <Flex>
            <Text>Edit Comment </Text>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(formSubmitHandle)}>
            <Tag size='lg' borderRadius='full'>
              {currentUser && (
                <>
                  <UserAvatar size='xs' mr='2' user={currentUser} />
                  <TagLabel>
                    {`${currentUser?.firstName} ${currentUser?.lastName}`}
                  </TagLabel>
                </>
              )}
            </Tag>
            <FormControl my='2'>
              <Input
                maxLength={1000}
                defaultValue={comment.description}
                placeholder='What do you want to comment?'
                size='lg'
                rows={3}
                {...register('commentContent')}
              />
            </FormControl>
            <Flex>
              <Button
                loadingText='Updating...'
                colorScheme='blue'
                type='submit'
                mx='1'
              >
                Update Comment
              </Button>
              <Button
                ml='1'
                variant='outline'
                colorScheme='red'
                onClick={() => onClose()}
              >
                Cancel
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
