import { toast, useToast } from '@chakra-ui/react';
import { useState } from 'react';

const kMaxTextLength = 1500;
const kMessageTooLongToastId = 'toast:message-too-long';

export const useMessageLimitValidator = () => {
  const showToast = useToast();

  /**
   * @param message The message to validate
   * @returns true if the message is valid, false otherwise
   */
  const validateMessage = (message: string): boolean => {
    if (message.length > kMaxTextLength) {
      // Preventing duplicate toasts since user could change text often
      if (!toast.isActive(kMessageTooLongToastId)) {
        showToast({
          id: kMessageTooLongToastId,
          title: 'Message too long',
          description: `Message cannot be more than ${kMaxTextLength} characters`,
          status: 'warning',
          variant: 'subtle',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
      }

      return false;
    }

    return true;
  };

  return validateMessage;
};

const useMessageWithLimit = () => {
  const [message, setMessage] = useState('');
  const validateMessage = useMessageLimitValidator();

  const handleMessageChange = (changedMessage: string) => {
    if (validateMessage(changedMessage)) {
      setMessage(changedMessage);
    }
  };

  return { message, handleMessageChange };
};

export default useMessageWithLimit;
