import { Icon } from '@chakra-ui/react';
import { FcEndCall } from 'react-icons/fc';
import { MdNotInterested, MdPhoneMissed } from 'react-icons/md';
import { VscCallIncoming, VscCallOutgoing } from 'react-icons/vsc';
import { User } from 'src/models/user.model';

export interface CallIconProps {
  joinedAt: Date | null;
  callerId: number;
  currentUser: User | null;
  receiverId: number;
  hasMissedCall: boolean | undefined;
  hasRejectedCall: boolean | null;
  isUnreachable: boolean;
  color: string;
}

const CallIcons: React.FC<CallIconProps> = ({
  joinedAt,
  callerId,
  currentUser,
  receiverId,
  hasMissedCall,
  hasRejectedCall,
  isUnreachable,
  color,
}) => {
  if (currentUser) {
    if (joinedAt) {
      return (
        <>
          {(callerId === currentUser.id && (
            <Icon
              as={VscCallOutgoing}
              aria-label='user'
              bg='white'
              color={color}
            />
          )) ||
            (receiverId === currentUser.id && (
              <Icon
                as={VscCallIncoming}
                aria-label='user'
                bg='white'
                color={color}
              />
            ))}
        </>
      );
    } else if (hasMissedCall) {
      if (currentUser.id === callerId) {
        return (
          <>
            <Icon
              as={VscCallOutgoing}
              aria-label='user'
              bg='white'
              color='gray.500'
            />
          </>
        );
      }

      return (
        <>
          <Icon as={MdPhoneMissed} aria-label='user' bg='white' color='red' />
        </>
      );
    } else if (hasRejectedCall) {
      if (currentUser.id === callerId) {
        return (
          <>
            <Icon
              as={VscCallOutgoing}
              aria-label='user'
              bg='white'
              color='gray.500'
            />
          </>
        );
      }
      return (
        <>
          <Icon as={FcEndCall} aria-label='user' bg='white' color='red' />
        </>
      );
    } else if (isUnreachable) {
      if (currentUser.id === callerId) {
        return (
          <>
            <Icon
              as={MdNotInterested}
              aria-label='user'
              bg='white'
              color='red.500'
            />
          </>
        );
      }
      return (
        <>
          <Icon as={VscCallIncoming} aria-label='user' bg='white' color='red' />
        </>
      );
    } else {
      return (
        <>
          {(callerId === currentUser.id && (
            <Icon
              as={VscCallOutgoing}
              aria-label='user'
              bg='white'
              color={color}
            />
          )) ||
            (receiverId === currentUser.id && (
              <Icon
                as={VscCallIncoming}
                aria-label='user'
                bg='white'
                color={color}
              />
            ))}
        </>
      );
    }
  } else {
    return <></>;
  }
};

export default CallIcons;
