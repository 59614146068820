import { Text } from '@chakra-ui/react';
import { useMemo } from 'react';

import { DeserializedPageContent } from '../../../types/page-content.type';
import { serializePlainText } from '../../../utils/page-content.utils';

interface BlogCardTextContentProps {
  deserializedContent: DeserializedPageContent;
}

const kMaxLinesTextContent = 3;

const BlogCardTextContent: React.FC<BlogCardTextContentProps> = ({
  deserializedContent,
}) => {
  const plainTextContent = useMemo(
    () => serializePlainText(deserializedContent),
    [deserializedContent],
  );

  return <Text noOfLines={kMaxLinesTextContent}>{plainTextContent}</Text>;
};

export default BlogCardTextContent;
