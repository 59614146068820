import { useCallback } from 'react';

import { localStorageKeys } from '../constants/local-storage-keys.constant';

const useLocalStorageCleanup = () => {
  const cleanup = useCallback(() => {
    for (const localStorageKey of Object.values(localStorageKeys)) {
      localStorage.removeItem(localStorageKey);
    }
  }, []);

  return { cleanup };
};

export default useLocalStorageCleanup;
