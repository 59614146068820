import { useState } from 'react';
import { useParams } from 'react-router';
import { Box } from '@chakra-ui/react';
import { useMutation } from 'react-query';
import _ from 'lodash';

import { kMessageThreadIdParamName as messageThreadId } from '../../routes/routeList';
import { getUserDisplayName } from '../../utils/user.utils';
import { updateMessageThreadAcceptance } from '../../apis/message-threads.api';
import { createTextMessage } from '../../apis/messages.api';
import { PersonalMessageThread } from '../../models/PersonalMessageThread.model';
import { MessageThread } from '../../models/MessageThread.model';

import useTypingObserver from './hooks/useTyping';
import useMessageThread from './hooks/useMessageThread';
import useMessages from './hooks/useMessages';

import GroupMessageBox from './components/GroupMessageBox';
import ChatFooter from './components/ChatFooter';
import ChatMessagesPanel from './components/ChatMessagesPanel';
import ChatHeader from './components/ChatHeader';
import { maxWidth } from '../../constants/max-width.constant';

interface ChatPageUrlParams {
  [messageThreadId]: string;
}

const kMaxUsersTypingDisplay = 2;

// TODO: Make a presentational component "Chat" which can be customised according to if it is personal or group
// Render accordingly for different types. Data fetching is similar so can be re-used
const ChatPage = () => {
  const chatPageParams = useParams<ChatPageUrlParams>();
  const messageThreadId = +chatPageParams.messageThreadId;

  const [footerEl, setFooterEl] = useState<HTMLDivElement | null>(null);

  const { messageThread, updateMessageThread } =
    useMessageThread(messageThreadId);
  const { mutate: updateAcceptance } = useMutation<
    MessageThread,
    any,
    { hasAccepted: boolean }
  >(
    ['updateMessageThreadAcceptance', messageThreadId],
    ({ hasAccepted }) =>
      updateMessageThreadAcceptance(messageThreadId, hasAccepted),
    { onSuccess: updateMessageThread },
  );

  const { messages } = useMessages({ messageThreadId });
  const { usersTyping, isSomeoneTyping } = useTypingObserver();

  const onMessageSend = (msg: string) => {
    createTextMessage(messageThreadId, msg);
  };

  if (!messageThread) {
    return null;
  }

  const personalMsgThread = messageThread as unknown as PersonalMessageThread;
  const hasAcceptedPersonalChat = personalMsgThread.hasAccepted;
  const hasRecipientBlocked = personalMsgThread.hasRecipientBlocked;

  const footerHeight = (footerEl && footerEl.clientHeight) || 0;

  const getTypingText = () => {
    if (messageThread.isGroup && !_.isEmpty(usersTyping)) {
      const countUsersTyping = usersTyping.length;
      if (countUsersTyping === 1) {
        return `${getUserDisplayName(usersTyping[0])} is typing...`;
      }

      if (countUsersTyping === 2) {
        const user1Name = getUserDisplayName(usersTyping[0]);
        const user2Name = getUserDisplayName(usersTyping[1]);

        return `${user1Name} and ${user2Name} are typing`;
      }

      const usersTypingClipped = _.take(usersTyping, kMaxUsersTypingDisplay);
      const commaSeparatedUsers = _.join(
        _.map(usersTypingClipped, getUserDisplayName),
        ', ',
      );

      const remainingUsers = countUsersTyping - usersTypingClipped.length;
      const suffix = remainingUsers > 0 ? `and ${remainingUsers} more ` : '';
      return `${commaSeparatedUsers} ${suffix}are typing...`;
    }

    return 'typing...';
  };

  return (
    <Box m='0' position='relative'>
      <ChatHeader messageThread={messageThread} />
      <Box
        maxW={maxWidth}
        mx='auto'
        pt='14'
        pb={isSomeoneTyping ? footerHeight + 16 : footerHeight}
      >
        <ChatMessagesPanel
          messages={messages}
          MessageComponent={messageThread.isGroup ? GroupMessageBox : undefined}
        />
        <ChatFooter
          ref={setFooterEl}
          chatInputPanelProps={{ onMessageSend, messageThreadId }}
          typing={isSomeoneTyping}
          typingText={getTypingText()}
          isGroupChat={messageThread.isGroup}
          hasAccepted={hasAcceptedPersonalChat}
          onAcceptanceChange={hasAccepted => updateAcceptance({ hasAccepted })}
          hasRecipientBlocked={hasRecipientBlocked}
        />
      </Box>
    </Box>
  );
};

export default ChatPage;
