import { useEffect, useRef, useState } from 'react';
import { IconButton } from '@chakra-ui/button';
import Icon from '@chakra-ui/icon';
import { Input } from '@chakra-ui/input';
import { Box, BoxProps, HStack } from '@chakra-ui/layout';
import { useToast } from '@chakra-ui/toast';
import { FaRegSmile } from 'react-icons/fa';
import { IoCameraOutline, IoAttachOutline } from 'react-icons/io5';
import { MdSend } from 'react-icons/md';
import { BaseEmoji, NimblePicker } from 'emoji-mart';
import data from 'emoji-mart/data/google.json';
import 'emoji-mart/css/emoji-mart.css';
import _ from 'lodash';

import { ValueCallback } from '../../../types/common.type';
import {
  stoppedTyping,
  typing,
} from '../../../apis/socket.io/messages.socket.io';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { megabytes } from '../../../utils/data.utils';
import MediaPreviewDialog from './MediaPreviewDialog';
import CameraDialog from './CameraDialog';
import useMessageWithLimit from '../hooks/useMessageWithLimit';
import { kMaxFileSizeMegabytes } from '../../../constants/files.constants';

export interface ChatInputPanelProps extends BoxProps {
  onMessageSend: ValueCallback<string>;
  messageThreadId: number;
}

// Using `leading: true`, we tell debounce to let the first (leading) function call be executed
// and then start debouncing. So that we don't wait for n seconds till user starts typing to emit the event
// we emit as soon as user starts typing but then start debouncing the change events
const emitTyping = _.debounce(typing, 1000, { leading: true });

const ChatInputPanel: React.FC<ChatInputPanelProps> = ({
  onMessageSend,
  messageThreadId,
  ...props
}) => {
  const [currentUser] = useCurrentUser();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const { message: msgContent, handleMessageChange: setMessage } =
    useMessageWithLimit();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showMediaPreviewDialog, setShowMediaPreviewDialog] = useState(false);
  const [mediaToPreview, setMediaToPreview] = useState<
    Blob[] | File[] | FileList
  >([]);
  const [showCameraDialog, setShowCameraDialog] = useState(false);
  const typingTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (mediaToPreview.length > 0) {
      setShowMediaPreviewDialog(true);
    } else {
      setShowMediaPreviewDialog(false);
    }
  }, [mediaToPreview]);

  const handleKeyPress: React.KeyboardEventHandler<HTMLInputElement> =
    event => {
      if (msgContent.trim().length === 0) {
        return;
      }

      if (event.key === 'Enter') {
        onMessageSend(msgContent);
        setMessage('');
      }
    };

  const handleOnTextInputChange: React.ChangeEventHandler<HTMLInputElement> =
    e => {
      setMessage(e.currentTarget.value);

      if (currentUser?.id) {
        const typingPayload = {
          messageThreadId,
          user: _.pick(currentUser, ['id', 'firstName', 'lastName']),
        };
        emitTyping(typingPayload);
        if (typingTimeout.current) {
          clearTimeout(typingTimeout.current);
        }
        typingTimeout.current = setTimeout(
          () => stoppedTyping(typingPayload),
          2000,
        );
      }
    };

  const handleEmojiIconClick = () =>
    setShowEmojiPicker(prevShowEmojiPicker => !prevShowEmojiPicker);

  const handleOnEmojiSelect = (emoji: BaseEmoji) => {
    setMessage(`${msgContent}${emoji.native}`);
  };

  const handleOnFileAttachIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const showToast = useToast();
  const handleOnFileAttachChange = () => {
    if (fileInputRef.current) {
      const files = fileInputRef.current.files;

      const validFiles = _.filter(files, file => {
        if (file.size > megabytes(kMaxFileSizeMegabytes)) {
          return false;
        }

        return true;
      });
      const totalInvalidFiles = (files?.length || 0) - validFiles.length;

      if (totalInvalidFiles > 0) {
        showToast({
          title: `${totalInvalidFiles} file(s) were too large and were not attached`,
          description: `File size should be less than ${kMaxFileSizeMegabytes} MB`,
          status: 'error',
          duration: 7000,
          isClosable: true,
        });
      }

      if (validFiles.length > 0) {
        setMediaToPreview(validFiles);
      }
    }
  };

  const handleOnCameraBtnClick = () => setShowCameraDialog(true);

  const handleOnImageCapture: BlobCallback = blob => {
    if (blob) {
      setShowCameraDialog(false);
      setMediaToPreview([blob]);
    }
  };

  const closeMediaPreview = () => {
    setMediaToPreview([]);
    setShowMediaPreviewDialog(false);
  };

  const onSend = () => {
    onMessageSend(msgContent);
    setMessage('');
  };

  return (
    <>
      <Box
        borderColor='gray'
        borderWidth='1px'
        borderRadius='8'
        w='full'
        p='2'
        minW='full'
        {...props}
      >
        <HStack>
          <IconButton
            icon={<Icon as={FaRegSmile} boxSize='6' />}
            aria-label='emote picker'
            variant='ghost'
            size='6'
            p='0'
            onClick={handleEmojiIconClick}
          />
          <Input
            onKeyPress={handleKeyPress}
            onChange={handleOnTextInputChange}
            value={msgContent}
            h='full'
            pl='1'
            variant='unstyled'
            placeholder='Type something here...'
            flexGrow={2}
          />
          <Input
            onChange={handleOnFileAttachChange}
            ref={fileInputRef}
            type='file'
            display='none'
            multiple
          />
          <IconButton
            icon={<Icon as={IoAttachOutline} boxSize='8' />}
            aria-label='multiple file picker'
            variant='ghost'
            size='8'
            p='0'
            onClick={handleOnFileAttachIconClick}
          />
          <IconButton
            icon={<Icon as={IoCameraOutline} boxSize='8' />}
            aria-label='camera icon button'
            variant='ghost'
            size='8'
            p='0'
            onClick={handleOnCameraBtnClick}
          />
          {!_.isEmpty(msgContent) && (
            <IconButton
              transitionProperty='height'
              transitionDuration='3s'
              transitionTimingFunction='ease-in'
              variant='ghost'
              icon={<Icon as={MdSend} boxSize='6' />}
              aria-label='send chat button'
              onClick={onSend}
            />
          )}
        </HStack>
      </Box>

      <Box w='full' display={showEmojiPicker ? 'block' : 'none'}>
        <NimblePicker
          set='google'
          data={data}
          onSelect={handleOnEmojiSelect}
          showPreview={false}
          showSkinTones={false}
          enableFrequentEmojiSort
          title=''
          emoji=''
          autoFocus
          style={{ width: '100%' }}
        />
      </Box>

      {showMediaPreviewDialog && mediaToPreview.length > 0 && (
        <MediaPreviewDialog
          messageThreadId={messageThreadId}
          media={mediaToPreview}
          isOpen={showMediaPreviewDialog}
          onClose={closeMediaPreview}
        />
      )}

      {showCameraDialog && (
        <CameraDialog
          isOpen
          onClose={() => setShowCameraDialog(false)}
          onImageCapture={handleOnImageCapture}
        />
      )}
    </>
  );
};

export default ChatInputPanel;
