import { Message } from '../../../models/Message.model';
import useArrayDataQuery from '../../../utils/query-utils/useArrayDataQuery';
import getAllMessagesQueryKey from './getMessagesQueryKey';

const useMessagesDataMethods = (messageThreadId: number) =>
  useArrayDataQuery<Message>({
    queryKey: getAllMessagesQueryKey(messageThreadId),
  });

export default useMessagesDataMethods;
