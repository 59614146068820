import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalProps,
} from '@chakra-ui/modal';
import _ from 'lodash';

import SearchUserToCall from './SearchUserToCall';

interface SelectUserToCallModalProps extends Omit<ModalProps, 'children'> {}

const SelectUserToCallModal: React.FC<SelectUserToCallModalProps> = ({
  ...props
}) => {
  return (
    <Modal scrollBehavior='inside' isCentered {...props}>
      <ModalOverlay />
      <ModalContent minH='50%' mx='4'>
        <ModalHeader>Select user to call</ModalHeader>
        <ModalBody>
          <SearchUserToCall />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SelectUserToCallModal;
