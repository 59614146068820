import { Link } from 'react-router-dom';
import { Icon, IconButton } from '@chakra-ui/react';
import { BiMessage } from 'react-icons/bi';

import { MESSAGES_PAGE_ROUTE } from '../../../routes/routeList';
import useUnreadChatsCount from '../../../hooks/useUnreadChatsCount';
import NumberBadge from '../../NumberBadge';

const MessagesButtonIcon: React.FC = () => {
  const unreadChatsCount = useUnreadChatsCount();
  const hasUnreadChats = unreadChatsCount > 0;

  return (
    <>
      <Icon as={BiMessage} boxSize='6' />
      {hasUnreadChats && (
        <NumberBadge
          position='absolute'
          top='0.5'
          right={{
            base: '16',
            md: '28',
          }}
          left={{
            base: '10',
            md: '20',
          }}
          minW='5'
          maxW='10'
          fontSize='xs'
          fontWeight='bold'
          color='white'
          bg='blue.500'
          displayNumber={unreadChatsCount}
        />
      )}
    </>
  );
};

const MessagesButton: React.FC = () => {
  return (
    <IconButton
      as={Link}
      to={MESSAGES_PAGE_ROUTE}
      position='relative'
      aria-label='Message'
      variant='ghost'
      w='full'
      _focus={{
        boxShadow: 'none',
      }}
      borderRight='1px solid'
      borderColor='gray.200'
      _hover={{
        background: 'none',
      }}
      size='lg'
      p='0'
      icon={<MessagesButtonIcon />}
    />
  );
};

export default MessagesButton;
