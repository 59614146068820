import { axiosInstance } from 'src/config';
import { FeedPostLikeDto } from 'src/dto/feed-post-likes.dto';
import { getRequestData } from './utils/get-request-data.util';

export const kLikeRootUrl = '/likes';

export const addLikesofPost = ({
  EntityId,
  entityType,
}: FeedPostLikeDto): Promise<FeedPostLikeDto[]> => {
  const url = `${kLikeRootUrl}/`;
  return getRequestData<FeedPostLikeDto[]>(
    axiosInstance.post(url, { EntityId, entityType }),
  );
};

export const deleteLikeOfPost = ({
  EntityId,
  entityType,
}: FeedPostLikeDto): Promise<FeedPostLikeDto[]> => {
  const url = `${kLikeRootUrl}/entity/${EntityId}/${entityType}`;
  return getRequestData<FeedPostLikeDto[]>(axiosInstance.delete(url));
};

export const getLikesOfPost = (
  EntityId: number,
  entityType: string,
): Promise<FeedPostLikeDto[]> => {
  const url = `${kLikeRootUrl}/entity/${EntityId}/${entityType}`;
  return getRequestData<FeedPostLikeDto[]>(axiosInstance.get(url));
};
