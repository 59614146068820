import { Button, ButtonGroup, Flex, Icon, IconButton } from '@chakra-ui/react';
import _ from 'lodash';
import { useState } from 'react';
import { RiDraftFill } from 'react-icons/ri';
import { useParams, Link } from 'react-router-dom';

import useCurrentUser from 'src/hooks/useCurrentUser';
import { APPROVE_USER_PAGE_ROUTE } from 'src/routes/routeList';
import HeaderWrapper from '../../../hoc/HeaderWrapper';
import usePage from '../../../pages/blogs/blog/hooks/usePage';
import usePageParams from '../../../pages/blogs/blog/hooks/usePageParams';
import usePageMembers from '../hooks/usePageMembers';
import { Editor } from './components/editor/Editor';
import usePageContentForEdit from './hooks/usePageContentForEdit';
import usePublishPageContent from './hooks/usePublishPageContent';

interface EditPageContentTabProps {}

const EditPageContent: React.FC<EditPageContentTabProps> = () => {
  const { id, id: pageId } = usePageParams();
  const { content, setContent } = usePageContentForEdit();
  const { page } = usePage();

  const { id: pageIdForLink }: { id: string } = useParams();

  const { pageMembers } = usePageMembers({
    pageId,
  });

  const [currentUser] = useCurrentUser();

  const currentUserMemberRecord = _.find(
    pageMembers,
    member => member.UserId === currentUser?.id,
  );

  const { handlePublishPageContent, updatePageContentMutation } =
    usePublishPageContent({ id });

  const handlePublishChanges = () => {
    if (content) {
      handlePublishPageContent(content);
    }
  };

  const [isReadonly, setIsReadonly] = useState(false);

  const toggleReadonly = () => setIsReadonly(prev => !prev);

  const isPublishingChanges = updatePageContentMutation.isLoading;

  return (
    <HeaderWrapper pageTitle={page?.title || 'Page Members'}>
      <Flex justifyContent='flex-end'>
        {currentUserMemberRecord?.canModerateUpdates && page?.draft !== null && (
          <Link to={APPROVE_USER_PAGE_ROUTE.replace(':id', pageIdForLink)}>
            <IconButton
              colorScheme='blue'
              variant='ghost'
              size='lg'
              aria-label='draft page request'
              icon={<Icon as={RiDraftFill} fontSize='2xl' />}
            >
              Draft Page Member
            </IconButton>
          </Link>
        )}
      </Flex>
      {currentUserMemberRecord?.canModerateUpdates && (
        <Editor
          isReadonly={isReadonly}
          pageId={id}
          w='full'
          value={content}
          onChange={setContent}
        />
      )}

      <Flex justify='flex-end'>
        <ButtonGroup colorScheme='blue' alignSelf='end'>
          <Button onClick={toggleReadonly} variant='outline'>
            {isReadonly ? 'Continue Editing' : 'Preview Changes'}
          </Button>
          {currentUserMemberRecord?.canModerateUpdates && (
            <Button
              onClick={handlePublishChanges}
              disabled={isPublishingChanges}
              isLoading={isPublishingChanges}
              loadingText='Publishing Changes'
            >
              Publish Changes
            </Button>
          )}
        </ButtonGroup>
      </Flex>
    </HeaderWrapper>
  );
};

export default EditPageContent;
