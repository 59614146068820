import React from 'react';
import { Box, Link } from '@chakra-ui/layout';
import Slider, { Settings as CarouselSettings } from 'react-slick';

import { ImageSliderCarouselProps } from '../types';
import ImageCard from '../../ImageCard';
import CarouselArrow from '../../../../../components/CarouselArrow';
import { Link as RouterLink } from 'react-router-dom';

export const ImageSliderCarousel: React.FC<ImageSliderCarouselProps> = ({
    imgSrcList,
}) => {
    const settings: CarouselSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <CarouselArrow direction='next' />,
        prevArrow: <CarouselArrow direction='prev' />,
    };

    return (
        <Box minHeight={0} minWidth={0} mx='2'>
            <Slider {...settings}>
                {}
                {imgSrcList.map(image =>
                    image.externalLink ? (
                        <Link href={image.linkUrl} target='_blank'>
                            <ImageCard
                                key={image.src}
                                {...image}
                                imageProps={{ maxH: '52' }}
                            />
                        </Link>
                    ) : (
                        <RouterLink to={image.linkUrl!}>
                            <ImageCard
                                key={image.src}
                                {...image}
                                imageProps={{ maxH: '52' }}
                            />
                        </RouterLink>
                    ),
                )}
            </Slider>
        </Box>
    );
};
