export const boxSize = {
  videoHeight: {
    height: {
      md: '180px',
      sm: '120px',
    },
  },
  center: {
    bottom: {
      inPixels: '180px',
    },
    height: {
      size: '20',
    },
    width: {
      size: '34',
    },
  },
  grid: {
    height: {
      size: 'calc(100vh - 10rem)',
    },
    padding: {
      size: 0,
      sizeForTwo: '2',
    },
    tempateColumns: {
      size: 'repeat(1,1fr)',
      sizeForTwo: 'repeat(2,1fr)',
    },
  },
  box: {
    paddingBottom: {
      size: '20',
    },
    paddingTop: {
      size: '32',
    },
    height: {
      size: '90.7vh',
    },
    fullHeight: {
      size: '100vh',
    },
  },
  callVideo: {
    size: '180px',
    sizeWhenOnlyTwo: '350px',
  },
};
