import _ from 'lodash';

import { axiosInstance } from '../config';
import {
  MultipleMessageStatusUpdate,
  MultipleMessageStatusUpdateDto,
} from '../dto/messages.dto';

import { AsDto } from '../dto/model-as.dto';
import { MessageThread } from '../models/MessageThread.model';
import { getRequestData } from './utils/get-request-data.util';

export const kMessageThreadsUrl = '/message-threads';

export const getOneMessageThread = async (
  id: number,
): Promise<MessageThread> => {
  const url = `${kMessageThreadsUrl}/${id}`;

  const messageThread = await getRequestData<AsDto<MessageThread>>(
    axiosInstance.get(url),
  );

  return {
    ...messageThread,
    createdAt: new Date(messageThread.createdAt),
    updatedAt: new Date(messageThread.updatedAt),
  };
};

export const updateMessageThreadAcceptance = async (
  id: number,
  hasAccepted: boolean,
): Promise<MessageThread> => {
  const url = `${kMessageThreadsUrl}/${id}/acceptance`;
  const messageThread = await getRequestData<AsDto<MessageThread>>(
    axiosInstance.patch(url, { hasAccepted }),
  );

  return {
    ...messageThread,
    createdAt: new Date(messageThread.createdAt),
    updatedAt: new Date(messageThread.updatedAt),
  };
};

const updateAllMessagesStatus = (updates: MultipleMessageStatusUpdateDto) => {
  const url = `${kMessageThreadsUrl}/message-status`;

  return axiosInstance.patch(url, updates);
};

export const markAllMessagesSeenForOneThread = (messageThreadId: number) => {
  return updateAllMessagesStatus({
    updates: [{ messageThreadId, status: 'seen' }],
  });
};

export const markAllMessagesDeliveredForMultipleThreads = (
  messageThreadIds: number[],
) => {
  const updates = _.map<number, MultipleMessageStatusUpdate>(
    messageThreadIds,
    messageThreadId => ({ messageThreadId, status: 'delivered' }),
  );

  return updateAllMessagesStatus({ updates });
};
