import { Button } from '@chakra-ui/button';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalProps,
} from '@chakra-ui/modal';
import { MdAdd } from 'react-icons/md';
import { useMutation } from 'react-query';
import _ from 'lodash';

import SearchSelectMultipleUsers, {
  useSelectedUsers,
} from '../../../components/SearchSelectMultipleUsers';
import { addMultipleMembers } from '../../../apis/feedsMembers.api';
import { CreateMultipleFeedMembersDto } from '../../../dto/feed-members.dto';
import { FeedMember } from '../../../models/FeedMember.model';
import { ValueCallback } from '../../../types/common.type';
import { User } from '../../../models';

interface AddFeedMemberDialogProps extends Omit<ModalProps, 'children'> {
  feedId: number;
  onMembersAdded: ValueCallback<FeedMember[]>;
  existingMembers: FeedMember[];
}

const AddFeedMemberDialog: React.FC<AddFeedMemberDialogProps> = ({
  feedId,
  onMembersAdded,
  existingMembers,
  ...props
}) => {
  const [selectedUsers, setSelectedUsers] = useSelectedUsers();
  const { mutate: addMembers, isLoading: isAddingMembers } = useMutation(
    'addFeedMembers',
    (createMembersDto: CreateMultipleFeedMembersDto) =>
      addMultipleMembers(feedId, createMembersDto),
    {
      onSuccess: addedMembers => {
        setSelectedUsers({});
        onMembersAdded(addedMembers);
      },
    },
  );

  const handleAddMembers = () =>
    addMembers(_.map(selectedUsers, ({ id: UserId }) => ({ UserId })));

  const isUserInMembers = (user: User): boolean =>
    !!_.find(existingMembers, member => member.UserId === user.id);

  return (
    <Modal scrollBehavior='inside' isCentered {...props}>
      <ModalOverlay />
      <ModalContent minH='50%' mx='4'>
        <ModalHeader>Add feed members</ModalHeader>
        <ModalBody>
          <SearchSelectMultipleUsers
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            shouldRenderUser={user => !isUserInMembers(user)}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={isAddingMembers}
            colorScheme='blue'
            leftIcon={<MdAdd />}
            onClick={handleAddMembers}
            disabled={_.isEmpty(selectedUsers)}
          >
            Add Selected Users
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddFeedMemberDialog;
