import { Link, LinkProps } from '@chakra-ui/layout';
import {
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps,
} from 'react-router-dom';

const RouteLink: React.FC<LinkProps & ReactRouterLinkProps> = props => {
  return <Link color='blue.500' as={ReactRouterLink} {...props} />;
};

export default RouteLink;
