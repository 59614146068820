import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import {
  Box,
  Flex,
  IconButton,
  Image,
  Text,
  useToast,
  UseToastOptions,
} from '@chakra-ui/react';
import { FaUserCircle } from 'react-icons/fa';
import { MdCall, MdMessage } from 'react-icons/md';
import { AxiosError } from 'axios';

import {
  createPersonalMessageThread,
  getOnePersonalMessageThreadWithUser,
} from '../../../apis/personal-message-threads.api';
import { getUserDetails } from '../../../apis/users.api';
import { CreatePersonalMessageThreadDto } from '../../../dto/personal-messageThreads.dto';
import HeaderWrapper from '../../../hoc/HeaderWrapper';
import { User } from '../../../models';
import { PersonalMessageThread } from '../../../models/PersonalMessageThread.model';
import {
  CHAT_PAGE_ROUTE,
  kMessageThreadIdParamName,
} from '../../../routes/routeList';
import useNewCall from 'src/hooks/useNewCall';

const UserProfilePage = () => {
  const showToast = useToast();

  const { userId }: { userId: string } = useParams();
  const { data: user } = useQuery<User | undefined>('getUserDetails', () =>
    getUserDetails(userId),
  );

  const [shouldCreateChat, setShouldCreateChat] = useState(false);
  const [shouldSearchChat, setShouldSearchChat] = useState(false);

  const [isCreateChatSuccess, setIsCreateChatSuccess] = useState(false);
  const [searchChatError, setSearchChatError] = useState<AxiosError>();
  const [createChatError, setCreateChatError] = useState<AxiosError>();
  const [isSearchChatSuccess, setIsSearchChatSuccess] = useState(false);

  const { data: searchedChatWithUser, isError: isSearchChatError } = useQuery<
    PersonalMessageThread | null,
    AxiosError
  >(
    'getPersonalChatWithUser',
    () => getOnePersonalMessageThreadWithUser(+userId),
    {
      onSuccess: () => setIsSearchChatSuccess(true),
      onError: err => setSearchChatError(err),
      enabled: shouldSearchChat,
      retry: (_, error) => {
        // Don't retry if we have a Unauthorized or NotFound error
        const statusCode = error.response && error.response.status;
        if (statusCode === 401 || statusCode === 404) {
          return false;
        }

        return true;
      },
    },
  );
  const {
    data: createdChat,
    mutate: createChatWithUser,
    isError: isCreateChatError,
  } = useMutation<any, any, CreatePersonalMessageThreadDto>(
    'createChatWithUser',
    () => createPersonalMessageThread({ recipient: +userId }),
    {
      onSuccess: () => setIsCreateChatSuccess(true),
      onError: err => setCreateChatError(err),
    },
  );

  const history = useHistory();
  const pushChatPage = (messageThreadId: number) =>
    history.push(
      CHAT_PAGE_ROUTE.replace(
        `:${kMessageThreadIdParamName}`,
        messageThreadId.toString(),
      ),
    );

  useEffect(() => {
    const errorToastOpts: UseToastOptions = {
      title: 'An unknown error occurred',
      description: 'Please try again after some time',
      status: 'error',
    };

    if (isSearchChatError && searchChatError) {
      if (searchChatError.response && searchChatError.response.status === 404) {
        setShouldCreateChat(true);
      } else {
        showToast(errorToastOpts);
      }
    }

    if (isCreateChatError && createChatError) {
      showToast(errorToastOpts);
    }
  }, [
    isSearchChatError,
    searchChatError,
    isCreateChatError,
    createChatError,
    showToast,
  ]);

  useEffect(() => {
    if (shouldCreateChat) {
      createChatWithUser({ recipient: +userId });
    }
  }, [shouldCreateChat, createChatWithUser, userId]);

  useEffect(
    () => {
      if (isSearchChatSuccess && searchedChatWithUser) {
        pushChatPage(searchedChatWithUser.id);
      }

      if (isCreateChatSuccess && createdChat) {
        pushChatPage(createdChat.id);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isSearchChatSuccess,
      searchedChatWithUser,
      isCreateChatSuccess,
      createdChat,
    ],
  );

  const handleOnMessageBtnClick = () => {
    setShouldSearchChat(true);
  };

  const { makePersonalCall } = useNewCall();

  return (
    <HeaderWrapper pageTitle='Profile'>
      <Box bg='gray.200' minH='85vh'>
        <Flex position='relative'>
          {user?.avatarUrl ? (
            <Image
              src={user?.avatarUrl}
              boxSize="100%"
              maxH="64"
              objectFit="cover"
            />
          ) : (
            <Flex justifyContent='center' bg='gray.100' py='8' w='full'>
              <FaUserCircle fontSize='12rem' />
            </Flex>
          )}
          <Text
            pos='absolute'
            bottom='0'
            color='gray.600'
            fontWeight='bold'
            fontSize='2xl'
            left='2'
          >
            {user?.firstName}&nbsp;{user?.lastName}
          </Text>
        </Flex>
        <Box
          my='2'
          borderY='1px solid'
          borderColor='gray.300'
          px='2'
          bg='white'
        >
          <Text color='blue.500' fontSize='lg'>
            Connect
          </Text>
          <Flex my='2'>
            <IconButton
              onClick={handleOnMessageBtnClick}
              aria-label='message-call'
              colorScheme='blue'
              mx='1'
            >
              <MdMessage fontSize='24' />
            </IconButton>
            {user && (
              <IconButton
                onClick={() => makePersonalCall({ ReceiverId: user.id })}
                aria-label='call'
                colorScheme='blue'
                mx='1'
              >
                <MdCall fontSize='24' />
              </IconButton>
            )}
          </Flex>
        </Box>
        <Box
          my='2'
          borderY='1px solid'
          borderColor='gray.300'
          px='2'
          bg='white'
        >
          <Flex fontSize='lg'>
            <Text color='blue.500'>Email & Phone number</Text>
          </Flex>
          <Text>{user?.email}</Text>
          <Text>{user?.phoneNumber}</Text>
        </Box>

        <Box
          my='2'
          borderY='1px solid'
          borderColor='gray.300'
          px='2'
          bg='white'
        >
          <Text color='blue.500' fontSize='lg'>
            Address
          </Text>
          <Flex>{user?.address1 ? user?.address1 : <span>-</span>}</Flex>
          <Flex>{user?.address2 ? user?.address2 : <span>-</span>}</Flex>
          <Text>{user?.city}</Text>
          <Text>{user?.state}</Text>
          <Text>{user?.country}</Text>
        </Box>
      </Box>
    </HeaderWrapper>
  );
};

export default UserProfilePage;
