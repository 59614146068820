import { Icon, IconButton, IconButtonProps } from '@chakra-ui/react';

interface IncomingCallButtonProps
  extends Required<
    Pick<IconButtonProps, 'onClick' | 'colorScheme' | 'aria-label'>
  > {
  icon: React.ElementType;
}

const IncomingCallButton: React.FC<IncomingCallButtonProps> = ({
  icon,
  ...props
}) => {
  return (
    <IconButton
      variant='ghost'
      t
      size='lg'
      isRound
      icon={<Icon as={icon} boxSize='7' />}
      _hover={{}}
      _active={{}}
      {...props}
    />
  );
};

export default IncomingCallButton;
