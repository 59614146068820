import {
  Divider,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import _ from 'lodash';
import { FC } from 'react';
import UserAvatar from 'src/components/UserAvatar';
import { Like } from 'src/models/Like.model';
import { Post } from 'src/models/Post.model';
import { EntityType } from 'src/models/View.model';
import useLikes from './hooks/useLikes';

interface ViewLikesByUsersProps {
  isOpen: boolean;
  onClose: () => void;
  post: Post;
}

export const ViewLikesByUsersModal: FC<ViewLikesByUsersProps> = ({
  isOpen,
  onClose,
  post,
}) => {
  const { data: likeDataForAvatar } = useLikes(post.id, EntityType.post);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          <Flex>
            <Text> Users liked this post :-</Text>
          </Flex>
          <Divider />
        </ModalHeader>
        <ModalBody>
          <HStack spacing='4' w='full'>
            <VStack align='start' spacing='2'>
              {_.map(likeDataForAvatar, (like: Like) => {
                return (
                  <>
                    <HStack>
                      <Flex justifyContent='space-around'>
                        <UserAvatar user={like.user} />
                        <Text fontWeight='bold' pl='2' pt='3'>
                          {`${like.user?.firstName} ${like.user?.lastName}`}
                        </Text>
                      </Flex>
                    </HStack>
                    <Divider />
                  </>
                );
              })}
            </VStack>
            <Spacer />
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
