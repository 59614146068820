import { BoxProps, Flex, Text } from '@chakra-ui/layout';
import { Center } from '@chakra-ui/react';
import { maxWidth } from '../constants/max-width.constant';

interface AppBarProps extends BoxProps {
  leftIcon?: React.ReactElement;
  title: string;
  actions?: React.ReactElement[];
}

const AppBar: React.FC<AppBarProps> = ({
  leftIcon,
  title,
  actions,
  ...props
}) => {
  return (
    <Center position="fixed" zIndex="modal" w="full">
      <Flex
        w="full"
        maxW={maxWidth}
        mx="auto"
        alignItems="center"
        bg="gray.50"
        h="12"
        justifyContent="space-between"
        boxShadow="lg"
        borderTop="1px solid"
        borderColor="gray.200"
        px="2"
        zIndex="200"
        {...props}
      >
        {leftIcon}
        <Text
          isTruncated
          maxW="full"
          fontWeight="bold"
          flexGrow={2}
          align="center"
        >
          {title}
        </Text>
        {actions}
      </Flex>
    </Center>
  );
};

export default AppBar;
