import { useCallback, useState } from 'react';
import { PlateRenderElementProps, setNodes } from '@udecode/plate';
import { ImageElement, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

type UseImageResizeOptions = Pick<
  PlateRenderElementProps<ImageElement>,
  'editor' | 'element'
>;

export interface ImageConstraints extends Pick<ImageElement, 'width' | 'height'> {}

const useImageResize = ({ editor, element }: UseImageResizeOptions) => {
  const [{ width, height }, setConstraints] = useState<{
    width: number;
    height: number;
  }>({ width: element.width, height: element.height });

  const setFinalImageConstraints = useCallback(
    (constraints: ImageConstraints) => {
      const path = ReactEditor.findPath(editor, element);

      if (
        constraints.height === element.height &&
        constraints.width === element.width
      ) {
        // If not resized, just focus the image node
        Transforms.select(editor, path);
      } else {
        setNodes<ImageElement>(editor, constraints, { at: path });
      }
    },
    [editor, element],
  );

  return {
    width,
    height,
    setConstraints,
    setFinalImageConstraints,
  };
};

export default useImageResize;
