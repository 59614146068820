import { Box, Flex, Icon, IconButton, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { MdGroup } from 'react-icons/md';

import { getFeed } from '../../../apis';
import FeedCoverImage from '../../../components/FeedCoverImage';
import HeaderWrapper from '../../../hoc/HeaderWrapper';
import { FeedModel } from '../../../models';
import { FEED_MEMBERS_PAGE_ROUTE } from '../../../routes/routeList';

import { AddPostForm } from './AddPostForm/AddPostForm';
import { AddPostView } from './AddPostView';
import useCurrentUserFeedMember from './hooks/useCurrentUserFeedMember';
import useFeedPosts from './hooks/useFeedPosts';
import PostsView from './PostsView';

const FeedPage = () => {
  const { id: feedId }: { id: string } = useParams();
  const { data: feed } = useQuery<FeedModel>(['getOneFeed', feedId], () =>
    getFeed(feedId)
  );
  const currentUserMemberRecord = useCurrentUserFeedMember(+feedId);
  const [isAddPostFormOpen, setIsAddPostFormOpen] = useState<boolean>(false);

  const handleFormDialogToggle = () => {
    setIsAddPostFormOpen(() => !isAddPostFormOpen);
  };

  const [
    postsList,
    { isFetching, isFetchingNextPage, fetchNextPage, hasNextPage },
  ] = useFeedPosts({
    feed,
    feedId: +feedId,
  });

  return (
    <HeaderWrapper
      pageTitle={feed?.name || 'Feed'}
      bodyContainerProps={{ bg: 'gray.100', minH: '100vh' }}
    >
      {feed && (
        <Flex
          border="1px solid"
          borderColor="gray.100"
          py="2"
          px="1"
          flexDir="column"
          bg="gray.100"
        >
          <FeedCoverImage
            maxH="44"
            objectFit="cover"
            coverImageUrl={feed.coverImageUrl}
          />
          <Flex m="2" justifyContent="space-between" alignItems="flex-start">
            <Text my="2" noOfLines={4}>
              {feed.description}
            </Text>
            <Link to={FEED_MEMBERS_PAGE_ROUTE.replace(':id', feedId)}>
              <IconButton
                colorScheme="blue"
                variant="ghost"
                aria-label="view members"
                icon={<Icon as={MdGroup} boxSize="8" />}
                mx="4"
              >
                View members
              </IconButton>
            </Link>
          </Flex>
        </Flex>
      )}
      <Box minW="full" minH="0.5" bg="gray.300" />
      <AddPostView
        handleOpen={handleFormDialogToggle}
        feed={feed}
        currentUserMemberRecord={currentUserMemberRecord}
      />
      <PostsView
        feedId={+feedId}
        posts={postsList}
        isFetching={isFetching}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
      />
      <AddPostForm
        isOpen={isAddPostFormOpen}
        handleClose={handleFormDialogToggle}
        feedId={feedId}
      />
    </HeaderWrapper>
  );
};

export default FeedPage;
