import { useCallback } from 'react';
import {
  Box,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import ReactPlayer from 'react-player/lazy';
import { MdMore } from 'react-icons/md';
import { PlateRenderElementProps } from '@udecode/plate';
import { MediaEmbedElement as SlateMediaEmbedElement, Transforms } from 'slate';
import { ReactEditor, useReadOnly } from 'slate-react';
import { getDocumentUrl } from 'src/apis/document.api';

interface MediaEmbedElementProps
  extends PlateRenderElementProps<SlateMediaEmbedElement> {}

const MediaEmbedElement: React.FC<MediaEmbedElementProps> = ({
  element,
  editor,
  attributes,
  children,
}) => {
  const isReadonly = useReadOnly();
  const removeMediaEmbed = useCallback(
    () =>
      Transforms.delete(editor, { at: ReactEditor.findPath(editor, element) }),
    [editor, element],
  );

  return (
    <Box contentEditable={false} {...attributes}>
      <HStack alignItems='start'>
        <ReactPlayer url={getDocumentUrl(element.url)} controls />
        {!isReadonly && (
          <Menu>
            <MenuButton
              as={IconButton}
              colorScheme='blue'
              variant='ghost'
              icon={<MdMore />}
              aria-label='Video Options'
              contentEditable={false}
            />
            <MenuList
              bg='gray.700'
              color='white'
              border='none'
              contentEditable={false}
            >
              <MenuItem
                _focus={{}}
                _hover={{ bgColor: 'gray.800' }}
                _active={{}}
                onClick={removeMediaEmbed}
              >
                Remove Video
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </HStack>
      {children}
    </Box>
  );
};

export default MediaEmbedElement;
