import { useCallback, useEffect } from 'react';
import { useMutation } from 'react-query';

import { updateMessageStatus } from '../../../apis/messages.api';
import {
  onNewMessage,
  unsubscribeNewMessage,
} from '../../../apis/socket.io/messages.socket.io';
import { MessageDto } from '../../../dto/messages.dto';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { Message } from '../../../models/Message.model';
import { ValueCallback } from '../../../types/common.type';
import useMessagesDataMethods from './useMessagesDataMethods';

const useNewMessageObserver = (messageThreadId: number) => {
  const { addOne, updateOne } = useMessagesDataMethods(messageThreadId);
  const [currentUser] = useCurrentUser();

  const makeMessageSeenMutation = useMutation(
    (message: Message) =>
      updateMessageStatus(message.MessageThreadId, message.id, 'seen'),
    { onSuccess: (_, message) => updateOne({ ...message, status: 'seen' }) }
  );

  const handleNewMessage = useCallback<ValueCallback<MessageDto>>(
    (newMessage) => {
      const msg = {
        ...newMessage,
        createdAt: new Date(newMessage.createdAt),
        messageAt: new Date(newMessage.createdAt),
      };

      addOne(msg);

      if (newMessage.SenderId !== currentUser?.id) {
        makeMessageSeenMutation.mutate(msg);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser?.id]
  );

  useEffect(() => {
    onNewMessage(handleNewMessage);

    return () => unsubscribeNewMessage(handleNewMessage);
  }, [handleNewMessage]);
};

export default useNewMessageObserver;
