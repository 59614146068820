import { useState } from 'react';
import { Button, ButtonGroup } from '@chakra-ui/button';
import { Checkbox, CheckboxProps } from '@chakra-ui/checkbox';
import { VStack, Text, HStack, Divider } from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/modal';
import { useMutation } from 'react-query';
import _ from 'lodash';

import UserAvatar from '../../../components/UserAvatar';
import {
  FeedMember,
  FeedMemberPermissions,
} from '../../../models/FeedMember.model';
import { ValueCallback } from '../../../types/common.type';
import { getUserDisplayName } from '../../../utils/user.utils';
import { updateFeedMember } from '../../../apis/feedsMembers.api';
import { useToast } from '@chakra-ui/toast';

interface ManageFeedMemberPermissionsDialogProps
  extends Omit<ModalProps, 'children'> {
  feedMember: FeedMember;
  onMemberUpdated: ValueCallback<FeedMember>;
}

interface PermissionItemProps
  extends Omit<CheckboxProps, 'isChecked' | 'onChange'> {
  hasPermission: boolean | undefined;
  onPermissionChanged?: ValueCallback<boolean>;
}

const PermissionItem: React.FC<PermissionItemProps> = ({
  hasPermission,
  onPermissionChanged = () => {},
  ...props
}) => (
  <Checkbox
    isChecked={hasPermission}
    onChange={e => onPermissionChanged(e.target.checked)}
    {...props}
  />
);

const ManageFeedMemberPermissionsDialog: React.FC<ManageFeedMemberPermissionsDialogProps> =
  ({ feedMember, ...props }) => {
    const initialPermissions = _.pick(feedMember, ['canPost']);
    const [memberPermissions, setMemberPermissions] =
      useState<Partial<FeedMemberPermissions>>(initialPermissions);

    const [hasPermissionsChanged, setHasPermissionsChanged] = useState(false);

    const showToast = useToast();
    const {
      mutate: updateMemberPermissions,
      isLoading: isUpdatingMemberPermissions,
    } = useMutation(
      ['updateFeedMemberPermissions', feedMember.id],
      () =>
        updateFeedMember(feedMember.FeedId, feedMember.id, memberPermissions),
      {
        onSuccess: () => {
          showToast({
            title: 'Permissions Updated',
            status: 'success',
          });
          props.onClose();
        },
      },
    );

    const handleMemberPermissionChange = (
      permission: keyof FeedMemberPermissions,
      permissionValue: boolean,
    ) => {
      setHasPermissionsChanged(true);
      setMemberPermissions(prev => ({
        ...prev,
        [permission]: permissionValue,
      }));
    };

    const resetMemberPermissions = () => {
      setHasPermissionsChanged(false);
      setMemberPermissions(initialPermissions);
    };

    const canUpdate = !isUpdatingMemberPermissions && hasPermissionsChanged;

    return (
      <Modal isCentered {...props}>
        <ModalOverlay />
        <ModalContent mx='4'>
          <ModalHeader>Manage feed member permissions</ModalHeader>
          <ModalBody as={VStack} spacing='4' align='start'>
            <HStack>
              <UserAvatar user={feedMember.user} />
              <VStack align='start' spacing='0'>
                <Text fontWeight='bold'>
                  {feedMember.user && getUserDisplayName(feedMember.user)}
                </Text>
                <Text color='gray.500' fontSize='sm'>
                  {feedMember.user?.email}
                </Text>
              </VStack>
            </HStack>
            <Divider />
            <Text fontWeight='bold'>Member Permissions</Text>
            <VStack align='start'>
              <PermissionItem
                hasPermission={memberPermissions.canPost}
                onPermissionChanged={canPost =>
                  handleMemberPermissionChange('canPost', canPost)
                }
              >
                Can Post
              </PermissionItem>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup variant='outline'>
              <Button
                onClick={resetMemberPermissions}
                isDisabled={!hasPermissionsChanged}
              >
                Reset
              </Button>
              <Button
                colorScheme='blue'
                variant='solid'
                isLoading={isUpdatingMemberPermissions}
                onClick={() => updateMemberPermissions()}
                isDisabled={!canUpdate}
              >
                Update Permissions
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

export default ManageFeedMemberPermissionsDialog;
