import { AxiosResponse } from 'axios';

export const getRequestData = async <
  Data = any,
  R extends AxiosResponse<Data> = AxiosResponse<Data>,
>(
  request: Promise<R>,
): Promise<Data> => {
  const { data } = await request;

  return data;
};
