import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { createGroupCall } from 'src/apis/calls.api';
import { CreateGroupCallDto } from 'src/dto/calls.dto';
import { Call } from 'src/models/Call.model';
import { buildGroupCallPageRoute } from 'src/routes/call/groupCall.route';

const useGroupCall = () => {
  const history = useHistory();
  const newGroupCallMutation = useMutation(createGroupCall);

  const goToCall = (callId: Call['id']) => {
    history.push(buildGroupCallPageRoute(callId.toString()));
  };

  const makeGroupCall = async (data: CreateGroupCallDto) => {
    const createdGroupCall = await newGroupCallMutation.mutateAsync(data);
    goToCall(createdGroupCall[0].id);
  };

  return { makeGroupCall };
};

export default useGroupCall;
