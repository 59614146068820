import { Icon, IconButton } from '@chakra-ui/react';
import { IoCall } from 'react-icons/io5';

import useNewCall from 'src/hooks/useNewCall';

interface PersonalVoiceCallButtonProps {
  otherUserId: number;
}

const PersonalVoiceCallButton: React.FC<PersonalVoiceCallButtonProps> = ({
  otherUserId,
}) => {
  const { makePersonalCall } = useNewCall();
  const handleClick = () => {
    makePersonalCall({ ReceiverId: otherUserId });
  };
  return (
    <IconButton
      icon={<Icon as={IoCall} boxSize='6' />}
      aria-label='personal-voice-call'
      bg='white'
      color='#3182ce'
      onClick={handleClick}
    />
  );
};
export default PersonalVoiceCallButton;
