import _ from 'lodash';

const disableTracks = (tracks: MediaStreamTrack[]): void => {
  _.forEach(tracks, track => (track.enabled = false));
};

const enableTracks = (tracks: MediaStreamTrack[]): void => {
  _.forEach(tracks, track => (track.enabled = true));
};

export const enableVideoTracks = (mediaStream: MediaStream) =>
  enableTracks(mediaStream.getVideoTracks());

export const disableVideoTracks = (mediaStream: MediaStream) =>
  disableTracks(mediaStream.getVideoTracks());

export const enableAudioTracks = (mediaStream: MediaStream) =>
  enableTracks(mediaStream.getAudioTracks());

export const disableAudioTracks = (mediaStream: MediaStream) =>
  disableTracks(mediaStream.getAudioTracks());

export const hasAudioTracks = (mediaStream: MediaStream) =>
  mediaStream.getAudioTracks().length > 0;

export const hasVideoTracks = (mediaStream: MediaStream) =>
  mediaStream.getVideoTracks().length > 0;
