import { Image } from '@chakra-ui/image';
import { Box } from '@chakra-ui/layout';

import MediaPreview from '../../../../components/MediaPreview';
import { isImage, isVideo } from '../../../../utils/mime-type.utils';

interface PostMediaPreviewProps {
  media: {
    id: number;
    mediaUrl: string;
    type: string;
    name: string;
  };
}

const EditPostMediaPreview: React.FC<PostMediaPreviewProps> = ({ media }) => {
  const mediaUrl = media.mediaUrl;
  const mediaMimeType = media.type;

  if (isImage(mediaMimeType)) {
    return <Image src={mediaUrl} boxSize="full" objectFit="cover" />;
  }

  if (isVideo(mediaMimeType)) {
    return <Box as="video" src={mediaUrl} autoPlay={false} boxSize="full" />;
  }

  return (
    <MediaPreview
      minH="full"
      flexDirection="column"
      justifyContent="center"
      media={{ mimeType: mediaMimeType, mediaUrl, previewName: media.name }}
    />
  );
};

export default EditPostMediaPreview;
