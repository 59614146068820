import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';

import { EditablePageContent } from '../types/EditablePageContent.type';
import { Page } from 'src/models/Page.model';
import { draftPage } from 'src/apis/pages.api';

interface UsePageContentForReview extends Pick<Page, 'id'> {}

type PageContentReviewDto = Pick<Page, 'draft'>;

const usePageContentForReview = ({ id }: UsePageContentForReview) => {
  const showToast = useToast();
  const history = useHistory();

  const reviewPageContentMutation = useMutation<
    Page,
    AxiosError,
    PageContentReviewDto
  >(pageContentReview => draftPage(id, pageContentReview), {
    onSuccess: () => {
      history.goBack();
      showToast({
        title: 'Your review has been submitted',
        description: 'Please wait for the admin to review your content',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
    onError: () => {
      showToast({
        title: 'Error while submitting your review',
        description: 'Please try again later',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const handleReviewPageContent = useCallback(
    (draft: EditablePageContent) =>
      reviewPageContentMutation.mutate({ draft: JSON.stringify(draft) }),
    [reviewPageContentMutation],
  );

  return { handleReviewPageContent, reviewPageContentMutation };
};

export default usePageContentForReview;
