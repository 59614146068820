import { Center, VStack } from '@chakra-ui/react';
import { maxWidth } from 'src/constants/max-width.constant';

import useCall from '../../hooks/useCall';
import PersonalCallStatus from './components/personal-call/status/PersonalCallStatus';
import PersonalCallTitle from './components/personal-call/title/PersonalCallTitle';

const CallPageHeader: React.FC = () => {
  const { call } = useCall();

  if (call?.isGroupCall) {
    // TODO: Add support for group calls
    return null;
  }

  return (
    <Center w='full' position='fixed' top='0' left='0'>
      <Center bgColor='blue.500' w='full' maxW={maxWidth} h='32'>
        <VStack>
          <PersonalCallTitle />
          <PersonalCallStatus />
        </VStack>
      </Center>
    </Center>
  );
};

export default CallPageHeader;
