import { ELEMENT_IMAGE, ELEMENT_MEDIA_EMBED } from '@udecode/plate';
import { BaseElementWithType, Node } from 'slate';
import _ from 'lodash';

import { Page } from '../../../models/Page.model';
import {
  DeserializedPageContent,
  PageContentImageNode,
  PageContentMediaEmbedNode,
} from '../types/page-content.type';

export const deserializePageContent = (
  pageContent: Page['content'],
): DeserializedPageContent => {
  return JSON.parse(pageContent);
};

export const serializePlainText = (
  pageContent: DeserializedPageContent,
): string => {
  return _.map(pageContent, node => Node.string(node)).join('\n');
};

export const findFirstNodeOfType = <NodeType extends BaseElementWithType>(
  pageContent: DeserializedPageContent,
  nodeType: string,
): NodeType | null => {
  const index = _.findIndex(pageContent, node => node.type === nodeType);
  if (index === -1) {
    return null;
  }

  // if node.type matches the given `nodeType`, then the node type can be guaranteed to be NodeType.
  // Note: This assumes that you pass the correct NodeType for the nodeType (string).
  // For example: for nodeType = ELEMENT_IMAGE, you should pass PageContentImageNode and not PageContentMediaEmbedNode
  return pageContent[index] as NodeType;
};

export const findFirstImageNode = (
  pageContent: DeserializedPageContent,
): PageContentImageNode | null => {
  return findFirstNodeOfType<PageContentImageNode>(pageContent, ELEMENT_IMAGE);
};

export const findFirstMediaEmbedNode = (
  pageContent: DeserializedPageContent,
): PageContentMediaEmbedNode | null => {
  return findFirstNodeOfType<PageContentMediaEmbedNode>(
    pageContent,
    ELEMENT_MEDIA_EMBED,
  );
};
