import { fetchFile } from '../apis/common.api';
import { isImage } from './mime-type.utils';

interface DowloadOptions {
  anchorRef: React.RefObject<HTMLAnchorElement>;
  url: string;
  mimeType: string;
}

export const downloadFile = async ({
  anchorRef,
  url,
  mimeType,
}: DowloadOptions) => {
  if (!anchorRef.current) {
    return;
  }

  const localObjectURL = URL.createObjectURL(await fetchFile(url, mimeType));

  if (isImage(mimeType)) {
    anchorRef.current.href = localObjectURL;
  } else {
    anchorRef.current.href = url;
  }
  
  anchorRef.current.click();
  URL.revokeObjectURL(localObjectURL);
};
