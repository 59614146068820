import { useParams } from 'react-router-dom';

import { CallParams } from 'src/routes/call/call.route';

const useCallParams = () => {
  const callParams = useParams<typeof CallParams>();

  return { callId: Number(callParams.callId) };
};

export default useCallParams;
