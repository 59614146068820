import { IconButton, IconButtonProps } from '@chakra-ui/button';

interface FloatingActionButtonProps extends IconButtonProps {}

const FloatingActionButton: React.FC<FloatingActionButtonProps> = props => {
  return (
    <IconButton
      colorScheme='blue'
      borderRadius='52'
      size='lg'
      position='fixed'
      bottom='16'
      right='8'
      {...props}
    />
  );
};

export default FloatingActionButton;
