import {
  Box,
  Center,
  Flex,
  Button,
  IconButton,
  IconButtonProps,
  Text,
} from '@chakra-ui/react';
import { FC, useCallback, useEffect, useState } from 'react';
import { MdClose, MdSearch } from 'react-icons/md';
import Select from 'react-select';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import _ from 'lodash';

import { getUsersPagination } from '../../apis/users.api';
import { User } from '../../models';
import {
  HOME_PAGE_ROUTE,
  MY_PROFILE_ROUTE,
  USER_PROFILE_ROUTE,
  FEEDS_PAGE_ROUTE,
  MESSAGES_PAGE_ROUTE,
  LOGIN_PAGE_ROUTE,
  REGISTER_PAGE_ROUTE,
} from '../../routes/routeList';
import CurrentUserAvatar from '../CurrentUserAvatar';
import GoBackButton from '../GoBackButton';
import LogoutButton from '../LogoutButton';
import { maxWidth } from '../../constants/max-width.constant';
import useCurrentUser from '../../hooks/useCurrentUser';

interface TopbarProps {
  pageTitle: string;
}

const kTopLevelRoutes: string[] = [
  HOME_PAGE_ROUTE,
  FEEDS_PAGE_ROUTE,
  MESSAGES_PAGE_ROUTE,
  LOGIN_PAGE_ROUTE,
  REGISTER_PAGE_ROUTE,
];
const kTopLevelRoutesRegex = new RegExp(`^/?(${kTopLevelRoutes.join('|')})/?$`);
const DEBOUNCE_TIME = 500;

export const Topbar: FC<TopbarProps> = ({ pageTitle }) => {
  const userQueryKey = 'users';
  const [currentUser, queryResult] = useCurrentUser();
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const { data: users } = useQuery<User[]>([userQueryKey, searchQuery], () =>
    getUsersPagination(searchQuery),
  );

  const history = useHistory();
  const location = useLocation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchTerm = useCallback(
    _.debounce(() => {
      setSearchQuery(searchInput);
    }, DEBOUNCE_TIME),
    [searchInput],
  );
  const handleSearch = (value: string) => {
    setSearchInput(value);
  };

  const handleSelect = (user: User | null) => {
    if (user) {
      history.push(USER_PROFILE_ROUTE.replace(':userId', `${user.id}`));
    }
  };

  const toggleSearch = () => {
    setIsSearch(o => !o);
  };

  useEffect(() => {
    debouncedSearchTerm();
    return debouncedSearchTerm.cancel;
  }, [debouncedSearchTerm, searchInput]);

  const getLeftButton = (): React.ReactNode => {
    const commonProps: Omit<IconButtonProps, 'aria-label'> = {
      variant: 'ghost',
      _focus: { boxShadow: 'none' },
      _hover: { background: 'none' },
      color: 'gray.500',
    };

    const isTopLevelRoute = kTopLevelRoutesRegex.test(location.pathname);

    if (location.pathname === HOME_PAGE_ROUTE) {
      return null;
    }

    if (isTopLevelRoute) {
      return <GoBackButton {...commonProps} />;
    }

    return <GoBackButton {...commonProps} />;
  };

  const isHomeRoute = 'Home';

  return (
    <Center minW='full' position='fixed' zIndex='modal'>
      <Flex
        bg='gray.50'
        h='12'
        w='full'
        justifyContent='space-between'
        alignItems='center'
        boxShadow='lg'
        borderTop='1px solid'
        borderColor='gray.200'
        px='2'
        zIndex='200'
        maxW={maxWidth}
      >
        {getLeftButton()}
        {isSearch ? (
          <Flex w='full'>
            <Box w='full'>
              <Select
                styles={{
                  control: styles => ({
                    ...styles,
                    cursor: 'pointer',
                  }),
                  option: styles => ({
                    ...styles,
                    cursor: 'pointer',
                  }),
                }}
                components={{
                  NoOptionsMessage: () => {
                    if (_.isEmpty(searchQuery)) {
                      return (
                        <Text p='2' color='gray.500'>
                          Start searching for users...
                        </Text>
                      );
                    }

                    return (
                      <Text p='2' color='gray.500'>
                        No users found
                      </Text>
                    );
                  },
                }}
                autoFocus
                options={users}
                onInputChange={handleSearch}
                onChange={handleSelect}
                getOptionLabel={u => `${u.firstName} ${u.lastName}`}
                getOptionValue={u => u.email}
              />
            </Box>
            <IconButton aria-label='close-search' onClick={toggleSearch}>
              <MdClose />
            </IconButton>
          </Flex>
        ) : (
          <>
            {pageTitle === isHomeRoute ? (
              <Text pl={{ base: '9rem', md: '15rem' }}>{pageTitle}</Text>
            ) : (
              <Text noOfLines={1}>{pageTitle}</Text>
            )}
            <Flex>
              {currentUser && (
                <IconButton
                  aria-label='search'
                  variant='ghost'
                  _focus={{ boxShadow: 'none' }}
                  _hover={{ background: 'none' }}
                  onClick={toggleSearch}
                >
                  <MdSearch />
                </IconButton>
              )}
              {currentUser && (
                <Link to={MY_PROFILE_ROUTE}>
                  <IconButton
                    aria-label='Menu'
                    variant='ghost'
                    _focus={{ boxShadow: 'none' }}
                    _hover={{ background: 'none' }}
                    icon={<CurrentUserAvatar size='sm' />}
                  />
                </Link>
              )}
              {queryResult.isFetched && !currentUser && (
                <Link to={LOGIN_PAGE_ROUTE}>
                  <Button colorScheme='gray' size='sm'>
                    Login
                  </Button>
                </Link>
              )}
              <LogoutButton />
            </Flex>
          </>
        )}
      </Flex>
    </Center>
  );
};
