import {
  Button,
  FormErrorMessage,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { AxiosError } from 'axios';
import _ from 'lodash';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';

import { PasswordField } from '../../../components/form/PasswordField';
import TextField from '../../../components/form/TextField';
import ErrorMessage from '../../../components/form/ErrorMessage';
import { LOGIN_PAGE_ROUTE } from '../../../routes/routeList';
import { register as registerUser } from '../../../apis/auth.api';
import RouteLink from '../../../components/RouteLink';

interface RegisterFormData {
  firstName: string;
  lastName?: string;
  email: string;
  password: string;
  confirmPassword?: string;
}

interface RegisterFormProps {
  onRegisterSuccess: () => void;
  onRegisterError: (err: AxiosError) => void;
}

const kRegisterFormId = 'login-form';

const kRegisterFormSchema: yup.SchemaOf<RegisterFormData> = yup.object().shape({
  firstName: yup.string().required('Please enter your first name'),
  lastName: yup.string().optional(),
  email: yup
    .string()
    .required('Please enter your email')
    .email('Please enter a valid email'),
  password: yup.string().required('Please enter a password'),
  confirmPassword: yup
    .string()
    .required()
    .test(
      'passwords-match',
      "The passwords you entered don't match",
      function (value) {
        return this.parent.password === value;
      }
    ),
});

const RegisterForm: React.FC<RegisterFormProps> = ({
  onRegisterError,
  onRegisterSuccess,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterFormData>({
    resolver: yupResolver(kRegisterFormSchema),
    shouldUnregister: false,
  });

  const submitRegisterForm = async (data: RegisterFormData) => {
    try {
      // Since confirmPassword is not required in register api, we remove it from the data to be sent
      await registerUser(_.omit(data, 'confirmPassword'));
      onRegisterSuccess();
    } catch (err: any) {
      onRegisterError(err);
    }
  };

  return (
    <VStack
      minHeight="96"
      spacing="4"
      as="form"
      onSubmit={handleSubmit(submitRegisterForm)}
      id={kRegisterFormId}
    >
      <TextField
        formControlProps={{ isRequired: true, isInvalid: !!errors.firstName }}
        inputProps={{
          ...register('firstName', {
            setValueAs: (value: string) => value?.trim(),
          }),
          placeholder: 'John',
        }}
        label="First Name"
      >
        <ErrorMessage error={errors.firstName} />
      </TextField>

      <TextField
        formControlProps={{ isInvalid: !!errors.lastName }}
        inputProps={{
          ...register('lastName', {
            setValueAs: (value: string) => value?.trim(),
          }),
          placeholder: 'Doe',
        }}
        label="Last Name"
      >
        <ErrorMessage error={errors.lastName} />
      </TextField>

      <TextField
        formControlProps={{ isRequired: true, isInvalid: !!errors.email }}
        inputProps={{
          ...register('email', {
            setValueAs: (value: string) => value?.trim(),
          }),
          placeholder: 'john.doe@example.com',
        }}
        label="Email"
      >
        <ErrorMessage error={errors.email} />
      </TextField>

      <PasswordField
        label="Enter Password"
        formControlProps={{ isRequired: true, isInvalid: !!errors.password }}
        inputProps={register('password')}
      >
        {errors.password && (
          <FormErrorMessage>{errors.password.message}</FormErrorMessage>
        )}
      </PasswordField>

      <PasswordField
        label="Confirm Password"
        formControlProps={{
          isRequired: true,
          isInvalid: !!errors.confirmPassword,
        }}
        inputProps={register('confirmPassword')}
      >
        {errors.confirmPassword && (
          <FormErrorMessage>{errors.confirmPassword.message}</FormErrorMessage>
        )}
      </PasswordField>

      <Button
        w="100%"
        py="4"
        colorScheme="blue"
        type="submit"
        form={kRegisterFormId}
      >
        Create an account
      </Button>
      <HStack>
        <Text fontWeight="bold">Already have an account?</Text>

        <RouteLink to={LOGIN_PAGE_ROUTE}>Login</RouteLink>
      </HStack>
    </VStack>
  );
};

export default RegisterForm;
