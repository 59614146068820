import _ from 'lodash';

import { axiosInstance } from '../config';
import {
    CreateMultipleFeedMembersDto,
    UpdateFeedMemberDto,
} from '../dto/feed-members.dto';
import { AsDto } from '../dto/model-as.dto';
import { FeedMember } from '../models/FeedMember.model';
import { getRequestData } from './utils/get-request-data.util';

const getFeedMembersUrl = (feedId: number) => `feeds/${feedId}/members`;

export const getAllMembers = async (feedId: number): Promise<FeedMember[]> => {
    return getRequestData(axiosInstance.get(getFeedMembersUrl(feedId)));
};

export const getAllMembersForPublicFeed = async (
    feedId: number,
): Promise<FeedMember[]> => {
    const url = `${getFeedMembersUrl(feedId)}/public`;
    return getRequestData(axiosInstance.get(url));
};

export const getCurrentUserMemberRecord = (
    feedId: number,
): Promise<FeedMember | undefined> => {
    const url = `${getFeedMembersUrl(feedId)}/me`;

    return getRequestData<FeedMember | undefined>(axiosInstance.get(url));
};

export const getMember = (
    feedId: number,
    feedMemberId: number,
): Promise<FeedMember | undefined> => {
    const url = `${getFeedMembersUrl(feedId)}/${feedMemberId}`;

    return getRequestData(axiosInstance.get(url));
};

export const addMultipleMembers = async (
    feedId: number,
    createMultipleMembersDto: CreateMultipleFeedMembersDto,
): Promise<FeedMember[]> => {
    const url = `${getFeedMembersUrl(feedId)}/multiple-members`;

    const addedMembers = await getRequestData<AsDto<FeedMember>[]>(
        axiosInstance.post(url, { members: createMultipleMembersDto }),
    );

    return _.map(addedMembers, member => ({
        ...member,
        createdAt: new Date(member.createdAt),
        updatedAt: new Date(member.updatedAt),
    }));
};

export const removeFeedMember = async (
    feedId: number,
    feedMemberId: number,
) => {
    const url = `${getFeedMembersUrl(feedId)}/${feedMemberId}`;

    return await getRequestData(axiosInstance.delete(url));
};

export const updateFeedMember = async (
    feedId: number,
    feedMemberId: number,
    updateFeedMemberDto: UpdateFeedMemberDto,
): Promise<FeedMember> => {
    const url = `${getFeedMembersUrl(feedId)}/${feedMemberId}`;

    const updatedMember = await getRequestData<AsDto<FeedMember>>(
        axiosInstance.put(url, updateFeedMemberDto),
    );

    return {
        ...updatedMember,
        createdAt: new Date(updatedMember.createdAt),
        updatedAt: new Date(updatedMember.updatedAt),
    };
};
