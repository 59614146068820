import { HStack, Text } from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/modal';
import { VStack } from '@chakra-ui/react';
import { useQuery } from 'react-query';

import { getUserDetails } from '../../../apis/users.api';
import UserAvatar from '../../../components/UserAvatar';
import { User } from '../../../models';
import { Post } from '../../../models/Post.model';
import { getUserDisplayName } from '../../../utils/user.utils';
import PostApprovalStatusBadge from './PostApprovalStatusBadge';

interface PostReviewPreviewProps extends Omit<ModalProps, 'children'> {
  post: Post;
}

const PostReviewPreview: React.FC<PostReviewPreviewProps> = ({
  post,
  ...props
}) => {
  const { data: reviewedByMember } = useQuery<User | undefined>(
    ['getUser', post.ReviewedByUserId],
    () => {
      if (post.ReviewedByUserId) {
        return getUserDetails(post.ReviewedByUserId.toString());
      }
    }
  );

  return (
    <Modal isCentered {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader as={HStack}>
          <Text>Post review</Text>
          <PostApprovalStatusBadge post={post} />
        </ModalHeader>
        <ModalBody>
          <VStack align="start" spacing="6" w="full">
            <VStack align="start" w="full">
              <Text fontWeight="500">Reviewed By</Text>
              <HStack
                borderWidth="medium"
                borderColor="gray.100"
                borderRadius="8"
                p="2"
                w="full"
              >
                <UserAvatar user={reviewedByMember} />
                <VStack spacing="0" align="start">
                  <Text fontWeight="500">
                    {(reviewedByMember &&
                      getUserDisplayName(reviewedByMember)) ||
                      'unknown user'}
                  </Text>
                  <Text>{reviewedByMember?.email}</Text>
                </VStack>
              </HStack>
            </VStack>
            {post.reviewRemarks && (
              <VStack align="start">
                <Text fontWeight="500">Review remarks:</Text>
                <Text color="gray.700">{post.reviewRemarks}</Text>
              </VStack>
            )}
            {post.reviewedAt && (
              <VStack align="start">
                <Text fontWeight="500">Reviewed at:</Text>
                <Text color="gray.700">
                  {Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  }).format(new Date(post.reviewedAt))}
                </Text>
              </VStack>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PostReviewPreview;
