import _ from 'lodash';

import { axiosInstance } from 'src/config';
import { AsDto } from 'src/dto/model-as.dto';
import { PageMembers } from 'src/models/PageMembersPage.model';
import { NewPageMember } from 'src/pages/blogs/types/pageMember.types';
import { getRequestData } from './utils/get-request-data.util';

export const createMultiplePageMembers = async (
  pageMembers: NewPageMember[],
  pageId: PageMembers['PageId'],
) => {
  const addedPageMembers = await axiosInstance
    .post(`pages/${pageId}/members/multiple`, { members: pageMembers })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw new Error('Error while creating member');
    });
  return addedPageMembers;
};

export const addMultiplePageMembers = async (
  pageId: PageMembers['PageId'],
  pageMembers: NewPageMember[],
): Promise<PageMembers[]> => {
  const url = `pages/${pageId}/members/multiple`;

  const addedMembers = await getRequestData<AsDto<PageMembers>[]>(
    axiosInstance.post(url, { members: pageMembers }),
  );

  return _.map(addedMembers, member => ({
    ...member,
    createdAt: new Date(member.createdAt),
    updatedAt: new Date(member.updatedAt),
  }));
};

const getPageMembersUrl = (pageId: number) => `pages/${pageId}/members`;

export const getAllPageMembers = async (
  pageId: number,
): Promise<PageMembers[]> => {
  return getRequestData(axiosInstance.get(getPageMembersUrl(pageId)));
};

export const getAllMembersForPublicPage = async (
  pageId: number,
): Promise<PageMembers[]> => {
  const url = `${getPageMembersUrl(pageId)}/public`;
  return getRequestData(axiosInstance.get(url));
};

export const removePageMember = async (pageId: number, id: number) => {
  const url = `/pages/${pageId}/members/${id}`;
  await axiosInstance.delete(url);
};
