import { useQuery } from 'react-query';
import { getAllPersonalCalls } from 'src/apis/personal-calls.api';
import { PersonalCalls } from 'src/models/PersonalCall';
import useArrayDataQuery from 'src/utils/query-utils/useArrayDataQuery';

const kPersonalCallsQueryKey = 'personalCalls';

export const usePersonalCallQueryClient = () => {
  return useArrayDataQuery<PersonalCalls>({
    queryKey: kPersonalCallsQueryKey,
  });
};

const usePersonalCalls = () => {
  const { data, ...queryMetadata } = useQuery<PersonalCalls[]>(
    kPersonalCallsQueryKey,
    getAllPersonalCalls,
  );
  const personalCalls = data || [];

  return { personalCalls, queryMetadata };
};

export default usePersonalCalls;
