import { ListItem, HStack, Text, VStack } from '@chakra-ui/layout';
import { Tag } from '@chakra-ui/tag';

export interface MessageItemProps {
  avatarComponent?: React.ReactNode;
  title: React.ReactNode;
  subtitle: React.ReactNode;
  topRightInfo: React.ReactNode;
  unreadMessageCount?: number;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

const MessageItem: React.FC<MessageItemProps> = ({
  avatarComponent,
  title,
  subtitle,
  topRightInfo,
  unreadMessageCount,
  onClick,
}) => {
  const hasUnreadMessages = Boolean(unreadMessageCount);

  return (
    <ListItem as={HStack} py="2" cursor="pointer">
      {avatarComponent}
      <VStack spacing="1" align="start" w="full" minW="0" onClick={onClick}>
        <HStack justifyContent="space-between" w="full">
          <Text fontWeight="bold" isTruncated>
            {title}
          </Text>
          <Text color="gray.500" fontSize="sm">
            {topRightInfo}
          </Text>
        </HStack>
        <HStack justifyContent='space-between' w='full'>
          <Text
            fontSize='sm'
            overflow='hidden'
            whiteSpace='nowrap'
            textOverflow='ellipsis'
            maxW='75%'
            fontWeight={hasUnreadMessages ? 'bold' : 'normal'}
            color={hasUnreadMessages ? 'black' : 'gray.500'}
          >
            {subtitle}
          </Text>
          {unreadMessageCount && (
            <Tag size='sm' bg='blue.500' color='white' borderRadius='16'>
              {unreadMessageCount}
            </Tag>
          )}
        </HStack>
      </VStack>
    </ListItem>
  );
};

export default MessageItem;
