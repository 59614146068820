import { useQuery } from 'react-query';

import { getFeedsForAuthenticated, getPublicFeeds } from '../../apis/feed.api';
import useCurrentUser from '../useCurrentUser';

const useFeeds = () => {
  const [currentUser, { isLoading: isLoadingCurrentUser, isIdle }] =
    useCurrentUser();

  const { data: feeds, ...queryMetadata } = useQuery(
    'getFeeds',
    () => {
      if (currentUser) {
        return getFeedsForAuthenticated();
      }

      return getPublicFeeds();
    },
    { enabled: !isLoadingCurrentUser && !isIdle }
  );

  return { feeds, ...queryMetadata };
};

export default useFeeds;
