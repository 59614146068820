import * as _ from 'lodash';

import { MessageThread } from '../../../../models/MessageThread.model';
import { ValueCallback } from '../../../../types/common.type';

type GetUnreadChatsCount = ValueCallback<MessageThread['id'], number>;

export const sortMessageThreads = <
  T extends Pick<MessageThread, 'id' | 'createdAt' | 'latestMessage'>
>(
  messageThreads: T[],
  getUnreadChatsCount: GetUnreadChatsCount
): T[] => {
  return (
    _.chain(messageThreads)
      // de-structuring for type-safety
      .orderBy(({ createdAt }) => createdAt, 'desc')
      .orderBy(({ latestMessage }) => {
        // Returning empty string since an empty string is always 'less than' any other string
        // hence, forcing this to go to bottom (chats without latestMessage will be at bottom)
        // If we don't return empty string, lodash will keep the null | undefined string at last
        // ...in this context, since we are doing a desc sort, nulls come at first
        return latestMessage?.createdAt || '';
      }, 'desc')
      .orderBy(({ id }) => getUnreadChatsCount(id), 'desc')
      .value()
  );
};
