import Icon, { IconProps } from '@chakra-ui/icon';
import { FiFileText } from 'react-icons/fi';
import { VscFileBinary } from 'react-icons/vsc';
import { RiFileMusicLine } from 'react-icons/ri';
import { FaRegFileVideo, FaRegFile, FaFilePdf } from 'react-icons/fa';

import {
  isAudio,
  isBinary,
  isPdf,
  isText,
  isVideo,
} from '../../../utils/mime-type.utils';

type MediaPreviewIconProps = IconProps & {
  mimeType: string;
};

const getIcon = (mimeType: string): React.ElementType => {
  if (isText(mimeType)) {
    return FiFileText;
  }

  if (isBinary(mimeType)) {
    return VscFileBinary;
  }

  if (isAudio(mimeType)) {
    return RiFileMusicLine;
  }

  if (isVideo(mimeType)) {
    return FaRegFileVideo;
  }

  if (isPdf(mimeType)) {
    return FaFilePdf;
  }

  return FaRegFile;
};

const MediaPreviewIcon: React.FC<MediaPreviewIconProps> = ({
  mimeType,
  ...props
}) => {
  return <Icon as={getIcon(mimeType)} {...props} />;
};

export default MediaPreviewIcon;
