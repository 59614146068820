import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ModalProps,
  VStack,
} from '@chakra-ui/react';
import _ from 'lodash';
import { MdAdd } from 'react-icons/md';

import useCurrentUser from 'src/hooks/useCurrentUser';
import useGroupCall from 'src/hooks/useGroupCall';
import { MessageThread } from 'src/models/MessageThread.model';
import { MessageThreadMember } from 'src/models/MessageThreadMember.model';
import MemberItem, {
  useSelectedMembers,
} from 'src/pages/call/group-call/components/MemberItem';

interface SelectParticipantsModalProps extends Omit<ModalProps, 'children'> {
  messageThread: MessageThread;
  groupMessageThreadId: number;
}

const SelectMemberForGroupCallDialog: React.FC<
  SelectParticipantsModalProps
> = ({ messageThread, groupMessageThreadId, ...props }) => {
  const [currentUser] = useCurrentUser();

  const [selectedMembers, setSelectedMembers] = useSelectedMembers();

  // omit currentUser from message thread members
  const messageThreadMembersWithoutCurrentUser: MessageThreadMember[] =
    messageThread?.members.filter((member, index) => {
      return member.user?.id !== currentUser?.id;
    });

  const { makeGroupCall } = useGroupCall();
  const handleOnAddMemberClick = () => {
    const selectedMembersUserIds = _.map(
      selectedMembers,
      ({ UserId }) => UserId,
    );
    makeGroupCall({
      receiverUserIds: selectedMembersUserIds,
      MessageGroupId: groupMessageThreadId,
    });
    setSelectedMembers({});
  };

  return (
    <Modal isCentered {...props}>
      <ModalOverlay />
      <ModalContent minH='50%' mx='4'>
        <ModalHeader>Select Members</ModalHeader>
        <ModalBody>
          <VStack spacing='6' align='start'>
            {messageThreadMembersWithoutCurrentUser.map(member => (
                <MemberItem
                  key={member.id}
                  member={member}
                  selectedMembers={selectedMembers}
                  setSelectedMembers={setSelectedMembers}
                />
            ))}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme='blue'
            leftIcon={<MdAdd />}
            onClick={handleOnAddMemberClick}
          >
            Add Selected Users
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SelectMemberForGroupCallDialog;
