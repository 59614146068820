import { createContext } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { useQueryClientInstance } from 'src/hooks/useQueryClientInstance';
import { QueryLoaderProvider } from './query-loader-context-provider';

interface QueryCustomContextType {
  queryClient: QueryClient;
}

export const QueryCustomContext = createContext<QueryCustomContextType>(null!);

interface QueryCustomProviderProps {}

export const QueryCustomProvider: React.FC<QueryCustomProviderProps> = ({
  children,
}) => {
  const queryClient = useQueryClientInstance();
  const value: QueryCustomContextType = { queryClient };

  return (
    <QueryCustomContext.Provider value={value}>
      <QueryClientProvider client={queryClient}>
        <QueryLoaderProvider>{children}</QueryLoaderProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </QueryCustomContext.Provider>
  );
};
