import React from 'react';
import { Grid, Link } from '@chakra-ui/layout';

import { BaseSection } from '../BaseSection';
import ImageCard from '../ImageCard';
import { DashboardSection } from '../../../../models/DashboardSection.model';
import useDashboardSectionLinks from '../../hooks/useDashboardSectionLinks';
import { Link as RouterLink } from 'react-router-dom';
export interface ImageSectionBaseProps {
    dashboardSection: DashboardSection;
}

const ImageSection: React.FC<ImageSectionBaseProps> = ({
    dashboardSection,
}) => {
    const [dashboardSectionLinks] = useDashboardSectionLinks({
        dashboardSectionId: dashboardSection.id,
    });

    const body = (
        <Grid gap='8'>
            {dashboardSectionLinks.map(link =>
                link.isExternal ? (
                    <Link href={link.linkUrl} target='_blank'>
                        <ImageCard
                            key={link.id}
                            src={link.imageUrl}
                            caption={link.linkText}
                        />
                    </Link>
                ) : (
                    <RouterLink to={link.linkUrl}>
                        <ImageCard
                            key={link.id}
                            src={link.imageUrl}
                            caption={link.linkText}
                        />
                    </RouterLink>
                ),
            )}
        </Grid>
    );

    return <BaseSection title={dashboardSection.title} body={body} />;
};

export default ImageSection;
