import { Text } from '@chakra-ui/layout';
import { ModalProps } from '@chakra-ui/modal';
import { useEffect } from 'react';
import { useMutation } from 'react-query';

import { removeUserFromGroupMessageThread } from '../../../apis/group-message-threads.api';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { MessageThreadMember } from '../../../models/MessageThreadMember.model';
import { getUserDisplayName } from '../../../utils/user.utils';

interface ConfirmRemoveMemberDialogProps extends Omit<ModalProps, 'children'> {
  memberToRemove: MessageThreadMember;
  onMemberRemoved: () => any;
}

const ConfirmRemoveMemberDialog: React.FC<ConfirmRemoveMemberDialogProps> = ({
  memberToRemove,
  onMemberRemoved,
  ...props
}) => {
  const {
    mutate: removeMember,
    isSuccess: hasRemovedMember,
    isLoading: isRemovingMember,
  } = useMutation(['updateMessageThreadMember', memberToRemove.id], () => {
    return removeUserFromGroupMessageThread(
      memberToRemove.MessageThreadId,
      memberToRemove.UserId,
    );
  });

  useEffect(() => {
    if (hasRemovedMember) {
      onMemberRemoved();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasRemovedMember]);

  const memberName =
    memberToRemove.user && getUserDisplayName(memberToRemove.user);

  const confirmationDescription = (
    <Text>
      Are you sure you want to remove{' '}
      <Text as='span' fontWeight={memberName && 'bold'}>
        {memberName || 'this member'}
      </Text>{' '}
      from the group?
    </Text>
  );

  return (
    <ConfirmationDialog
      title='Remove Member'
      description={confirmationDescription}
      confirmLabel='Remove Member'
      confirmButtonProps={{ isLoading: isRemovingMember }}
      onConfirmed={() => removeMember()}
      {...props}
    />
  );
};

export default ConfirmRemoveMemberDialog;
