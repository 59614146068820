import { useMutation } from 'react-query';
import { createFile } from 'src/apis/document.api';
import ImageToolbarButton from '../../plugins/image/ImageToolbarButton';

interface ImageToolbarProps {}

export const ImageToolbar: React.FC<ImageToolbarProps> = () => {
  const uploadImageMutation = useMutation(createFile);

  return (
    <ImageToolbarButton
      getImageUrl={async imageFile => {
        const uploadedImage = await uploadImageMutation.mutateAsync({
          file: imageFile,
        });

        return uploadedImage.documentUrl;
      }}
    />
  );
};
