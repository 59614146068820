import { socket } from '../../config/socketIoInstance';
import { PersonalMessageThreadDto } from '../../dto/personal-messageThreads.dto';
import { PersonalMessageThread } from '../../models/PersonalMessageThread.model';
import { ValueCallback } from '../../types/common.type';
import { kNewPersonalMessageThreadEvent } from './events/personal-message-threads.events';

export const onNewPersonalMessageThread = (
  callback: ValueCallback<PersonalMessageThread>,
) =>
  socket.on(
    kNewPersonalMessageThreadEvent,
    (message: PersonalMessageThreadDto) => {
      callback({ ...message, createdAt: new Date(message.createdAt) });
    },
  );

export const unsubscribeNewPersonalMessageThread = (
  callback: ValueCallback<PersonalMessageThread>,
) => {
  socket.off(kNewPersonalMessageThreadEvent, callback);
};
