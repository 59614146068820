import { MessageThread } from '../../../models/MessageThread.model';

const time24Hour = (date: Date) => {
  return Intl.DateTimeFormat('en-US', {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  }).format(new Date(date));
};

const getMessageThreadDate = ({
  latestMessage,
  createdAt,
}: Pick<MessageThread, 'createdAt' | 'latestMessage'>): string => {
  return latestMessage
    ? time24Hour(latestMessage.createdAt)
    : time24Hour(createdAt);
};

export default getMessageThreadDate;
