import { SortOrder } from '../types/common.type';

type ValidDate = string | number | Date;

export const sortByDate = (
  date: ValidDate,
  otherDate: ValidDate,
  order: SortOrder = 'asc',
): number => {
  const a = new Date(date);
  const b = new Date(otherDate);

  if (order === 'asc') {
    return a.valueOf() - b.valueOf();
  }

  return b.valueOf() - a.valueOf();
};
