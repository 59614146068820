import { Icon, IconProps } from '@chakra-ui/react';

interface ToolbarIconProps extends IconProps {
  isActive: boolean;
  as: React.ElementType;
}

const ToolbarIcon: React.FC<ToolbarIconProps> = ({
  as,
  isActive,
  ...props
}) => {
  return <Icon as={as} color={isActive ? 'blue.500' : 'inherit'} {...props} />;
};

export default ToolbarIcon;
