import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { getFeed } from '../../apis';
import { FeedModel } from '../../models';

const useFeed = (feedId: FeedModel['id']) => {
  const { data, ...queryResult } = useQuery<void, AxiosError, FeedModel>(
    ['getOneFeed', feedId],
    () => getFeed(feedId.toString())
  );

  return { feed: data, ...queryResult };
};

export default useFeed;
