import { Center, Text, VStack } from '@chakra-ui/react';
import _ from 'lodash';

import useCall from 'src/pages/call/hooks/useCall';
import useCurrentUser from 'src/hooks/useCurrentUser';
import { useEffect, useState } from 'react';
import { CallSession } from 'src/models/CallSession.model';
import useCallSessions from 'src/pages/call/hooks/useCallSessions';
import CallDuration from 'src/pages/call/components/header/components/call-duration';

const GroupCallHeader = () => {
    const { call } = useCall();
    // const sessions = useGroupCallSession();
    const { callSessions } = useCallSessions();
    const [currentUser] = useCurrentUser();
    const [isTimerStarted, setIsTimerStarted] = useState<boolean>(true);
    const [session, setSession] = useState<CallSession>();

    const AllCallSessionOfHostUser = _.map(callSessions, session => {
        if (call?.HostUserId === session?.CallerId) {
            return session;
        }
    });

    const callSessionWithHost = _.head(AllCallSessionOfHostUser);

    const isCurrentUserHost = currentUser?.id === call?.HostUserId;

    useEffect(() => {
        if (callSessions) {
            const findSessionWithJoinedAt = callSessions.find(session => {
                if (session && session.joinedAt) {
                    return session;
                }
                return undefined;
            });
            setSession(findSessionWithJoinedAt);
            setIsTimerStarted(false);
        }
    }, [callSessions, isTimerStarted]);

    return (
        <Center
            bgColor='blue.500'
            h='20'
            color='white'
            alignContent='center'
            flexDirection='column'
        >
            <VStack>
                <Text fontSize='x-large' fontWeight='bold'>
                    {call?.messageGroup?.name}
                </Text>

                {/* if the current user is host of the call then his call duration will be visible as soon as any of user that are in the group call is pick up the call */}
                {isCurrentUserHost && session && (
                    <CallDuration callSession={session} />
                )}

                {/* if the current user is receiver then the call duration is visible as soon as he pickup the call */}
                {!isCurrentUserHost && callSessionWithHost && (
                    <CallDuration callSession={callSessionWithHost} />
                )}
            </VStack>
        </Center>
    );
};
export default GroupCallHeader;
