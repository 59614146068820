import { axiosInstance } from 'src/config';
import { CreateGroupCallDto, CreatePersonalCallDto } from 'src/dto/calls.dto';
import { Call } from 'src/models/Call.model';

import { getRequestData } from './utils/get-request-data.util';

export const createPersonalCall = ({
    ReceiverId,
}: CreatePersonalCallDto): Promise<Call> => {
    return getRequestData<Call>(
        axiosInstance.post('/calls/personal', { ReceiverId }),
    );
};

export const createGroupCall = ({
  receiverUserIds,
  MessageGroupId,
}: CreateGroupCallDto): Promise<Call[]> => {
  return getRequestData<Call[]>(
    axiosInstance.post('/calls/group', { receiverUserIds, MessageGroupId }),
  );
};

export const getCall = (callId: Call['id']): Promise<Call | undefined> => {
    return getRequestData<Call | undefined>(
        axiosInstance.get(`/calls/${callId}`),
    );
};
