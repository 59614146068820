import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { createPersonalCall } from 'src/apis/calls.api';
import { CreatePersonalCallDto } from 'src/dto/calls.dto';
import { Call } from 'src/models/Call.model';
import { buildCallPageRoute } from 'src/routes/routeList';

const useNewCall = () => {
    const history = useHistory();
    const newPersonalCallMutation = useMutation(createPersonalCall);

    const goToCall = (callId: Call['id']) => {
        history.push(buildCallPageRoute(callId.toString()));
    };

    const makePersonalCall = async (data: CreatePersonalCallDto) => {
        const createdCall = await newPersonalCallMutation.mutateAsync(data);
        goToCall(createdCall.id);
    };

    return { makePersonalCall };
};

export default useNewCall;
