import { IconButton, Icon } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { MdCallEnd } from 'react-icons/md';
import { useCallback, useEffect, useRef } from 'react';

import useCallSessions from 'src/pages/call/hooks/useCallSessions';
import { leaveCallSession } from 'src/apis/call-sessions.api';
import { usePeerConnection } from 'src/pages/call/context/peer-connection/hooks/usePeerConnections';
import _ from 'lodash';

interface EndCallButtonProps {}

const EndCallButton: React.FC<EndCallButtonProps> = () => {
  const history = useHistory();
  const { callSessions } = useCallSessions();

  const { peerConnCleanups } = usePeerConnection();

  // used ref to store peer connection cleanup functions
  const isFinalUnmount = useRef<boolean>();

  useEffect(() => {
    return () => {
      // Create this ref to prevent unmounting of component before cleanup is done
      isFinalUnmount.current = true;
    };
  }, []);

  useEffect(() => {
    return () => {
      if (isFinalUnmount.current) {
        _.forEach(peerConnCleanups, cleanup => cleanup());
      }
    };
  }, [isFinalUnmount, peerConnCleanups]);

  const leaveCall = useCallback(() => {
    callSessions.forEach(session => leaveCallSession(session.id));
    history.goBack();
  }, [callSessions, history]);

  return (
    <IconButton
      variant='solid'
      aria-label='End Call'
      size='lg'
      colorScheme='red'
      isRound
      icon={<Icon as={MdCallEnd} fontSize='28px' />}
      onClick={leaveCall}
    />
  );
};

export default EndCallButton;
