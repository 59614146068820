import { Box, Container, Divider, VStack } from '@chakra-ui/react';
import { MdAdd } from 'react-icons/md';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import _ from 'lodash';

import { getOneMessageThread } from '../../apis/message-threads.api';
import { GroupMessageThread } from '../../models/GroupMessageThread.model';
import useCurrentUser from '../../hooks/useCurrentUser';
import AppBar from '../../components/AppBar';
import GoBackButton from '../../components/GoBackButton';
import FloatingActionButton from '../../components/FloatingActionButton';
import { Footer } from '../../components';
import MemberItem from './components/MemberItem';
import useDialog from '../../hooks/useDialog';
import AddMemberDialog from './components/AddMemberDialog';
import { MessageThreadMember } from '../../models/MessageThreadMember.model';
import { getUserDisplayName } from '../../utils/user.utils';

interface MessageMembersPageUrlParams {
  messageThreadId: string;
}

const MessageMembersPage = () => {
  const params = useParams<MessageMembersPageUrlParams>();
  const messageThreadId = +params.messageThreadId;

  const [isAddMemberDialogOpen, openAddMemberDialog, closeAddMemberDialog] =
    useDialog();

  const [currentUser] = useCurrentUser();

  const getMessageThreadQueryKey = ['getMessageThread', messageThreadId];
  const queryClient = useQueryClient();
  // TODO: Re-use these queries. Right now, ChatPage also uses this
  const { data: messageThread } = useQuery(getMessageThreadQueryKey, () =>
    getOneMessageThread(messageThreadId),
  );
  const handleOnMembersAdded = (addedMembers: MessageThreadMember[]) => {
    closeAddMemberDialog();
    queryClient.setQueryData<typeof messageThread>(
      getMessageThreadQueryKey,
      prevMessageThread => {
        if (!prevMessageThread) {
          return prevMessageThread;
        }

        const members = _.concat(prevMessageThread.members, addedMembers);
        return { ...prevMessageThread, members: _.uniqBy(members, 'id') };
      },
    );
  };

  const handleOnMemberRemoved = (memberRemoved: MessageThreadMember) => {
    queryClient.setQueryData<typeof messageThread>(
      getMessageThreadQueryKey,
      prevMessageThread => {
        if (!prevMessageThread) {
          return prevMessageThread;
        }

        const members = _.filter(
          prevMessageThread.members,
          member => member.id !== memberRemoved.id,
        );

        return { ...prevMessageThread, members };
      },
    );
  };

  const handleOnMemberUpdated = (memberUpdated: MessageThreadMember) => {
    queryClient.setQueryData<typeof messageThread>(
      getMessageThreadQueryKey,
      prevMessageThread => {
        if (!prevMessageThread) {
          return prevMessageThread;
        }

        const updatedMembers = _.map(prevMessageThread.members, member => {
          return member.id === memberUpdated.id ? memberUpdated : member;
        });

        return { ...prevMessageThread, members: updatedMembers };
      },
    );
  };

  if (!messageThread?.isGroup) {
    return null;
  }

  const currentUserMemberRecord = _.find(
    messageThread?.members,
    member => member.user?.id === currentUser?.id,
  );

  const groupName = (messageThread as GroupMessageThread).group.name;

  return (
    <>
      <Box m="0">
        <AppBar leftIcon={<GoBackButton />} title={`Members: ${groupName}`} />
        <Container pt="12" maxH="xl" position="relative">
          <VStack w="full" h="xl" mt="4" spacing="4">
            {_.chain(messageThread?.members)
              .sortBy(({ user }) => {
                // return `null` for current loggedIn user to make them appear at last during sort
                // lodash keeps null/undefined at the end for ascending order
                // Source: https://github.com/lodash/lodash/issues/4169#issuecomment-457607652
                if (user?.id === currentUser?.id) {
                  return null;
                }

                return user && getUserDisplayName(user);
              })
              .value()
              .map((member, index, { length: memberArrLen }) => (
                <>
                  {index === memberArrLen - 1 && <Divider />}
                  <MemberItem
                    key={member.id}
                    member={member}
                    currentUserMemberRecord={currentUserMemberRecord}
                    onMemberRemoved={() => handleOnMemberRemoved(member)}
                    onMemberUpdated={handleOnMemberUpdated}
                  />
                </>
              ))}
          </VStack>
          {currentUserMemberRecord?.isAdmin && (
            <>
              {messageThread && (
                <>
                  <FloatingActionButton
                    aria-label="add member button"
                    icon={<MdAdd />}
                    onClick={openAddMemberDialog}
                    position="absolute"
                    bottom="0"
                    right="0"
                  />
                  <AddMemberDialog
                    existingMembers={messageThread.members}
                    messageThreadId={messageThreadId}
                    onMembersAdded={handleOnMembersAdded}
                    isOpen={isAddMemberDialogOpen}
                    onClose={closeAddMemberDialog}
                  />
                </>
              )}
            </>
          )}
        </Container>
        <Footer />
      </Box>
    </>
  );
};

export default MessageMembersPage;
