import { useEffect, useMemo } from 'react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { Text, VStack } from '@chakra-ui/layout';
import { Button, ButtonGroup } from '@chakra-ui/button';
import { useToast } from '@chakra-ui/toast';
import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as _ from 'lodash';

import { CustomModalProps } from '../../../types/common.type';
import GroupMetadataForm, {
  kNewGroupChatFormSchema as editGroupChatFormSchema,
  NewGroupChatFormData as GroupChatFormData,
} from '../../messages/components/new-group-chat-dialog/GroupMetadataForm.step';
import { useMutation, useQuery } from 'react-query';
import {
  editGroupMessageThread,
  getGroupMessageThread,
} from '../../../apis/group-message-threads.api';
import {
  Group,
  GroupMessageThread,
} from '../../../models/GroupMessageThread.model';
import { UpdateGroupMessageThreadDto } from '../../../dto/group-messageThreads.dto';

interface GroupEditDialogProps {
  messageThreadId: number;
}

const kEditGroupFormId = 'edit-group-dialog-form';

const GroupEditDialog: React.FC<CustomModalProps<GroupEditDialogProps>> = ({
  messageThreadId,
  ...props
}) => {
  const { data: groupMessageThread, isLoading } = useQuery(
    ['getOneGroupChat', messageThreadId],
    () => getGroupMessageThread(messageThreadId)
  );

  const groupMetadata: Partial<Pick<Group, 'name' | 'description'>> = useMemo(
    () => _.pick(groupMessageThread?.group, ['name', 'description']),
    [groupMessageThread]
  );

  const editGroupForm = useForm<GroupChatFormData>({
    defaultValues: groupMetadata,
    resolver: yupResolver(editGroupChatFormSchema),
  });

  useEffect(() => {
    editGroupForm.reset(groupMetadata);
    // Adding editGroupForm to dependencies causes infinite re-rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupMetadata]);

  const editGroupMutation = useMutation<
    GroupMessageThread,
    AxiosError,
    UpdateGroupMessageThreadDto
  >((updateData) => editGroupMessageThread(messageThreadId, updateData));

  const showToast = useToast();

  const handleOnClose = () => {
    editGroupForm.reset();
    props.onClose();
  };

  const handleEditGroupSubmit = (data: GroupChatFormData) => {
    editGroupMutation.mutate(data, {
      onSuccess: () => {
        showToast({
          title: 'Group updated',
          description: 'Group has been updated successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        handleOnClose();
      },
      onError: () => {
        showToast({
          title: 'Error',
          description: 'Error updating group',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      },
    });
  };

  const isUpdating = editGroupMutation.isLoading;
  const isLoadingOrUptading = isLoading || isUpdating;

  return (
    <Modal {...props} onClose={handleOnClose} isCentered>
      <ModalOverlay />
      <ModalContent mx="2">
        <ModalHeader>
          <Text>Edit Group Info</Text>
        </ModalHeader>
        <ModalBody>
          {isLoading ? (
            <VStack>
              <Text color="gray.500">Fetching group...</Text>
            </VStack>
          ) : (
            <GroupMetadataForm
              id={kEditGroupFormId}
              onSubmit={handleEditGroupSubmit}
              formProps={editGroupForm}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button onClick={handleOnClose}>Cancel</Button>
            <Button
              colorScheme="blue"
              disabled={isLoadingOrUptading}
              isLoading={isUpdating}
              loadingText={isUpdating ? 'Updating...' : undefined}
              type="submit"
              form={kEditGroupFormId}
            >
              Edit Group
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default GroupEditDialog;
