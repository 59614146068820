import { Image, ImageProps } from '@chakra-ui/image';
import { VStack, Flex, Text } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/react';
import React from 'react';
import { ImageWithCaption } from '../../types/common.types';

type ImageCardProps = ImageWithCaption & { imageProps?: ImageProps };

const ImageCard: React.FC<ImageCardProps> = ({ src, caption, imageProps }) => {
  return (
    <VStack borderWidth='1px' borderRadius='lg' borderColor='gray.300'>
      <Flex w='full' justify='center' bgColor='blackAlpha.800'>
        <Image
          src={src}
          maxHeight='250px'
          w='100%'
          fit='cover'
          {...(imageProps || {})}
        />
      </Flex>
      <Box px='2' pb='2' w='full'>
        <Text noOfLines={2} maxW='full'>
          {caption}
        </Text>
      </Box>
    </VStack>
  );
};

export default ImageCard;
