import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { Link, LinkProps } from 'react-router-dom';

interface BaseFooterButtonProps {
  icon: NonNullable<IconButtonProps['icon']>;
  to: LinkProps['to'];
}

const BaseFooterButton: React.FC<BaseFooterButtonProps> = ({ icon, to }) => {
  return (
    <IconButton
      as={Link}
      to={to}
      position='relative'
      aria-label='Message'
      variant='ghost'
      w='full'
      _focus={{
        boxShadow: 'none',
      }}
      borderRight='1px solid'
      borderColor='gray.200'
      _hover={{
        background: 'none',
      }}
      size='lg'
      p='0'
      icon={icon}
    />
  );
};

export default BaseFooterButton;
