import { MenuItem, MenuItemProps, MenuList } from '@chakra-ui/react';
import { PlateRenderElementProps } from '@udecode/plate';
import {
  MdFormatAlignLeft,
  MdFormatAlignCenter,
  MdFormatAlignRight,
  MdClose,
} from 'react-icons/md';
import { ImageElement } from 'slate';
import useImageModify from './useImageModify';

interface ImageBubbleToolbarListProps
  extends Pick<PlateRenderElementProps<ImageElement>, 'element' | 'editor'> {}

const ImageBubbleToolbarList: React.FC<ImageBubbleToolbarListProps> = ({
  element,
  editor,
}) => {
  const { setImageAlignment, removeImage } = useImageModify({
    element,
    editor,
  });

  return (
    <MenuList
      bg='gray.700'
      color='white'
      display='flex'
      flexWrap='wrap'
      width='fit-content'
      minW='0'
    >
      <ImageMenuItem
        icon={<MdFormatAlignLeft fontSize='20px' />}
        onClick={() => setImageAlignment('left')}
      />
      <ImageMenuItem
        icon={<MdFormatAlignCenter fontSize='20px' />}
        onClick={() => setImageAlignment('center')}
      />
      <ImageMenuItem
        icon={<MdFormatAlignRight fontSize='20px' />}
        onClick={() => setImageAlignment('right')}
      />
      <ImageMenuItem icon={<MdClose fontSize='20px' />} onClick={removeImage} />
    </MenuList>
  );
};

const ImageMenuItem: React.FC<MenuItemProps> = props => {
  return (
    <MenuItem
      _focus={{}}
      _hover={{ color: 'blue.500' }}
      _active={{}}
      width='fit-content'
      {...props}
    />
  );
};

export default ImageBubbleToolbarList;
