import {
  HStack,
  MenuItem,
  MenuItemProps,
} from '@chakra-ui/react';

interface LoadingMenuItemProps extends MenuItemProps {
  isLoading: boolean;
}

const LoadingMenuItem: React.FC<LoadingMenuItemProps> = ({
  isLoading,
  children,
  ...props
}) => {
  return (
    <MenuItem
      as={HStack}
      cursor="pointer"
      closeOnSelect={false}
      isDisabled={isLoading}
      {...props}
    >
      {children}
    </MenuItem>
  );
};

export default LoadingMenuItem;
