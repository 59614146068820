import { useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { reviewPage } from 'src/apis/pages.api';
import { Page } from 'src/models/Page.model';
import { EditablePageContent } from '../types/EditablePageContent.type';

interface UseApprovePageContentOptions extends Pick<Page, 'id'> {}

type PageContentUpdateDto = Pick<Page, 'isApproved'>;

const useApproveContent = ({ id }: UseApprovePageContentOptions) => {
  const showToast = useToast();
  const history = useHistory();

  const updatePageContentMutation = useMutation<
    Page,
    AxiosError,
    PageContentUpdateDto
  >(pageContentUpdate => reviewPage(id, pageContentUpdate), {
    onSuccess: () => {
      history.goBack();
      showToast({
        title: 'Page content Approved',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    },
    onError: () => {
      showToast({
        title: 'Error',
        description: 'Error while Approve page content',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const handlePublishPageContent = useCallback(
    (isApproved: EditablePageContent) =>
      updatePageContentMutation.mutate({ isApproved: true }),
    [updatePageContentMutation],
  );

  return { handlePublishPageContent, updatePageContentMutation };
};

export default useApproveContent;
