import { Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { getAllCommmentOfOnePost } from 'src/apis/posts.api';
import HeaderWrapper from 'src/hoc/HeaderWrapper';

import PostCard from './PostCard';

interface CommentCardProps {}

export const CommentCard: FC<CommentCardProps> = () => {
  const { id } = useParams<{ id: string }>();
  const { postId } = useParams<{ postId: string }>();

  const { data: comments } = useQuery(
    [
      'getAllCommmentOfOnePost',
      {
        postId: postId,
        id: id,
      },
    ],
    () => getAllCommmentOfOnePost(+id, +postId),
  );

  if (comments) {
    return (
      <HeaderWrapper pageTitle='comments'>
        <PostCard post={comments} />
      </HeaderWrapper>
    );
  }

  return (
    <HeaderWrapper pageTitle='comments'>
      <Text>loading...</Text>
    </HeaderWrapper>
  );
};
