import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { EditablePageContent } from '../types/EditablePageContent.type';
import { useToast } from '@chakra-ui/react';
import { Page } from 'src/models/Page.model';
import { updatePage } from 'src/apis/pages.api';

interface UsePublishPageContentOptions extends Pick<Page, 'id'> {}

type PageContentUpdateDto = Pick<Page, 'content'>;

const usePublishPageContent = ({ id }: UsePublishPageContentOptions) => {
  const showToast = useToast();
  const history = useHistory();

  const updatePageContentMutation = useMutation<
    Page,
    unknown,
    PageContentUpdateDto
  >(pageContentUpdate => updatePage(id, pageContentUpdate), {
    onSuccess: () => {
      history.goBack();
      showToast({
        title: 'Page content updated',
        description: 'Page content has been updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
    onError: () => {
      showToast({
        title: 'Error',
        description: 'Error updating page content',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const handlePublishPageContent = useCallback(
    (content: EditablePageContent) =>
      updatePageContentMutation.mutate({ content: JSON.stringify(content) }),
    [updatePageContentMutation],
  );

  return { handlePublishPageContent, updatePageContentMutation };
};

export default usePublishPageContent;
