import { useState } from 'react';
import _ from 'lodash';

import useFileWithSizeLimit from '../../../../hooks/useFileWIthSizeLimit';

const useFiles = () => {
  const [files, setFiles] = useState<File[]>([]);
  const [fileURIs, setFileURIs] = useState<string[]>([]);

  const { validateFileSize } = useFileWithSizeLimit();

  const addFile = (files: FileList | null) => {
    const file = files?.item(0);
    if (file) {
      const isValidFile = validateFileSize(file);
      if (!isValidFile) {
        return;
      }

      setFiles(prevFiles => [...prevFiles, file]);

      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setFileURIs(prevURIs => {
          if (_.isNull(reader.result)) {
            return prevURIs;
          }

          return [...prevURIs, reader.result.toString()];
        });
      });

      reader.readAsDataURL(file);
    }
  };

  const removeFile = (fileURI: string, index: number) => {
    setFileURIs(prevURIs => _.without(prevURIs, fileURI));
    setFiles(prevFiles =>
      _.filter(prevFiles, (__, indexValue) => indexValue !== index),
    );
  };

  const removeAllFiles = () => {
    setFiles([]);
    setFileURIs([]);
  };

  return { files, fileURIs, addFile, removeFile, removeAllFiles };
};

export default useFiles;
