import { Icon, IconButton, useToast } from '@chakra-ui/react';
import _ from 'lodash';
import { IoCallOutline } from 'react-icons/io5';
import { useQuery } from 'react-query';
import { getOneMessageThread } from 'src/apis/message-threads.api';
import {
    MAXIMUM_MEMBERS_IN_GROUP_CALL,
    NO_MEMBERS_IN_GROUP,
    ONE_MEMBER_IN_GROUP,
} from 'src/constants/group-call.constant';

import useCurrentUser from 'src/hooks/useCurrentUser';
import useDialog from 'src/hooks/useDialog';
import useGroupCall from 'src/hooks/useGroupCall';
import { GroupMessageThread } from 'src/models/GroupMessageThread.model';
import SelectMemberForGroupCallDialog from './SelectParticipantsModal';

interface GroupCallButtonProps {
    groupMessageThread: GroupMessageThread;
}

const GroupCallButton: React.FC<GroupCallButtonProps> = ({
    groupMessageThread,
}) => {
    const [currentUser] = useCurrentUser();

    const toast = useToast();

    const [
        isSelectMemberDialogOpen,
        openSelectMemberDialog,
        closeSelectMemberDialog,
    ] = useDialog();

    const getMessageThreadQueryKey = [
        'getMessageThread',
        groupMessageThread.id,
    ];
    const { data: messageThread } = useQuery(
        getMessageThreadQueryKey,
        async () => {
            return await getOneMessageThread(groupMessageThread.id);
        },
    );

    // filter current user from message thread member
    const messageThreadMembersWithoutCurrentUser =
        messageThread?.members.filter((member, index) => {
            return member.user?.id !== currentUser?.id;
        });

    const existingMemberUserId = _.map(
        messageThreadMembersWithoutCurrentUser,
        ({ UserId }) => UserId,
    );

    const { makeGroupCall } = useGroupCall();

    const handleCallForExistingMember = (
        userId: number[],
        MessageGroupId: number,
    ) => {
        makeGroupCall({
            receiverUserIds: userId,
            MessageGroupId,
        });
    };

    if (!currentUser) {
        return null;
    }

    return (
        <>
            {(existingMemberUserId.length > MAXIMUM_MEMBERS_IN_GROUP_CALL && (
                <IconButton
                    aria-label={`Make Group Call in ${
                        groupMessageThread?.group?.name || 'this group'
                    }`}
                    icon={<Icon as={IoCallOutline} boxSize='6' />}
                    onClick={openSelectMemberDialog}
                />
            )) ||
                (existingMemberUserId.length === NO_MEMBERS_IN_GROUP && (
                    <IconButton
                        aria-label={`Make Group Call in ${
                            groupMessageThread?.group?.name || 'this group'
                        }`}
                        icon={<Icon as={IoCallOutline} boxSize='6' />}
                        onClick={() =>
                            toast({
                                title: 'No members in this group',
                                description:
                                    "You can't make a group call without members.",
                                status: 'error',
                                duration: 5000,
                                isClosable: true,
                            })
                        }
                    />
                )) ||
                (existingMemberUserId.length === ONE_MEMBER_IN_GROUP && (
                    <IconButton
                        aria-label={`Make Group Call in ${
                            groupMessageThread?.group?.name || 'this group'
                        }`}
                        icon={<Icon as={IoCallOutline} boxSize='6' />}
                        onClick={() =>
                            toast({
                                title: 'You can only make a group call with 2 members',
                                status: 'error',
                                duration: 5000,
                                isClosable: true,
                            })
                        }
                    />
                )) ||
                (groupMessageThread && (
                    <IconButton
                        aria-label={`Make Group Call in ${
                            groupMessageThread?.group?.name || 'this group'
                        }`}
                        icon={<Icon as={IoCallOutline} boxSize='6' />}
                        onClick={() => {
                            handleCallForExistingMember(
                                existingMemberUserId,
                                groupMessageThread?.group?.id,
                            );
                        }}
                    />
                ))}

            {messageThread && (
                <SelectMemberForGroupCallDialog
                    isOpen={isSelectMemberDialogOpen}
                    onClose={closeSelectMemberDialog}
                    messageThread={messageThread}
                    groupMessageThreadId={groupMessageThread.group.id}
                />
            )}
        </>
    );
};

export default GroupCallButton;
