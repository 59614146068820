import { BoxProps, HStack, Spacer, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';

import UserAvatar from '../../../components/UserAvatar';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { FeedMember } from '../../../models/FeedMember.model';
import { getUserDisplayName } from '../../../utils/user.utils';
import FeedMemberActionsMenu from './FeedMemberActionsMenu';

interface FeedMemberCardProps extends BoxProps {
  feedMember: FeedMember;
  currentUserMemberRecord: FeedMember | undefined;
  onMemberRemoved: () => any;
}

export const FeedMemberCard: FC<FeedMemberCardProps> = ({
  feedMember,
  currentUserMemberRecord,
  onMemberRemoved,
  ...props
}) => {
  const [currentUser] = useCurrentUser();
  const isCurrentUserAndMemberSame = feedMember.user?.id === currentUser?.id;

  return (
    <HStack spacing='4' w='full' {...props}>
      <UserAvatar user={feedMember.user} />
      <VStack align='start' spacing='0'>
        <HStack>
          <Text fontWeight='bold'>
            {feedMember.user && getUserDisplayName(feedMember.user)}
          </Text>
          {isCurrentUserAndMemberSame && (
            <Text as='span' color='gray' fontWeight='500'>
              (you)
            </Text>
          )}
        </HStack>
        <Text color='gray.500' fontSize='sm'>
          {feedMember.user?.email}
        </Text>
      </VStack>
      <Spacer />
      {!isCurrentUserAndMemberSame &&
        currentUserMemberRecord?.canModerateMembers && (
          <FeedMemberActionsMenu
            feedMember={feedMember}
            onMemberRemoved={onMemberRemoved}
          />
        )}
    </HStack>
  );
};
