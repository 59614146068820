import { useQuery } from 'react-query';
import _ from 'lodash';

import { getAllDashboardSections } from '../../../apis/dashboard-section.api';
import { DashboardSection } from '../../../models/DashboardSection.model';

const useDashboardSections = () => {
  const { data } = useQuery<DashboardSection[]>(
    'getAllDashboardSections',
    getAllDashboardSections,
  );

  const dashboardSections = data || [];
  const sortedDashboardSections = _.sortBy(dashboardSections, 'position');

  return [sortedDashboardSections];
};

export default useDashboardSections;
