import {
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { IoCameraOutline } from 'react-icons/io5';

interface CameraDialogProps extends Omit<ModalProps, 'children'> {
  onImageCapture: BlobCallback;
}

const CameraDialog: React.FC<CameraDialogProps> = ({
  onImageCapture,
  onClose,
  ...props
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const [videoStream, setVideoStream] = useState<MediaStream>();

  const releaseCamera = () => {
    videoStream?.getTracks().forEach((track) => track.stop());
  };

  useEffect(() => {
    const video = videoRef.current;
    if (video && videoStream) {
      video.srcObject = videoStream;
      video.play();
    }
  }, [videoStream]);

  useEffect(() => {
    const getUserVideo = async () => {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      setVideoStream(stream);
    };

    getUserVideo();
  }, []);

  const takePhoto = () => {
    if (!videoRef.current || !canvasRef.current) {
      return;
    }

    const canvas = canvasRef.current;
    const video = videoRef.current;

    const videoHeight = video.clientHeight;
    const videoWidth = video.clientWidth;

    canvas.height = videoHeight;
    canvas.width = videoWidth;

    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.drawImage(video, 0, 0, videoWidth, videoHeight);
      canvas.toBlob((blob) => {
        releaseCamera();
        onImageCapture(blob);
      });
    }
  };

  const handleModalClose = () => {
    releaseCamera();
    onClose();
  };

  return (
    <Modal {...props} onClose={handleModalClose}>
      <ModalOverlay />

      <ModalContent minH="2xl">
        <ModalHeader>
          <Text>Take a picture</Text>
        </ModalHeader>

        <ModalBody h="full" display="flex" alignItems="center">
          <video ref={videoRef} style={{ minWidth: '100%' }} />
          <canvas ref={canvasRef} style={{ display: 'none' }} />
        </ModalBody>

        <ModalFooter display="flex" justifyContent="center">
          <IconButton
            borderRadius="52"
            colorScheme="blue"
            aria-label="capture image button"
            boxSize="20"
            icon={<Icon as={IoCameraOutline} boxSize="14" />}
            onClick={takePhoto}
          ></IconButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CameraDialog;
