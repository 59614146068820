import { socket } from '../../config/socketIoInstance';

const kUserEvents = {
  joinUserRoom: 'users:join_room',
  userUnauthorized: 'users:unauthorized',
};

export const joinUserRoom = (userId: number) => {
  socket.emit(kUserEvents.joinUserRoom, { userId });
};

export const notifyUserUnauthorized = () => {
  socket.emit(kUserEvents.userUnauthorized);
};
