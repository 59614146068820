export const getDateDiffSecondsFromNow = (date: Date) => {
  const millisecondsInOneSecond = 1000;
  const diffInMilliseconds = new Date().getTime() - date.getTime();

  return Math.floor(diffInMilliseconds / millisecondsInOneSecond);
};

export const secondsToTime = (secs: number) => {
  const secondsInAnHour = 60 * 60;
  const hours = Math.floor(secs / secondsInAnHour);

  const secondsInAMinute = 60;

  /**
   * We want to get the remainder of the seconds after we consider the hours.
   * Example: if input is 3662 seconds, hour is 1 (3600 seconds) which leaves
   * extra 62 seconds. The new "minutes" will be calculated based on 62 seconds instead of
   * original 3662 seconds.
   */
  const extraSecondsAfterHour = secs % secondsInAnHour;
  const minutes = Math.floor(extraSecondsAfterHour / secondsInAMinute);

  /**
   * Just like above, we want to get the remainder of the seconds after we consider the minutes.
   * Example: if input is 3662 seconds, hour is 1 (3600 seconds) and minute is 1 (60 seconds),
   * which leaves extra 2 seconds. The new "seconds" will be calculated based on 2 seconds instead of
   * original 3662 seconds or 62 seconds.
   */
  const extraSecondsAfterMinute = extraSecondsAfterHour % secondsInAMinute;
  const seconds = Math.ceil(extraSecondsAfterMinute);

  return { hours, minutes, seconds };
};

// Here, duration is hour, minutes or seconds. We want to return a string
// that pads the duration with a 0 if it's less than 10 (e.g. "01", "02", "03")
const durationPadded = (duration: number) => {
  const maxDurationLength = 2;
  return String(duration).padStart(maxDurationLength, '0');
};

export const secondsToDuration = (secs: number) => {
  const { hours, minutes, seconds } = secondsToTime(secs);

  const minutesString = durationPadded(minutes);
  const secondsString = durationPadded(seconds);
  const minsAndSecs = `${minutesString}:${secondsString}`;

  // Avoiding situations like 00:03:21 (where an hour hasn't passed yet)
  if (!hours) {
    return minsAndSecs;
  }

  const hoursString = durationPadded(hours);
  return `${hoursString}:${minsAndSecs}`;
};

export const everySecond = (
  fn: Parameters<typeof setInterval>[0],
): NodeJS.Timeout => setInterval(fn, 1000);
