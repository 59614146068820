export enum EntityType {
    post = 'post',
    pages = 'pages',
  }
  
export interface View {
    id: number;
    entityType: EntityType;
    UserId: number;
    EntityId: number;
    details: string;
    createdAt: string;
    updatedAt: string;
}
