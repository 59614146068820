import { AlignToolbarButton } from '@udecode/plate';
import {
  MdFormatAlignLeft,
  MdFormatAlignCenter,
  MdFormatAlignRight,
  MdFormatAlignJustify,
} from 'react-icons/md';

export const AlignToolbarButtons: React.FC = () => {
  return (
    <>
      <AlignToolbarButton value='left' icon={<MdFormatAlignLeft />} />
      <AlignToolbarButton value='center' icon={<MdFormatAlignCenter />} />
      <AlignToolbarButton value='right' icon={<MdFormatAlignRight />} />
      <AlignToolbarButton value='justify' icon={<MdFormatAlignJustify />} />
    </>
  );
};
