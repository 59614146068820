import useCallAcceptedUpdate from './hooks/useCallAcceptedUpdate';
import useCallDisconnectedUpdate from './hooks/useCallDisconnectedUpdate';
import useCallMissedUpdate from './hooks/useCallMissedUpdate';
import useCallRejectedUpdate from './hooks/useCallRejectedUpdate';
import useCallSessionCreatedOnExistingCallUpdate from './hooks/useCallSessionCreatedOnExistingCallUpdate';
import useCallUnreacheableUpdate from './hooks/useCallUnreacheableUpdate';

const useCallSessionsUpdates = () => {
  useCallAcceptedUpdate();
  useCallRejectedUpdate();
  useCallUnreacheableUpdate();
  useCallMissedUpdate();
  useCallDisconnectedUpdate();
  useCallSessionCreatedOnExistingCallUpdate();
};

export default useCallSessionsUpdates;
