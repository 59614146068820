export enum CallParams {
  callId = 'callId',
}

const asParam = (param: string): string => `:${param}`;
const kCallPageRegex = /^\/call\/(\d+)$/;

export const buildCallPageRoute = (callId: string): string => {
  return CALL_PAGE_ROUTE.replace(asParam(CallParams.callId), callId);
};

export const CALL_PAGE_ROUTE = `/call/${asParam(CallParams.callId)}`;

export const isCallPageRoute = (route: string): boolean =>
  kCallPageRegex.test(route);
