import { IconButton } from '@chakra-ui/button';
import Icon from '@chakra-ui/icon';
import { MdExitToApp } from 'react-icons/md';
import { useHistory } from 'react-router';

import useCurrentUser from '../hooks/useCurrentUser';
import useDialog from '../hooks/useDialog';
import useLocalStorageCleanup from '../hooks/useLocalStorageCleanup';
import { LOGIN_PAGE_ROUTE } from '../routes/routeList';
import ConfirmationDialog from './ConfirmationDialog';

interface LogoutButtonProps {}

const LogoutButton: React.FC<LogoutButtonProps> = () => {
  const [
    isLogoutConfirmationDialogOpen,
    openLogoutConfirmationDialog,
    closeLogoutConfirmationDialog,
  ] = useDialog();
  const [currentUser] = useCurrentUser();
  const { cleanup } = useLocalStorageCleanup();
  const history = useHistory();

  if (!currentUser) {
    return null;
  }

  const handleLogout = () => {
    cleanup();
    closeLogoutConfirmationDialog();
    history.push(LOGIN_PAGE_ROUTE);
  };

  return (
    <>
      <IconButton
        aria-label="Logout"
        icon={<Icon as={MdExitToApp} boxSize="6" />}
        onClick={openLogoutConfirmationDialog}
      />
      <ConfirmationDialog
        onConfirmed={handleLogout}
        title="Logout"
        description="Are you sure you want to logout?"
        isOpen={isLogoutConfirmationDialogOpen}
        onClose={closeLogoutConfirmationDialog}
      />
    </>
  );
};

export default LogoutButton;
