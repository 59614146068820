import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQueryClient,
} from 'react-query';

import {
  getAllPostsForPublicFeed,
  getAllPosts,
} from '../../../../apis/posts.api';
import { FeedModel } from '../../../../models';
import { Post } from '../../../../models/Post.model';
import { ValueCallback } from '../../../../types/common.type';
import { PostsListQuery, QueryParamsType } from '../../../../types/post.type';

interface UseFeedPostsOptions {
  feed?: FeedModel;
  feedId: FeedModel['id'];
}

const kDefaultFeedPostQueryParams: QueryParamsType = {
  searchUserInput: '',
  startDate: '',
  endDate: '',
  sortOrder: '',
  page: 1,
  limit: 30,
};

type UseFeedPostsData = UseInfiniteQueryResult<PostsListQuery>['data'];

const getFeedPostsQueryKey = (feedId: FeedModel['id']) => [
  'getPostsPagination',
  feedId,
];

const useFeedPosts = ({
  feed,
  feedId,
}: UseFeedPostsOptions): [
  Post[],
  Omit<UseInfiniteQueryResult<PostsListQuery>, 'data'>
] => {
  const { data, ...queryResults } = useInfiniteQuery<PostsListQuery>(
    getFeedPostsQueryKey(feedId),
    ({ pageParam = kDefaultFeedPostQueryParams }) => {
      // useInfiniteQuery is not respecting the enabled flag so having to check here
      if (!feed) {
        return { posts: [], ...pageParam };
      }

      if (feed.isPublic) {
        return getAllPostsForPublicFeed(feedId.toString(), pageParam);
      }

      return getAllPosts(feedId.toString(), pageParam);
    },
    {
      enabled: !!feed,
      getNextPageParam: ({ posts, ...lastPage }) => ({
        ...lastPage,
      }),
    }
  );

  const [postsList, setPostsList] = useState<Post[]>([]);

  useEffect(() => {
    if (data?.pages?.length) {
      if (_.flatMap(data?.pages).length) {
        setPostsList(
          _.orderBy(
            _.uniqBy(
              _.flatMap(data?.pages, (p) => p.posts),
              ({ id }) => id
            ),
            (post) => new Date(post.updatedAt),
            'desc'
          )
        );
      }
    }
  }, [data]);

  return [postsList, queryResults];
};

export const useFeedPostsQueryClient = ({
  feedId,
}: Pick<UseFeedPostsOptions, 'feedId'>) => {
  const queryKey = getFeedPostsQueryKey(feedId);
  const queryClient = useQueryClient();

  const addFeedPost = useCallback<ValueCallback<Post>>(() => {
    queryClient.refetchQueries(queryKey);
  }, [queryKey, queryClient]);

  const updateFeedPost = useCallback<ValueCallback<Post>>(
    (postToUpdate) => {
      queryClient.setQueryData<UseFeedPostsData>(queryKey, (prevData) => {
        if (!prevData) {
          return prevData;
        }

        const updatedPages = _.map(prevData.pages, (page) => {
          const updatedPagePosts = _.map(page.posts, (post) => {
            if (post.id === postToUpdate.id) {
              return { ...post, ...postToUpdate };
            }

            return post;
          });

          return { ...page, posts: updatedPagePosts };
        });

        return { ...prevData, pages: updatedPages };
      });
    },
    [queryClient, queryKey]
  );

  return { addFeedPost, updateFeedPost };
};

export default useFeedPosts;
