import { Box, BoxProps } from '@chakra-ui/react';
import { Plate } from '@udecode/plate';

import { Page } from '../../../../../models/Page.model';
import { DeserializedPageContent } from '../../../types/page-content.type';
import useSlatePlugins from './hooks/useSlatePlugins';
import { getPageContentRendererId } from './utils/getPageContentRendererId.util';

interface PageContentProps extends Omit<BoxProps, 'value'> {
  pageId: Page['id'];
  deserializedPageContent: DeserializedPageContent;
}

export const PageContent: React.FC<PageContentProps> = ({
  pageId,
  deserializedPageContent,
  ...props
}) => {
  const plugins = useSlatePlugins();

  return (
    <Box maxW='full' w='full' {...props}>
      <Plate
        id={getPageContentRendererId(pageId)}
        value={deserializedPageContent}
        editableProps={{
          readOnly: true,
        }}
        plugins={plugins}
      />
    </Box>
  );
};
