import {
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  ListItem,
  Text,
  Spacer,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import _ from 'lodash';
import React, { useState, useCallback } from 'react';
import { MdSearch } from 'react-icons/md';
import { useQuery } from 'react-query';

import { getUsersPagination } from '../apis/users.api';
import { User } from '../models';
import { ValueCallback } from '../types/common.type';
import { sortByDate } from '../utils/sort-by-date';
import UserAvatar from './UserAvatar';
import { getUserDisplayName } from '../utils/user.utils';

type InputChangeHandler = React.ChangeEventHandler<HTMLInputElement>;
type SearchCallback = ValueCallback<string>;

type SelectedUser = User & { selectedAt: Date };
export type SelectedUserMap = Record<string, SelectedUser>;

interface SearchSelectMultipleUsersProps {
  selectedUsers: SelectedUserMap;
  setSelectedUsers: React.Dispatch<React.SetStateAction<SelectedUserMap>>;
  searchLabel?: string;
  shouldRenderUser?: ValueCallback<User, boolean>;
}

export const useSelectedUsers = (initialState: SelectedUserMap = {}) =>
  useState<SelectedUserMap>(initialState);

const SearchSelectMultipleUsers: React.FC<SearchSelectMultipleUsersProps> = ({
  selectedUsers,
  setSelectedUsers,
  shouldRenderUser,
  searchLabel,
}) => {
  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchQuery, setSearchQuery] = useState<string>();

  const { data: userSearchResults, isLoading: isSearchingUsers } = useQuery<
    User[]
  >(
    ['searchUsersForNewChat', searchQuery],
    () => getUsersPagination(searchQuery),
    { enabled: !!searchQuery }
  );

  // React Hook useCallback received a function whose dependencies are unknown. Pass an inline function instead  react-hooks/exhaustive-deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const performSearch = useCallback<SearchCallback>(
    _.debounce<SearchCallback>(setSearchQuery, 500),
    [setSearchQuery]
  );

  const onSearchInputChange: InputChangeHandler = (e) => {
    const searchInputValue = e.currentTarget.value?.trim();
    setSearchInputValue(searchInputValue);

    if (!_.isEmpty(searchInputValue)) {
      performSearch(searchInputValue);
    }
  };

  const toggleUserSelect = (user: User) => {
    setSelectedUsers((prevUsers) => {
      const wasUserSelected = !!prevUsers[user.id];
      if (wasUserSelected) {
        return _.omit(prevUsers, user.id);
      }

      const selectedAt = new Date();
      return { ...prevUsers, [user.id]: { ...user, selectedAt } };
    });
  };

  // Keep selected users at top
  const userSearchResultAndSelectedUsers = _.concat(
    _.values(selectedUsers).sort((a, b) =>
      sortByDate(a.selectedAt, b.selectedAt)
    ),
    userSearchResults || []
  );

  // Users selected could re-appear in search results if user changes search term
  // To avoid showing selected users twice,
  // we select the unique items from the concatenated array of search results and selected users
  const userListWithoutDuplicates = _.uniqBy(
    userSearchResultAndSelectedUsers,
    'id'
  );

  const canRenderUser: typeof shouldRenderUser = (user) => {
    // Always render if no predicate is provided
    return shouldRenderUser ? shouldRenderUser(user) : true;
  };

  return (
    <VStack spacing="6" align="start">
      <FormControl>
        <FormLabel>{searchLabel}</FormLabel>
        <InputGroup>
          <InputLeftElement>
            <Icon as={MdSearch} color="gray.300" boxSize="6" />
          </InputLeftElement>
          <Input
            placeholder="Search for a user..."
            onChange={onSearchInputChange}
            value={searchInputValue}
          />
        </InputGroup>
      </FormControl>

      {_.isEmpty(userListWithoutDuplicates) ? (
        !isSearchingUsers &&
        searchQuery && (
          <Text color="gray.500" fontSize="sm" pl="2">
            No users found
          </Text>
        )
      ) : (
        <UnorderedList spacing="4" w="full">
          {userListWithoutDuplicates.map((user) => (
            <React.Fragment key={user.id}>
              {canRenderUser(user) && (
                <ListItem
                  as={HStack}
                  cursor="pointer"
                  spacing="4"
                  w="full"
                  onClick={() => toggleUserSelect(user)}
                >
                  <UserAvatar user={user} size="sm" />
                  <Text fontWeight="bold">{getUserDisplayName(user)}</Text>
                  <Spacer />
                  <Checkbox
                    onChange={() => toggleUserSelect(user)}
                    colorScheme="green"
                    isChecked={!!selectedUsers[user.id]}
                  />
                </ListItem>
              )}
            </React.Fragment>
          ))}
        </UnorderedList>
      )}
    </VStack>
  );
};

export default SearchSelectMultipleUsers;
