import { axiosInstance } from '../config';
import {
  LoginDto,
  LoginResponseDto,
  RegisterDto,
  RegisterResponseDto,
} from '../dto/auth.dto';
import { User } from '../models';
import { getRequestData } from './utils/get-request-data.util';
import { getAuthorizationHeaders } from './utils/set-authorization-headers.util';

const rootUrl = '/auth';

export const login = async (loginData: LoginDto): Promise<LoginResponseDto> => {
  const endpointUrl = `${rootUrl}/login`;
  const res = await axiosInstance.post(endpointUrl, loginData);

  return res.data;
};

export const register = async (
  registerData: RegisterDto,
): Promise<RegisterResponseDto> => {
  const endpointUrl = `${rootUrl}/register`;
  const res = await axiosInstance.post(endpointUrl, registerData);

  return res.data;
};

export const getCurrentUser = async (): Promise<User> => {
  const url = `${rootUrl}/me`;

  return getRequestData<User>(
    axiosInstance.get(url, { headers: getAuthorizationHeaders() }),
  );
};
