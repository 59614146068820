import {
  getPluginType,
  isMarkActive,
  MarkToolbarButton as PlateMarkToolbarButton,
} from '@udecode/plate';

import useEditorState from '../../../hooks/useEditorState';
import ToolbarIcon from '../ToolbarIcon';

interface MarkToolbarButtonProps {
  mark: string;
  icon: React.ElementType;
}

export const MarkToolbarButton: React.FC<MarkToolbarButtonProps> = ({
  icon,
  mark,
}) => {
  const editor = useEditorState();

  return (
    <PlateMarkToolbarButton
      type={getPluginType(editor, mark)}
      icon={<ToolbarIcon isActive={isMarkActive(editor, mark)} as={icon} />}
    />
  );
};
