import React from 'react';
import { Grid, Text, VStack, Link } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/image';

import { BaseSection } from '../BaseSection';
import { IconSectionItemProps, IconSectionProps } from './types';
import useDashboardSectionLinks from '../../hooks/useDashboardSectionLinks';
import { Link as RouterLink } from 'react-router-dom';

const IconSectionItem: React.FC<IconSectionItemProps> = ({
    src: imgSrc,
    caption,
}) => {
    return (
        <VStack>
            <Image src={imgSrc} boxSize='14' />
            <Text
                fontSize='md'
                mt='-1'
                align='center'
                maxW='full'
                noOfLines={2}
            >
                {caption}
            </Text>
        </VStack>
    );
};

const IconSection: React.FC<IconSectionProps> = ({ dashboardSection }) => {
    const [dashboardSectionLinks] = useDashboardSectionLinks({
        dashboardSectionId: dashboardSection.id,
    });

    const body = (
        <Grid templateColumns='repeat(4, 1fr)' gap={8}>
            {dashboardSectionLinks.map(link =>
                link.isExternal ? (
                    <Link
                        href={link.linkUrl}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <IconSectionItem
                            key={link.id}
                            src={link.imageUrl}
                            caption={link.linkText}
                        />
                    </Link>
                ) : (
                    <Link href={link.linkUrl}>
                        <IconSectionItem
                            key={link.id}
                            src={link.imageUrl}
                            caption={link.linkText}
                        />
                    </Link>
                ),
            )}
        </Grid>
    );

    return <BaseSection gap={6} title={dashboardSection.title} body={body} />;
};

export default IconSection;
