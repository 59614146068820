import { AvatarProps } from '@chakra-ui/avatar';

import useCurrentUser from '../hooks/useCurrentUser';
import UserAvatar from './UserAvatar';

const CurrentUserAvatar: React.FC<Omit<AvatarProps, 'name'>> = props => {
  const [currentUser] = useCurrentUser();

  return <UserAvatar user={currentUser} {...props} />;
};

export default CurrentUserAvatar;
