import { Flex, Image as ChakraImage } from '@chakra-ui/react';
import { PlateRenderElementProps } from '@udecode/plate';
import { ImageElement as ImageNodeData } from 'slate';

import { getDocumentUrl } from 'src/config/document-url.config';

const ImageElement = ({
  attributes,
  children,
  element,
}: PlateRenderElementProps<ImageNodeData>) => {
  const { width, height, url, align } = element;

  return (
    <Flex
      {...attributes}
      position='relative'
      w='full'
      my='1'
      justifyContent={align}
      contentEditable={false}
    >
      <ChakraImage
        h={`${height}px`}
        w={`${width}px`}
        src={getDocumentUrl(url)}
      />
      {children}
    </Flex>
  );
};

export default ImageElement;
