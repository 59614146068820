import { FormErrorMessage } from '@chakra-ui/form-control';
import React from 'react';
import { FieldError } from 'react-hook-form';

interface ErrorMessageProps {
  error?: FieldError;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ error }) => {
  return error ? <FormErrorMessage>{error.message}</FormErrorMessage> : null;
};

export default ErrorMessage;
