import { useContext, useEffect } from 'react';
import { QueryLoaderContext } from 'src/hoc/hooks/query-context/query-loader-context-provider';

export const useDisableLoader = () => {
  const { disableLoader, enableLoader } = useContext(QueryLoaderContext);

  useEffect(() => {
    disableLoader && disableLoader();
    return () => enableLoader();
  }, []);
};
