import { useParams } from 'react-router-dom';

export interface PageParams {
  id: string;
}

interface UsePageParamsReturn {
  id: number;
}

const usePageParams = (): UsePageParamsReturn => {
  const { id } = useParams<PageParams>();

  return { id: Number(id) };
};

export default usePageParams;
