import { useQuery, UseQueryResult } from 'react-query';

import { getOnePage } from '../../../../apis/pages.api';
import { Page } from '../../../../models/Page.model';
import usePageParams from './usePageParams';

const getPageQueryKey = (id: Page['id']) => [`getPage-${id}`, id];

type UsePageReturn = Omit<UseQueryResult, 'data'> & {
  page: Page | undefined;
};

const usePage = (): UsePageReturn => {
  const { id } = usePageParams();
  const { data, ...queryMetadata } = useQuery(
    getPageQueryKey(id),
    () => getOnePage(id),
    {
      cacheTime: 1,
    },
  );

  return { page: data, ...queryMetadata };
};

export default usePage;
