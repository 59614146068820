import { Box, Center } from '@chakra-ui/react';
import { maxWidth } from 'src/constants/max-width.constant';
import { useDisableLoader } from 'src/hooks/useDisableLoader';

import CallPageBody from './components/body/CallPageBody';
import CallPageFooter from './components/footer';
import CallPageHeader from './components/header';
import PeerConnectionProvider from './context/peer-connection/provider';
import useCallParams from './hooks/useCallParams';
import useCallSessionsUpdates from './hooks/useCallSessionUpdates';
import useCallValidator from './hooks/useCallValidator';

interface CallPageProps {}

const CallPage: React.FC<CallPageProps> = () => {
  useCallSessionsUpdates();
  useCallValidator();
  useDisableLoader();

  return (
    <Center>
      <Box h='100vh' maxH='100vh' w='full' maxW={maxWidth} color='white'>
        <CallPageHeader />
        <PeerConnectionProvider>
          <CallPageBody />
          <CallPageFooter />
        </PeerConnectionProvider>
      </Box>
    </Center>
  );
};

const CallPageWrapper = () => {
  const { callId } = useCallParams();

  return <CallPage key={`call-page-${callId}`} />;
};

export default CallPageWrapper;
