import { useCallback, useEffect } from 'react';

import {
  onStatusUpdated,
  unsubscribeStatusUpdated,
  onMultipleStatusUpdated,
  unsubscribeMultipleStatusUpdated,
} from '../../../apis/socket.io/messages.socket.io';
import useMessagesDataMethods from './useMessagesDataMethods';

const useMessageStatusUpdateObserver = (messageThreadId: number) => {
  const { updatePartial, updateMultiplePartial } =
    useMessagesDataMethods(messageThreadId);

  const handleMessageStatusUpdate = useCallback<
    Parameters<typeof onStatusUpdated>[0]
  >(updatePartial, [updatePartial]);

  useEffect(
    () => {
      onStatusUpdated(handleMessageStatusUpdate);

      return () => unsubscribeStatusUpdated(handleMessageStatusUpdate);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleMultipleStatusUpdates = useCallback<
    Parameters<typeof onMultipleStatusUpdated>[0]
  >(updateMultiplePartial, [updateMultiplePartial]);

  useEffect(
    () => {
      onMultipleStatusUpdated(handleMultipleStatusUpdates);

      return () =>
        unsubscribeMultipleStatusUpdated(handleMultipleStatusUpdates);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
};

export default useMessageStatusUpdateObserver;
