import { MdVideocam, MdVideocamOff } from 'react-icons/md';

import { useUserMedia } from '../context/user-media/user-media.context';
import FooterIconButton from './FooterIconButton';

interface CallPageVideoButtonProps {
  isVideoDisabled: boolean;
}

const CallPageVideoButton: React.FC<CallPageVideoButtonProps> = ({
  isVideoDisabled,
}) => {
  const { toggleVideo, isVideoMuted } = useUserMedia();

  return (
    <FooterIconButton
      activeIcon={MdVideocam}
      inactiveIcon={MdVideocamOff}
      onToggleClick={toggleVideo}
      isActive={!isVideoMuted}
      aria-label='Toggle video'
      disabled={isVideoDisabled}
    />
  );
};

export default CallPageVideoButton;
