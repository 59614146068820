import { Container, HStack, Text } from '@chakra-ui/layout';
import {
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    TabProps,
    TabPanelProps,
} from '@chakra-ui/tabs';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import NumberBadge from '../../components/NumberBadge';

import HeaderWrapper from '../../hoc/HeaderWrapper';
import useUnreadGroupChatsCount from '../../hooks/useUnreadGroupChatsCount';
import useUnreadPersonalChatsCount from '../../hooks/useUnreadPersonalChatsCount';
import GroupMessagesPanel from './panels/GroupMessagePanel';
import PersonalMessagesPanel from './panels/PersonalMessagesPanel';

interface MessagePanelTabProps extends TabProps {
    unreadChatsCount: number;
}

const MessagePanelTab: React.FC<MessagePanelTabProps> = ({
    unreadChatsCount,
    children,
    ...props
}) => (
    <Tab
        as={HStack}
        alignItems='center'
        positon='relative'
        m='1'
        fontWeight='bold'
        color='gray.500'
        borderRadius='10'
        cursor='pointer'
        _selected={{
            color: 'black',
            bg: 'white',
            // boxShadow: xAxisOffset yAxisOffset blur spread color
            boxShadow: '0 0.2rem 0.5rem -0.1rem grey',
        }}
        {...props}
    >
        {children}
        {unreadChatsCount && (
            <NumberBadge
                minW='6'
                maxW='10'
                fontSize='xs'
                fontWeight='bold'
                color='white'
                bg='blue.500'
                borderRadius='full'
                py='0.5'
                px='1'
                displayNumber={unreadChatsCount}
            />
        )}
    </Tab>
);

const MessagesPage = () => {
    const unreadPersonalThreadsCount = useUnreadPersonalChatsCount();
    const unreadGroupThreadsCount = useUnreadGroupChatsCount();

    const tabPanelProps: TabPanelProps = {
        position: 'relative',
        px: '0',
        pt: '8',
        h: {
            base: '75vh',
            md: '68vh',
        },
    };

    const kTabIndexForChat = 'kTabIndexForChat';

    const [tabIndex, setTabIndex] = useState<number>(0);

    const handleTabChange = (indexValue: number) => {
        setTabIndex(indexValue);
        localStorage.setItem(kTabIndexForChat, indexValue.toString());
    };

    useEffect(() => {
        localStorage.getItem(kTabIndexForChat) &&
            setTabIndex(
                _.toNumber(localStorage.getItem(kTabIndexForChat) || '0'),
            );
    }, []);

    return (
        <HeaderWrapper pageTitle='Messages'>
            <Container my='8'>
                <Tabs
                    isFitted
                    isLazy
                    variant='unstyled'
                    index={tabIndex}
                    onChange={handleTabChange}
                >
                    <TabList bg='gray.100' borderRadius='10' mx='3'>
                        <MessagePanelTab
                            unreadChatsCount={unreadPersonalThreadsCount}
                        >
                            <Text>Personal</Text>
                        </MessagePanelTab>
                        <MessagePanelTab
                            unreadChatsCount={unreadGroupThreadsCount}
                        >
                            <Text>Groups</Text>
                        </MessagePanelTab>
                    </TabList>

                    <TabPanels>
                        <TabPanel {...tabPanelProps}>
                            <PersonalMessagesPanel />
                        </TabPanel>
                        <TabPanel {...tabPanelProps}>
                            <GroupMessagesPanel />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Container>
        </HeaderWrapper>
    );
};

export default MessagesPage;
