import { Text } from '@chakra-ui/layout';
import { ModalProps } from '@chakra-ui/modal';
import { useMutation } from 'react-query';
import { removeFeedMember } from '../../../apis/feedsMembers.api';

import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { FeedMember } from '../../../models/FeedMember.model';
import { getUserDisplayName } from '../../../utils/user.utils';

interface ConfirmRemoveMemberDialogProps extends Omit<ModalProps, 'children'> {
  memberToRemove: FeedMember;
  onMemberRemoved: () => any;
}

const ConfirmRemoveFeedMemberDialog: React.FC<ConfirmRemoveMemberDialogProps> =
  ({ memberToRemove, onMemberRemoved, ...props }) => {
    const { mutate: removeMember, isLoading: isRemovingMember } = useMutation(
      ['removeFeedMember', memberToRemove.id],
      () => removeFeedMember(memberToRemove.FeedId, memberToRemove.id),
      { onSuccess: onMemberRemoved },
    );

    const memberName =
      memberToRemove.user && getUserDisplayName(memberToRemove.user);

    const confirmationDescription = (
      <Text>
        Are you sure you want to remove{' '}
        <Text as='span' fontWeight={memberName && 'bold'}>
          {memberName || 'this member'}
        </Text>{' '}
        from the feed?
      </Text>
    );

    return (
      <ConfirmationDialog
        title='Remove Member'
        description={confirmationDescription}
        confirmLabel='Remove Member'
        confirmButtonProps={{ isLoading: isRemovingMember }}
        onConfirmed={() => removeMember()}
        {...props}
      />
    );
  };

export default ConfirmRemoveFeedMemberDialog;
