import { Badge, BadgeProps } from '@chakra-ui/react';

import { Post } from '../../../models/Post.model';

const CustomBadge: React.FC<BadgeProps> = props => (
  <Badge fontSize='xs' {...props} />
);

const PostApprovalStatusBadge: React.FC<{ post: Post }> = ({ post }) => {
  if (post.isApproved === null) {
    return <CustomBadge colorScheme='cyan'>Awaiting Approval</CustomBadge>;
  }

  return post.isApproved ? (
    <CustomBadge colorScheme='green'>Approved</CustomBadge>
  ) : (
    <CustomBadge colorScheme='red'>Rejected</CustomBadge>
  );
};

export default PostApprovalStatusBadge;
