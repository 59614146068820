import { Icon, IconButton } from '@chakra-ui/react';
import { BiPhoneCall } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { CALLS_PAGE_ROUTE } from '../../../routes/routeList';

const CallButtonIcon: React.FC = () => {
  return <Icon as={BiPhoneCall} boxSize='6' />;
};

const CallButton: React.FC = () => {
  return (
    <IconButton
      as={Link}
      to={CALLS_PAGE_ROUTE}
      position='relative'
      aria-label='Call'
      variant='ghost'
      w='full'
      _focus={{
        boxShadow: 'none',
      }}
      borderRight='1px solid'
      borderColor='gray.200'
      _hover={{
        background: 'none',
      }}
      size='lg'
      p='0'
      icon={<CallButtonIcon />}
    />
  );
};

export default CallButton;
