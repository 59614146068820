import { Text, TextProps } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { CallSession } from 'src/models/CallSession.model';

enum CallConnectionStatusInfo {
  connecting = 'Connecting...',
  unreachable = 'User is unreachable',
  missed = 'Call was missed',
  rejected = 'Call was rejected',
}

interface CallConnectionStatusProps extends TextProps {
  callSession: CallSession;
}

const CallConnectionStatus: React.FC<CallConnectionStatusProps> = ({
  callSession,
  ...props
}) => {
  const [status, setStatus] = useState<CallConnectionStatusInfo>(
    CallConnectionStatusInfo.connecting,
  );

  useEffect(() => {
    const { isUnreachable, hasMissed, hasRejected } = callSession;

    if (isUnreachable) {
      setStatus(CallConnectionStatusInfo.unreachable);
    }

    if (hasMissed) {
      setStatus(CallConnectionStatusInfo.missed);
    }

    if (hasRejected) {
      setStatus(CallConnectionStatusInfo.rejected);
    }
  }, [callSession]);

  return <Text {...props}>{status}</Text>;
};

export default CallConnectionStatus;
