import { useEffect, useState } from 'react';
import usePage from 'src/pages/blogs/blog/hooks/usePage';
import { EditablePageContent } from '../types/EditablePageContent.type';

const useReviewContent = () => {
  const { page } = usePage();

  const [draft, setDraft] = useState<EditablePageContent>();

  useEffect(() => {
    if (page && page.draft) {
      setDraft(JSON.parse(page.draft));
    }
  }, [page]);

  return { draft, setDraft };
};

export default useReviewContent;
