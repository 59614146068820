import { axiosInstance } from '../config';
import {
  GetAllMessagesDto,
  MediaMessageDto,
  MessageStatusUpdateDto,
} from '../dto/messages.dto';
import { AsDto } from '../dto/model-as.dto';
import { Message } from '../models/Message.model';
import { kMessageThreadsUrl } from './message-threads.api';
import { getRequestData } from './utils/get-request-data.util';

const kMessageThreadsUrlIdParam = ':messageThreadId';

// Sample URL: /message-threads/123/messages
const kMessagesUrl = `${kMessageThreadsUrl}/${kMessageThreadsUrlIdParam}/messages`;

const getMessagesUrl = (messageThreadId: number) =>
  kMessagesUrl.replace(kMessageThreadsUrlIdParam, messageThreadId.toString());

export const getMessages = async (
  messageThreadId: number,
): Promise<Message[]> => {
  const url = getMessagesUrl(messageThreadId);

  const messages = await getRequestData<GetAllMessagesDto>(
    axiosInstance.get(url),
  );
  return messages.map<Message>(message => ({
    ...message,
    messageAt: new Date(message.messageAt),
    createdAt: new Date(message.createdAt),
  }));
};

export const createTextMessage = async (
  messageThreadId: number,
  text: string,
) => {
  const url = getMessagesUrl(messageThreadId);

  const data = {
    text,
    messageAt: new Date().toISOString(),
  };

  return axiosInstance.post(url, data);
};

export const createMediaMessage = async (
  messageThreadId: number,
  dto: MediaMessageDto,
) => {
  const url = getMessagesUrl(messageThreadId) + '/upload';

  const formData = new FormData();
  formData.append('messageAt', new Date().toISOString());

  if (dto.caption) {
    formData.append('text', dto.caption);
  }

  for (let file of dto.messageMedia) {
    formData.append('messageMedia', file);
  }

  return axiosInstance.post(url, formData);
};

export const updateMessageStatus = async (
  messageThreadId: number,
  messageId: number,
  updatedStatus: MessageStatusUpdateDto['status'],
): Promise<Message> => {
  const url = `${getMessagesUrl(messageThreadId)}/${messageId}/status`;
  const data: MessageStatusUpdateDto = {
    status: updatedStatus,
  };

  const updatedMessage = await getRequestData<AsDto<Message>>(
    axiosInstance.patch(url, data),
  );

  return {
    ...updatedMessage,
    messageAt: new Date(updatedMessage.messageAt),
    createdAt: new Date(updatedMessage.createdAt),
    updatedAt: new Date(updatedMessage.updatedAt),
  };
};
