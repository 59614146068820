import { BoxProps, HStack } from '@chakra-ui/layout';
import {
  IconButton,
  Icon,
  Image,
  ImageProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';

import { downloadFile } from '../../../../utils/download-file.utils';

interface ImageMediaProps extends BoxProps {
  mediaUrl: string;
  mimeType: string;
}

const ImageMedia: React.FC<ImageMediaProps> = ({
  mediaUrl,
  mimeType,
  ...props
}) => {
  const anchorRef = useRef<HTMLAnchorElement>(null);
  const handleOnDownloadClick = () =>
    downloadFile({ anchorRef, url: mediaUrl, mimeType });

  return (
    <HStack
      maxH='100%'
      minW='full'
      w='full'
      alignItems='start'
      spacing='0'
      p='0'
      {...props}
    >
      <Image
        flexGrow={1}
        minW='full'
        src={mediaUrl}
        {...(props as ImageProps)}
      />

      <Menu autoSelect={false}>
        <MenuButton
          as={IconButton}
          minW='0'
          height='unset'
          aria-label='more image actions'
          icon={<Icon p='0' boxSize='6' as={MdKeyboardArrowDown} />}
          variant='ghost'
          mr='-2'
          pl='1'
        />
        <MenuList>
          <MenuItem onClick={handleOnDownloadClick}>Download</MenuItem>
        </MenuList>
      </Menu>
      <a href={mediaUrl} ref={anchorRef} style={{ display: 'none' }} download>
        .
      </a>
    </HStack>
  );
};

export default ImageMedia;
