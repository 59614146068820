import { Text } from '@chakra-ui/react';

import usePersonalCallPeerName from './hook/usePersonalCallPeerName';

interface PersonalCallTitleProps {}

const PersonalCallTitle: React.FC<PersonalCallTitleProps> = () => {
  const otherPersonName = usePersonalCallPeerName();

  return (
    <Text fontWeight='bold' fontSize='x-large'>
      {otherPersonName}
    </Text>
  );
};

export default PersonalCallTitle;
