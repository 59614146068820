import {
  Box,
  Flex,
  Heading,
  Icon,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react';
import UserAvatar from '../../../components/UserAvatar';
import HeaderWrapper from '../../../hoc/HeaderWrapper';
import { getUserDisplayName } from '../../../utils/user.utils';

import usePage from './hooks/usePage';
import usePageContent from './hooks/usePageContent';
import { PageContent } from './components/page-content';
import usePageParams from './hooks/usePageParams';
import { MdGroup } from 'react-icons/md';
import {
  DRAFT_REQUEST_PAGE_ROUTE,
  PAGE_CONTENT_PAGE_ROUTE,
  PAGE_MEMBERS_PAGE_ROUTE,
} from 'src/routes/routeList';
import { Link, useParams } from 'react-router-dom';
import { BsFillPencilFill } from 'react-icons/bs';
import _ from 'lodash';
import useCurrentUser from 'src/hooks/useCurrentUser';
import usePageMembers from 'src/pages/page-members/hooks/usePageMembers';

const BlogPage: React.FC = () => {
  const { id: pageIdForLink }: { id: string } = useParams();

  const { id: pageId } = usePageParams();
  const { page } = usePage();
  const { content } = usePageContent({ page });

  const { pageMembers } = usePageMembers({
    pageId,
  });

  const [currentUser] = useCurrentUser();

  const currentUserMemberRecord = _.find(
    pageMembers,
    member => member.UserId === currentUser?.id,
  );

  if (!page || !content) {
    return null;
  }

  return (
    <HeaderWrapper pageTitle={`Page - ${page.title}`}>
      <VStack align='start' w='full' p='4' spacing='4'>
        <Flex
          justify='column'
          alignContent='center'
          justifyContent='space-between'
        >
          <Heading fontSize='xl' noOfLines={1}>
            <Flex align='baseline'>
              <Flex mr='2'>{page.title}</Flex>
              <Flex>
                {currentUserMemberRecord?.canModerateUpdates && (
                  <Link
                    to={PAGE_CONTENT_PAGE_ROUTE.replace(':id', pageIdForLink)}
                  >
                    <IconButton
                      colorScheme='blue'
                      variant='ghost'
                      size='sm'
                      aria-label='view members of page'
                      icon={<Icon as={BsFillPencilFill} boxSize='5' />}
                    >
                      Edit Page Content
                    </IconButton>
                  </Link>
                )}
                {currentUserMemberRecord?.canUpdate && (
                  <Link
                    to={DRAFT_REQUEST_PAGE_ROUTE.replace(':id', pageIdForLink)}
                  >
                    <IconButton
                      colorScheme='blue'
                      variant='ghost'
                      size='sm'
                      aria-label='view members of page'
                      icon={<Icon as={BsFillPencilFill} boxSize='5' />}
                    >
                      Draft Page Member
                    </IconButton>
                  </Link>
                )}
              </Flex>
            </Flex>
          </Heading>
        </Flex>
        {page.createdBy && (
          <Flex width='full'>
            <Flex justifyContent='left' flex='1' alignItems='center'>
              <UserAvatar user={page.createdBy} size='sm' mr='2' />
              <Text fontSize='sm'>
                By
                <Text as='span' ml='1.5' fontWeight='semibold'>
                  {getUserDisplayName(page.createdBy)}
                </Text>
              </Text>
            </Flex>
            <Flex justifyContent='right'>
              <Link to={PAGE_MEMBERS_PAGE_ROUTE.replace(':id', pageIdForLink)}>
                <IconButton
                  colorScheme='blue'
                  variant='ghost'
                  aria-label='view members of page'
                  icon={<Icon as={MdGroup} boxSize='8' />}
                  mx='4'
                >
                  View members
                </IconButton>
              </Link>
            </Flex>
          </Flex>
        )}
        <Box w='full' h='0.5' bg='gray.300' />
        <PageContent pageId={pageId} deserializedPageContent={content} />
      </VStack>
    </HeaderWrapper>
  );
};

export default BlogPage;
