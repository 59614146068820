import {
  Avatar,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Icon,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Radio,
  RadioGroup,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { BiCamera } from 'react-icons/bi';
import { FaUserCircle } from 'react-icons/fa';
import { useMutation } from 'react-query';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import * as _ from 'lodash';

import { UpdateUserType } from '../../../types/user.type';
import {
  removeCurrentUserAvatar,
  updateUser,
  updateUserAvatar,
} from '../../../apis/users.api';
import HeaderWrapper from '../../../hoc/HeaderWrapper';
import useCurrentUser, {
  useCurrentUserQueryClient,
} from '../../../hooks/useCurrentUser';
import { MdEdit } from 'react-icons/md';
import LoadingMenuItem from '../../message-members/components/LoadingMenuItem';

const schema = yup.object().shape({
  firstName: yup.string().required('Please enter your first name'),
  lastName: yup.string().required('Please enter your last name'),
  email: yup.string().email().required('Please provide an email'),
  address1: yup.string().nullable(),
  address2: yup.string().nullable(),
  city: yup.string().nullable(),
  state: yup.string().nullable(),
  postalAreaCode: yup.number().nullable(),
  country: yup.string().nullable(),
  phoneNumber: yup
    .string()
    .optional()
    .nullable()
    .typeError('Please provide a valid phone number'),
  gender: yup.string().required('Please select your gender'),
});

const MyProfilePage = () => {
  const [user] = useCurrentUser();
  const { refetchCurrentUser } = useCurrentUserQueryClient();
  const [avatarSrc, setAvatarSrc] = useState<string>('');
  const showToast = useToast();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<UpdateUserType>({
    defaultValues: { ...user },
    resolver: yupResolver(schema),
  });

  const updateImageMutation = useMutation(
    ({ image, id }: { image: File; id: string }) => updateUserAvatar(id, image),
  );

  const removeAvatarMutation = useMutation(removeCurrentUserAvatar, {
    onSuccess: () => {
      showToast({
        title: 'Success',
        description: 'Avatar removed successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setAvatarSrc('');
      refetchCurrentUser();
    },
    onError: () => {
      showToast({
        title: 'Something went wrong',
        description: 'Could not remove avatar',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const avatarFile = _.head(e.target.files);
    if (avatarFile && user) {
      const reader = new FileReader();
      reader.onload = () => {
        setAvatarSrc(
          (prevAvatar: string) => reader.result?.toString() || prevAvatar,
        );
      };

      updateImageMutation.mutate(
        { image: avatarFile, id: user.id.toString() },
        {
          onSuccess: () => {
            // Update avatar src only once we have successfully uploaded the image
            const avatarFile = _.head(e.target.files);
            if (avatarFile) {
              reader.readAsDataURL(avatarFile);
            }

            showToast({
              title: 'Avatar updated successfully',
              duration: 3000,
              isClosable: true,
              status: 'success',
              position: 'bottom',
            });
          },
          onError: () => {
            showToast({
              title: 'Error updating avatar',
              duration: 3000,
              isClosable: true,
              status: 'error',
              position: 'bottom',
            });
          },
        },
      );
    }
  };

  const updateUserMutation = useMutation(
    ({ user, id }: { user: UpdateUserType; id: string }) =>
      updateUser(id, user),
  );

  const formSubmitHandle = (data: UpdateUserType) => {
    updateUserMutation.mutate(
      { user: data, id: data.id.toString() },
      {
        onSuccess: () => {
          showToast({
            title: 'Profile updated successfully',
            duration: 3000,
            isClosable: true,
            status: 'success',
            position: 'bottom',
          });
          reset(data);
        },
        onError: () => {
          showToast({
            title: 'Error updating profile',
            duration: 3000,
            isClosable: true,
            status: 'error',
            position: 'bottom',
          });
        },
      },
    );
  };

  useEffect(() => {
    reset(user);
    if (user?.avatarUrl) {
      setAvatarSrc(user.avatarUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <HeaderWrapper pageTitle='Profile'>
      <Flex
        my='2'
        mx='2'
        alignItems='center'
        flexDir='column'
        bg='gray.100'
        h='12rem'
        justifyContent='center'
      >
        {avatarSrc || user?.avatarUrl ? (
          <Avatar
            src={avatarSrc || user?.avatarUrl}
            boxSize='10rem'
            maxH='64'
            position='relative'
            bg='white'
          >
            <Menu closeOnSelect={false}>
              <MenuButton
                as={IconButton}
                aria-label='edit avatar'
                position='absolute'
                p='0'
                right='0'
                bottom='0'
                colorScheme='blue'
                rounded='full'
              >
                <Icon as={MdEdit} boxSize='5' />
              </MenuButton>
              <MenuList>
                <MenuItem cursor='pointer'>
                  <FormLabel
                    htmlFor='input:update-avatar'
                    m='0'
                    cursor='pointer'
                    w='full'
                  >
                    <Input
                      type='file'
                      id='input:update-avatar'
                      accept='image/*'
                      display='none'
                      onChange={handleImageUpload}
                    />
                    <Text as='span'>Update Avatar</Text>
                  </FormLabel>
                </MenuItem>
                <LoadingMenuItem
                  isLoading={removeAvatarMutation.isLoading}
                  color='red'
                  fontSize='md'
                  onClick={() => removeAvatarMutation.mutate()}
                >
                  <Text textTransform='none'>Remove Avatar</Text>
                </LoadingMenuItem>
              </MenuList>
            </Menu>
          </Avatar>
        ) : (
          <Flex
            justifyContent='center'
            py='8'
            w='full'
            bg='gray.100'
            position='relative'
          >
            <FaUserCircle fontSize='12rem' style={{ position: 'relative' }} />
            <FormLabel
              htmlFor='upload-button'
              m='0'
              position='relative'
              p='0'
              rounded='full'
            >
              <Input
                type='file'
                id='upload-button'
                accept='image/*'
                display='none'
                onChange={handleImageUpload}
              />
              <IconButton
                as='span'
                aria-label='image'
                rounded='full'
                colorScheme='blue'
                right='3'
                bottom='3'
                position='absolute'
              >
                <BiCamera />
              </IconButton>
            </FormLabel>
          </Flex>
        )}
      </Flex>
      <form onSubmit={handleSubmit(formSubmitHandle)}>
        <Grid gap='2' mx='2'>
          <FormControl isInvalid={!!errors.firstName}>
            <FormLabel>First name</FormLabel>
            <Input {...register('firstName')} />
            <FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.lastName}>
            <FormLabel>Last name</FormLabel>
            <Input {...register('lastName')} />
            <FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.email}>
            <FormLabel>Email</FormLabel>
            <Input {...register('email')} type='email' />
            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.phoneNumber}>
            <FormLabel>Phone number</FormLabel>
            <Input {...register('phoneNumber')} />
            <FormErrorMessage>{errors.phoneNumber?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={!!errors.gender}>
            <FormLabel>Gender</FormLabel>
            <Controller
              name='gender'
              control={control}
              render={({ field }) => (
                <RadioGroup {...field} my='2'>
                  <Radio mr='2' value='Female' name='gender'>
                    Female
                  </Radio>
                  <Radio value='Male' name='gender' ml='2'>
                    Male
                  </Radio>
                </RadioGroup>
              )}
            />
            <FormErrorMessage>{errors.gender?.message}</FormErrorMessage>
          </FormControl>

          <FormControl gridColumn='1' isInvalid={!!errors.address1}>
            <FormLabel>Address Line one</FormLabel>
            <Textarea {...register('address1')} />
            <FormErrorMessage>{errors.address1?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.address2}>
            <FormLabel>Address Line two</FormLabel>
            <Textarea {...register('address2')} />
            <FormErrorMessage>{errors.address2?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.city}>
            <FormLabel>City</FormLabel>
            <Input {...register('city')} />
            <FormErrorMessage>{errors.city?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.state}>
            <FormLabel>State</FormLabel>
            <Input {...register('state')} />
            <FormErrorMessage>{errors.state?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.postalAreaCode}>
            <FormLabel>Postal Area Code</FormLabel>
            <Input {...register('postalAreaCode')} />
            <FormErrorMessage>
              {errors.postalAreaCode?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.country}>
            <FormLabel>Country</FormLabel>
            <Input {...register('country')} />
            <FormErrorMessage>{errors.country?.message}</FormErrorMessage>
          </FormControl>
          <Flex gridColumn='1/-1' justifyContent='center' my='2'>
            <Button type='submit' mx='1' colorScheme='blue'>
              Save
            </Button>
            <Button
              mx='1'
              colorScheme='red'
              onClick={() => reset()}
              variant='outline'
            >
              Reset
            </Button>
          </Flex>
        </Grid>
      </form>
    </HeaderWrapper>
  );
};

export default MyProfilePage;
