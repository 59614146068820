import {
  createPlugins,
  createParagraphPlugin,
  createListPlugin,
  createBoldPlugin,
  createItalicPlugin,
  createUnderlinePlugin,
  createCodePlugin,
  createImagePlugin,
  createAlignPlugin,
  createSelectOnBackspacePlugin,
  ELEMENT_IMAGE,
  createPlateUI,
  createLinkPlugin,
  createMediaEmbedPlugin,
  ELEMENT_MEDIA_EMBED,
} from '@udecode/plate';
import { useMemo } from 'react';
import ImageElement from '../plugins/image/image-element';
import MediaEmbedElement from '../plugins/media-embed/media-embed-element';

const useSlatePlugins = () => {
  return useMemo(
    () =>
      createPlugins(
        [
          // mark plugins
          createBoldPlugin(),
          createItalicPlugin(),
          createUnderlinePlugin(),
          createCodePlugin(),

          // block (element) plugins
          createParagraphPlugin(),
          createListPlugin(),
          createImagePlugin(),
          createMediaEmbedPlugin(),
          createAlignPlugin(),
          createLinkPlugin(),

          // util plugins
          createSelectOnBackspacePlugin({
            options: {
              query: {
                allow: [ELEMENT_IMAGE, ELEMENT_MEDIA_EMBED],
              },
            },
          }),
        ],
        {
          components: createPlateUI({
            [ELEMENT_IMAGE]: ImageElement,
            [ELEMENT_MEDIA_EMBED]: MediaEmbedElement,
          }),
        },
      ),
    [],
  );
};

export default useSlatePlugins;
