import { Box, Flex, Icon, List } from '@chakra-ui/react';
import * as _ from 'lodash';

import UserAvatar from '../../../components/UserAvatar';
import CallItem from '../components/CallItem';
import usePersonalCalls from '../hooks/usePersonalCalls';
import { getUserDisplayName } from 'src/utils/user.utils';
import FloatingActionButton from 'src/components/FloatingActionButton';
import { MdAddIcCall } from 'react-icons/md';
import useDialog from 'src/hooks/useDialog';
import SelectUserToCallModal from '../components/SelectUserForCall';

const PersonalCallsPanel = () => {
  const { personalCalls } = usePersonalCalls();
  const time24Hour = (date: Date) => {
    return Intl.DateTimeFormat('en-US', {
      day: 'numeric',
      month: 'short',
      hour12: true,
      hour: '2-digit',
      minute: '2-digit',
    }).format(new Date(date));
  };

  const [
    isSelectUserToCallModalOpen,
    openSelectUserToCallModal,
    closeSelectUserToCallModal,
  ] = useDialog();

  return (
    <Box position='relative'>
      <List spacing='4' paddingInline='0'>
        {_.chain(personalCalls)
          .orderBy(({ createdAt }) => createdAt, 'desc')
          .value()
          .map(({ id, otherUser, session, createdAt }) => (
            <CallItem
              key={id}
              callerId={session.CallerId}
              receiverId={session.ReceiverId}
              joinedAt={session.joinedAt}
              hasRejectedCall={session.hasRejected}
              hasMissedCall={session.hasMissed}
              isUnreachable={session.isUnreachable}
              title={getUserDisplayName(otherUser)}
              subtitle={time24Hour(createdAt)}
              avatarComponent={<UserAvatar user={otherUser} />}
            />
          ))}
      </List>
      <Flex justify='center'>
        <FloatingActionButton
        aria-label='Make Call Button'
          icon={<Icon as={MdAddIcCall} boxSize='5' />}
          onClick={openSelectUserToCallModal}
          position='fixed'
          bottom='13%'
          right={{ base: '20%', md: '80%' }}
          left={{ base: '80%', md: '60%' }}
        />
      </Flex>
      <SelectUserToCallModal
        isOpen={isSelectUserToCallModalOpen}
        onClose={closeSelectUserToCallModal}
      />
    </Box>
  );
};

export default PersonalCallsPanel;
