import { socket } from '../../config/socketIoInstance';
import { GroupMessageThreadDto } from '../../dto/group-messageThreads.dto';
import { GroupMessageThread } from '../../models/GroupMessageThread.model';
import { ValueCallback } from '../../types/common.type';
import { kNewGroupMessageThreadEvent } from './events/group-message-threads.events';

export const onNewGroupMessageThread = (
  callback: ValueCallback<GroupMessageThread>,
) =>
  socket.on(
    kNewGroupMessageThreadEvent,
    (messageThread: GroupMessageThreadDto) => {
      callback({
        ...messageThread,
        createdAt: new Date(messageThread.createdAt),
        updatedAt: new Date(messageThread.updatedAt),
        group: {
          ...messageThread.group,
          createdAt: new Date(messageThread.group.createdAt),
          updatedAt: new Date(messageThread.group.updatedAt),
        },
      });
    },
  );

export const unsubscribeNewGroupMessageThread = (
  callback: ValueCallback<GroupMessageThread>,
) => {
  socket.off(kNewGroupMessageThreadEvent, callback);
};
