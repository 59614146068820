import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
} from '@chakra-ui/react';
import { PickerProps } from 'emoji-mart';
import { useState } from 'react';

import EmojiButton from './EmojiButton';
import EmojiPicker from './EmojiPicker';

interface PopoverEmojiButtonProps {
  onSelectEmoji: PickerProps['onSelect'];
}

const PopoverEmojiButton: React.FC<PopoverEmojiButtonProps> = ({
  onSelectEmoji,
}) => {
  const [emojiButtonRef, setEmojiButtonRef] = useState<HTMLElement | null>(
    null,
  );

  return (
    <Popover boundary={emojiButtonRef || 'clippingParents'} placement='top'>
      <PopoverTrigger>
        <EmojiButton ref={setEmojiButtonRef} aria-label='emoji selector' />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody p='0' paddingInline='0'>
          <EmojiPicker
            show
            w='full'
            pickerProps={{ style: { width: '100%' }, onSelect: onSelectEmoji }}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default PopoverEmojiButton;
