import { CallSession } from 'src/models/CallSession.model';
type CallSessionUserId = CallSession['CallerId'] | CallSession['ReceiverId'];
export const getOtherUserId = (
  callSession: Pick<CallSession, 'ReceiverId' | 'CallerId'>,
  selfUserId: CallSessionUserId,
) => {
  return callSession.CallerId === selfUserId
    ? callSession.ReceiverId
    : callSession.CallerId;
};
