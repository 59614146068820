import { SimpleGrid, Text } from '@chakra-ui/react';
import _ from 'lodash';

import HeaderWrapper from '../../hoc/HeaderWrapper';
import useFeeds from '../../hooks/apis/useFeeds';
import FeedCard from './FeedCard';

const EmptyFeeds = () => {
  return (
    <Text
      w='full'
      mt='8'
      textAlign='center'
      fontSize='xl'
      color='gray.500'
      fontWeight='semibold'
    >
      There are no feeds yet.
    </Text>
  );
};

const FeedsPage = () => {
  const { feeds, ...feedsQuery } = useFeeds();

  const renderBody = () => {
    if (feedsQuery.isFetched && _.isEmpty(feeds)) {
      return <EmptyFeeds />;
    }

    return _.map(feeds, feed => <FeedCard key={feed.id} feed={feed} />);
  };

  return (
    <HeaderWrapper pageTitle='Feeds'>
      <SimpleGrid columns={1} spacing={4} mx='auto' justifyContent='center'>
        {renderBody()}
      </SimpleGrid>
    </HeaderWrapper>
  );
};

export default FeedsPage;
