import { Box, Grid, useMediaQuery } from '@chakra-ui/react';
import _ from 'lodash';
import { useEffect } from 'react';

import { ONE_MEMBER_IN_GROUP as ONE_JOINED_CALL_SESSION } from 'src/constants/group-call.constant';
import useCurrentUser from 'src/hooks/useCurrentUser';
import { CallSession } from 'src/models/CallSession.model';
import CallPageBody from 'src/pages/call/components/body/CallPageBody';
import CallUserImage from 'src/pages/call/components/body/components/CallUserImage';
import PeerVideo from 'src/pages/call/components/body/components/PeerVideo';
import { useUserMedia } from 'src/pages/call/components/footer/context/user-media/user-media.context';
import VideoStream from 'src/pages/call/components/VideoStream';
import { usePeerConnection } from 'src/pages/call/context/peer-connection/hooks/usePeerConnections';
import useCallSessions from 'src/pages/call/hooks/useCallSessions';
import { getOtherUser } from 'src/pages/call/utils/call-session.utils';
import { boxSize } from 'src/utils/chakra-theme/theme-blocks/boxSize';
import { MIN_SCREEN_HEIGHT_SIZE } from '../../../constants/call.constants';

const GroupCallBody = () => {
  const [currentUser] = useCurrentUser();
  const { localStream, isVideoMuted } = useUserMedia();
  const { peerStreams, peerVideoStatus } = usePeerConnection();
  const { callSessions, refetch } = useCallSessions();

  const [isSmallerScreen] = useMediaQuery(MIN_SCREEN_HEIGHT_SIZE);

  useEffect(() => {
    if (callSessions) {
      refetch();
    }
  }, [callSessions, refetch]);

  return (
    <>
      {callSessions.length === ONE_JOINED_CALL_SESSION ? (
        <CallPageBody />
      ) : (
        <Grid
          bgColor='black'
          gap='2'
          p='2'
          color='white'
          overflow='hidden'
          alignItems='center'
          templateColumns={
            callSessions.length === boxSize.grid.padding.size
              ? boxSize.grid.tempateColumns.size
              : boxSize.grid.tempateColumns.sizeForTwo
          }
          h={boxSize.grid.height.size}
        >
          {/* 
      - map call session and get peerStreams by session id
      - if there is streams so display that stream otherwise callUser Image will be display
       */}

          {_.map(callSessions, (session: CallSession) => {
            const stream = session && peerStreams[session.id];
            const isPeerVideoEnabled = session && peerVideoStatus[session.id];

            const otherUser =
              session && currentUser && getOtherUser(session, currentUser.id);
            return stream ? (
              <PeerVideo
                key={session.id}
                stream={stream}
                user={otherUser}
                peerVideoStatus={isPeerVideoEnabled}
                session={session}
                isSmallerScreen={isSmallerScreen}
              />
            ) : (
              session && (
                <CallUserImage
                  key={otherUser?.id}
                  receiver={otherUser}
                  joinedCall={session.joinedAt}
                />
              )
            );
          })}
          {/* TODO: We have to figure out why we are passing the muted to video stream everywhere  */}
          {currentUser && localStream && !isVideoMuted ? (
            <Box
              w='full'
              h='full'
              backgroundColor='gray.700'
              alignItems='center'
              display='flex'
              justifyContent='center'
              alignSelf='center'
            >
              {callSessions.length === ONE_JOINED_CALL_SESSION ? (
                <VideoStream
                  stream={localStream}
                  w='full'
                  h='full'
                  objectFit='cover'
                  muted
                />
              ) : (
                <VideoStream
                  stream={localStream}
                  w='full'
                  h={
                    isSmallerScreen
                      ? boxSize.videoHeight.height.md
                      : boxSize.videoHeight.height.sm
                  }
                  objectFit='cover'
                  muted
                />
              )}
            </Box>
          ) : (
            <Box w='full' h='full'>
              <CallUserImage
                receiver={currentUser}
                key={currentUser?.id}
                isCurrentUser={true}
              />
            </Box>
          )}
        </Grid>
      )}
    </>
  );
};
export default GroupCallBody;
