import React, { useState } from 'react';
import { VStack } from '@chakra-ui/layout';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalProps,
} from '@chakra-ui/modal';
import { Radio } from '@chakra-ui/radio';
import {
  Button,
  FormControl,
  FormLabel,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { useMutation } from 'react-query';

import { updatePostReview } from '../../../apis/posts.api';
import { PostReviewDto } from '../../../dto/feed-posts.dto';
import { Post } from '../../../models/Post.model';

interface PostReviewDialogProps extends Omit<ModalProps, 'children'> {
  post: Post;
}

const PostReviewDialog: React.FC<PostReviewDialogProps> = ({
  post,
  ...props
}) => {
  const [review, setReview] = useState<PostReviewDto>({
    isApproved: post.isApproved,
    reviewRemarks: post.reviewRemarks || undefined,
  });

  const showToast = useToast();
  const updateReviewMutation = useMutation(
    ['updatePostReview', post.FeedId, post.id],
    () => updatePostReview(post.FeedId, post.id, review),
    {
      onSuccess: () => {
        props.onClose();
        showToast({
          title: 'Post Review updated successfully',
          status: 'success',
        });
      },
    },
  );

  const handleRemarkChange: React.ChangeEventHandler<HTMLTextAreaElement> =
    e => {
      setReview(prev => ({ ...prev, reviewRemarks: e.target.value }));
    };

  const handleApprovalChange = (isApproved: boolean) => {
    setReview(prev => ({ ...prev, isApproved }));
  };

  return (
    <Modal isCentered {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Review post</ModalHeader>
        <ModalBody>
          <VStack align='start' spacing='6'>
            <FormControl>
              <FormLabel>Post Approval:</FormLabel>
              {/* using true and false checking because isApproved could be null which indicates no action taken */}
              <VStack align='start'>
                <Radio
                  isChecked={review.isApproved === true}
                  onChange={() => handleApprovalChange(true)}
                >
                  Approve Post
                </Radio>
                <Radio
                  isChecked={review.isApproved === false}
                  onChange={() => handleApprovalChange(false)}
                >
                  Reject Post
                </Radio>
              </VStack>
            </FormControl>

            <FormControl>
              <FormLabel>Review Remarks (optional)</FormLabel>
              <Textarea
                placeholder='Let the post author know your remarks, if any...'
                value={review.reviewRemarks}
                onChange={handleRemarkChange}
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme='blue'
            isLoading={updateReviewMutation.isLoading}
            isDisabled={review.isApproved === null}
            onClick={() => updateReviewMutation.mutate()}
          >
            Submit Post Review
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PostReviewDialog;
