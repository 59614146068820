import { usePlateEditorState } from '@udecode/plate';
import { useEditorId } from './useEditorId';

const useEditorState = () => {
  const editorId = useEditorId();
  const editor = usePlateEditorState(editorId);

  return editor;
};

export default useEditorState;
