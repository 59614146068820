import React from 'react';
import { Container, Grid } from '@chakra-ui/layout';

import ImageSliderSection from './components/ImageSliderSection';
import IconSection from './components/IconSection';
import TextSection from './components/TextSection';
import ImageSection from './components/ImageSection';

import useDashboardSections from './hooks/useDashboardSections';
import { DashboardSection } from '../../models/DashboardSection.model';

const renderDashboardSection = (dashboardSection: DashboardSection) => {
  const { type, id } = dashboardSection;
  switch (type) {
    default:
    case 'textLink':
      return <TextSection dashboardSection={dashboardSection} key={id} />;
    case 'imageSlider':
      return (
        <ImageSliderSection dashboardSection={dashboardSection} key={id} />
      );
    case 'imageLink':
      return <ImageSection dashboardSection={dashboardSection} key={id} />;
    case 'iconLink':
      return <IconSection dashboardSection={dashboardSection} key={id} />;
  }
};

const HomePage = () => {
  const [dashboardSections] = useDashboardSections();

  return (
    <Container my='4'>
      <Grid gap={8} align='start'>
        {dashboardSections.map(renderDashboardSection)}
      </Grid>
    </Container>
  );
};

export default HomePage;
