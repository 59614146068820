import { MdMic, MdMicOff } from 'react-icons/md';

import usePersonalCallSession from 'src/pages/call/hooks/usePersonalCallSession';
import { useUserMedia } from '../context/user-media/user-media.context';
import FooterIconButton from './FooterIconButton';

interface MicrophoneButtonProps {
  isMicDisabled: boolean;
}

const MicrophoneButton: React.FC<MicrophoneButtonProps> = ({isMicDisabled}) => {
  const { isAudioMuted, toggleAudio } = useUserMedia();

  return (
    <FooterIconButton
      activeIcon={MdMic}
      inactiveIcon={MdMicOff}
      onToggleClick={toggleAudio}
      isActive={!isAudioMuted}
      aria-label='Toggle microphone'
      disabled={isMicDisabled}
    />
  );
};

export default MicrophoneButton;
