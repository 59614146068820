import { Center, Flex, Icon, IconButton } from '@chakra-ui/react';
import { FC } from 'react';
import { CgTemplate } from 'react-icons/cg';
import { MdHome } from 'react-icons/md';
import { Link } from 'react-router-dom';
import CallButton from './components/CallButton';
import { HOME_PAGE_ROUTE } from 'src/routes/routeList';

import { maxWidth } from '../../constants/max-width.constant';
import MessagesButton from './components/MessagesButton';
import PagesButton from './components/PagesButton';

interface FooterProps {}

export const Footer: FC<FooterProps> = () => {
  return (
    <Center position='fixed' w='full' h='12' bottom='0' zIndex='modal'>
      <Flex
        maxW={maxWidth}
        w='full'
        justifyContent='space-around'
        alignItems='center'
        bg='gray.50'
        borderTop='1px solid'
        borderColor='gray.200'
        boxShadow='rgb(0 0 0 / 10%) 0px -10px 15px 3px, rgb(0 0 0 / 5%) 0px -4px 6px 2px'
      >
        <IconButton
          variant='ghost'
          w='full'
          borderRight='1px solid'
          borderColor='gray.200'
          _focus={{
            boxShadow: 'none',
          }}
          _hover={{
            background: 'none',
          }}
          size='lg'
          as={Link}
          to={HOME_PAGE_ROUTE}
          aria-label='Go to home'
          icon={<Icon as={MdHome} boxSize='7' />}
        />
        <MessagesButton />
        <CallButton />
        <IconButton
          aria-label='Message'
          variant='ghost'
          w='full'
          borderRight='1px solid'
          borderColor='gray.200'
          _focus={{
            boxShadow: 'none',
          }}
          _hover={{
            background: 'none',
          }}
          size='lg'
        >
          <Link to='/feeds'>
            <CgTemplate fontSize='24px' />
          </Link>
        </IconButton>
        <PagesButton />
      </Flex>
    </Center>
  );
};
