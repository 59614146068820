import React from 'react';
import useDashboardSectionLinks from '../../hooks/useDashboardSectionLinks';

import { BaseSection } from '../BaseSection';
import { ImageSliderCarousel } from './components';
import { ImageSliderSectionProps } from './types';

const ImageSliderSection: React.FC<ImageSliderSectionProps> = ({
    dashboardSection,
}) => {
    const [dashbaordSectionLinks] = useDashboardSectionLinks({
        dashboardSectionId: dashboardSection.id,
    });

    const body = (
        <ImageSliderCarousel
            imgSrcList={dashbaordSectionLinks.map(link => {
                return {
                    src: link.imageUrl,
                    caption: link.linkText,
                    externalLink: link.isExternal,
                    linkUrl: link.linkUrl,
                };
            })}
        />
    );

    return <BaseSection title={dashboardSection.title} body={body} />;
};

export default ImageSliderSection;
