import { Button } from '@chakra-ui/button';
import { Box, BoxProps, Center, Grid } from '@chakra-ui/layout';
import _ from 'lodash';
import { useRef } from 'react';
import { UseInfiniteQueryResult } from 'react-query';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';

import { Post } from '../../../models/Post.model';
import PostCard from './PostCard';

type PostListProps = Pick<
  UseInfiniteQueryResult,
  'hasNextPage' | 'isFetching' | 'isFetchingNextPage' | 'fetchNextPage'
> &
  BoxProps & {
    posts: Post[];
    showPostApprovalStatus?: boolean;
  };

const PostList: React.FC<PostListProps> = ({
  posts,
  showPostApprovalStatus,
  hasNextPage,
  isFetching,
  isFetchingNextPage,
  fetchNextPage,
  ...props
}) => {
  const loadMoreButtonRef = useRef<HTMLButtonElement>(null);
  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  if (posts.length === 0) {
    return (
      <Center h="full" color="gray.500" fontWeight="500" fontSize="lg">
        No posts to show
      </Center>
    );
  }

  return (
    <>
      <Grid
        templateColumns={{
          base: 'minmax(250px, 1fr)',
        }}
        justifyContent="center"
        background="gray.100"
        {...props}
      >
        {_.map(posts, (post, index) => {
          const marginTopProp: Pick<BoxProps, 'mt'> = {};
          if (index === 0) {
            marginTopProp.mt = '0';
          }

          return (
            <PostCard
              key={post.id}
              post={post}
              showPostApprovalStatus={showPostApprovalStatus}
              {...marginTopProp}
            />
          );
        })}
      </Grid>
      <Box pb="4">
        <Button
          colorScheme="blue"
          variant="ghost"
          ref={loadMoreButtonRef}
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage}
          isLoading={isFetchingNextPage}
          loadingText="Loading more..."
        >
          {hasNextPage ? 'Load Newer' : 'Nothing more to load'}
        </Button>
        <div>
          {isFetching && !isFetchingNextPage ? 'Background Updating...' : null}
        </div>
      </Box>
    </>
  );
};

export default PostList;
