import { Box, Center } from '@chakra-ui/react';
import _ from 'lodash';

import useCurrentUser from 'src/hooks/useCurrentUser';
import { usePeerConnection } from '../../context/peer-connection/hooks/usePeerConnections';

import { getOtherUser } from '../../utils/call-session.utils';
import CallUserImage from './components/CallUserImage';
import EndCallButton from './components/EndCallButton';
import useCallSessions from '../../hooks/useCallSessions';
import PeerVideo from './components/PeerVideo';
import useCall from '../../hooks/useCall';
import { boxSize } from 'src/utils/chakra-theme/theme-blocks/boxSize';

interface CallPageBodyProps {}

const CallPageBody: React.FC<CallPageBodyProps> = () => {
  const [currentUser] = useCurrentUser();
  const { call } = useCall();

  const { callSessions } = useCallSessions();
  const session = _.head(callSessions);
  const otherUser =
    session && currentUser && getOtherUser(session, currentUser.id);

  const { peerStreams, peerVideoStatus } = usePeerConnection();

  const firstStream = session && peerStreams[session.id];

  const isGroupCall = call?.isGroupCall;

  const isPeerVideoEnabled = session && peerVideoStatus[session.id];

  return (
    <Box
      position='relative'
      pt={isGroupCall ? 'none' : boxSize.box.paddingTop.size}
      pb={isGroupCall ? boxSize.box.paddingBottom.size : 'none'}
      h={isGroupCall ? boxSize.box.height.size : boxSize.box.height.size}
      w='full'
    >
      {firstStream ? (
        <PeerVideo
          stream={firstStream}
          user={otherUser}
          peerVideoStatus={isPeerVideoEnabled}
        />
      ) : (
        <CallUserImage receiver={otherUser} />
      )}
      {!isGroupCall && (
        <Center position='absolute' bottom='28' right='0' w='full'>
          <EndCallButton />
        </Center>
      )}
    </Box>
  );
};

export default CallPageBody;
